import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080 907" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  >
 
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1081.000000,674.000000 
	C1081.000000,751.990234 1081.000000,829.980408 1081.000000,907.978027 
	C721.054932,907.985352 361.109863,907.985352 1.082393,907.985352 
	C1.082393,605.759521 1.082393,303.519043 1.082393,1.000000 
	C357.029327,1.000000 713.058716,1.000000 1069.534912,1.468586 
	C1069.979614,222.325745 1069.977539,442.714325 1069.975586,663.102905 
	C1069.975586,674.000000 1069.975586,674.000000 1081.000000,674.000000 
M756.682373,643.931824 
	C757.454895,642.955383 758.227417,641.978882 759.689209,640.895325 
	C760.126099,639.930237 760.563049,638.965088 761.000000,637.208496 
	C760.334106,635.804871 759.668274,634.401245 759.000610,632.164917 
	C759.001221,630.777405 759.001831,629.389954 759.621704,627.680969 
	C760.828186,625.095459 757.354370,614.655823 754.876160,613.610657 
	C754.330078,613.380432 753.583374,613.394958 752.990540,613.542969 
	C748.590576,614.641541 747.891724,613.133484 748.640869,608.851868 
	C749.058350,606.465942 747.554932,601.685486 746.286743,601.455078 
	C742.710632,600.805603 741.927856,598.425720 740.449585,596.079285 
	C739.659973,594.825867 738.172546,594.012146 736.927734,592.295837 
	C735.198792,589.783997 733.469849,587.272217 731.740906,584.760376 
	C731.211731,585.185852 730.682556,585.611328 730.153381,586.036804 
	C732.524780,583.024841 734.896179,580.012939 737.556213,576.634338 
	C735.855530,575.837036 734.926025,574.983521 734.157837,575.109924 
	C729.647766,575.852295 728.443787,573.071106 727.709473,569.525879 
	C726.476318,563.571777 723.500427,557.533569 728.947144,552.026611 
	C729.483093,551.484741 729.737915,550.540283 729.864563,549.741699 
	C730.234070,547.410583 729.384521,544.669128 732.963501,543.809204 
	C735.309998,543.245361 737.537415,541.050171 739.725830,541.177185 
	C746.262146,541.556396 752.841553,542.311462 759.235779,543.696899 
	C765.156128,544.979675 767.950378,543.413452 767.994019,537.470520 
	C768.024536,533.311340 768.000000,529.151794 768.000000,524.992371 
	C768.000000,514.750793 767.999573,514.755066 758.201111,513.785706 
	C757.951233,513.761047 757.736328,513.382629 757.371582,513.045959 
	C758.233765,508.561859 759.415710,504.031219 759.924500,499.426239 
	C760.930420,490.321655 760.490784,490.000214 751.137451,490.000000 
	C749.473694,489.999969 747.809875,490.009155 746.146179,489.997375 
	C743.807861,489.980804 741.297546,490.306122 741.016846,486.844635 
	C740.768982,483.787079 741.720215,481.437836 744.948730,480.935272 
	C748.360413,480.404205 751.878113,480.517456 755.268311,479.902130 
	C756.890442,479.607727 758.325134,478.280457 759.844727,477.420990 
	C758.256104,476.281647 756.779541,474.404663 755.057617,474.143280 
	C750.170654,473.401428 745.113831,473.712799 740.257935,472.852783 
	C736.414551,472.172089 731.121704,472.452667 731.802734,465.716003 
	C731.917297,464.583282 729.982544,463.243378 728.924927,461.300751 
	C727.281677,458.199860 725.638367,455.098938 724.779968,451.892853 
	C725.463501,451.238556 726.740479,450.581116 726.737000,449.930450 
	C726.708618,444.603271 728.512451,439.053802 724.009888,434.310181 
	C721.064697,431.207336 721.308655,426.443665 724.400940,425.335571 
	C728.105957,424.007874 726.841980,421.487732 726.845886,419.278870 
	C726.847229,418.538757 725.289673,417.045593 724.877930,417.184113 
	C723.029846,417.805939 721.337830,418.891418 719.155334,420.037628 
	C718.024536,418.369324 716.342224,416.132202 714.928467,413.736511 
	C713.340088,411.044800 714.048767,409.386566 717.373352,408.977570 
	C719.567322,408.707703 721.716309,408.072327 723.885498,407.600616 
	C723.989929,407.126404 724.094421,406.652191 724.198853,406.177979 
	C723.015015,405.438507 721.903137,404.248840 720.633606,404.046021 
	C717.160156,403.491119 713.243530,404.191803 710.204956,402.828888 
	C706.643066,401.231262 703.618835,401.380920 700.227417,402.397339 
	C698.175415,403.012299 696.077393,403.473419 693.467224,403.762115 
	C692.976501,403.175385 692.485840,402.588654 692.083557,401.246765 
	C692.834351,398.925446 693.717102,396.633606 694.247559,394.262939 
	C694.388428,393.633392 693.446960,392.761719 692.563049,391.783234 
	C692.289062,391.582672 692.101379,391.320374 691.929993,390.299469 
	C691.349487,389.013123 690.768921,387.726807 690.651794,387.467194 
	C692.047607,384.819946 693.870056,382.984772 693.717102,381.332458 
	C693.344604,377.309113 692.055725,373.370636 691.043823,369.000000 
	C689.127441,369.000000 687.148560,369.064453 685.175110,368.988434 
	C677.950684,368.710083 670.730042,368.239502 663.503784,368.135254 
	C659.552673,368.078278 658.834229,366.041779 658.955933,362.703094 
	C659.097656,358.814728 660.057434,354.511627 655.260132,352.574005 
	C657.487366,346.251038 659.779480,340.260925 656.200623,334.182007 
	C655.595764,333.984344 654.990906,333.786652 654.385986,333.588989 
	C652.855652,336.930054 651.325256,340.271118 649.538025,344.172852 
	C647.599487,343.147430 645.876526,342.235992 644.559265,341.539185 
	C646.189758,339.301178 647.433411,337.594147 648.677002,335.887146 
	C646.551147,335.257965 644.462402,334.321808 642.292236,334.059692 
	C636.743591,333.389557 631.158691,333.019531 625.398132,332.512756 
	C625.254089,331.416077 625.322083,330.376465 624.963928,329.513733 
	C624.071777,327.364258 622.985718,325.295288 621.977478,323.194000 
	C620.245605,324.957642 618.309143,326.575653 616.893494,328.564392 
	C616.401611,329.255280 617.122559,330.884125 617.426025,332.049042 
	C617.889221,333.827332 618.470947,335.574768 619.002319,337.335266 
	C618.588989,337.562958 618.175598,337.790649 617.762207,338.018341 
	C614.514343,334.113678 606.857117,334.469818 607.384094,326.115021 
	C608.910583,327.238831 610.019470,328.055237 611.448975,329.107635 
	C613.417786,324.476349 615.913696,320.374573 616.906738,315.936493 
	C619.857239,302.750305 622.467407,289.471619 624.584167,276.129730 
	C624.984436,273.606995 622.755554,270.485352 621.288086,267.896057 
	C619.609619,264.934509 617.578064,267.272644 615.454834,267.680054 
	C613.336548,268.086517 610.900085,266.904083 608.617065,266.359894 
	C606.916321,265.954529 605.241089,265.074707 603.548828,265.060028 
	C590.700806,264.948486 577.851440,264.998138 564.916199,264.352905 
	C564.267700,263.905640 563.619202,263.458374 562.542053,262.520294 
	C558.904724,262.261017 555.150269,262.477234 551.660583,261.618317 
	C547.587952,260.615906 543.823181,258.402130 539.770996,257.259583 
	C537.854126,256.719086 535.485657,257.112122 533.483887,257.666992 
	C527.618469,259.292877 521.834412,261.212250 515.157654,263.003357 
	C513.438416,263.002228 511.719208,263.001099 509.212952,262.998810 
	C505.277802,261.856476 501.449738,262.338531 497.379242,262.693787 
	C491.702179,263.189240 485.103363,265.209747 480.446869,258.707581 
	C479.674774,257.629517 474.947388,258.351532 472.761078,259.546539 
	C468.916290,261.648071 465.826233,265.111633 462.016632,267.303528 
	C459.929626,268.504303 456.968506,268.088318 454.494263,268.748718 
	C452.908783,269.171936 451.492340,270.228546 449.901947,271.801056 
	C450.105896,274.736542 450.309845,277.672028 450.513763,280.607513 
	C452.492279,279.556244 454.464111,278.492004 456.457245,277.469269 
	C456.722687,277.333099 457.096191,277.407532 457.420135,277.391510 
	C460.955444,281.786987 460.420929,283.331940 454.716003,285.207642 
	C448.150208,287.366394 448.269043,287.602173 449.897736,294.280182 
	C450.281616,295.854309 448.591553,298.626678 447.056000,299.811676 
	C444.839630,301.522064 441.893005,302.286255 439.002777,303.567291 
	C437.808319,300.288055 435.450714,298.626221 431.089386,301.623749 
	C429.891083,302.447327 427.317230,302.209442 425.827759,301.503021 
	C420.089050,298.781464 420.157501,298.637146 417.140350,304.298706 
	C413.835480,310.500183 414.266418,311.746948 420.863312,313.981628 
	C420.094727,314.600372 419.334839,314.958527 418.958252,315.557648 
	C416.934326,318.777161 414.727295,321.928833 413.150726,325.364258 
	C412.234283,327.361237 412.382324,329.846741 411.682770,332.567047 
	C411.128998,332.731934 410.575256,332.896820 409.700226,332.583679 
	C409.126160,333.024353 408.552124,333.465027 407.263977,333.973236 
	C406.547791,334.010590 405.831604,334.047943 404.290039,334.021729 
	C403.193359,334.015289 402.096680,334.008850 400.592133,333.499756 
	C398.989685,334.169373 397.432373,335.236176 395.775208,335.424561 
	C392.488464,335.798187 387.703033,337.101044 386.122711,335.487457 
	C382.641693,331.933105 380.152740,332.687592 376.640350,334.555298 
	C372.308533,336.858765 367.937683,338.658112 362.803741,335.972717 
	C360.484222,334.759399 357.423065,334.963989 354.347260,334.468719 
	C355.143372,340.387421 352.939301,344.877380 350.276245,345.687958 
	C348.392334,346.261475 346.850586,347.959076 345.156097,349.154907 
	C346.758514,350.446411 348.381287,351.713684 349.956085,353.038025 
	C350.979340,353.898468 352.736633,354.867371 352.709167,355.741974 
	C352.676514,356.782501 351.263153,358.166718 350.122070,358.686768 
	C348.719635,359.325897 346.980408,359.225922 345.650482,359.409119 
	C345.220581,362.837799 345.794403,367.647980 344.257843,368.458771 
	C341.508331,369.909576 337.487457,368.951508 333.998047,368.999146 
	C333.665436,369.003693 333.332672,368.999908 332.094086,368.999390 
	C329.835266,369.004333 327.408722,368.500275 325.369568,369.172028 
	C323.676453,369.729767 322.439209,371.671417 320.426910,373.427185 
	C319.133789,374.284546 317.888123,375.779266 316.538635,375.879761 
	C312.487579,376.181488 310.712982,378.846832 310.652191,382.070648 
	C310.581543,385.815308 311.954834,389.564545 312.245697,393.346863 
	C312.430023,395.743164 312.525421,398.708557 311.307953,400.498749 
	C309.981628,402.449005 307.091370,403.335663 306.017548,403.992218 
	C301.872314,403.126160 297.552399,400.830475 295.694275,402.109894 
	C291.060516,405.300629 286.632416,404.039978 281.990417,403.886536 
	C280.672180,403.842957 278.591095,403.901489 278.181702,404.643036 
	C277.542969,405.799896 277.490295,407.886383 278.142517,409.039978 
	C280.382111,413.001129 281.518616,416.761902 279.239105,421.178314 
	C278.567902,422.478790 279.220520,424.434601 279.161285,426.086273 
	C279.092194,428.012787 279.471649,430.220001 278.669891,431.807007 
	C276.055573,436.981873 276.270813,441.715332 279.082001,446.772644 
	C281.177063,450.541534 283.171356,454.674133 280.002411,459.751984 
	C279.998749,460.501831 279.995087,461.251648 279.363831,462.327881 
	C278.574677,464.551514 277.785492,466.775146 276.172058,468.999725 
	C273.700348,469.591064 271.228516,470.681671 268.756989,470.680969 
	C261.057220,470.678864 253.339798,469.895264 245.670197,470.264740 
	C243.730209,470.358185 241.536713,473.372192 240.233292,475.514740 
	C239.413284,476.862671 239.960251,479.088379 240.017944,480.916382 
	C240.323853,490.612000 240.669022,500.306396 241.000610,510.900940 
	C241.000610,513.600647 241.000610,516.300293 241.000061,519.888489 
	C241.045303,521.873718 241.031082,523.862366 241.147095,525.843445 
	C241.478653,531.506409 241.806808,537.171021 242.279205,542.822998 
	C242.363297,543.829285 243.142441,544.773926 243.583176,545.754822 
	C244.395554,547.562927 246.044693,549.470154 245.857819,551.172852 
	C244.864929,560.219971 248.646011,569.593811 242.448563,578.465759 
	C240.015839,581.948242 241.370651,588.076599 241.000610,593.856934 
	C241.000610,595.571289 241.000610,597.285645 241.082962,599.641602 
	C241.721970,600.094360 242.360992,600.547180 243.000000,601.898987 
	C242.708710,604.690735 242.232697,607.477844 242.163193,610.275085 
	C241.999954,616.846436 238.173370,624.757935 248.264114,627.833740 
	C248.967865,628.048218 249.483261,628.939514 250.043930,629.554688 
	C253.093033,632.900146 252.558502,633.912598 248.613495,636.112183 
	C245.760254,637.703064 242.984512,640.100830 241.310089,642.834473 
	C240.559616,644.059631 242.347122,648.691467 243.401367,648.841736 
	C247.444595,649.418030 253.928833,650.351807 255.323456,648.304993 
	C258.036774,644.322876 260.809906,644.201660 265.450928,644.908325 
	C265.033417,641.782776 265.423889,637.517273 264.238251,637.021606 
	C257.622528,634.255676 263.210144,629.712280 262.395752,626.243103 
	C262.326935,625.950012 266.041748,624.768677 267.999725,624.799927 
	C268.000214,625.866638 268.000702,626.933289 268.083130,628.641846 
	C268.722504,629.094543 269.361847,629.547302 270.724304,630.011902 
	C272.447968,631.342773 274.171631,632.673706 275.993378,634.468079 
	C275.993378,634.468079 276.029785,634.940491 275.962921,635.591553 
	C275.641968,636.394348 275.320984,637.197205 274.358368,638.082520 
	C273.905579,638.721680 273.452789,639.360840 272.998749,640.665833 
	C272.998352,641.109741 272.997955,641.553650 272.400238,642.076050 
	C272.081116,642.483826 271.761993,642.891663 271.442871,643.299500 
	C271.961090,643.533813 272.479309,643.768127 273.094910,644.667480 
	C274.395050,645.779968 275.695190,646.892395 276.996918,648.837036 
	C276.997955,650.224670 276.998962,651.612366 276.413788,653.571594 
	C276.485046,656.824036 276.384155,660.091492 276.664734,663.325745 
	C277.185822,669.332825 281.091736,675.291504 276.602020,681.435608 
	C276.278534,681.878235 278.901337,685.788635 280.012604,685.721985 
	C283.552795,685.509949 284.954224,687.254395 285.997498,690.665833 
	C285.997528,691.109741 285.997559,691.553650 285.329468,692.098511 
	C285.552155,693.066467 285.774841,694.034485 286.148834,695.746765 
	C288.659576,696.193848 291.170319,696.640991 292.776581,696.927002 
	C292.139801,700.943359 291.493164,705.021790 290.846527,709.100281 
	C293.272858,709.100281 295.699188,709.100281 298.125549,709.100281 
	C300.658875,711.066833 303.632141,712.407776 306.677917,713.556580 
	C308.390137,714.202454 310.222046,714.530945 312.061340,715.680542 
	C312.708801,717.120361 313.356232,718.560181 313.999451,720.835693 
	C313.999634,724.223816 313.999817,727.611877 314.454529,731.537170 
	C316.636353,733.023987 318.818176,734.510803 321.000000,736.000000 
	C321.000000,736.000000 321.002441,736.000000 321.318390,736.636902 
	C323.879578,740.262146 326.304596,743.995911 329.065460,747.462158 
	C330.258118,748.959473 332.042664,750.079407 333.736237,751.065125 
	C338.013306,753.554688 341.447845,750.804443 345.296021,749.593811 
	C346.546021,750.713684 347.795990,751.833557 349.106140,753.690491 
	C349.447449,755.117188 349.788757,756.543823 349.475525,758.272461 
	C346.455139,761.281677 344.386139,764.973755 346.804901,768.849487 
	C348.474792,771.525269 348.935669,773.557861 348.189087,776.556396 
	C347.359833,779.886780 347.979767,782.311096 352.792114,781.202026 
	C356.258514,780.403076 360.063080,780.816406 363.694305,781.045593 
	C369.563354,781.416077 373.774231,778.845276 377.110443,774.298096 
	C377.536865,773.716919 378.360535,773.427185 379.646698,773.282288 
	C381.431122,773.854858 383.215546,774.427429 385.083893,775.752991 
	C385.349152,776.538574 385.658569,777.312439 385.872131,778.111816 
	C386.890259,781.923035 391.492096,785.402832 394.489349,784.602478 
	C398.827454,783.444092 401.780426,778.445129 400.771881,774.314026 
	C400.617676,773.682373 401.553894,772.784485 402.066467,772.699402 
	C403.423523,773.766907 404.780579,774.834412 406.853973,775.921875 
	C407.569916,775.954224 408.285858,775.986633 408.984161,776.745361 
	C408.538696,777.550964 407.799042,778.325684 407.710632,779.168762 
	C407.539978,780.795715 407.736969,782.461304 407.781525,784.110840 
	C409.109741,783.574036 410.435120,783.030151 411.766907,782.502441 
	C413.171051,781.945984 414.571625,781.378235 415.992126,780.866333 
	C416.988251,780.507385 418.015167,780.233948 419.679260,780.005920 
	C420.110260,780.079651 420.541290,780.153320 421.122498,780.865540 
	C422.381012,782.508728 423.639526,784.151917 424.947937,785.860229 
	C427.101288,785.179749 429.060516,784.560669 431.819977,783.947449 
	C433.886780,784.297485 435.953552,784.647522 438.148285,785.721497 
	C443.247284,789.522705 448.754730,791.180420 455.655212,788.984680 
	C456.088898,789.001709 456.522552,789.018799 457.103821,789.729980 
	C463.069244,794.905273 469.925507,794.553650 477.902771,793.000671 
	C481.005127,792.885315 484.107452,792.769958 488.114838,792.735657 
	C498.003387,792.700134 507.891968,792.664551 518.523499,792.960571 
	C519.326172,793.026733 520.128845,793.092834 521.178223,793.920532 
	C522.208618,794.273804 523.265381,794.994080 524.265137,794.921997 
	C531.805542,794.378479 539.360474,793.871521 546.846191,792.870056 
	C548.389771,792.663513 549.662354,790.431580 551.907043,789.074280 
	C553.606934,789.045105 555.306885,789.015869 557.374939,789.492493 
	C558.275696,789.084534 559.176453,788.676636 560.803528,788.147400 
	C561.537598,788.094116 562.271729,788.040894 563.442810,788.533081 
	C566.102112,788.687073 568.831055,788.955139 569.864136,785.053040 
	C571.498413,784.964722 573.132751,784.876404 575.669495,784.935181 
	C579.762268,785.199768 583.903870,785.559021 585.696045,780.039551 
	C586.130798,780.024231 586.565552,780.008972 587.513672,780.564148 
	C595.297424,782.172974 600.578796,776.298828 606.825378,773.648438 
	C610.449036,772.110901 612.889893,771.470459 616.249939,773.537292 
	C617.664246,774.407288 619.813904,774.269714 621.606995,774.173645 
	C621.955566,774.154907 622.636475,771.398193 622.422668,771.283264 
	C621.070374,770.556091 619.501160,770.240295 618.025452,769.725891 
	C617.795105,769.645630 617.676575,769.244568 617.774170,769.389465 
	C618.581970,767.400574 619.279297,765.683777 620.551147,763.681580 
	C621.357422,763.416199 622.163757,763.150879 623.444336,763.481445 
	C631.657043,763.943176 636.972351,759.541382 641.698486,752.899780 
	C642.135681,751.951294 642.572937,751.002869 643.594971,749.928528 
	C643.992126,749.898132 644.389343,749.867798 645.527161,750.208679 
	C648.683167,750.136719 651.839111,750.064697 655.743042,749.918579 
	C661.495728,749.855103 667.543030,750.776123 671.622742,745.123169 
	C671.749329,744.947693 672.256653,744.995300 672.579834,745.021484 
	C675.710144,745.275574 677.120178,743.890625 677.128113,740.708740 
	C677.130249,739.861694 678.130798,739.017090 679.708740,736.555542 
	C679.869141,745.949158 685.317139,745.245911 690.502869,745.000977 
	C690.667419,744.993225 690.832642,745.000000 691.703247,744.923706 
	C692.086121,743.509338 692.790833,742.095520 692.793152,740.680481 
	C692.800781,735.925903 691.234741,730.712952 692.688110,726.511719 
	C695.666138,717.903076 713.918579,713.879150 721.819580,719.081360 
	C723.333374,720.078064 726.167297,720.635986 727.510803,719.863586 
	C728.737549,719.158386 728.992615,716.406433 729.259521,714.498352 
	C729.484009,712.893311 728.951355,711.196899 729.029358,709.554077 
	C729.272339,704.434082 730.060791,699.304932 729.872192,694.207642 
	C729.585449,686.459290 728.656311,678.734680 728.000000,670.200073 
	C728.000793,669.133362 728.001587,668.066711 728.503723,666.584473 
	C728.016113,663.564880 726.184631,660.531921 728.839172,657.540527 
	C729.136292,657.205566 728.306946,655.871033 728.000000,654.250000 
	C728.000793,653.500000 728.001587,652.750000 728.697266,651.876709 
	C730.464844,649.917786 732.232422,647.958862 734.666626,646.000000 
	C735.111084,646.000793 735.555542,646.001648 736.414062,646.504395 
	C740.586304,645.760620 744.647888,642.254883 749.799927,646.000000 
	C750.866638,646.000854 751.933289,646.001648 753.343201,646.459290 
	C753.895447,645.972839 754.447754,645.486450 755.217041,644.561279 
	C755.417053,644.287781 755.678894,644.100647 756.682373,643.931824 
M841.502625,620.923645 
	C853.636292,621.229919 865.769409,621.557129 877.903931,621.826599 
	C880.631165,621.887268 887.175232,616.659546 887.544861,614.190308 
	C887.635681,613.583923 887.589600,612.591248 887.220947,612.338501 
	C883.533325,609.810791 885.018738,606.050232 884.883301,602.733887 
	C884.647034,596.943848 882.567261,595.018921 876.901367,595.181702 
	C863.935303,595.554199 850.958862,595.635315 838.000183,596.166138 
	C833.160645,596.364380 831.677063,594.706970 831.718750,589.903870 
	C831.921082,566.573059 831.812805,543.239563 831.808716,519.906982 
	C831.806824,509.144653 827.986816,505.837311 817.535217,507.464142 
	C816.626160,507.605652 815.606567,507.605530 814.739136,507.340881 
	C811.957153,506.492065 802.802429,510.437897 801.845886,513.118225 
	C801.618713,513.754761 801.992004,514.617615 802.128296,515.369324 
	C802.446777,517.125977 803.047607,518.874084 803.075806,520.633057 
	C803.186890,527.560791 803.120056,534.491272 803.120850,541.420776 
	C803.123596,564.919739 803.100708,588.418823 803.143738,611.917725 
	C803.159790,620.702026 804.891968,622.188721 813.796509,621.822327 
	C822.696350,621.456055 831.601562,621.217773 841.502625,620.923645 
M347.329803,339.553741 
	C349.069427,339.211945 350.809052,338.870178 352.548676,338.528412 
	C351.959564,337.728821 350.942749,336.185455 350.845520,336.241364 
	C349.415192,337.063782 348.107239,338.099060 347.329803,339.553741 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M1081.000000,673.531372 
	C1069.975586,674.000000 1069.975586,674.000000 1069.975586,663.102905 
	C1069.977539,442.714325 1069.979614,222.325745 1069.990723,1.468586 
	C1073.664307,1.000000 1077.328613,1.000000 1080.996460,1.000000 
	C1081.000000,225.020889 1081.000000,449.041779 1081.000000,673.531372 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M728.994751,461.999207 
	C729.982544,463.243378 731.917297,464.583282 731.802734,465.716003 
	C731.121704,472.452667 736.414551,472.172089 740.257935,472.852783 
	C745.113831,473.712799 750.170654,473.401428 755.057617,474.143280 
	C756.779541,474.404663 758.256104,476.281647 759.844727,477.421021 
	C758.325134,478.280457 756.890442,479.607727 755.268311,479.902130 
	C751.878113,480.517456 748.360413,480.404205 744.948730,480.935272 
	C741.720215,481.437836 740.768982,483.787079 741.016846,486.844635 
	C741.297546,490.306122 743.807861,489.980804 746.146179,489.997375 
	C747.809875,490.009155 749.473694,489.999969 751.137451,490.000000 
	C760.490784,490.000214 760.930420,490.321655 759.924500,499.426239 
	C759.415710,504.031219 758.233765,508.561859 757.371582,513.045959 
	C757.736328,513.382629 757.951233,513.761047 758.201111,513.785706 
	C767.999573,514.755066 768.000000,514.750793 768.000000,524.992371 
	C768.000000,529.151794 768.024536,533.311340 767.994019,537.470520 
	C767.950378,543.413452 765.156128,544.979675 759.235779,543.696899 
	C752.841553,542.311462 746.262146,541.556396 739.725830,541.177185 
	C737.537415,541.050171 735.309998,543.245361 732.963501,543.809204 
	C729.384521,544.669128 730.234070,547.410583 729.864563,549.741699 
	C729.737915,550.540283 729.483093,551.484741 728.947144,552.026611 
	C723.500427,557.533569 726.476318,563.571777 727.709473,569.525879 
	C728.443787,573.071106 729.647766,575.852295 734.157837,575.109924 
	C734.926025,574.983521 735.855530,575.837036 737.556213,576.634338 
	C734.896179,580.012939 732.524780,583.024841 730.153381,586.036804 
	C730.682556,585.611328 731.211731,585.185852 731.740906,584.760376 
	C733.469849,587.272217 735.198792,589.783997 736.592468,592.643616 
	C734.630920,591.973145 733.019409,590.930176 731.374573,589.942871 
	C729.300293,588.697815 726.054260,588.380737 726.643982,591.121887 
	C727.442810,594.834656 725.270630,596.489807 723.750488,598.935181 
	C723.517212,598.960876 723.051819,599.022522 722.912354,598.805054 
	C722.518372,598.359314 722.263794,598.130981 721.957031,597.557007 
	C720.886169,596.856873 719.867554,596.502502 718.697021,595.999268 
	C718.545044,595.850464 718.418274,595.444458 718.646729,595.148682 
	C719.209839,593.966125 719.544495,593.079346 719.913696,591.784058 
	C719.129578,585.347473 718.417725,584.861755 710.515686,584.982056 
	C709.857483,584.561890 709.433289,584.258545 708.947632,583.604187 
	C708.259888,581.502136 707.633606,579.751038 707.355225,578.057373 
	C708.819214,579.278564 709.747253,580.792725 711.098694,581.518372 
	C712.832092,582.449158 714.904114,583.111267 716.834167,583.121155 
	C717.527527,583.124695 719.100769,580.382568 718.752625,579.797180 
	C718.005859,578.541199 716.204651,577.954102 714.956543,576.943359 
	C713.464233,575.734985 712.008667,574.454041 710.700317,573.052307 
	C708.974121,571.202942 707.406372,569.205627 705.770569,567.271912 
	C705.180359,570.180664 704.590210,573.089417 703.668884,575.998169 
	C702.897156,575.985657 702.456543,575.973145 702.013672,575.518799 
	C701.805969,572.635254 701.763611,570.166138 701.359375,567.757874 
	C700.624084,563.376709 702.559998,560.662292 707.149902,559.996765 
	C711.255127,559.401489 711.832520,557.696655 709.689209,553.998230 
	C708.452759,551.864685 707.876099,549.348755 707.001221,547.002441 
	C707.000000,547.000000 707.002808,547.002441 707.344971,547.052734 
	C708.851868,548.344849 710.016541,549.586731 711.388062,551.049133 
	C713.460083,548.073425 714.021179,545.943787 710.040405,544.624146 
	C710.426025,542.533325 710.768738,540.818420 711.471313,539.075012 
	C712.552429,539.029419 713.273804,539.012268 714.386780,538.997559 
	C716.204590,539.642395 717.583984,540.622925 719.070496,540.828430 
	C720.454895,541.019714 722.978638,540.761841 723.237549,540.055420 
	C725.131226,534.888123 730.428650,533.570984 734.011353,533.560913 
	C737.635864,533.550842 743.819214,531.348450 745.797058,537.927856 
	C745.980103,538.536987 747.809509,538.909607 748.888794,538.944092 
	C752.686096,539.065430 756.489685,538.991333 760.711731,538.991333 
	C761.640686,536.629883 760.787354,534.818359 757.651062,534.095825 
	C749.408020,532.196716 741.835999,527.200928 732.831970,528.736694 
	C732.281860,528.830505 731.618164,528.257935 731.004028,527.747192 
	C731.000732,527.499023 730.997559,527.002747 730.956421,526.622803 
	C730.608521,524.493591 730.301819,522.744324 729.999023,520.669006 
	C730.015747,519.908813 730.028564,519.474670 730.370483,518.999878 
	C732.132690,517.976624 733.565796,516.994019 734.991577,516.404236 
	C734.711243,518.566528 733.898499,520.482727 734.327698,522.061340 
	C734.702637,523.440857 737.051208,525.632019 737.530029,525.400513 
	C739.538574,524.429626 741.172180,522.683044 742.943115,521.220581 
	C741.628723,520.147034 740.314392,519.073547 738.994995,517.646240 
	C738.328247,516.528320 737.666565,515.764160 737.001953,514.999939 
	C736.998962,514.999878 736.987793,514.995117 736.985596,514.622925 
	C735.649780,511.840698 734.316223,509.430725 733.316650,506.964783 
	C733.955750,506.213379 734.260925,505.517975 734.566101,504.822540 
	C734.201782,504.694214 733.837402,504.565857 733.473083,504.437531 
	C733.309021,505.297485 733.144897,506.157440 732.616821,506.977966 
	C730.836792,506.621094 729.420837,506.303650 727.919678,505.602844 
	C717.881714,503.013885 708.027649,499.510834 698.326843,502.341064 
	C700.255310,504.934113 702.122803,507.445190 703.596191,510.017273 
	C700.806580,510.389191 698.410889,510.700104 695.708130,510.860962 
	C694.076538,509.470825 692.923035,507.538574 691.386108,507.159119 
	C689.285583,506.640564 686.014893,506.432983 684.813660,507.645447 
	C682.487244,509.993500 685.346558,511.232635 687.245850,512.100708 
	C691.193359,513.904907 691.440979,517.147766 690.873840,520.781189 
	C690.596985,522.554688 690.318481,524.327942 689.989380,526.455139 
	C684.313416,532.891296 678.688843,538.973572 672.753235,544.767151 
	C672.260925,535.169006 672.003052,525.860168 671.935974,516.549927 
	C671.909058,512.801941 671.101501,510.248627 666.394470,511.205078 
	C666.213806,511.188263 666.017212,510.883331 666.025818,510.503876 
	C664.132141,508.750946 662.169983,506.159058 660.339539,506.248932 
	C656.077576,506.458191 650.861328,504.799591 647.787354,510.242249 
	C646.716614,510.653198 645.849060,510.800201 644.785706,510.704102 
	C640.383911,512.544189 640.208862,513.917175 643.824585,517.499634 
	C643.842346,523.625122 643.786621,529.291016 643.463257,535.012085 
	C643.382263,535.724915 643.568848,536.382690 643.838074,537.499756 
	C643.891846,561.964233 643.863037,585.969360 643.465820,610.077026 
	C642.804321,612.822571 641.991394,615.547974 642.393005,618.080872 
	C642.681702,619.902405 644.273499,622.284973 647.429199,619.995117 
	C652.555054,620.404907 657.262512,620.788635 662.044312,621.501221 
	C666.567200,624.100891 670.687012,620.129883 671.243225,618.197632 
	C672.603455,613.472351 671.735352,608.105591 671.786133,602.706787 
	C671.812622,602.028625 671.817200,601.640747 671.883545,600.780273 
	C671.906372,597.789795 671.867371,595.271973 671.828247,592.401367 
	C671.828857,591.107605 671.829651,590.166687 671.896851,588.828247 
	C673.634949,587.325378 675.306641,586.220093 677.265991,585.114136 
	C677.925720,585.201294 678.297791,585.289185 678.724976,585.700928 
	C678.873291,586.674438 678.966614,587.323914 678.799561,588.161133 
	C681.026123,590.565857 683.513062,592.782898 685.987244,595.368530 
	C685.446411,597.306030 684.918396,598.874939 684.270264,600.800720 
	C686.823242,600.502197 688.866150,600.263306 691.081787,600.321045 
	C694.178223,603.741394 697.101929,606.865051 699.773560,610.176758 
	C701.094543,611.898621 702.667603,613.432556 704.292358,615.108765 
	C704.343994,615.251099 704.190308,615.512085 704.135498,615.918213 
	C705.050537,617.552795 706.020386,618.781250 706.986816,620.371826 
	C706.376465,621.615784 705.359619,623.324524 705.223328,623.256958 
	C702.737122,622.024536 700.356689,620.578674 697.894470,619.143677 
	C696.039551,625.704529 696.276184,626.153381 701.846436,628.164368 
	C702.729553,628.483093 703.781555,629.096008 704.180115,629.869385 
	C706.452820,634.280151 709.999207,633.119568 714.145325,632.391785 
	C712.935486,628.094482 714.573059,625.305115 718.438660,623.710144 
	C718.796082,623.562683 718.781250,622.512878 718.940186,621.561523 
	C718.918823,621.028503 718.896545,620.815979 719.337891,620.643188 
	C722.904419,620.668945 726.007263,620.654968 729.079956,620.955322 
	C729.050659,621.478271 729.051514,621.687012 729.036499,622.314819 
	C729.020630,624.313965 729.020630,625.894043 729.020630,626.368347 
	C725.962280,627.367798 723.883057,627.926453 721.945923,628.800232 
	C721.625549,628.944763 721.835266,631.226929 721.955017,631.241516 
	C723.968262,631.485413 726.052612,631.832458 728.026550,631.532898 
	C730.406738,631.171692 736.219421,633.782837 737.036499,636.362061 
	C738.140991,639.228210 737.799438,640.952087 734.624329,640.988525 
	C731.417358,641.025330 728.209778,640.998840 724.995728,640.609131 
	C723.627502,638.818176 722.456482,636.566589 720.861572,636.209656 
	C718.203552,635.614807 715.143433,635.748047 712.490967,636.461121 
	C708.996704,637.400513 706.543762,636.385437 703.750732,634.456238 
	C702.016846,633.258667 699.462280,632.987549 697.237122,632.820679 
	C693.292114,632.524902 688.687927,633.881775 685.494080,632.282043 
	C679.889221,629.474854 677.606812,634.297546 674.147766,635.960144 
	C673.217102,636.407532 674.006836,640.434143 674.006836,643.488403 
	C673.427673,643.962585 672.420227,645.391052 671.119812,645.745972 
	C665.992981,647.145203 662.987000,650.578125 661.007324,655.233154 
	C660.763611,655.806274 660.382446,656.558777 659.882812,656.748596 
	C654.404785,658.829773 656.215088,664.731934 657.756958,666.062622 
	C662.430481,670.096130 660.566345,672.944763 658.039795,676.637268 
	C657.198059,677.867493 657.267456,679.748352 657.014954,681.346619 
	C656.778503,682.843384 656.667114,684.359985 656.500732,685.867859 
	C656.221436,685.347351 655.942139,684.826843 655.662842,684.306335 
	C651.960510,684.291199 648.258179,684.276123 644.555847,684.260986 
	C645.693298,686.322021 646.830750,688.382935 648.529358,691.460693 
	C646.860291,692.130066 643.197327,694.280029 642.680908,693.687561 
	C640.504944,691.190979 639.267639,687.876221 637.570923,684.639587 
	C634.042053,683.516785 629.957825,682.217285 624.227722,680.394165 
	C625.250061,683.997192 625.847107,688.312073 626.581970,688.335693 
	C631.728149,688.501038 632.585083,693.550110 635.819763,695.722046 
	C636.444641,696.141541 636.432434,698.037842 636.147095,699.100708 
	C635.954346,699.818481 634.767212,700.269165 633.557983,701.197876 
	C633.557983,699.527039 633.557983,698.472900 633.557983,696.180603 
	C630.701965,697.251343 626.682495,697.898682 626.563782,698.976013 
	C626.289429,701.465698 627.905457,704.163757 628.754822,706.777283 
	C629.735657,706.976929 629.669006,707.518311 629.376404,707.873840 
	C628.086609,709.441223 626.731750,710.955078 625.400452,712.488281 
	C627.326599,712.725037 629.260132,712.915833 631.174683,713.224548 
	C631.809875,713.326843 632.396240,713.732239 632.968262,714.350586 
	C630.951294,717.165894 630.301880,721.093262 625.630371,720.998779 
	C622.825378,719.075623 620.390564,717.158508 617.796936,715.116394 
	C616.531311,718.815979 615.410217,720.423706 612.050110,716.975464 
	C610.584839,715.471802 607.320862,715.654663 604.847900,715.218567 
	C602.543457,714.812134 599.856201,715.228699 597.953369,714.172974 
	C593.973633,711.964844 592.958679,713.151794 592.919617,717.172241 
	C592.907471,718.416321 592.199402,720.503540 591.378235,720.746704 
	C587.225830,721.976135 586.579285,724.225525 588.336243,727.885742 
	C588.771240,728.791870 588.305237,730.091248 588.456360,731.181763 
	C588.707214,732.992676 589.122375,734.780823 589.468323,736.578491 
	C591.050537,736.041565 592.650696,735.551697 594.211060,734.957520 
	C597.012939,733.890747 600.095276,730.460449 601.835510,736.319946 
	C597.445862,737.900574 593.225586,739.159241 587.783569,740.782349 
	C587.977356,741.744324 588.409546,743.890015 588.947754,746.245422 
	C589.195496,746.737061 589.388794,746.977539 589.632324,747.327820 
	C589.630920,747.479065 589.820496,747.714844 589.548462,747.808167 
	C588.911194,748.018677 588.546021,748.135803 587.794983,748.131226 
	C586.605835,747.997498 585.802490,747.985474 584.719971,747.685181 
	C581.837952,747.479980 579.120544,747.110657 576.655396,747.736084 
	C570.708191,749.244812 564.879028,751.218689 558.542969,753.003296 
	C555.288818,753.003296 552.440857,752.686768 549.721313,753.140442 
	C548.359436,753.367554 547.231628,754.998901 545.665039,755.996460 
	C544.888000,755.999390 544.444031,756.000916 543.810913,755.757690 
	C542.413696,756.671143 541.205627,757.829468 539.601624,758.990601 
	C537.804626,759.665100 536.403503,760.336731 534.687866,761.053589 
	C533.955017,761.164368 533.536621,761.229919 532.712769,761.197388 
	C531.205200,761.082031 530.103149,761.064819 528.733643,760.748474 
	C521.814087,760.656494 515.151733,760.726685 508.521851,761.226990 
	C507.588928,761.297363 506.814728,763.470825 505.578857,764.756836 
	C504.138031,764.786011 503.085449,764.729126 502.014954,764.286743 
	C501.052460,762.948608 499.928040,761.048523 499.190796,761.188477 
	C490.869354,762.767883 483.542328,757.200195 474.891571,758.575439 
	C468.764069,759.549561 462.029724,756.390747 455.517944,755.391724 
	C452.074341,754.863403 448.736542,754.776672 446.594696,751.303040 
	C446.081635,750.470947 444.257416,750.055298 443.065887,750.108765 
	C439.818024,750.254578 435.883057,753.307556 434.225891,747.595154 
	C434.177979,747.429871 432.122101,747.847046 430.994293,747.623718 
	C429.482269,745.716797 426.520233,743.956116 426.715515,742.677673 
	C427.828796,735.389404 422.832581,736.964905 419.088348,737.056458 
	C414.672333,737.164429 414.451721,735.370300 415.932556,731.892029 
	C416.448212,730.680725 415.856049,728.997864 415.769836,727.530334 
	C414.268646,727.772888 412.771851,728.048645 411.265167,728.249939 
	C409.185577,728.527710 407.098511,728.749390 404.967529,728.670898 
	C404.261505,727.912109 403.602631,727.477539 402.971619,727.021484 
	C402.999481,727.000000 402.954468,727.054077 402.973328,726.635986 
	C402.463043,717.267517 398.331879,713.201477 389.305847,713.154297 
	C386.247009,712.766479 383.609985,712.386841 381.178406,711.805420 
	C377.358917,704.637756 372.638245,708.732605 368.418762,710.924377 
	C362.625885,705.046082 357.095825,699.434570 351.426208,693.681396 
	C353.196533,692.861206 355.049561,692.002686 356.902588,691.144165 
	C356.970428,690.605652 357.038300,690.067078 357.106140,689.528564 
	C355.624115,689.228027 354.063049,688.473633 352.675354,688.715149 
	C349.043304,689.347351 345.014587,688.700562 344.351807,685.379211 
	C343.101440,679.113098 339.069550,678.996460 334.310486,678.618591 
	C334.048920,676.943848 334.207550,675.480713 333.680359,674.332336 
	C332.974731,672.795227 331.682312,671.538025 330.743317,670.094666 
	C329.616272,668.362366 328.680115,666.502747 327.510132,664.802185 
	C325.692169,662.159668 323.746582,659.604980 322.147766,656.817444 
	C321.687408,652.809021 321.569672,648.627563 316.867615,646.864014 
	C316.829529,646.643616 316.798553,646.197266 316.820129,645.832764 
	C315.879517,644.996887 314.917297,644.525513 313.919403,643.673584 
	C313.804810,642.533447 313.725952,641.773804 313.984009,640.832581 
	C314.073395,632.904358 309.801331,626.974304 305.467316,621.076477 
	C305.401031,620.986328 305.150818,621.031311 304.965088,620.666626 
	C304.921844,619.857605 304.899933,619.395142 305.263611,618.891113 
	C309.109711,616.244202 308.855133,612.487671 307.123901,609.597717 
	C305.678070,607.184265 302.530273,605.790405 300.098358,603.513611 
	C299.992737,601.061035 299.924927,599.039856 300.175232,596.763672 
	C300.360962,593.765747 300.193237,591.023926 300.104431,588.279602 
	C299.973541,584.235229 300.010254,580.182129 299.762268,576.145874 
	C299.588989,573.325928 299.170349,570.506226 298.630829,567.731201 
	C298.427856,566.687378 297.102844,565.774597 297.093079,564.794128 
	C297.016968,557.152283 297.259674,549.506531 297.134399,541.866394 
	C297.085785,538.900940 296.342499,535.946838 295.930267,532.572632 
	C295.963318,530.773376 295.981750,529.389160 296.394897,527.990723 
	C299.855408,525.571777 301.701813,515.455383 299.905945,510.596985 
	C299.988403,509.154266 300.054565,508.105377 300.405029,507.006104 
	C300.747375,506.301147 300.805420,505.646545 300.885254,504.615234 
	C300.928223,503.484070 300.949371,502.729645 301.329895,501.927460 
	C305.193756,500.146729 305.329742,497.112274 304.806641,493.584625 
	C304.810638,493.347626 304.873352,492.877686 305.262085,492.872070 
	C308.269684,490.559814 310.168274,482.428802 308.918976,478.660248 
	C308.980865,477.901489 309.015839,477.467896 309.392273,476.986084 
	C311.152954,474.956940 312.572205,472.976013 314.370636,471.065552 
	C316.048279,471.753571 317.333801,472.870483 318.647278,472.904388 
	C325.193054,473.073547 331.746613,472.990570 338.296265,472.906738 
	C339.253052,472.894470 340.189575,472.248596 341.159851,472.158508 
	C343.997070,471.895050 344.323547,470.382904 343.530212,468.024994 
	C342.203156,464.080688 343.445770,460.718231 345.509827,457.232910 
	C346.636749,455.330017 347.392975,452.242889 346.629395,450.382111 
	C344.939850,446.264832 342.385925,442.439301 339.759308,438.801910 
	C338.771515,437.433990 336.606232,436.916382 335.125122,435.696838 
	C336.835205,434.580658 338.398010,433.778717 340.275269,433.139038 
	C341.087616,434.132233 341.439178,435.116852 342.115295,435.760559 
	C343.341888,436.928253 344.794373,438.780396 346.093170,438.732666 
	C348.003510,438.662445 350.082153,437.427368 351.584137,436.111938 
	C351.950562,435.791046 350.351868,433.226410 349.635345,431.646332 
	C351.782654,429.895416 353.916046,427.757202 356.428375,426.251129 
	C358.034393,425.288422 360.200562,425.260193 362.115601,424.812988 
	C361.467407,426.533691 360.669189,428.215302 360.208191,429.984772 
	C359.603394,432.306244 359.264496,434.696991 358.811249,437.057922 
	C361.439392,436.954315 364.544373,437.695221 366.581482,436.544922 
	C369.482056,434.907166 371.789886,432.040009 373.988281,429.396484 
	C375.678345,427.364227 376.808929,424.866730 378.247375,422.478088 
	C371.227997,419.656860 371.550751,419.455078 370.767975,413.402344 
	C370.185944,408.901672 370.807922,407.694641 375.533234,408.806244 
	C380.927460,410.075226 382.366974,408.208191 382.057007,402.697021 
	C381.947296,400.746338 383.063416,398.726746 383.619873,396.738586 
	C385.776459,397.350891 387.912048,398.057739 390.097107,398.541321 
	C391.488403,398.849243 393.956696,399.330750 394.195435,398.856018 
	C394.894592,397.465790 395.095276,395.554749 394.761993,394.004517 
	C394.514191,392.852020 393.129913,391.943817 392.250610,390.925385 
	C396.217529,389.910034 399.649567,388.639984 403.169037,388.313995 
	C404.681122,388.173950 407.195221,389.627869 407.807800,391.002228 
	C408.937439,393.536682 409.734711,394.625702 412.488251,393.051361 
	C417.440460,390.219910 418.003662,388.052368 414.987946,382.477112 
	C412.916718,378.648010 410.933441,374.771332 408.911316,370.897461 
	C408.911774,370.879028 408.885284,370.847107 409.290466,370.891022 
	C416.440155,368.465088 423.304504,368.082428 430.453766,368.873291 
	C435.311493,369.410675 440.328766,368.996552 445.222168,368.495453 
	C447.377991,368.274658 450.294037,367.339478 451.295013,365.743805 
	C453.143311,362.797394 453.641205,359.038452 455.093628,355.793457 
	C455.753052,354.320190 457.348083,353.265717 458.518707,352.021271 
	C459.677002,353.278046 461.709290,354.463989 461.818024,355.805786 
	C462.071320,358.930695 462.661896,361.223999 465.988556,362.314758 
	C466.830505,362.590790 467.555023,364.026306 467.835602,365.063721 
	C468.694061,368.237915 470.857025,369.517670 473.801025,368.927856 
	C477.251312,368.236633 480.592529,367.000885 484.281189,366.145081 
	C486.045929,368.193939 487.510559,370.099060 488.734344,372.172913 
	C488.329010,372.894867 488.164520,373.448059 487.575104,374.001190 
	C485.799744,374.556366 483.573090,374.838867 483.296295,375.728210 
	C482.836731,377.204803 483.073730,379.599243 484.049561,380.627716 
	C485.059662,381.692352 487.298523,381.591217 489.349365,381.961365 
	C492.132111,380.952637 494.565460,379.982574 497.333832,379.041565 
	C499.116425,380.373566 500.563934,381.676575 502.007721,383.234375 
	C502.002228,383.659058 502.000519,383.828918 501.603363,383.998413 
	C499.130829,383.889191 496.532593,382.937103 495.078033,383.835846 
	C491.298035,386.171509 487.996704,389.292267 484.574860,392.183167 
	C484.180267,392.516510 484.230713,393.376648 484.065369,394.029816 
	C485.729889,394.989014 487.235077,395.856415 488.446899,396.554749 
	C486.406036,399.594818 484.531525,402.387115 482.255219,405.777893 
	C476.655884,407.013550 470.150726,408.751526 463.535614,409.786224 
	C460.026245,410.335114 455.549713,411.351776 454.553284,405.582581 
	C454.473816,405.122559 453.028046,404.898529 452.217224,404.564819 
	C451.951691,405.276031 451.243073,406.299744 451.489838,406.649536 
	C453.691864,409.771057 451.228302,411.443329 450.000580,413.941650 
	C448.591431,416.809235 447.127533,418.267731 444.060120,418.556549 
	C443.003967,418.655975 442.017670,419.497406 440.666443,419.999786 
	C439.889313,419.999237 439.445282,419.998749 438.963928,419.635132 
	C437.337769,415.571167 439.440063,415.122925 442.441559,414.896210 
	C443.910553,414.785278 445.301880,413.645905 446.728363,412.972015 
	C445.738037,411.912079 444.655731,410.922211 443.781097,409.774109 
	C442.733398,408.398895 442.151123,406.494751 440.842377,405.537140 
	C439.228210,404.356079 437.073334,403.914032 435.152161,403.152649 
	C434.622070,404.790680 433.808105,406.400665 433.642456,408.074768 
	C433.480103,409.715790 433.977600,411.422058 434.185486,413.099701 
	C432.363861,412.868713 430.459961,412.897156 428.738190,412.351227 
	C425.426086,411.301056 422.735138,412.492950 421.732147,415.294342 
	C421.216431,416.734802 422.920929,419.137939 423.960785,420.906982 
	C424.624695,422.036438 425.908356,422.801636 427.991089,424.724365 
	C421.708588,424.315582 418.724640,426.473541 417.963013,431.359406 
	C417.878113,431.903809 417.125061,432.311310 416.830597,432.861877 
	C415.850586,434.694397 414.092621,436.598328 414.167938,438.411133 
	C414.351227,442.823639 412.123993,444.623962 408.602325,445.720612 
	C408.145966,445.323578 407.827057,445.149933 407.665649,444.881622 
	C407.415405,444.465607 407.185333,444.001251 407.097382,443.529327 
	C405.914825,437.180939 405.218689,437.007446 399.114197,437.688873 
	C393.595947,438.304871 387.835327,439.218903 382.886261,435.056091 
	C381.543304,433.926422 379.499298,433.630157 377.777069,432.951416 
	C377.348785,433.302155 376.920471,433.652893 376.492188,434.003632 
	C377.277954,435.933594 377.603516,439.070770 378.931946,439.577118 
	C383.770203,441.421295 383.150543,444.837677 382.680359,448.590973 
	C382.107971,453.160095 381.027954,457.813965 381.424530,462.320587 
	C381.990723,468.755066 379.979095,472.952423 373.896179,474.877625 
	C372.617645,475.282318 371.367706,475.777466 369.918304,476.297821 
	C376.277740,485.369843 376.518982,487.584869 372.357758,491.193756 
	C369.811646,487.882843 367.614166,484.160858 364.545074,481.412018 
	C363.088715,480.107605 359.890686,480.216614 357.644226,480.611053 
	C356.848236,480.750793 356.165009,483.615845 356.073517,485.272156 
	C355.849518,489.327850 356.000366,493.404205 356.000366,498.064758 
	C354.344086,497.467896 353.597107,497.202271 352.852631,496.929871 
	C347.753204,495.063751 345.118622,496.760834 345.005005,502.002045 
	C344.976135,503.334503 345.001831,504.668121 345.001923,506.375916 
	C345.001740,507.500000 345.002106,508.249390 345.036041,509.343323 
	C347.457214,512.148315 345.984406,514.575684 345.006775,517.338440 
	C345.007050,518.111023 345.007294,518.552551 345.005554,519.385620 
	C345.346954,521.201904 345.265472,522.987549 346.112396,523.984436 
	C348.794830,527.141907 348.602325,529.517212 345.978882,532.885498 
	C343.695404,535.817322 345.136414,538.819580 349.004242,540.446411 
	C349.003693,542.224548 349.390778,543.713379 348.923218,544.850952 
	C347.842438,547.480408 345.474152,549.865784 345.252014,552.487671 
	C345.000793,555.453125 347.665100,558.829468 347.039948,561.565125 
	C345.749573,567.211914 346.183716,572.608276 347.097351,578.095520 
	C347.268158,579.121521 348.194305,580.021790 349.446106,582.098694 
	C350.611694,579.149170 350.982880,577.324890 351.968445,575.936951 
	C353.235657,574.152466 354.826324,571.773743 356.601593,571.454956 
	C357.847198,571.231262 359.634003,574.021423 362.069489,576.297974 
	C354.605194,577.215210 357.750519,581.877014 356.754211,584.999268 
	C356.332489,585.002258 356.163818,585.003601 355.607269,585.004761 
	C350.268341,585.848083 348.942139,587.879883 349.854492,593.301392 
	C345.945374,596.844116 343.858673,600.633545 346.046753,605.628357 
	C346.569916,606.822571 347.371490,608.293884 348.421234,608.727844 
	C349.214050,609.055664 350.708252,608.084167 351.701691,607.412048 
	C352.601959,606.802917 353.247223,605.816711 354.283875,605.200439 
	C356.402008,608.488953 358.306824,610.046753 362.186981,607.691101 
	C365.880920,605.448547 367.955353,607.158813 370.159912,611.239197 
	C372.203735,615.022034 373.423126,620.636719 380.016846,619.311584 
	C380.060883,620.034180 380.077271,620.447693 380.059509,621.254883 
	C380.018188,622.431580 380.010986,623.214600 379.959778,624.368652 
	C376.653076,630.713501 376.906982,631.367065 383.148621,634.198730 
	C384.102722,634.631592 385.642731,635.753113 385.531738,636.243958 
	C384.539581,640.630615 387.626404,639.834839 390.218201,640.106567 
	C390.711182,640.414978 390.898621,640.676636 390.999786,641.333313 
	C390.999329,642.111084 390.999054,642.555542 390.619507,643.042542 
	C388.493896,643.389282 386.747559,643.693420 384.991028,643.615173 
	C384.087158,642.814819 383.193451,642.396851 382.299744,641.978882 
	C382.350494,644.444031 380.437927,647.384277 383.999268,649.366028 
	C384.333160,650.821350 384.666595,651.910706 385.000000,653.403992 
	C384.609680,656.548950 384.356659,659.319519 383.774475,662.019104 
	C383.456451,663.493591 382.581116,664.847961 381.957092,666.256470 
	C383.344910,665.621521 385.272400,665.357910 386.014862,664.278870 
	C387.907166,661.528809 389.494293,661.658936 391.462769,663.963806 
	C392.290527,664.932922 393.165405,665.861938 394.199158,667.009216 
	C396.242554,665.505066 397.909485,663.779846 399.941681,662.961975 
	C401.238770,662.439880 404.078278,662.780701 404.461700,663.580566 
	C405.242920,665.210327 405.707092,668.243347 404.763031,669.238647 
	C399.929108,674.334900 399.638763,674.370239 405.682434,678.015015 
	C407.416595,679.060852 410.145325,679.021790 412.226959,678.580017 
	C412.843872,678.449097 412.808441,675.244568 413.071442,673.451965 
	C415.862549,672.997437 418.932495,672.497498 422.192230,672.266968 
	C423.590057,674.022583 424.798096,675.508850 426.029541,677.317017 
	C426.701965,678.425903 427.350983,679.212952 428.231628,680.270996 
	C433.943359,680.975708 439.422241,681.427368 444.906494,681.801392 
	C445.255371,681.825134 445.645416,681.245911 446.351929,680.956543 
	C447.473297,681.285095 448.259064,681.604431 449.064178,682.292603 
	C450.380646,684.948669 451.790741,687.142578 449.391663,689.721436 
	C448.978638,690.165405 449.796906,692.609192 449.949921,692.599609 
	C453.284973,692.389282 456.653625,692.183838 459.911835,691.489624 
	C461.729584,691.102356 463.359406,689.833069 465.304993,689.216064 
	C466.677765,689.603943 467.819458,689.737061 469.192383,690.173279 
	C471.438812,691.643188 473.418274,693.742188 475.475189,693.820984 
	C486.377075,694.238770 497.147644,695.070862 507.521454,698.830994 
	C508.372833,699.139648 510.481873,698.486938 510.556244,698.039673 
	C511.243683,693.906738 514.362854,695.200134 516.806274,695.000916 
	C521.093628,694.651306 525.435120,694.601685 529.639893,693.816101 
	C530.953735,693.570679 531.900452,691.360596 533.410645,690.025635 
	C534.861755,689.973022 535.917603,689.944580 537.257324,690.230896 
	C542.577332,690.545593 547.613342,690.545593 553.097229,690.545593 
	C552.170105,688.561035 551.555847,687.246216 551.166260,685.678406 
	C552.907166,683.941528 554.423523,682.457581 556.309326,680.977417 
	C558.156189,680.100159 559.633545,679.219177 561.110962,678.338257 
	C563.896545,679.690796 566.807190,680.304565 569.610474,679.983032 
	C577.599548,679.066589 577.582336,678.916748 574.098999,672.552368 
	C574.856323,672.197327 575.404541,672.040344 576.204529,672.190552 
	C584.479248,673.841797 591.355713,666.811401 588.802979,659.471680 
	C588.806824,657.412537 588.802002,655.765198 589.108154,653.880493 
	C590.969299,649.126770 588.676208,645.744080 585.454102,643.410889 
	C581.555420,640.587830 578.187988,637.822815 578.681335,632.617249 
	C568.468506,630.800110 564.198730,632.400696 563.001099,643.408936 
	C562.692383,643.836975 562.444885,643.995422 561.787476,644.052734 
	C560.282532,643.683899 559.140076,643.341919 558.046021,642.604980 
	C559.131775,638.885437 560.162964,635.562012 555.395020,632.246094 
	C554.586609,635.419312 553.260925,638.004089 553.450500,640.472534 
	C553.760803,644.513245 551.789124,644.982056 548.720337,644.772400 
	C546.273682,644.605164 543.824707,644.471497 541.378540,644.297974 
	C532.115051,643.640686 524.423645,651.324158 514.859558,649.321411 
	C514.154907,649.173828 513.217163,650.672119 512.337585,650.738281 
	C507.631683,651.092346 502.889771,651.591187 498.200256,651.327759 
	C494.924927,651.143860 491.750458,649.399292 488.464233,649.110779 
	C484.759979,648.785461 480.567444,651.061096 477.974396,646.347839 
	C477.742065,645.925476 476.421265,646.060974 475.601440,646.009521 
	C470.068298,645.662048 464.533966,645.334229 458.688232,644.868774 
	C454.774628,643.404968 455.228302,646.126831 454.599701,648.124146 
	C450.782471,646.446960 447.339539,644.786499 443.966309,642.791870 
	C444.690277,641.970093 445.344513,641.482605 446.218231,641.234985 
	C448.688690,638.676331 450.422180,636.010803 447.337097,632.462769 
	C446.049805,630.982361 445.624817,628.680481 445.035675,626.684753 
	C444.431793,624.639160 444.290192,622.449646 443.613403,620.434937 
	C442.753265,617.874451 441.619080,615.895325 438.078278,615.861755 
	C433.606232,615.819397 431.377930,618.593811 429.439392,621.583923 
	C427.503113,624.570740 430.318970,625.805359 432.374054,627.177063 
	C432.797760,627.459900 432.799896,628.374268 432.625305,628.995117 
	C430.782227,628.084900 428.805206,627.508240 427.946167,626.198364 
	C426.298523,623.685974 425.285492,620.757324 424.271118,617.790161 
	C423.915497,612.101318 420.995087,610.402710 414.929535,611.722717 
	C414.590424,610.971375 414.285126,610.510315 413.944885,609.718140 
	C413.249603,608.935547 412.589203,608.484131 411.915710,607.680054 
	C411.563202,606.220886 411.223816,605.114258 411.275818,603.996094 
	C412.767242,602.568054 414.404358,601.303528 414.856934,599.703857 
	C415.835571,596.244690 416.785492,592.586792 416.620972,589.062683 
	C416.513489,586.760559 414.304047,584.944214 411.459839,587.065125 
	C410.610901,587.698181 409.663391,588.198975 409.197968,588.488525 
	C408.365417,584.739258 407.583130,581.216248 406.706207,577.266968 
	C408.429626,577.130920 411.433838,576.893677 414.789856,576.628723 
	C414.597870,574.118713 413.415375,571.076660 414.436218,569.808838 
	C418.392914,564.894958 415.750946,559.274170 416.787415,554.105469 
	C417.397369,551.063782 418.451538,547.074036 414.692871,544.478638 
	C414.306885,544.212097 414.618439,542.338867 415.105774,541.501953 
	C418.057617,536.432434 416.234924,532.858337 410.228180,531.968750 
	C408.858215,531.765808 407.556274,531.103455 406.223022,530.652588 
	C419.007690,527.914490 419.457031,512.557739 413.406403,504.250549 
	C415.904419,504.041138 417.841980,503.485352 419.615173,503.814667 
	C422.969635,504.437653 424.387390,503.411346 424.134491,499.687469 
	C426.542725,497.990540 428.808990,496.610992 431.075256,495.231445 
	C431.737640,496.934174 432.147858,498.806671 433.143402,500.285065 
	C433.919128,501.436981 435.663269,503.012268 436.551178,502.778900 
	C438.169464,502.353577 439.758118,500.942413 440.819092,499.535309 
	C441.732361,498.324097 441.917297,496.552979 442.382751,495.016846 
	C443.602234,490.992065 444.550964,486.863007 446.082123,482.961731 
	C447.343567,479.747559 448.574890,475.179138 451.077240,474.083618 
	C455.782166,472.023804 455.243896,470.145874 453.033722,466.634460 
	C453.965698,462.956940 455.972107,461.202057 459.497864,460.529083 
	C461.162415,460.211365 462.510803,458.237122 464.334106,457.006866 
	C465.108063,456.995758 465.550354,456.991241 466.443054,456.992554 
	C469.595734,456.996887 472.298035,456.995392 475.008240,457.374573 
	C476.257172,458.791168 477.394043,460.490936 478.763885,460.705811 
	C480.768433,461.020294 483.071838,460.623962 485.013000,459.900452 
	C489.075500,458.386230 492.984619,456.460510 496.956757,454.703827 
	C496.723755,454.079468 496.490723,453.455109 496.257690,452.830750 
	C494.130859,453.238953 492.016327,453.893219 489.874451,454.000214 
	C486.256714,454.181000 482.621674,454.015259 479.183411,453.658264 
	C483.482819,452.546173 487.576324,451.662994 491.708771,451.036652 
	C494.183990,450.661469 496.858521,450.890533 497.502655,447.257751 
	C502.967499,447.405975 507.980469,447.640503 513.085327,448.123169 
	C513.452271,448.582397 513.727295,448.793457 514.002319,449.378235 
	C514.004456,450.499573 514.006714,451.247101 513.804382,452.278442 
	C513.439148,454.234009 512.512024,456.631531 513.270691,457.432922 
	C515.090759,459.355225 517.702759,460.527832 520.155884,462.352966 
	C521.117798,463.447510 521.965881,464.878815 522.712585,464.827728 
	C528.557861,464.428009 534.818787,466.940735 540.108948,462.100037 
	C541.526489,460.802948 544.007324,460.667816 546.036743,460.352966 
	C546.380920,461.285248 546.539001,462.092560 547.019409,462.406036 
	C550.708740,464.813690 554.392456,466.896576 555.835815,471.774261 
	C556.354492,473.526947 559.733032,474.335297 561.417236,470.942780 
	C563.605652,471.661346 565.524902,472.163147 567.180725,473.120667 
	C572.660461,476.289368 578.741577,476.211121 583.834412,473.753174 
	C589.925171,470.813568 595.131714,466.041931 600.557800,462.180145 
	C601.111389,463.340118 601.951294,465.099945 602.791138,466.859772 
	C603.276062,466.746979 603.761047,466.634186 604.245972,466.521393 
	C604.245972,461.164124 604.245972,455.806854 604.245972,450.015045 
	C602.869934,450.599579 601.765381,451.068817 601.433411,451.209839 
	C599.186951,448.198029 597.409058,445.270050 595.095703,442.855011 
	C592.396912,440.037720 589.230225,437.668579 586.157532,435.010651 
	C583.598450,435.010651 580.296814,435.010651 576.996826,434.562134 
	C576.336853,430.274933 575.766663,426.417084 574.967285,422.607269 
	C574.660706,421.146179 573.831543,419.794708 573.240601,418.393311 
	C572.483765,419.630646 571.824524,420.943146 570.944641,422.085846 
	C569.917236,423.420105 568.674438,425.654724 567.565491,425.630707 
	C565.473083,425.585449 562.828247,424.738373 561.463867,423.256775 
	C559.139648,420.732910 557.627869,417.460968 555.816345,414.569824 
	C549.256287,418.188568 548.936340,418.022980 547.954346,411.838623 
	C547.851440,411.190491 547.498352,410.573578 547.446777,409.927399 
	C547.275940,407.786346 546.464111,404.142029 547.210571,403.779968 
	C549.269287,402.781342 552.041260,402.779449 554.441711,403.085297 
	C557.115723,403.426056 559.644531,404.386749 560.998779,400.748230 
	C560.999939,400.498413 561.002441,399.998779 561.366821,399.961670 
	C562.347107,399.679413 563.019226,399.523224 563.569580,399.174713 
	C567.081482,396.950958 570.401733,395.038818 572.449890,400.936646 
	C572.640198,401.484680 575.114441,401.239532 576.528137,401.362671 
	C577.049255,400.871216 577.570374,400.379791 578.091492,399.888336 
	C573.330200,398.965118 573.530396,397.157990 575.503601,394.828369 
	C578.183228,391.664856 580.864502,392.782928 583.047180,393.944672 
	C583.047180,396.711914 582.182129,399.561584 583.225708,400.790405 
	C585.457458,403.418304 588.697937,405.120972 593.009888,403.162903 
	C591.350037,396.675018 595.438477,395.315613 600.399475,395.715515 
	C602.017639,390.522522 600.866760,386.999481 596.218567,384.044037 
	C598.569641,383.436554 600.920776,382.829071 603.620056,382.131622 
	C603.806274,383.891632 603.597595,385.366821 604.166260,386.423859 
	C604.882935,387.756073 606.156799,389.568268 607.331116,389.678925 
	C608.829163,389.820068 610.959717,388.874451 611.949768,387.678711 
	C614.470215,384.634491 612.342346,381.386108 607.394653,380.000061 
	C606.197144,379.664612 605.183228,378.674042 604.084900,377.984711 
	C604.939026,376.886383 605.712891,375.711670 606.666870,374.708252 
	C607.984497,373.322449 610.754150,371.046936 610.641846,370.901886 
	C607.330322,366.623840 611.453308,362.701447 611.198669,358.604340 
	C611.089600,356.849274 613.192078,353.405121 614.220520,353.431458 
	C616.948364,353.501221 619.987305,354.588135 622.154480,356.247467 
	C622.913818,356.828888 622.029907,360.602844 620.938660,362.304871 
	C619.375793,364.742554 616.990540,366.675812 614.853516,368.716644 
	C612.164185,371.284851 610.849121,377.445709 613.261169,379.536255 
	C617.173462,382.927032 618.471802,386.791382 618.200806,391.835663 
	C618.117920,393.379211 620.067749,395.744934 621.656433,396.449249 
	C623.032043,397.059174 625.361328,396.151855 626.997314,395.356384 
	C628.527466,394.612366 629.700500,393.134033 631.397461,391.984222 
	C632.498657,392.003754 633.233032,392.017944 633.979736,392.364746 
	C634.329712,393.460297 634.667297,394.223328 634.996948,395.375458 
	C634.057617,398.890350 632.741577,401.964966 632.317017,405.158142 
	C631.979431,407.696930 632.731628,410.380646 632.612793,412.993774 
	C629.720825,412.540375 626.981567,411.005341 625.355591,414.528229 
	C623.931641,417.613373 625.352905,423.391052 627.846558,425.218964 
	C630.216431,426.956238 631.154663,429.593445 628.740601,431.502563 
	C625.292542,434.229401 623.757629,440.196777 617.308594,437.987885 
	C617.206787,442.151398 615.312134,446.876495 620.417358,447.675781 
	C626.472107,448.623718 624.172913,442.435608 625.738037,438.956390 
	C631.816956,438.638031 637.908508,438.319000 644.365051,438.038513 
	C646.152588,438.385071 647.575073,438.693146 649.036743,439.300049 
	C649.483826,439.918213 649.891724,440.237488 650.299683,440.556793 
	C650.533936,440.038269 650.768188,439.519745 651.377136,439.001160 
	C652.501221,439.000763 653.250610,439.000366 654.365601,439.006500 
	C655.347778,439.209167 656.234253,439.209930 656.539490,439.631500 
	C659.772705,444.097260 662.751770,448.757874 666.166992,453.075165 
	C667.369507,454.595306 669.577759,455.319824 672.532104,457.157227 
	C669.549255,458.326904 666.826294,459.394653 663.909119,460.538574 
	C667.752869,464.748444 671.098083,468.412231 675.472046,473.202728 
	C677.453308,472.706665 680.798584,471.869110 684.751831,470.879333 
	C687.260742,471.998779 690.626709,473.500610 693.994385,475.251831 
	C693.996094,475.501190 693.995117,476.000000 693.718750,476.243958 
	C692.185730,480.496704 690.376465,484.905304 696.355286,486.528046 
	C700.879333,487.755951 705.590454,488.552185 710.264648,488.880768 
	C713.522217,489.109741 715.789551,489.708771 717.535767,492.820770 
	C719.697510,496.673309 723.333984,497.784698 727.497009,496.497345 
	C728.148499,495.774109 728.799988,495.050873 729.451477,494.327637 
	C727.788330,493.567169 726.125183,492.806702 724.471252,492.050415 
	C732.325134,485.796295 732.592041,484.846680 728.997803,475.664246 
	C728.400330,474.530853 727.807373,473.742950 727.144043,472.540588 
	C726.919250,470.456818 726.764771,468.787537 726.931824,466.979309 
	C727.504639,466.220978 727.755981,465.601593 728.010010,464.646210 
	C728.340088,463.539886 728.667419,462.769562 728.994751,461.999207 
M573.386414,510.095612 
	C568.576904,510.833405 568.876343,514.455933 568.202881,517.989258 
	C565.783569,520.977966 563.364319,523.966675 560.318665,527.264526 
	C556.720642,528.619141 555.472961,531.301575 555.278259,535.052246 
	C551.594238,537.520813 547.943848,540.012878 548.133850,545.956299 
	C547.125000,547.051453 546.116150,548.146606 544.713135,548.602417 
	C542.787964,547.414368 540.862732,546.226379 538.458069,544.687073 
	C538.284119,544.130859 538.110168,543.574585 538.513794,542.643677 
	C539.311707,542.248047 540.073120,541.718079 540.916199,541.488342 
	C542.172485,541.145996 543.486450,541.015564 544.775879,540.794922 
	C544.635803,540.026917 544.684448,538.711609 544.322693,538.586914 
	C542.861084,538.083252 541.192932,537.517273 539.752441,537.785950 
	C538.062439,538.101196 536.529724,539.259766 534.449463,539.695007 
	C534.270813,539.135437 534.092163,538.575928 534.555237,537.731934 
	C536.534119,535.262817 538.513000,532.793762 540.491882,530.324646 
	C540.094604,530.030396 539.697327,529.736145 539.300049,529.441956 
	C536.146667,530.329773 532.993286,531.217590 529.389587,531.715820 
	C529.214111,531.154480 529.038574,530.593079 529.510986,529.753357 
	C530.888794,528.565308 532.082520,526.871094 533.680969,526.289673 
	C537.553223,524.881226 537.187988,521.885925 536.884949,518.946411 
	C536.787292,517.999084 535.722473,516.402344 535.138977,516.419617 
	C534.116028,516.449829 533.010742,517.371155 532.143372,518.146729 
	C530.775452,519.369812 529.575745,520.780945 528.304871,522.112549 
	C527.818054,521.969543 527.331238,521.826538 526.844482,521.683533 
	C530.034424,517.190918 529.309570,514.881897 524.062805,514.762146 
	C521.422607,514.701904 518.791870,514.227600 516.021667,513.312744 
	C515.687378,512.529114 515.353088,511.745483 514.899353,510.275513 
	C512.282593,508.703766 509.759644,506.017975 507.029846,505.788025 
	C501.011353,505.281067 494.841858,505.648682 488.831726,506.420563 
	C486.484497,506.722046 482.895386,507.833160 484.040131,512.952087 
	C484.060425,547.625122 484.080750,582.298096 483.579376,617.314148 
	C483.354645,619.913696 484.327820,621.029053 487.613495,620.040161 
	C488.385925,619.851746 489.158356,619.663330 490.771790,619.432251 
	C491.849243,619.646179 492.926666,619.860107 494.418732,620.558228 
	C497.213623,620.518372 500.008514,620.478577 503.648132,620.288391 
	C505.075470,620.178040 506.502808,620.067749 508.652130,619.990173 
	C511.673920,619.438049 511.973938,619.021301 510.909058,616.210266 
	C510.987549,615.486694 511.066071,614.763184 511.804413,613.883789 
	C511.564270,612.572205 511.324158,611.260620 511.041229,609.019653 
	C511.048004,602.694031 511.054749,596.368408 511.764557,589.877747 
	C513.458008,587.962097 514.001465,586.210815 511.031158,584.037048 
	C511.041901,580.040161 511.052643,576.043274 511.787445,571.889160 
	C514.642883,570.348999 517.498291,568.808899 520.842896,567.004883 
	C521.991211,567.422485 524.051331,568.171631 526.141174,568.807007 
	C526.141174,568.807007 526.258606,568.813965 526.210266,569.644287 
	C526.701782,572.218201 526.357971,575.510864 527.839661,577.232788 
	C532.497314,582.645935 537.841248,587.468567 543.621216,593.217834 
	C549.149353,587.151733 554.766846,581.696533 559.522278,575.571960 
	C564.370422,569.328064 568.370972,562.425964 573.468872,555.977966 
	C575.156128,556.673767 576.843384,557.369568 578.790894,558.850220 
	C578.718994,560.213989 578.647034,561.577698 577.890564,562.975952 
	C576.362305,565.396973 574.833984,567.817993 573.305664,570.238953 
	C575.021057,571.184387 576.736511,572.129761 578.726685,573.852844 
	C578.652161,575.212097 578.577637,576.571289 577.735352,577.962158 
	C576.826721,579.178894 575.154968,580.379822 575.129395,581.614807 
	C574.913940,592.020569 574.884583,602.435364 575.131348,612.839539 
	C575.164490,614.236572 577.047668,615.589722 578.163879,617.639160 
	C579.105957,618.501709 580.048035,619.364258 581.232849,620.574463 
	C581.431763,620.787231 581.671082,620.934387 582.874512,621.069397 
	C586.267273,621.009460 589.660095,620.949524 593.837585,620.962280 
	C594.889526,620.953491 595.941467,620.944763 597.421936,621.504456 
	C600.061279,620.458252 602.700623,619.412109 606.179138,618.033325 
	C606.085999,617.996704 607.181213,618.427246 608.692566,619.021423 
	C609.134094,613.637512 604.835510,612.460388 601.851074,609.184204 
	C601.748474,606.375916 601.615967,603.568298 601.549072,600.759155 
	C601.352600,592.506653 601.182190,584.253601 601.180847,575.077087 
	C601.124695,565.716248 601.068542,556.355347 601.436584,546.136475 
	C601.304565,539.099915 601.172546,532.063293 601.549255,525.003418 
	C601.709900,525.058289 601.870544,525.113098 602.549377,524.886353 
	C603.913147,523.801331 605.276917,522.716248 606.640686,521.631226 
	C605.272522,521.109436 603.904419,520.587646 602.204773,519.300964 
	C602.279480,518.208374 602.354126,517.115784 603.182495,516.030212 
	C605.438843,515.320862 607.695190,514.611572 609.951477,513.902222 
	C607.425354,511.514130 604.627014,511.054810 603.161011,509.408813 
	C600.343567,506.245300 597.113464,505.807220 593.313843,505.964203 
	C588.991882,506.142792 584.602539,505.608521 580.352234,506.188477 
	C578.428894,506.450928 576.770569,508.656097 574.582764,510.075562 
	C574.582764,510.075562 574.163818,510.108643 573.386414,510.095612 
M712.913940,567.759949 
	C712.657532,568.963867 712.401123,570.167786 712.018921,571.962219 
	C714.557678,571.841248 716.845947,572.349670 718.311646,571.476807 
	C719.601379,570.708740 719.971008,568.395447 720.746948,566.764526 
	C719.258362,566.565002 717.768494,566.206238 716.281677,566.218750 
	C715.185669,566.227905 714.093750,566.721985 713.072754,566.297791 
	C714.320435,565.065430 715.353394,563.318604 716.854004,562.692383 
	C722.436157,560.362915 723.617615,555.246277 724.778137,550.338257 
	C725.137390,548.818909 723.453918,546.816650 723.058899,545.876343 
	C718.938354,551.968323 715.281311,557.620544 711.352356,563.076965 
	C709.475586,565.683472 710.392639,566.557129 712.913940,567.759949 
M478.429199,464.980560 
	C476.119293,464.422302 473.821808,463.803314 471.495331,463.325836 
	C469.778992,462.973602 468.020966,462.829285 466.288757,462.547760 
	C465.131866,462.359711 463.987701,462.093475 462.837860,461.862244 
	C463.075348,463.126953 463.304810,464.393250 463.553741,465.655701 
	C463.679901,466.295654 463.992401,466.935883 463.962341,467.563629 
	C463.753876,471.918518 466.595551,472.827667 470.053101,472.981262 
	C472.040161,473.069550 474.099335,473.310791 476.007050,472.912781 
	C477.941162,472.509247 479.728760,471.403412 481.580505,470.605194 
	C480.777161,468.797791 479.973846,466.990356 478.429199,464.980560 
M752.891357,503.378510 
	C752.721375,501.042999 752.646423,496.686188 752.367310,496.673096 
	C747.669067,496.452393 742.910522,496.525879 738.256775,497.181732 
	C737.324097,497.313141 736.580444,500.069397 736.143982,501.719696 
	C735.998169,502.271179 737.063843,503.763855 737.605469,503.779236 
	C742.458801,503.917389 747.318848,503.821716 752.891357,503.378510 
M695.273804,497.538361 
	C696.564575,497.625366 698.357544,498.280548 699.039001,497.678192 
	C700.355774,496.514221 701.426086,494.737305 701.858826,493.027252 
	C702.137756,491.925262 701.015930,489.358429 700.664490,489.384338 
	C698.036072,489.577881 694.808167,489.478943 692.992371,490.966309 
	C690.444702,493.053223 692.741089,495.348816 695.273804,497.538361 
M609.111145,396.190552 
	C608.863159,403.633545 609.083374,403.822327 616.636780,402.363342 
	C616.251404,400.315308 616.241333,398.072937 615.387207,396.219452 
	C614.003174,393.215820 611.935791,391.511719 609.111145,396.190552 
M662.866394,465.703552 
	C661.237305,462.157440 659.611938,462.337769 658.132507,465.771393 
	C657.622009,466.956055 657.073425,468.262421 657.101990,469.500275 
	C657.127747,470.618256 657.918030,472.654541 658.370239,472.654297 
	C659.826721,472.653473 661.919189,472.320801 662.577332,471.327881 
	C663.395569,470.093262 663.028503,468.072998 662.866394,465.703552 
M622.147827,404.994904 
	C623.001038,406.311188 623.672546,407.833740 624.805847,408.832123 
	C625.204224,409.183075 627.577209,408.309509 627.607422,407.890991 
	C627.765991,405.696228 627.462341,403.468109 627.319214,401.251556 
	C626.443787,400.793304 625.568359,400.335022 624.692932,399.876740 
	C623.944214,401.348328 623.195435,402.819916 622.147827,404.994904 
M675.253479,599.940247 
	C677.126648,601.526672 678.734619,601.411621 678.875488,598.744080 
	C679.007446,596.244385 680.188416,592.667114 675.057800,592.514160 
	C675.057800,594.924133 675.057800,597.021240 675.253479,599.940247 
M420.807343,407.177582 
	C420.320984,406.159332 419.834595,405.141083 419.348236,404.122833 
	C418.611725,404.589600 417.242340,405.063385 417.247375,405.521973 
	C417.270264,407.591888 417.682251,409.657532 417.954041,411.724731 
	C418.452240,411.663147 418.950409,411.601593 419.448608,411.540009 
	C419.809692,410.331482 420.170746,409.122925 420.807343,407.177582 
M749.622192,513.969910 
	C748.479431,514.070312 747.336731,514.170776 746.193970,514.271240 
	C746.509216,515.309753 746.602600,517.056458 747.185852,517.239136 
	C748.393005,517.617249 749.860718,517.163391 751.221313,517.051758 
	C750.939514,516.054749 750.657715,515.057800 749.622192,513.969910 
M409.917389,719.404724 
	C408.781647,718.725891 407.693146,717.902039 406.466461,717.502380 
	C406.246704,717.430786 405.455750,719.112610 404.250763,721.106140 
	C407.038269,720.006531 408.912476,723.459595 409.917389,719.404724 
M409.009827,399.681122 
	C409.090546,400.726166 409.171265,401.771240 409.251984,402.816284 
	C410.078857,402.512421 410.905701,402.208557 411.732574,401.904694 
	C411.037720,401.048767 410.342865,400.192871 409.009827,399.681122 
M723.093872,588.390564 
	C724.010498,587.817322 724.927124,587.244019 725.843750,586.670776 
	C725.337769,586.165527 724.831848,585.660339 724.325867,585.155090 
	C723.750732,586.070068 723.175598,586.985046 723.093872,588.390564 
M707.609558,493.797424 
	C707.214661,493.793274 706.819824,493.789093 706.424927,493.784912 
	C706.423401,493.948792 706.410889,494.255096 706.421936,494.255951 
	C706.814880,494.286194 707.210022,494.287842 707.609558,493.797424 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M631.030701,391.978943 
	C629.700500,393.134033 628.527466,394.612366 626.997314,395.356384 
	C625.361328,396.151855 623.032043,397.059174 621.656433,396.449249 
	C620.067749,395.744934 618.117920,393.379211 618.200806,391.835663 
	C618.471802,386.791382 617.173462,382.927032 613.261169,379.536255 
	C610.849121,377.445709 612.164185,371.284851 614.853516,368.716644 
	C616.990540,366.675812 619.375793,364.742554 620.938660,362.304871 
	C622.029907,360.602844 622.913818,356.828888 622.154480,356.247467 
	C619.987305,354.588135 616.948364,353.501221 614.220520,353.431458 
	C613.192078,353.405121 611.089600,356.849274 611.198669,358.604340 
	C611.453308,362.701447 607.330322,366.623840 610.641846,370.901886 
	C610.754150,371.046936 607.984497,373.322449 606.666870,374.708252 
	C605.712891,375.711670 604.939026,376.886383 604.084900,377.984711 
	C605.183228,378.674042 606.197144,379.664612 607.394653,380.000061 
	C612.342346,381.386108 614.470215,384.634491 611.949768,387.678711 
	C610.959717,388.874451 608.829163,389.820068 607.331116,389.678925 
	C606.156799,389.568268 604.882935,387.756073 604.166260,386.423859 
	C603.597595,385.366821 603.806274,383.891632 603.620056,382.131622 
	C600.920776,382.829071 598.569641,383.436554 596.218567,384.044037 
	C600.866760,386.999481 602.017639,390.522522 600.399475,395.715515 
	C595.438477,395.315613 591.350037,396.675018 593.009888,403.162903 
	C588.697937,405.120972 585.457458,403.418304 583.225708,400.790405 
	C582.182129,399.561584 583.047180,396.711914 583.047180,393.944672 
	C580.864502,392.782928 578.183228,391.664856 575.503601,394.828369 
	C573.530396,397.157990 573.330200,398.965118 578.091492,399.888336 
	C577.570374,400.379791 577.049255,400.871216 576.528137,401.362671 
	C575.114441,401.239532 572.640198,401.484680 572.449890,400.936646 
	C570.401733,395.038818 567.081482,396.950958 563.569580,399.174713 
	C563.019226,399.523224 562.347107,399.679413 561.402893,399.597107 
	C560.580505,397.875458 560.186829,395.341949 559.574707,395.287994 
	C557.169739,395.076019 553.739624,393.654877 553.073303,398.549561 
	C555.760864,399.380005 558.379211,400.189026 560.997559,400.998077 
	C559.644531,404.386749 557.115723,403.426056 554.441711,403.085297 
	C552.041260,402.779449 549.269287,402.781342 547.210571,403.779968 
	C546.464111,404.142029 547.275940,407.786346 547.446777,409.927399 
	C547.498352,410.573578 547.851440,411.190491 547.954346,411.838623 
	C548.936340,418.022980 549.256287,418.188568 555.816345,414.569824 
	C557.627869,417.460968 559.139648,420.732910 561.463867,423.256775 
	C562.828247,424.738373 565.473083,425.585449 567.565491,425.630707 
	C568.674438,425.654724 569.917236,423.420105 570.944641,422.085846 
	C571.824524,420.943146 572.483765,419.630646 573.240601,418.393311 
	C573.831543,419.794708 574.660706,421.146179 574.967285,422.607269 
	C575.766663,426.417084 576.336853,430.274933 576.639648,434.525635 
	C571.673706,432.432861 569.817139,433.015564 565.921021,438.189026 
	C562.232971,443.086243 558.937500,444.138794 554.167725,441.665894 
	C554.536499,440.583832 554.823303,439.805023 555.483154,439.016296 
	C561.955444,438.622986 560.584656,434.310364 559.925659,430.765991 
	C559.240967,427.083374 558.896545,422.815674 553.705688,422.347229 
	C553.495789,422.328278 553.245178,422.761169 552.583618,422.987000 
	C548.105408,421.987061 544.057129,420.983643 539.934937,419.610535 
	C537.444641,418.494415 535.044983,417.687378 532.608643,417.013062 
	C525.596558,415.072357 517.130493,417.464569 511.954193,409.870758 
	C511.377075,409.024139 509.214020,409.119629 507.770477,409.026794 
	C501.840454,408.645508 495.906769,408.272339 489.968536,408.098969 
	C488.392822,408.052979 486.795959,408.730042 484.984070,409.123993 
	C486.608582,411.913910 487.798218,413.956940 488.978394,416.291443 
	C488.968842,416.777252 488.968719,416.971649 488.667206,417.152283 
	C487.965027,417.137695 487.564209,417.136902 486.770996,417.074158 
	C485.585571,417.006744 484.792542,417.001312 483.801025,416.742035 
	C482.732452,416.982391 481.862366,417.476562 480.576599,417.983948 
	C478.773224,417.994629 477.385498,417.992157 475.721375,417.704102 
	C471.683258,417.132629 467.567566,415.415985 465.005646,420.000305 
	C465.000336,420.000336 464.999786,420.010986 464.561035,420.006683 
	C459.084106,421.004028 454.045898,422.005676 448.926636,422.628448 
	C446.230164,421.499664 443.614838,420.749756 440.999481,419.999878 
	C442.017670,419.497406 443.003967,418.655975 444.060120,418.556549 
	C447.127533,418.267731 448.591431,416.809235 450.000580,413.941650 
	C451.228302,411.443329 453.691864,409.771057 451.489838,406.649536 
	C451.243073,406.299744 451.951691,405.276031 452.217224,404.564819 
	C453.028046,404.898529 454.473816,405.122559 454.553284,405.582581 
	C455.549713,411.351776 460.026245,410.335114 463.535614,409.786224 
	C470.150726,408.751526 476.655884,407.013550 482.255219,405.777893 
	C484.531525,402.387115 486.406036,399.594818 488.446899,396.554749 
	C487.235077,395.856415 485.729889,394.989014 484.065369,394.029816 
	C484.230713,393.376648 484.180267,392.516510 484.574860,392.183167 
	C487.996704,389.292267 491.298035,386.171509 495.078033,383.835846 
	C496.532593,382.937103 499.130829,383.889191 501.543945,384.351929 
	C501.420502,385.128082 500.942505,385.532013 500.505188,385.976013 
	C499.358551,387.139984 498.229645,388.321442 497.093658,389.495911 
	C498.646057,390.331909 500.114258,391.578461 501.768555,391.917725 
	C505.114014,392.603790 508.628845,392.521271 511.929535,393.338501 
	C513.148438,393.640259 514.217712,395.457489 514.880737,396.815247 
	C515.449646,397.980377 515.342102,399.475830 515.753174,402.370789 
	C517.452209,400.321869 518.799377,399.446442 518.800537,398.569275 
	C518.806091,394.230042 521.174988,392.080627 525.428589,391.000916 
	C527.571594,391.014252 529.286072,391.029938 531.045166,391.420044 
	C533.122192,398.434875 536.257080,400.783691 539.820862,397.850433 
	C541.565674,396.414337 541.983337,393.365662 543.418213,391.034607 
	C545.223694,391.016144 546.612671,391.009918 548.263794,391.285400 
	C551.375488,391.754028 554.839355,393.724213 556.005554,388.689117 
	C556.034790,387.979401 556.053589,387.574677 556.389282,387.116272 
	C557.133118,387.025543 557.559998,386.988556 558.185181,387.215271 
	C559.920715,387.326294 561.457947,387.173615 563.105103,387.237427 
	C563.417480,387.722076 563.679077,387.905731 564.020874,388.302765 
	C564.362793,389.068268 564.683838,389.535919 564.968506,390.361084 
	C565.086853,392.006866 565.241577,393.295074 565.396240,394.583313 
	C567.215149,394.203918 569.332092,394.254150 570.737915,393.279083 
	C571.542419,392.721100 571.446350,390.469299 571.297791,389.027222 
	C571.088806,386.997833 570.454712,385.012238 570.221191,382.896454 
	C570.711548,382.584442 570.906860,382.326172 571.387329,382.004120 
	C572.835144,381.007050 573.921570,380.016113 575.338379,379.015900 
	C576.109192,379.000366 576.549744,378.994080 577.363647,378.987305 
	C579.490173,377.323944 581.243286,375.661102 583.262451,373.782166 
	C583.658997,372.672241 583.789490,371.778351 584.108459,370.849457 
	C584.296936,370.814453 584.678589,370.850342 585.054688,370.863617 
	C585.905334,370.151764 586.379822,369.426636 586.886230,368.404602 
	C587.275574,367.401154 587.632996,366.694580 588.328857,365.954315 
	C589.056641,365.210907 589.445984,364.501190 589.933350,363.592590 
	C590.203308,363.138092 590.435730,362.962372 591.124451,362.914032 
	C594.358398,364.987000 597.196533,367.012573 599.496948,368.654327 
	C601.156799,364.120361 602.645081,360.146149 604.051575,356.143219 
	C604.368469,355.241455 604.889343,353.858246 604.487488,353.342560 
	C600.449829,348.161926 603.499023,342.405609 602.973999,336.597656 
	C602.998413,335.489746 603.014832,334.750793 603.320923,333.976868 
	C603.739502,333.294464 603.868530,332.647064 603.997559,331.624756 
	C603.997559,330.500061 603.997559,329.750305 604.293091,328.771057 
	C605.845276,325.067413 608.534363,321.358582 603.018555,318.660583 
	C603.009033,317.885986 603.005737,317.443359 603.229126,316.853699 
	C603.295288,316.135986 603.134888,315.565277 602.973572,314.668640 
	C602.969543,313.908051 602.966431,313.473450 602.938416,312.679138 
	C601.621704,311.544800 600.329895,310.770172 598.979980,309.626831 
	C598.866211,308.265015 598.810608,307.271881 598.839111,305.837006 
	C598.944885,303.930878 598.966431,302.466522 599.318726,300.859802 
	C601.281555,301.422455 602.913757,302.127472 604.599731,302.855743 
	C604.888062,302.265930 605.442810,301.131134 606.301208,300.125000 
	C611.357727,300.495911 613.245483,297.195587 613.862854,293.461853 
	C614.466492,289.811066 614.363342,286.390198 609.950867,283.878510 
	C606.754395,282.058960 604.873474,277.928223 602.405884,274.828156 
	C601.735901,275.078430 601.065918,275.328735 600.395874,275.579010 
	C600.781982,277.398254 600.758484,279.464142 601.665283,280.969818 
	C602.640198,282.588806 604.839478,283.471497 605.810242,285.091522 
	C606.313660,285.931580 605.316711,287.670715 604.607544,289.001953 
	C602.611389,288.195312 601.005127,287.390411 599.081787,286.426544 
	C593.854858,289.150513 593.854858,289.150513 587.585266,280.539978 
	C593.418457,277.452942 593.418457,277.452942 592.023682,273.292816 
	C587.696960,273.527008 583.356873,273.761932 578.901672,273.779297 
	C578.582947,273.290100 578.319946,273.100922 577.993408,272.618835 
	C576.176453,267.409180 573.721863,266.248657 568.619385,268.004883 
	C567.732666,268.092255 567.227600,268.168549 566.432373,268.227417 
	C565.760681,268.144165 565.379150,268.078339 564.999512,267.635437 
	C565.000610,266.506012 564.999695,265.753601 564.999390,265.000610 
	C565.000000,265.000000 565.002441,264.997559 565.002441,264.997559 
	C577.851440,264.998138 590.700806,264.948486 603.548828,265.060028 
	C605.241089,265.074707 606.916321,265.954529 608.617065,266.359894 
	C610.900085,266.904083 613.336548,268.086517 615.454834,267.680054 
	C617.578064,267.272644 619.609619,264.934509 621.288086,267.896057 
	C622.755554,270.485352 624.984436,273.606995 624.584167,276.129730 
	C622.467407,289.471619 619.857239,302.750305 616.906738,315.936493 
	C615.913696,320.374573 613.417786,324.476349 611.448975,329.107635 
	C610.019470,328.055237 608.910583,327.238831 607.384094,326.115021 
	C606.857117,334.469818 614.514343,334.113678 617.762207,338.018341 
	C618.175598,337.790649 618.588989,337.562958 619.002319,337.335266 
	C618.470947,335.574768 617.889221,333.827332 617.426025,332.049042 
	C617.122559,330.884125 616.401611,329.255280 616.893494,328.564392 
	C618.309143,326.575653 620.245605,324.957642 621.977478,323.194000 
	C622.985718,325.295288 624.071777,327.364258 624.963928,329.513733 
	C625.322083,330.376465 625.254089,331.416077 625.398132,332.512756 
	C631.158691,333.019531 636.743591,333.389557 642.292236,334.059692 
	C644.462402,334.321808 646.551147,335.257965 648.677002,335.887146 
	C647.433411,337.594147 646.189758,339.301178 644.559265,341.539185 
	C645.876526,342.235992 647.599487,343.147430 649.538025,344.172852 
	C651.325256,340.271118 652.855652,336.930054 654.385986,333.588989 
	C654.990906,333.786652 655.595764,333.984344 656.200623,334.182007 
	C659.779480,340.260925 657.487366,346.251038 655.260132,352.574005 
	C660.057434,354.511627 659.097656,358.814728 658.955933,362.703094 
	C658.834229,366.041779 659.552673,368.078278 663.503784,368.135254 
	C670.730042,368.239502 677.950684,368.710083 685.175110,368.988434 
	C687.148560,369.064453 689.127441,369.000000 691.043823,369.000000 
	C692.055725,373.370636 693.344604,377.309113 693.717102,381.332458 
	C693.870056,382.984772 692.047607,384.819946 690.651794,387.467194 
	C690.768921,387.726807 691.349487,389.013123 691.557251,390.649933 
	C687.108459,390.683777 683.032410,390.367157 678.968018,390.045776 
	C678.979675,390.040985 678.961060,390.049011 678.943970,389.698517 
	C677.592896,388.236725 676.258972,387.125427 674.805542,385.825043 
	C674.494019,385.400085 674.259460,385.219543 673.949951,384.757568 
	C673.256775,383.950867 672.596008,383.480865 671.892944,382.825012 
	C671.850708,382.639191 671.861328,382.258148 671.882324,381.877930 
	C668.769775,376.517120 666.445923,370.527069 658.622437,370.887451 
	C657.199829,369.853180 656.044617,368.992279 654.923706,367.743073 
	C651.587585,364.613892 648.217163,361.873016 644.631470,359.114075 
	C644.416199,359.095947 643.990173,359.023865 643.959351,358.654114 
	C642.744202,354.780487 641.037476,352.396423 636.661499,355.031006 
	C635.912415,354.974487 635.485107,354.931580 634.955383,354.520782 
	C632.551453,353.837219 630.253845,353.433929 627.943115,353.333893 
	C627.697266,353.323273 627.374573,355.087219 627.054810,356.394806 
	C623.276367,363.088928 619.530701,369.416138 615.425903,376.350067 
	C617.697449,378.344879 620.346313,380.670959 623.028503,383.344177 
	C624.425232,384.750977 625.788574,385.810669 627.370361,386.885498 
	C627.588806,386.900665 628.022888,386.958496 628.047424,387.302917 
	C629.058167,389.091187 630.044434,390.535065 631.030701,391.978943 
M592.464050,377.672363 
	C586.325012,376.484314 579.308777,382.029602 577.549805,389.459869 
	C581.788940,391.610840 584.941284,391.044952 584.809753,387.853821 
	C584.596436,382.676666 587.011475,381.544434 591.394531,381.882507 
	C592.313416,381.953400 593.418884,381.552155 594.195679,381.016479 
	C595.120850,380.378418 595.784363,379.360931 596.561218,378.507812 
	C595.434509,378.250336 594.307861,377.992889 592.464050,377.672363 
M609.520386,313.017975 
	C608.383057,314.246246 606.128418,315.759216 606.352966,316.640533 
	C606.756470,318.223877 608.561584,319.540314 610.016418,320.663208 
	C610.222534,320.822357 612.237610,319.436340 612.569397,318.473846 
	C613.344360,316.225220 613.604309,313.799469 609.520386,313.017975 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M483.981049,366.001312 
	C480.592529,367.000885 477.251312,368.236633 473.801025,368.927856 
	C470.857025,369.517670 468.694061,368.237915 467.835602,365.063721 
	C467.555023,364.026306 466.830505,362.590790 465.988556,362.314758 
	C462.661896,361.223999 462.071320,358.930695 461.818024,355.805786 
	C461.709290,354.463989 459.677002,353.278046 458.518707,352.021271 
	C457.348083,353.265717 455.753052,354.320190 455.093628,355.793457 
	C453.641205,359.038452 453.143311,362.797394 451.295013,365.743805 
	C450.294037,367.339478 447.377991,368.274658 445.222168,368.495453 
	C440.328766,368.996552 435.311493,369.410675 430.453766,368.873291 
	C423.304504,368.082428 416.440155,368.465088 409.356018,370.550964 
	C410.282776,369.151581 411.397186,367.747467 412.840057,367.183533 
	C422.572205,363.379608 432.331329,359.634613 442.169525,356.115936 
	C447.855774,354.082214 450.536102,348.800385 448.431061,343.210052 
	C447.699005,341.265991 447.419739,339.151367 446.956329,336.693726 
	C445.973083,333.517151 444.967194,330.761475 443.823792,327.701752 
	C442.457642,326.263184 441.229034,325.128632 439.987366,323.593445 
	C438.589844,322.477753 437.243958,321.280975 435.811371,321.166107 
	C433.896973,321.012634 431.693695,321.259552 430.006500,322.111145 
	C425.899750,324.184021 422.019073,326.704773 418.034271,329.023132 
	C418.021210,329.001099 418.030975,329.047943 417.664124,329.036194 
	C416.519806,329.738678 415.742371,330.452881 414.679749,331.287415 
	C413.613556,331.642609 412.832458,331.877502 412.051392,332.112427 
	C412.382324,329.846741 412.234283,327.361237 413.150726,325.364258 
	C414.727295,321.928833 416.934326,318.777161 418.958252,315.557648 
	C419.334839,314.958527 420.094727,314.600372 420.863312,313.981628 
	C414.266418,311.746948 413.835480,310.500183 417.140350,304.298706 
	C420.157501,298.637146 420.089050,298.781464 425.827759,301.503021 
	C427.317230,302.209442 429.891083,302.447327 431.089386,301.623749 
	C435.450714,298.626221 437.808319,300.288055 439.002777,303.567291 
	C441.893005,302.286255 444.839630,301.522064 447.056000,299.811676 
	C448.591553,298.626678 450.281616,295.854309 449.897736,294.280182 
	C448.269043,287.602173 448.150208,287.366394 454.716003,285.207642 
	C460.420929,283.331940 460.955444,281.786987 457.419891,277.388000 
	C457.096191,277.407532 456.722687,277.333099 456.457245,277.469269 
	C454.464111,278.492004 452.492279,279.556244 450.513763,280.607513 
	C450.309845,277.672028 450.105896,274.736542 450.351288,271.400940 
	C456.464111,273.787598 461.023438,270.772034 465.633636,268.138947 
	C466.630463,267.569641 467.430298,266.393372 468.454987,266.197174 
	C469.940796,265.912781 471.548615,266.265778 473.104370,266.346924 
	C472.605865,267.632874 472.463074,269.328552 471.542236,270.127960 
	C468.773560,272.531433 467.178650,275.194916 468.488586,278.843384 
	C469.111206,280.577393 470.180084,282.151245 471.046783,283.797638 
	C472.558746,282.413269 475.003693,281.256958 475.408234,279.601868 
	C476.636414,274.577118 479.130737,271.808380 483.064117,272.603271 
	C484.192413,269.569641 484.518341,266.058136 485.817230,265.651031 
	C491.865082,263.755432 486.723785,271.278351 491.234344,271.859131 
	C491.789154,270.476166 492.175781,268.724091 493.137238,267.384674 
	C493.811371,266.445526 495.403900,265.394073 496.349762,265.582214 
	C498.318573,265.973785 500.130432,267.154663 501.856018,268.313721 
	C499.004608,270.976135 496.033813,273.094696 493.662354,275.748688 
	C489.380798,280.540344 485.520569,285.705505 481.366760,290.615204 
	C477.234222,295.499756 473.330597,300.385132 473.918732,307.397552 
	C474.036713,308.804474 473.522064,310.387177 472.915070,311.713043 
	C467.230865,324.130005 471.456329,336.240692 474.714691,348.050018 
	C476.467407,354.402405 480.808075,360.040771 483.981049,366.001312 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M267.998779,624.000000 
	C266.041748,624.768677 262.326935,625.950012 262.395752,626.243103 
	C263.210144,629.712280 257.622528,634.255676 264.238251,637.021606 
	C265.423889,637.517273 265.033417,641.782776 265.450928,644.908325 
	C260.809906,644.201660 258.036774,644.322876 255.323456,648.304993 
	C253.928833,650.351807 247.444595,649.418030 243.401367,648.841736 
	C242.347122,648.691467 240.559616,644.059631 241.310089,642.834473 
	C242.984512,640.100830 245.760254,637.703064 248.613495,636.112183 
	C252.558502,633.912598 253.093033,632.900146 250.043930,629.554688 
	C249.483261,628.939514 248.967865,628.048218 248.264114,627.833740 
	C238.173370,624.757935 241.999954,616.846436 242.163193,610.275085 
	C242.232697,607.477844 242.708710,604.690735 243.412567,601.448364 
	C247.698975,601.315979 248.197205,598.893494 248.003250,595.878845 
	C247.764343,592.165466 247.914276,588.881775 251.715866,586.412781 
	C253.194321,585.452576 253.605850,582.353577 253.813461,580.163452 
	C253.903366,579.215149 252.316437,577.332275 251.368759,577.247864 
	C250.033112,577.128845 248.209610,577.954956 247.290222,578.993042 
	C246.405090,579.992493 246.599579,581.866699 245.976578,583.195801 
	C244.421799,586.512573 242.672089,589.737915 241.000610,593.000000 
	C241.370651,588.076599 240.015839,581.948242 242.448563,578.465759 
	C248.646011,569.593811 244.864929,560.219971 245.857819,551.172852 
	C246.044693,549.470154 244.395554,547.562927 243.583176,545.754822 
	C243.142441,544.773926 242.363297,543.829285 242.279205,542.822998 
	C241.806808,537.171021 241.478653,531.506409 241.147095,525.843445 
	C241.031082,523.862366 241.045303,521.873718 241.355423,519.503784 
	C243.558304,520.138855 245.359131,522.676147 247.321030,519.242859 
	C249.134567,516.069092 247.903183,509.940704 244.903870,507.351959 
	C243.603714,508.236420 242.302155,509.118805 241.000595,510.001221 
	C240.669022,500.306396 240.323853,490.612000 240.017944,480.916382 
	C239.960251,479.088379 239.413284,476.862671 240.233292,475.514740 
	C241.536713,473.372192 243.730209,470.358185 245.670197,470.264740 
	C253.339798,469.895264 261.057220,470.678864 268.756989,470.680969 
	C271.228516,470.681671 273.700348,469.591064 276.540497,469.386475 
	C276.590942,471.833862 276.272919,473.894470 275.619690,476.008972 
	C274.131927,476.884583 272.979309,477.706360 272.850739,477.798035 
	C269.880463,475.749603 266.966400,472.209351 264.987915,472.671783 
	C259.737549,473.898956 251.440536,469.197723 250.313416,480.802521 
	C249.479111,478.553833 249.411804,477.803650 249.004898,477.378510 
	C247.575912,475.885498 246.030075,474.504303 244.528610,473.080627 
	C243.690002,474.477295 242.335480,475.798828 242.118851,477.286102 
	C241.650726,480.499817 243.923218,481.075348 246.586395,481.017456 
	C249.917511,480.945038 253.294769,480.708191 256.562500,481.172668 
	C257.808624,481.349792 259.665710,483.214294 259.747253,484.411713 
	C259.946106,487.331757 259.567596,490.389679 258.819611,493.233765 
	C258.449677,494.640289 256.749542,495.696991 255.651260,496.911987 
	C254.768616,495.556458 253.684326,494.288269 253.050522,492.824951 
	C252.093842,490.616272 251.451523,488.271484 250.614334,485.803986 
	C246.059769,488.417938 243.594284,491.615784 245.227875,495.411560 
	C247.973831,501.791931 249.443802,509.486115 257.657349,511.860565 
	C257.432709,514.146912 257.460785,516.495789 256.921783,518.706482 
	C256.005524,522.464172 255.090744,526.300110 253.485382,529.785278 
	C252.379837,532.185425 250.138901,534.074402 248.351669,536.145386 
	C246.448135,538.351135 246.533020,540.300720 249.539154,540.926819 
	C253.532669,541.758484 254.380753,544.058411 254.064514,547.718689 
	C253.764404,551.192322 254.265289,554.737000 253.940933,558.206238 
	C253.385025,564.151733 253.976791,569.155273 261.204712,570.353149 
	C261.355286,570.378113 261.420898,570.915771 261.526123,571.214233 
	C261.097198,571.049194 260.668243,570.884216 260.239288,570.719177 
	C258.846649,573.937073 257.336395,577.113892 256.184326,580.415649 
	C256.005188,580.929077 257.461761,582.013306 258.161926,582.833557 
	C258.774597,582.222351 259.387299,581.611145 260.366089,581.066101 
	C261.486877,584.087341 262.241608,587.042419 262.615173,590.004150 
	C261.386017,591.175537 259.955811,592.272827 259.811188,593.519226 
	C259.510101,596.113464 260.104340,598.800049 259.941132,601.424438 
	C259.671478,605.761353 256.783142,607.046082 253.161789,604.664734 
	C252.164062,604.008728 251.076157,603.489807 248.945923,602.305420 
	C249.585190,609.782715 249.759186,616.153564 250.814255,622.375000 
	C251.362228,625.606201 253.309723,628.662964 254.977386,631.598267 
	C255.408234,632.356567 257.259277,632.779541 258.286133,632.558472 
	C258.947998,632.415894 259.663513,630.841003 259.721893,629.869995 
	C259.965515,625.817871 259.364471,621.605774 260.292725,617.745605 
	C260.831909,615.503479 259.005249,611.062256 264.005524,611.380859 
	C265.340302,615.841125 266.669556,619.920532 267.998779,624.000000 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M333.000000,369.000000 
	C333.332672,368.999908 333.665436,369.003693 333.998047,368.999146 
	C337.487457,368.951508 341.508331,369.909576 344.257843,368.458771 
	C345.794403,367.647980 345.220581,362.837799 345.650482,359.409119 
	C346.980408,359.225922 348.719635,359.325897 350.122070,358.686768 
	C351.263153,358.166718 352.676514,356.782501 352.709167,355.741974 
	C352.736633,354.867371 350.979340,353.898468 349.956085,353.038025 
	C348.381287,351.713684 346.758514,350.446411 345.156097,349.154907 
	C346.850586,347.959076 348.392334,346.261475 350.276245,345.687958 
	C352.939301,344.877380 355.143372,340.387421 354.347260,334.468719 
	C357.423065,334.963989 360.484222,334.759399 362.803741,335.972717 
	C367.937683,338.658112 372.308533,336.858765 376.640350,334.555298 
	C380.152740,332.687592 382.641693,331.933105 386.122711,335.487457 
	C387.703033,337.101044 392.488464,335.798187 395.775208,335.424561 
	C397.432373,335.236176 398.989685,334.169373 400.795685,334.123199 
	C400.997223,335.490326 400.995209,336.233978 400.611328,337.007202 
	C399.208282,337.058014 398.187195,337.079224 396.804749,337.093048 
	C395.305328,338.405396 394.167236,339.725128 392.789001,341.307739 
	C388.398834,343.398682 384.248749,345.226685 379.745728,347.079956 
	C378.923065,348.046570 378.453339,348.987946 377.597534,349.961792 
	C376.178650,350.018738 375.145844,350.043274 373.747955,350.073090 
	C371.271149,352.062622 369.159424,354.046844 367.032684,356.020386 
	C367.017670,356.009705 367.018219,356.046417 366.616516,356.028687 
	C365.143219,356.006500 364.071625,356.002014 363.149963,355.691406 
	C364.317383,354.302551 365.341644,353.226227 366.350861,352.135956 
	C368.751038,349.542999 368.748169,346.803955 366.584625,344.144226 
	C364.621887,341.731262 362.191711,339.548981 359.009888,341.767639 
	C356.264709,343.681793 354.470001,346.156555 355.478699,350.195709 
	C356.513397,354.339020 356.538544,358.734375 356.710022,363.187927 
	C353.272552,366.238098 350.130676,369.119843 346.624878,372.003357 
	C345.178833,373.010345 344.096710,374.015564 342.870544,375.319275 
	C340.168488,378.089539 337.610535,380.561279 334.719727,383.060669 
	C333.941223,383.751465 333.495575,384.414642 332.902130,385.386963 
	C330.847565,387.471832 328.940796,389.247589 326.705994,391.054199 
	C325.936157,391.737732 325.494324,392.390350 325.026581,393.023712 
	C325.000702,393.004456 325.043915,393.053192 324.694641,393.066315 
	C322.902893,394.731842 321.460449,396.384247 319.886627,398.335663 
	C318.513153,400.095551 317.271027,401.556427 315.689514,403.057434 
	C307.739410,407.733826 304.803955,415.705505 301.060364,423.332336 
	C301.047760,423.555054 301.009766,424.001831 300.624237,424.005585 
	C295.245392,426.909546 294.113983,431.691925 293.572571,436.960602 
	C291.420685,437.748993 289.633118,438.516205 287.845551,439.283417 
	C288.231781,439.461090 288.617981,439.638763 289.004211,439.816406 
	C288.435974,441.681458 287.959503,443.580780 287.278015,445.403442 
	C286.257782,448.132019 285.274139,450.901001 283.931824,453.473785 
	C282.896027,455.459137 281.333282,457.169525 280.004883,459.002197 
	C283.171356,454.674133 281.177063,450.541534 279.082001,446.772644 
	C276.270813,441.715332 276.055573,436.981873 278.669891,431.807007 
	C279.471649,430.220001 279.092194,428.012787 279.161285,426.086273 
	C279.220520,424.434601 278.567902,422.478790 279.239105,421.178314 
	C281.518616,416.761902 280.382111,413.001129 278.142517,409.039978 
	C277.490295,407.886383 277.542969,405.799896 278.181702,404.643036 
	C278.591095,403.901489 280.672180,403.842957 281.990417,403.886536 
	C286.632416,404.039978 291.060516,405.300629 295.694275,402.109894 
	C297.552399,400.830475 301.872314,403.126160 306.017548,403.992218 
	C307.091370,403.335663 309.981628,402.449005 311.307953,400.498749 
	C312.525421,398.708557 312.430023,395.743164 312.245697,393.346863 
	C311.954834,389.564545 310.581543,385.815308 310.652191,382.070648 
	C310.712982,378.846832 312.487579,376.181488 316.538635,375.879761 
	C317.888123,375.779266 319.133789,374.284546 321.067230,373.273468 
	C321.470367,375.978363 321.310303,378.846619 320.955627,381.690582 
	C320.825897,382.730743 320.326630,384.504272 319.834717,384.561707 
	C318.723389,384.691406 317.471741,384.049500 316.354156,383.549438 
	C315.561188,383.194580 314.894012,382.558624 313.498260,381.573608 
	C313.498260,384.478210 313.498260,386.581573 313.498260,388.763153 
	C316.966156,388.763153 320.824127,389.945312 322.733826,388.518982 
	C326.806183,385.477264 330.355194,381.396057 333.119781,377.098267 
	C334.336334,375.207062 333.114655,371.747498 333.000000,369.000000 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M841.003540,620.923584 
	C831.601562,621.217773 822.696350,621.456055 813.796509,621.822327 
	C804.891968,622.188721 803.159790,620.702026 803.143738,611.917725 
	C803.100708,588.418823 803.123596,564.919739 803.120850,541.420776 
	C803.120056,534.491272 803.186890,527.560791 803.075806,520.633057 
	C803.047607,518.874084 802.446777,517.125977 802.128296,515.369324 
	C801.992004,514.617615 801.618713,513.754761 801.845886,513.118225 
	C802.802429,510.437897 811.957153,506.492065 814.739136,507.340881 
	C815.606567,507.605530 816.626160,507.605652 817.535217,507.464142 
	C827.986816,505.837311 831.806824,509.144653 831.808716,519.906982 
	C831.812805,543.239563 831.921082,566.573059 831.718750,589.903870 
	C831.677063,594.706970 833.160645,596.364380 838.000183,596.166138 
	C850.958862,595.635315 863.935303,595.554199 876.901367,595.181702 
	C882.567261,595.018921 884.647034,596.943848 884.883301,602.733887 
	C885.018738,606.050232 883.533325,609.810791 887.220947,612.338501 
	C887.589600,612.591248 887.635681,613.583923 887.544861,614.190308 
	C887.175232,616.659546 880.631165,621.887268 877.903931,621.826599 
	C865.769409,621.557129 853.636292,621.229919 841.003540,620.923584 
M806.058594,568.500000 
	C806.069641,582.153687 806.055786,595.807434 806.101562,609.460999 
	C806.126526,616.893738 808.210938,618.956360 815.418152,618.955139 
	C833.400940,618.952087 851.383728,618.972046 869.366516,618.961426 
	C881.956970,618.953918 883.154846,621.229675 882.825134,604.711670 
	C882.713074,599.096802 881.618896,598.005737 875.992371,597.991028 
	C862.338989,597.955322 848.685120,598.051880 835.031738,598.016846 
	C829.000854,598.001343 826.844971,595.609863 827.150757,589.751953 
	C827.418884,584.615356 827.760010,579.474487 827.779236,574.334473 
	C827.849670,555.520325 827.807678,536.705688 827.789673,517.891235 
	C827.783264,511.181183 826.434814,510.059448 819.855652,510.766815 
	C817.550598,511.014648 815.199524,510.820007 812.869995,510.857697 
	C807.208435,510.949280 806.100159,511.998077 806.088928,517.547791 
	C806.055298,534.198486 806.065613,550.849243 806.058594,568.500000 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M589.820496,747.714844 
	C589.820496,747.714844 589.630920,747.479065 589.862183,747.056641 
	C591.075562,745.531982 592.057617,744.429810 593.443481,743.267273 
	C595.156067,743.063599 596.683960,743.307922 597.737183,742.712585 
	C603.552795,739.424683 609.312195,736.026611 614.964783,732.466064 
	C617.466675,730.890137 619.671997,728.843384 622.005981,727.005188 
	C622.000000,727.000000 622.010071,727.012268 622.358459,726.980957 
	C624.270935,725.751465 625.770935,724.456482 627.409485,723.370667 
	C638.455750,716.050110 648.309021,707.287476 656.990784,697.374634 
	C664.219482,689.120789 670.890076,680.329285 677.242798,671.375061 
	C681.207825,665.786255 683.845337,659.267395 687.657654,653.552185 
	C689.041748,651.477234 692.017029,649.466003 694.422485,649.261414 
	C701.026245,648.699768 707.721313,648.915527 714.364075,649.200073 
	C719.700073,649.428650 722.623169,654.731689 720.085205,659.422485 
	C715.804016,667.335205 711.303589,675.129333 706.849243,683.302124 
	C706.800232,684.294128 706.797363,684.957397 706.476685,685.751221 
	C701.950989,690.853333 697.683594,695.776062 693.551697,700.809875 
	C689.241516,706.060913 685.061707,711.419006 680.500610,716.832886 
	C678.446106,718.957947 676.715881,720.980347 674.856689,723.197388 
	C674.524902,723.635315 674.280701,723.825256 673.670898,723.997437 
	C672.874146,724.674927 672.401672,725.316956 671.637939,726.115967 
	C669.551514,728.157471 667.756348,730.041992 665.621216,731.967285 
	C663.794617,733.629883 662.308044,735.251770 660.514709,736.939270 
	C659.745850,737.642090 659.283630,738.279297 658.522827,739.061462 
	C657.127563,740.093201 656.030884,740.979980 654.566956,741.888306 
	C651.061951,744.552368 647.924255,747.194885 644.786499,749.837402 
	C644.389343,749.867798 643.992126,749.898132 642.984436,749.989746 
	C641.852356,750.625854 641.330872,751.200745 640.464722,751.855835 
	C634.403503,755.585938 628.686768,759.235718 622.970032,762.885498 
	C622.163757,763.150879 621.357422,763.416199 619.908569,763.851440 
	C614.239197,766.633423 609.252747,769.327393 604.174805,771.836060 
	C598.493286,774.642944 592.728760,777.281738 587.000305,779.993652 
	C586.565552,780.008972 586.130798,780.024231 584.988281,780.026550 
	C581.109314,781.605042 577.938171,783.196594 574.767029,784.788086 
	C573.132751,784.876404 571.498413,784.964722 569.080383,785.029663 
	C566.533020,786.000061 564.769409,786.993835 563.005798,787.987671 
	C562.271729,788.040894 561.537598,788.094116 560.101807,788.120972 
	C558.602295,788.391968 557.804565,788.689331 557.006775,788.986694 
	C555.306885,789.015869 553.606934,789.045105 551.190186,789.030640 
	C549.987183,788.691467 549.501038,788.395874 549.258667,787.805298 
	C556.838867,785.744995 564.275513,784.310059 571.484497,782.124390 
	C579.192505,779.787537 586.800537,777.016968 594.232117,773.909485 
	C599.107666,771.870728 603.592896,768.898560 608.572632,766.264038 
	C609.532410,766.017273 610.172546,765.850952 611.174683,765.765320 
	C618.445435,761.738708 625.661865,758.064514 632.193665,753.426392 
	C643.097778,745.683411 653.861694,737.681885 664.141968,729.136475 
	C677.998169,717.618530 688.689331,703.158020 699.392639,688.808594 
	C701.620667,685.821594 703.040833,682.232117 704.914062,678.717773 
	C704.994934,678.515320 705.109375,678.094666 705.447021,678.033997 
	C709.639038,671.079346 713.601929,664.240356 717.166077,657.199463 
	C717.492798,656.554199 715.493469,653.820984 714.182312,653.452881 
	C711.695984,652.754761 708.819092,653.503540 706.291931,652.884949 
	C696.590759,650.510437 689.435791,652.375366 686.195251,663.025635 
	C685.129517,666.528381 682.700745,669.616394 680.893921,672.904968 
	C680.892700,672.915894 680.879150,672.900757 680.542725,672.956116 
	C679.831848,673.326172 679.368042,673.577942 679.095642,673.964661 
	C667.399048,690.571106 654.406921,705.853210 638.282715,718.549194 
	C625.977112,728.238647 613.130249,736.885437 599.352783,744.202698 
	C596.389404,745.776550 593.008850,746.564758 589.820496,747.714844 
z"/>
<path fill="#5776A2" opacity="1.000000" stroke="none" 
	d="
M608.252930,766.344482 
	C603.592896,768.898560 599.107666,771.870728 594.232117,773.909485 
	C586.800537,777.016968 579.192505,779.787537 571.484497,782.124390 
	C564.275513,784.310059 556.838867,785.744995 548.939941,787.795227 
	C547.953796,788.110535 547.530151,788.140869 546.664062,788.067261 
	C537.170593,789.063354 528.117188,790.145264 519.075012,791.314270 
	C518.594849,791.376282 518.209778,792.173767 517.780518,792.628967 
	C507.891968,792.664551 498.003387,792.700134 487.503815,792.347290 
	C481.409851,791.079468 475.940857,790.097351 470.439911,789.350586 
	C465.288177,788.651184 460.102600,788.201172 454.609192,787.669189 
	C453.638367,787.700806 452.990204,787.704468 452.187378,787.405518 
	C449.679047,786.368286 447.325439,785.633545 444.686920,784.862061 
	C444.021576,784.794067 443.641113,784.762817 443.102722,784.393677 
	C431.285522,780.064636 419.626312,776.073608 407.736267,771.904907 
	C406.989716,771.155029 406.473999,770.582764 405.986389,769.610718 
	C409.007935,762.461914 412.001434,755.713013 415.393463,748.950317 
	C416.258331,748.703552 416.724670,748.470459 417.550934,748.360596 
	C418.922882,748.558960 419.934937,748.634155 421.105316,749.041138 
	C428.089905,752.153748 434.904083,754.965271 441.763702,757.661072 
	C442.353241,757.892822 443.243958,757.358337 444.386261,757.210876 
	C446.078369,757.332764 447.378967,757.423462 448.804810,757.833252 
	C451.574463,759.255737 454.126526,760.879028 456.880005,761.367737 
	C466.240753,763.029541 475.672607,764.290710 485.447021,765.763916 
	C486.277832,765.659241 486.738983,765.495544 487.619995,765.465088 
	C491.028198,765.647278 494.016541,765.696350 497.260712,766.078491 
	C501.693542,766.164490 505.870514,765.917297 510.424622,765.700806 
	C511.499451,765.518555 512.197144,765.305664 513.271973,765.313232 
	C515.364563,765.444763 517.079956,765.355896 519.108215,765.474854 
	C519.913208,765.682251 520.405396,765.681824 521.348328,765.778442 
	C526.043701,765.367859 530.285156,764.831299 534.533813,764.359741 
	C540.044739,763.748108 545.561340,763.188232 550.869873,762.941223 
	C538.492615,768.794983 525.290344,767.346191 512.532288,768.883362 
	C508.929565,769.317444 505.225281,769.980896 501.674103,769.584717 
	C486.588715,767.901672 471.582916,765.404480 456.471008,764.090820 
	C444.018066,763.008301 433.342224,757.055664 421.951538,753.096436 
	C417.144012,751.425354 414.812561,751.757996 413.179352,756.643311 
	C412.450165,758.824402 411.801544,761.036987 410.967346,763.177185 
	C410.237640,765.049316 409.299286,766.840210 408.453125,768.666992 
	C410.379700,769.055481 412.306244,769.443909 414.232788,769.832336 
	C415.980682,771.909363 418.107544,773.141724 420.380554,773.975708 
	C421.894226,774.531067 423.678528,774.315491 425.235016,774.792908 
	C433.539154,777.339600 441.711060,780.384338 450.116241,782.518066 
	C461.162933,785.322266 472.276764,788.255310 483.994293,787.429077 
	C487.845428,791.840088 492.657562,789.312073 497.418701,789.909729 
	C500.251801,789.925903 502.639069,789.902466 505.485382,789.922363 
	C508.758026,789.971130 511.637238,790.349182 514.365356,789.868835 
	C516.725525,789.453308 518.914124,788.063232 521.635681,787.113708 
	C536.459106,788.568115 550.093262,784.079285 564.377075,781.932007 
	C566.818604,780.941833 568.901611,779.969604 571.394470,779.007812 
	C574.862244,778.009766 577.920227,777.001282 581.233643,775.723999 
	C585.525391,774.036987 589.550232,772.585266 593.600891,771.209290 
	C598.473511,769.554077 603.367981,767.963196 608.252930,766.344482 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M706.794495,685.620605 
	C706.797363,684.957397 706.800232,684.294128 707.227905,683.309021 
	C709.101868,681.657471 710.550964,680.327820 712.280518,679.254211 
	C714.000610,685.312805 715.363525,691.194031 720.041504,695.411255 
	C720.986694,696.263428 723.355103,697.052795 723.835388,696.590698 
	C724.850159,695.614319 725.444458,693.799133 725.471375,692.314636 
	C725.496399,690.927063 724.266724,689.551697 724.124817,688.117981 
	C723.912292,685.969299 724.014160,683.756409 724.265320,681.603821 
	C724.549744,679.166077 725.146545,676.764771 725.607544,674.347656 
	C725.118713,674.119080 724.629944,673.890564 724.141113,673.661987 
	C725.427429,672.774658 726.713684,671.887329 728.000000,671.000000 
	C728.656311,678.734680 729.585449,686.459290 729.872192,694.207642 
	C730.060791,699.304932 729.272339,704.434082 729.029358,709.554077 
	C728.951355,711.196899 729.484009,712.893311 729.259521,714.498352 
	C728.992615,716.406433 728.737549,719.158386 727.510803,719.863586 
	C726.167297,720.635986 723.333374,720.078064 721.819580,719.081360 
	C713.918579,713.879150 695.666138,717.903076 692.688110,726.511719 
	C691.234741,730.712952 692.800781,735.925903 692.793152,740.680481 
	C692.790833,742.095520 692.086121,743.509338 691.310181,744.586548 
	C690.012207,741.438660 689.505798,738.412903 688.126587,735.858337 
	C684.264526,728.705078 686.214050,722.786438 693.693604,720.069275 
	C695.139221,715.510010 696.587952,710.951355 697.760803,707.260620 
	C700.426208,706.235107 702.623962,705.523254 704.696960,704.546448 
	C706.163391,703.855530 707.377197,702.623047 708.849060,701.950195 
	C713.615295,699.771606 714.096497,698.142517 710.715271,694.283997 
	C709.635132,693.051270 708.246643,692.088623 706.998535,690.559570 
	C706.930054,688.618042 706.862244,687.119324 706.794495,685.620605 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M397.166077,337.100433 
	C398.187195,337.079224 399.208282,337.058014 400.976013,337.015533 
	C402.853546,336.024628 403.984497,335.054932 405.115417,334.085266 
	C405.831604,334.047943 406.547791,334.010590 407.919250,333.959259 
	C409.056854,333.650757 409.539154,333.356232 410.021484,333.061707 
	C410.575256,332.896820 411.128998,332.731934 411.867065,332.339722 
	C412.832458,331.877502 413.613556,331.642609 414.875427,331.452271 
	C416.247833,330.680542 417.139404,329.864258 418.031006,329.047974 
	C418.030975,329.047943 418.021210,329.001099 418.413757,329.017700 
	C425.641571,326.389282 432.304321,323.019012 440.000427,323.994080 
	C441.229034,325.128632 442.457642,326.263184 443.816010,328.122620 
	C444.941742,331.603210 445.937714,334.358917 446.933685,337.114655 
	C447.419739,339.151367 447.699005,341.265991 448.431061,343.210052 
	C450.536102,348.800385 447.855774,354.082214 442.169525,356.115936 
	C432.331329,359.634613 422.572205,363.379608 412.840057,367.183533 
	C411.397186,367.747467 410.282776,369.151581 408.950836,370.507019 
	C408.885284,370.847107 408.911774,370.879028 408.552979,370.877960 
	C407.472839,370.867126 406.751434,370.857361 405.812561,370.625519 
	C400.571167,373.578888 395.505798,376.692139 390.563080,379.989319 
	C389.714386,380.555511 389.388214,381.904968 388.467407,382.892456 
	C387.408264,382.920898 386.702362,382.949463 385.648315,383.006714 
	C383.830231,384.335632 382.360260,385.635834 380.541199,386.980225 
	C377.795135,388.685333 375.398071,390.346222 372.692322,392.137329 
	C369.540314,395.149689 366.696960,398.031799 363.543396,400.918732 
	C362.820160,400.945190 362.407074,400.966797 361.765503,401.086731 
	C361.251068,401.384094 361.067261,401.653015 360.859924,402.263733 
	C360.121887,403.323608 359.509460,404.111511 358.595520,404.891144 
	C357.348389,404.635132 356.402802,404.387390 355.473389,403.811493 
	C358.128601,400.721039 360.767639,397.958771 363.732635,395.122437 
	C367.834778,391.811768 371.610992,388.575165 375.745789,385.299988 
	C379.054565,383.491760 382.004761,381.722168 385.276550,379.935638 
	C386.093262,379.317261 386.588440,378.715820 387.355469,377.958435 
	C388.705170,377.164886 389.783020,376.527222 391.189606,375.898621 
	C392.047974,375.323761 392.577606,374.739807 393.397522,374.098816 
	C394.077850,373.983002 394.467896,373.924194 395.209656,373.881805 
	C396.389160,372.949829 397.216919,372.001465 398.354492,370.884827 
	C400.764313,369.808075 402.864288,368.899597 405.329834,367.963989 
	C408.495270,366.350861 411.295166,364.764862 414.316071,363.130707 
	C414.537109,363.082581 414.980499,362.992798 415.301697,362.967468 
	C416.105896,362.321747 416.588867,361.701355 417.434937,361.004974 
	C419.493500,360.585846 421.188934,360.242676 423.218323,359.900513 
	C424.047455,359.275848 424.542694,358.650146 425.220215,357.773865 
	C428.917908,356.674500 432.433258,355.825745 436.273834,354.961426 
	C437.092957,354.332611 437.586853,353.719360 438.277466,352.930573 
	C439.580688,352.758087 440.687195,352.761139 442.127991,352.807007 
	C442.984467,352.259766 443.506653,351.669678 444.307007,350.799316 
	C444.797974,348.670502 445.010803,346.822052 445.435577,344.736267 
	C444.432587,341.324982 443.217651,338.151001 441.953979,334.637909 
	C441.617065,333.201263 441.328888,332.103668 441.287292,330.791199 
	C440.556366,329.087585 439.443848,326.271545 438.626221,326.354736 
	C435.985718,326.623260 433.453735,327.958313 430.477051,328.926208 
	C428.458313,329.060211 426.842407,329.150330 424.864716,329.192719 
	C423.311005,330.067078 422.119110,330.989105 420.590149,332.009674 
	C417.534210,333.094910 414.815277,334.081635 411.735168,335.041687 
	C410.555420,335.655090 409.736847,336.295197 408.560638,337.016357 
	C399.652954,341.377228 390.686462,344.996307 382.636749,350.069977 
	C363.987213,361.824646 345.889832,374.435577 331.246124,391.235657 
	C325.710022,397.586975 320.535248,404.252899 315.012512,410.616364 
	C312.961182,412.979919 310.385437,414.888306 307.789246,416.831543 
	C308.053070,415.591431 308.477814,414.460449 309.117828,413.468323 
	C311.381714,409.958984 313.719269,406.497162 316.028900,403.017303 
	C317.271027,401.556427 318.513153,400.095551 320.229858,398.298553 
	C322.150940,396.325989 323.597412,394.689575 325.043884,393.053162 
	C325.043915,393.053192 325.000702,393.004456 325.344299,392.980530 
	C326.136627,392.312225 326.585327,391.667786 327.034058,391.023346 
	C328.940796,389.247589 330.847565,387.471832 333.217590,385.327209 
	C334.138062,384.316589 334.595337,383.674835 335.052582,383.033051 
	C337.610535,380.561279 340.168488,378.089539 343.198975,375.289673 
	C344.777252,373.974884 345.883026,372.988220 346.988770,372.001556 
	C350.130676,369.119843 353.272552,366.238098 357.013855,363.065613 
	C358.762634,361.567841 359.912048,360.360809 361.385162,359.129517 
	C362.142334,359.081787 362.575836,359.058258 363.340179,359.008606 
	C364.786774,358.003754 365.902496,357.025085 367.018219,356.046417 
	C367.018219,356.046417 367.017670,356.009705 367.379974,355.990112 
	C369.865845,354.002960 371.989441,352.035370 374.113037,350.067810 
	C375.145844,350.043274 376.178650,350.018738 377.896179,349.850708 
	C379.086792,348.823029 379.592712,347.938843 380.098633,347.054688 
	C384.248749,345.226685 388.398834,343.398682 393.130981,341.271332 
	C394.864075,339.681458 396.015076,338.390930 397.166077,337.100433 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M694.000000,404.003662 
	C696.077393,403.473419 698.175415,403.012299 700.227417,402.397339 
	C703.618835,401.380920 706.643066,401.231262 710.204956,402.828888 
	C713.243530,404.191803 717.160156,403.491119 720.633606,404.046021 
	C721.903137,404.248840 723.015015,405.438507 724.198853,406.177979 
	C724.094421,406.652191 723.989929,407.126404 723.885498,407.600616 
	C721.716309,408.072327 719.567322,408.707703 717.373352,408.977570 
	C714.048767,409.386566 713.340088,411.044800 714.928467,413.736511 
	C716.342224,416.132202 718.024536,418.369324 719.155334,420.037628 
	C721.337830,418.891418 723.029846,417.805939 724.877930,417.184113 
	C725.289673,417.045593 726.847229,418.538757 726.845886,419.278870 
	C726.841980,421.487732 728.105957,424.007874 724.400940,425.335571 
	C721.308655,426.443665 721.064697,431.207336 724.009888,434.310181 
	C728.512451,439.053802 726.708618,444.603271 726.737000,449.930450 
	C726.740479,450.581116 725.463501,451.238556 724.120239,451.757263 
	C721.995117,450.411652 720.529724,449.201599 718.995361,447.686584 
	C718.603333,446.617004 718.280151,445.852417 717.969238,444.721649 
	C717.303223,443.570221 716.624939,442.784973 715.797180,441.703552 
	C715.395142,440.346802 715.142517,439.286255 714.902588,437.863220 
	C713.947327,434.676636 713.514832,431.494141 711.909973,429.096313 
	C707.273132,422.168304 702.189514,415.537323 697.192505,408.856140 
	C696.794128,408.323547 695.712524,408.301941 694.952271,408.033691 
	C694.956055,408.024536 694.941284,408.047607 694.958008,407.693848 
	C694.649841,406.227936 694.324951,405.115814 694.000000,404.003662 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M706.999268,691.002441 
	C708.246643,692.088623 709.635132,693.051270 710.715271,694.283997 
	C714.096497,698.142517 713.615295,699.771606 708.849060,701.950195 
	C707.377197,702.623047 706.163391,703.855530 704.696960,704.546448 
	C702.623962,705.523254 700.426208,706.235107 697.760803,707.260620 
	C696.587952,710.951355 695.139221,715.510010 693.693604,720.069275 
	C686.214050,722.786438 684.264526,728.705078 688.126587,735.858337 
	C689.505798,738.412903 690.012207,741.438660 690.957275,744.624634 
	C690.832642,745.000000 690.667419,744.993225 690.502869,745.000977 
	C685.317139,745.245911 679.869141,745.949158 679.708740,736.555542 
	C678.130798,739.017090 677.130249,739.861694 677.128113,740.708740 
	C677.120178,743.890625 675.710144,745.275574 672.579834,745.021484 
	C672.256653,744.995300 671.749329,744.947693 671.622742,745.123169 
	C667.543030,750.776123 661.495728,749.855103 655.374512,749.587402 
	C655.336487,747.838257 655.666992,746.420349 656.380005,744.958130 
	C657.858337,744.627014 659.124023,743.833862 660.022644,744.135254 
	C666.045532,746.155151 666.297791,741.870056 667.311401,737.846130 
	C671.038025,729.090942 683.572693,727.785278 684.232727,716.956543 
	C684.248596,716.696411 684.589661,716.320312 684.856750,716.231201 
	C691.841125,713.899292 694.587708,707.998962 697.115295,701.859985 
	C697.471802,700.994080 698.351868,700.334229 699.021423,699.608459 
	C701.673889,696.733459 704.338867,693.870117 706.999268,691.002441 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M312.000000,715.002441 
	C310.222046,714.530945 308.390137,714.202454 306.677917,713.556580 
	C303.632141,712.407776 300.658875,711.066833 298.125549,709.100281 
	C295.699188,709.100281 293.272858,709.100281 290.846527,709.100281 
	C291.493164,705.021790 292.139801,700.943359 292.776581,696.927002 
	C291.170319,696.640991 288.659576,696.193848 286.074036,694.999023 
	C285.998688,693.500000 285.998138,692.748779 285.997559,691.997559 
	C285.997559,691.553650 285.997528,691.109741 286.338501,690.185303 
	C288.093170,688.832153 289.506836,687.959656 290.920532,687.087097 
	C289.966034,685.613159 289.038086,684.121216 288.050690,682.669678 
	C286.616821,680.561584 284.065826,678.560425 283.907654,676.374939 
	C283.588531,671.966125 284.560547,667.463745 285.305969,663.156067 
	C288.054535,667.145447 290.812683,670.817627 292.871185,674.847168 
	C296.654816,682.253479 295.201477,691.927307 303.941681,697.366028 
	C308.113647,699.962097 310.889374,704.850708 314.165466,708.810120 
	C315.240814,710.109741 315.900513,711.753235 316.750275,713.239502 
	C315.166870,713.827148 313.583435,714.414795 312.000000,715.002441 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M484.281189,366.145081 
	C480.808075,360.040771 476.467407,354.402405 474.714691,348.050018 
	C471.456329,336.240692 467.230865,324.130005 472.915070,311.713043 
	C473.522064,310.387177 474.036713,308.804474 473.918732,307.397552 
	C473.330597,300.385132 477.234222,295.499756 481.366760,290.615204 
	C485.520569,285.705505 489.380798,280.540344 493.662354,275.748688 
	C496.033813,273.094696 499.004608,270.976135 502.230042,268.312714 
	C503.503387,268.002411 504.251892,267.995605 505.375488,267.989746 
	C509.173218,266.332703 512.595886,264.674683 516.018494,263.016663 
	C521.834412,261.212250 527.618469,259.292877 533.483887,257.666992 
	C535.485657,257.112122 537.854126,256.719086 539.770996,257.259583 
	C543.823181,258.402130 547.587952,260.615906 551.660583,261.618317 
	C555.150269,262.477234 558.904724,262.261017 562.757568,263.097504 
	C562.981262,264.117279 562.989441,264.559875 562.998291,265.375702 
	C563.001099,266.494019 563.003296,267.239075 562.639038,267.960236 
	C560.183533,266.970032 558.161743,265.382050 555.993347,265.147217 
	C547.516663,264.229126 539.298401,261.946838 530.472290,262.764282 
	C519.701721,263.761810 509.927338,266.924561 501.369049,273.045410 
	C495.383972,277.325897 489.909546,282.629211 485.215271,288.309021 
	C481.396301,292.929810 477.366272,298.512665 476.456909,304.156616 
	C474.753143,314.731384 472.131531,325.147858 474.495239,336.544250 
	C476.798462,347.649139 480.500763,357.337280 487.520752,365.931030 
	C490.587769,369.685669 493.958405,373.192291 497.127014,376.874695 
	C497.062836,376.937805 496.934204,377.063721 496.590668,377.007507 
	C493.823151,375.302246 491.399170,373.653229 488.975189,372.004181 
	C487.510559,370.099060 486.045929,368.193939 484.281189,366.145081 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M276.029785,634.940491 
	C276.029785,634.940491 275.993378,634.468079 275.988892,633.825562 
	C274.202362,626.266174 272.119293,619.410339 270.704285,612.419250 
	C268.188416,599.989136 266.077301,587.477234 263.861725,574.557739 
	C263.859436,571.744873 263.792236,569.373901 263.810242,566.539429 
	C263.899933,555.711365 263.904388,545.346741 264.208496,534.754517 
	C265.570068,531.644287 266.631989,528.761780 267.804993,526.305725 
	C267.300629,533.147888 266.343536,539.552307 266.132294,545.981262 
	C265.433228,567.256042 267.172363,588.344604 272.584320,608.979004 
	C275.587067,620.427673 279.160065,631.732483 282.750000,643.015015 
	C283.268738,644.645264 285.325867,645.786133 286.726379,647.454651 
	C286.799652,648.163513 286.817657,648.571167 286.595001,649.218994 
	C286.410095,650.255493 286.207123,651.196594 286.559692,651.826904 
	C293.741852,664.668457 300.671387,677.668640 308.367218,690.197388 
	C311.720520,695.656616 316.671967,700.134155 320.957703,705.268677 
	C321.017792,705.479858 321.181427,705.887207 321.102051,706.268921 
	C321.013184,707.426208 321.003723,708.201782 320.651215,708.965942 
	C312.960510,699.417542 304.799103,690.383911 298.499969,680.198547 
	C292.508636,670.510986 288.526794,659.589233 283.534210,649.271667 
	C281.186584,644.420166 278.540588,639.713013 276.029785,634.940491 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M379.000153,773.002441 
	C378.360535,773.427185 377.536865,773.716919 377.110443,774.298096 
	C373.774231,778.845276 369.563354,781.416077 363.694305,781.045593 
	C360.063080,780.816406 356.258514,780.403076 352.792114,781.202026 
	C347.979767,782.311096 347.359833,779.886780 348.189087,776.556396 
	C348.935669,773.557861 348.474792,771.525269 346.804901,768.849487 
	C344.386139,764.973755 346.455139,761.281677 350.096680,758.270752 
	C353.189789,759.784790 355.661774,761.300537 358.332977,762.895752 
	C358.532196,762.975220 358.936707,763.118530 358.919678,763.445923 
	C359.270203,764.521973 359.637756,765.270630 360.043518,766.341919 
	C361.053802,766.778137 362.025818,766.891785 363.382507,767.015869 
	C365.502258,767.367981 367.237396,767.709534 369.032532,768.433960 
	C371.625641,773.018066 375.725647,770.173218 379.003357,771.351318 
	C379.001556,772.121826 379.000854,772.562134 379.000153,773.002441 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M275.996338,635.265991 
	C278.540588,639.713013 281.186584,644.420166 283.534210,649.271667 
	C288.526794,659.589233 292.508636,670.510986 298.499969,680.198547 
	C304.799103,690.383911 312.960510,699.417542 320.657837,708.974976 
	C321.007507,708.995483 321.018402,709.011047 321.002625,709.358276 
	C322.351471,711.793762 323.716095,713.881958 324.684784,715.979492 
	C320.860474,717.325867 317.432068,718.662964 314.003662,720.000000 
	C313.356232,718.560181 312.708801,717.120361 312.030670,715.341553 
	C313.583435,714.414795 315.166870,713.827148 316.750275,713.239502 
	C315.900513,711.753235 315.240814,710.109741 314.165466,708.810120 
	C310.889374,704.850708 308.113647,699.962097 303.941681,697.366028 
	C295.201477,691.927307 296.654816,682.253479 292.871185,674.847168 
	C290.812683,670.817627 288.054535,667.145447 285.186401,662.889465 
	C284.173889,661.977905 283.586945,661.488953 282.998840,660.582275 
	C282.197937,656.507935 281.564026,652.799744 280.456848,649.238647 
	C280.238556,648.536560 278.196381,648.401489 276.995331,648.004883 
	C275.695190,646.892395 274.395050,645.779968 273.047058,644.000793 
	C272.998657,642.888611 272.998108,642.443054 272.997559,641.997559 
	C272.997955,641.553650 272.998352,641.109741 273.320129,640.291504 
	C274.094330,639.278076 274.547150,638.639038 275.000000,638.000000 
	C275.320984,637.197205 275.641968,636.394348 275.996338,635.265991 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M505.000397,267.988831 
	C504.251892,267.995605 503.503387,268.002411 502.380829,268.010193 
	C500.130432,267.154663 498.318573,265.973785 496.349762,265.582214 
	C495.403900,265.394073 493.811371,266.445526 493.137238,267.384674 
	C492.175781,268.724091 491.789154,270.476166 491.234344,271.859131 
	C486.723785,271.278351 491.865082,263.755432 485.817230,265.651031 
	C484.518341,266.058136 484.192413,269.569641 483.064117,272.603271 
	C479.130737,271.808380 476.636414,274.577118 475.408234,279.601868 
	C475.003693,281.256958 472.558746,282.413269 471.046783,283.797638 
	C470.180084,282.151245 469.111206,280.577393 468.488586,278.843384 
	C467.178650,275.194916 468.773560,272.531433 471.542236,270.127960 
	C472.463074,269.328552 472.605865,267.632874 473.104370,266.346924 
	C471.548615,266.265778 469.940796,265.912781 468.454987,266.197174 
	C467.430298,266.393372 466.630463,267.569641 465.633636,268.138947 
	C461.023438,270.772034 456.464111,273.787598 450.400360,271.001007 
	C451.492340,270.228546 452.908783,269.171936 454.494263,268.748718 
	C456.968506,268.088318 459.929626,268.504303 462.016632,267.303528 
	C465.826233,265.111633 468.916290,261.648071 472.761078,259.546539 
	C474.947388,258.351532 479.674774,257.629517 480.446869,258.707581 
	C485.103363,265.209747 491.702179,263.189240 497.379242,262.693787 
	C501.449738,262.338531 505.277802,261.856476 509.454651,263.300781 
	C508.131042,265.064758 506.565735,266.526794 505.000397,267.988831 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M723.983704,598.909424 
	C725.270630,596.489807 727.442810,594.834656 726.643982,591.121887 
	C726.054260,588.380737 729.300293,588.697815 731.374573,589.942871 
	C733.019409,590.930176 734.630920,591.973145 736.628601,592.995728 
	C738.172546,594.012146 739.659973,594.825867 740.449585,596.079285 
	C741.927856,598.425720 742.710632,600.805603 746.286743,601.455078 
	C747.554932,601.685486 749.058350,606.465942 748.640869,608.851868 
	C747.891724,613.133484 748.590576,614.641541 752.990540,613.542969 
	C753.583374,613.394958 754.330078,613.380432 754.876160,613.610657 
	C757.354370,614.655823 760.828186,625.095459 758.883301,627.840698 
	C756.429932,628.000244 754.714966,628.000122 752.798523,627.741577 
	C752.090271,626.528564 751.141113,625.554871 751.181335,624.623962 
	C751.240356,623.259827 752.007751,621.926331 752.473206,620.579773 
	C750.829163,620.146912 749.193726,619.399414 747.539062,619.354065 
	C744.974243,619.283691 742.396301,619.692139 739.864136,619.488403 
	C739.882507,617.418335 739.860779,615.757324 740.225586,614.058838 
	C746.720398,613.351379 742.259216,609.989258 741.928223,608.045166 
	C740.965332,602.390015 735.260925,597.841858 729.682922,598.114929 
	C728.729126,598.161682 727.835449,599.434998 726.594727,600.101440 
	C725.511536,599.677307 724.747620,599.293396 723.983704,598.909424 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M739.823914,619.897583 
	C742.396301,619.692139 744.974243,619.283691 747.539062,619.354065 
	C749.193726,619.399414 750.829163,620.146912 752.473206,620.579773 
	C752.007751,621.926331 751.240356,623.259827 751.181335,624.623962 
	C751.141113,625.554871 752.090271,626.528564 752.798340,628.145752 
	C752.461792,630.893372 751.923950,632.978271 751.595825,634.250366 
	C753.245544,636.778687 754.622742,638.889343 755.666687,641.000000 
	C754.888916,641.000000 754.444458,641.000000 753.625000,641.000000 
	C752.500000,641.000000 751.750000,641.000000 750.681763,641.168335 
	C749.908936,642.891113 749.454468,644.445557 749.000000,646.000000 
	C744.647888,642.254883 740.586304,645.760620 736.361450,645.947388 
	C739.879883,641.869995 746.533691,641.185852 746.476868,633.678833 
	C745.054321,633.463135 743.527161,633.231567 741.695190,632.846069 
	C739.662415,628.849121 733.606995,627.582581 735.172852,621.633057 
	C736.861084,620.875488 738.342529,620.386475 739.823914,619.897583 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M728.000000,670.600037 
	C726.713684,671.887329 725.427429,672.774658 724.141113,673.661987 
	C724.629944,673.890564 725.118713,674.119080 725.607544,674.347656 
	C725.146545,676.764771 724.549744,679.166077 724.265320,681.603821 
	C724.014160,683.756409 723.912292,685.969299 724.124817,688.117981 
	C724.266724,689.551697 725.496399,690.927063 725.471375,692.314636 
	C725.444458,693.799133 724.850159,695.614319 723.835388,696.590698 
	C723.355103,697.052795 720.986694,696.263428 720.041504,695.411255 
	C715.363525,691.194031 714.000610,685.312805 712.335449,678.905396 
	C715.073242,673.201111 718.036621,668.101807 721.170410,663.320068 
	C723.561340,664.758484 725.781860,665.879272 728.002380,667.000000 
	C728.001587,668.066711 728.000793,669.133362 728.000000,670.600037 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M263.796844,574.999512 
	C266.077301,587.477234 268.188416,599.989136 270.704285,612.419250 
	C272.119293,619.410339 274.202362,626.266174 275.939880,633.593750 
	C274.171631,632.673706 272.447968,631.342773 270.321136,629.685242 
	C269.279053,628.905701 268.640137,628.452881 268.001221,628.000000 
	C268.000702,626.933289 268.000214,625.866638 267.999268,624.399963 
	C266.669556,619.920532 265.340302,615.841125 264.048370,611.000366 
	C264.159729,608.651611 264.389313,607.053223 264.279236,605.478638 
	C263.918060,600.313660 263.433472,595.157227 262.996338,589.997559 
	C262.241608,587.042419 261.486877,584.087341 260.366089,580.661377 
	C259.904724,577.464844 259.893127,574.800293 263.796844,574.999512 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M314.001556,720.417847 
	C317.432068,718.662964 320.860474,717.325867 325.012054,716.026123 
	C326.819397,717.385742 327.903595,718.708069 328.989990,720.410034 
	C330.328156,723.526489 331.664062,726.263245 332.853912,729.329590 
	C329.942932,733.871582 325.412689,734.825073 321.002441,736.000000 
	C321.002441,736.000000 321.000000,736.000000 321.000031,735.561523 
	C320.592133,733.158997 320.184174,731.194946 319.776215,729.230896 
	C317.850800,729.820557 315.925385,730.410278 314.000000,731.000000 
	C313.999817,727.611877 313.999634,724.223816 314.001556,720.417847 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M321.160400,736.318481 
	C325.412689,734.825073 329.942932,733.871582 333.253784,729.329590 
	C334.866089,728.999146 335.932434,728.998352 336.999390,729.452515 
	C337.128693,739.124146 335.802185,737.887268 345.087097,738.424194 
	C345.140961,740.835571 345.173981,742.820312 345.137634,745.223083 
	C345.047089,746.759949 345.025879,747.878723 345.004700,748.997559 
	C341.447845,750.804443 338.013306,753.554688 333.736237,751.065125 
	C332.042664,750.079407 330.258118,748.959473 329.065460,747.462158 
	C326.304596,743.995911 323.879578,740.262146 321.160400,736.318481 
M337.477142,741.017517 
	C336.150330,740.027466 334.936584,738.781738 333.460663,738.128906 
	C332.298187,737.614868 330.812592,737.831421 329.470520,737.723389 
	C329.763458,738.864868 330.037628,740.011597 330.354279,741.146423 
	C330.715179,742.439819 330.930664,743.818726 331.542358,744.985962 
	C332.322479,746.474487 333.289764,748.795288 334.437378,748.962097 
	C336.649475,749.283691 339.044769,748.345093 341.364075,747.929016 
	C340.698425,746.488586 340.069214,745.029724 339.352051,743.615417 
	C338.979828,742.881348 338.452087,742.226013 337.477142,741.017517 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M283.000000,661.000000 
	C283.586945,661.488953 284.173889,661.977905 284.880432,662.733398 
	C284.560547,667.463745 283.588531,671.966125 283.907654,676.374939 
	C284.065826,678.560425 286.616821,680.561584 288.050690,682.669678 
	C289.038086,684.121216 289.966034,685.613159 290.920532,687.087097 
	C289.506836,687.959656 288.093170,688.832153 286.338501,689.852356 
	C284.954224,687.254395 283.552795,685.509949 280.012604,685.721985 
	C278.901337,685.788635 276.278534,681.878235 276.602020,681.435608 
	C281.091736,675.291504 277.185822,669.332825 276.664734,663.325745 
	C276.384155,660.091492 276.485046,656.824036 276.994171,653.558594 
	C277.717438,655.012329 277.962524,656.477722 277.983215,657.946289 
	C278.036133,661.700134 279.607941,662.806702 283.000000,661.000000 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M332.547058,368.999695 
	C333.114655,371.747498 334.336334,375.207062 333.119781,377.098267 
	C330.355194,381.396057 326.806183,385.477264 322.733826,388.518982 
	C320.824127,389.945312 316.966156,388.763153 313.498260,388.763153 
	C313.498260,386.581573 313.498260,384.478210 313.498260,381.573608 
	C314.894012,382.558624 315.561188,383.194580 316.354156,383.549438 
	C317.471741,384.049500 318.723389,384.691406 319.834717,384.561707 
	C320.326630,384.504272 320.825897,382.730743 320.955627,381.690582 
	C321.310303,378.846619 321.470367,375.978363 321.353760,373.059875 
	C322.439209,371.671417 323.676453,369.729767 325.369568,369.172028 
	C327.408722,368.500275 329.835266,369.004333 332.547058,368.999695 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M401.985748,772.009766 
	C401.553894,772.784485 400.617676,773.682373 400.771881,774.314026 
	C401.780426,778.445129 398.827454,783.444092 394.489349,784.602478 
	C391.492096,785.402832 386.890259,781.923035 385.872131,778.111816 
	C385.658569,777.312439 385.349152,776.538574 385.398621,775.341309 
	C387.395844,773.766907 389.050690,772.561096 390.769714,771.455017 
	C392.139557,770.573486 393.587769,769.813721 395.379761,768.957153 
	C397.498505,768.605469 399.238647,768.296631 401.225647,767.992188 
	C401.472473,767.996582 401.966187,767.996460 401.983978,768.364868 
	C402.013580,769.470886 402.025391,770.208496 402.021362,771.211792 
	C401.998932,771.655029 401.992340,771.832397 401.985748,772.009766 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M345.206970,744.805054 
	C345.173981,742.820312 345.140961,740.835571 345.110291,738.054199 
	C346.007965,735.162720 346.903320,733.067810 348.108978,730.781250 
	C348.922729,728.724670 349.426178,726.859680 350.005188,724.653687 
	C351.079132,722.591980 352.077484,720.871277 353.435974,719.087219 
	C355.736237,717.720520 357.549530,716.132751 359.652618,715.194702 
	C361.711975,714.276245 364.066498,714.019653 366.157471,713.761353 
	C363.602295,715.746521 361.245331,717.544617 358.748474,719.121155 
	C349.784607,724.780945 344.949585,740.741821 350.185608,749.370117 
	C352.886536,753.820862 355.487579,758.332275 358.133759,762.816284 
	C355.661774,761.300537 353.189789,759.784790 350.423920,758.119751 
	C349.788757,756.543823 349.447449,755.117188 349.074707,752.946350 
	C347.764526,749.736511 346.485748,747.270752 345.206970,744.805054 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M241.000610,593.428467 
	C242.672089,589.737915 244.421799,586.512573 245.976578,583.195801 
	C246.599579,581.866699 246.405090,579.992493 247.290222,578.993042 
	C248.209610,577.954956 250.033112,577.128845 251.368759,577.247864 
	C252.316437,577.332275 253.903366,579.215149 253.813461,580.163452 
	C253.605850,582.353577 253.194321,585.452576 251.715866,586.412781 
	C247.914276,588.881775 247.764343,592.165466 248.003250,595.878845 
	C248.197205,598.893494 247.698975,601.315979 243.371155,600.678101 
	C242.278320,599.905701 241.639465,599.452881 241.000610,599.000000 
	C241.000610,597.285645 241.000610,595.571289 241.000610,593.428467 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M587.256958,780.278931 
	C592.728760,777.281738 598.493286,774.642944 604.174805,771.836060 
	C609.252747,769.327393 614.239197,766.633423 619.621277,763.994080 
	C619.279297,765.683777 618.581970,767.400574 617.774170,769.389465 
	C617.676575,769.244568 617.795105,769.645630 618.025452,769.725891 
	C619.501160,770.240295 621.070374,770.556091 622.422668,771.283264 
	C622.636475,771.398193 621.955566,774.154907 621.606995,774.173645 
	C619.813904,774.269714 617.664246,774.407288 616.249939,773.537292 
	C612.889893,771.470459 610.449036,772.110901 606.825378,773.648438 
	C600.578796,776.298828 595.297424,782.172974 587.256958,780.278931 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M742.000000,633.000000 
	C743.527161,633.231567 745.054321,633.463135 746.476868,633.678833 
	C746.533691,641.185852 739.879883,641.869995 736.154419,645.696411 
	C735.555542,646.001648 735.111084,646.000793 734.104004,645.730164 
	C729.771851,643.631958 729.498474,647.274109 727.725342,648.772217 
	C726.758789,644.707214 723.877258,644.856323 721.000000,644.623169 
	C721.000000,643.500000 721.000000,642.751221 721.231079,641.729370 
	C722.642273,641.304199 723.822388,641.152100 725.002441,641.000000 
	C728.209778,640.998840 731.417358,641.025330 734.624329,640.988525 
	C737.799438,640.952087 738.140991,639.228210 737.442749,636.362061 
	C739.944031,636.026733 742.293152,636.357422 742.000000,633.000000 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M280.003632,459.377075 
	C281.333282,457.169525 282.896027,455.459137 283.931824,453.473785 
	C285.274139,450.901001 286.257782,448.132019 287.278015,445.403442 
	C287.959503,443.580780 288.435974,441.681458 289.004211,439.816406 
	C288.617981,439.638763 288.231781,439.461090 287.845551,439.283417 
	C289.633118,438.516205 291.420685,437.748993 293.594025,436.981140 
	C293.979797,436.980530 294.022003,437.022339 293.873230,437.353271 
	C293.453308,439.085754 293.182159,440.487366 292.664398,442.112244 
	C291.698486,443.902710 290.979218,445.469849 290.169312,447.404480 
	C287.660095,452.852081 285.241486,457.932159 282.520660,462.980347 
	C281.476105,462.632751 280.733795,462.317139 279.991455,462.001495 
	C279.995087,461.251648 279.998749,460.501831 280.003632,459.377075 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M395.001221,769.000000 
	C393.587769,769.813721 392.139557,770.573486 390.769714,771.455017 
	C389.050690,772.561096 387.395844,773.766907 385.356689,774.964844 
	C383.215546,774.427429 381.431122,773.854858 379.323425,773.142334 
	C379.000854,772.562134 379.001556,772.121826 379.173309,771.087280 
	C379.903229,769.677734 380.462128,768.862305 381.316711,768.000000 
	C382.148254,767.447754 382.684113,766.942383 383.519318,766.372925 
	C384.225525,766.282349 384.632385,766.255920 385.241302,766.478149 
	C386.297424,766.822754 387.151489,766.918762 388.220337,767.280396 
	C391.792480,768.538086 391.888733,766.265869 392.279541,763.861938 
	C393.375824,763.474670 394.180450,763.222107 394.991089,763.400208 
	C394.998505,765.553894 394.999878,767.276978 395.001221,769.000000 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M407.967041,772.082581 
	C419.626312,776.073608 431.285522,780.064636 442.667664,784.483887 
	C440.933838,784.940613 439.477112,784.969055 438.020355,784.997559 
	C435.953552,784.647522 433.886780,784.297485 431.214600,783.661987 
	C427.396912,782.326721 424.184601,781.276855 420.972290,780.227051 
	C420.541290,780.153320 420.110260,780.079651 419.159546,779.678955 
	C415.425110,777.892151 412.210358,776.432434 408.874695,774.685303 
	C408.491547,773.626160 408.229309,772.854370 407.967041,772.082581 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M693.733643,403.882874 
	C694.324951,405.115814 694.649841,406.227936 694.655457,407.553772 
	C689.209534,401.861847 684.082947,395.956177 678.956299,390.050537 
	C683.032410,390.367157 687.108459,390.683777 691.592285,390.998352 
	C692.101379,391.320374 692.289062,391.582672 692.742310,392.258240 
	C692.264587,394.532166 691.327271,396.289246 691.050293,398.144928 
	C690.869446,399.356598 691.646912,400.711304 691.995117,402.001923 
	C692.485840,402.588654 692.976501,403.175385 693.733643,403.882874 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M547.106506,788.171265 
	C547.530151,788.140869 547.953796,788.110535 548.696167,788.090210 
	C549.501038,788.395874 549.987183,788.691467 550.766602,789.060120 
	C549.662354,790.431580 548.389771,792.663513 546.846191,792.870056 
	C539.360474,793.871521 531.805542,794.378479 524.265137,794.921997 
	C523.265381,794.994080 522.208618,794.273804 521.519165,793.460815 
	C527.265869,792.643494 532.729370,792.646240 538.055298,791.791504 
	C541.185425,791.289124 544.096741,789.423645 547.106506,788.171265 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M241.000610,510.451080 
	C242.302155,509.118805 243.603714,508.236420 244.903870,507.351959 
	C247.903183,509.940704 249.134567,516.069092 247.321030,519.242859 
	C245.359131,522.676147 243.558304,520.138855 241.355698,519.059570 
	C241.000610,516.300293 241.000610,513.600647 241.000610,510.451080 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M546.664062,788.067261 
	C544.096741,789.423645 541.185425,791.289124 538.055298,791.791504 
	C532.729370,792.646240 527.265869,792.643494 521.395874,793.080017 
	C520.128845,793.092834 519.326172,793.026733 518.151978,792.794800 
	C518.209778,792.173767 518.594849,791.376282 519.075012,791.314270 
	C528.117188,790.145264 537.170593,789.063354 546.664062,788.067261 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M756.000000,641.000000 
	C754.622742,638.889343 753.245544,636.778687 751.595825,634.250366 
	C751.923950,632.978271 752.461792,630.893372 752.999878,628.404236 
	C754.714966,628.000122 756.429932,628.000244 758.573669,628.001465 
	C759.001831,629.389954 759.001221,630.777405 758.645996,632.646118 
	C755.601746,636.748657 757.562866,637.784607 761.000000,638.000000 
	C760.563049,638.965088 760.126099,639.930237 758.969604,640.947998 
	C757.500000,641.000427 756.750000,641.000244 756.000000,641.000000 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M623.207153,763.183472 
	C628.686768,759.235718 634.403503,755.585938 640.533691,752.163025 
	C640.971252,752.596924 640.995422,752.803955 641.019531,753.011047 
	C636.972351,759.541382 631.657043,763.943176 623.207153,763.183472 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M454.931915,787.641357 
	C460.102600,788.201172 465.288177,788.651184 470.439911,789.350586 
	C475.940857,790.097351 481.409851,791.079468 487.051300,792.306763 
	C484.107452,792.769958 481.005127,792.885315 477.162231,792.688965 
	C469.933197,791.263428 463.444702,790.149597 456.956238,789.035828 
	C456.522552,789.018799 456.088898,789.001709 455.318176,788.636047 
	C454.964752,788.072021 454.948334,787.856689 454.931915,787.641357 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M721.000000,644.997559 
	C723.877258,644.856323 726.758789,644.707214 727.724731,649.147095 
	C728.000793,650.500549 728.001587,651.250305 728.002441,652.000000 
	C728.001587,652.750000 728.000793,653.500000 727.724121,654.819214 
	C726.301270,655.928040 725.154297,656.467651 724.000610,656.632446 
	C722.995117,653.504272 721.996338,650.750916 720.998413,647.622925 
	C720.998718,646.498840 720.998108,645.749451 720.998779,645.000000 
	C721.000000,645.000000 721.000000,644.997559 721.000000,644.997559 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M282.998840,660.582275 
	C279.607941,662.806702 278.036133,661.700134 277.983215,657.946289 
	C277.962524,656.477722 277.717438,655.012329 277.287292,653.272766 
	C276.998962,651.612366 276.997955,650.224670 276.996124,648.420959 
	C278.196381,648.401489 280.238556,648.536560 280.456848,649.238647 
	C281.564026,652.799744 282.197937,656.507935 282.998840,660.582275 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M655.997559,745.002441 
	C655.666992,746.420349 655.336487,747.838257 655.000549,749.624390 
	C651.839111,750.064697 648.683167,750.136719 645.156860,750.023071 
	C647.924255,747.194885 651.061951,744.552368 654.612244,742.222717 
	C655.349060,743.357849 655.673340,744.180176 655.997559,745.002441 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M408.995636,774.972656 
	C412.210358,776.432434 415.425110,777.892151 418.834076,779.637146 
	C418.015167,780.233948 416.988251,780.507385 415.992126,780.866333 
	C414.571625,781.378235 413.171051,781.945984 411.766907,782.502441 
	C410.435120,783.030151 409.109741,783.574036 407.781525,784.110840 
	C407.736969,782.461304 407.539978,780.795715 407.710632,779.168762 
	C407.799042,778.325684 408.538696,777.550964 408.992981,776.120483 
	C408.999756,775.321289 408.997681,775.146973 408.995636,774.972656 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M724.007263,657.007263 
	C725.154297,656.467651 726.301270,655.928040 727.724121,655.194214 
	C728.306946,655.871033 729.136292,657.205566 728.839172,657.540527 
	C726.184631,660.531921 728.016113,663.564880 728.253052,666.792236 
	C725.781860,665.879272 723.561340,664.758484 721.207214,662.972046 
	C722.051514,660.539978 723.029419,658.773621 724.007263,657.007263 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M719.064331,447.991577 
	C720.529724,449.201599 721.995117,450.411652 723.727783,451.809875 
	C725.638367,455.098938 727.281677,458.199860 728.959839,461.649994 
	C728.667419,462.769562 728.340088,463.539886 727.690674,464.648193 
	C726.942993,464.977783 726.517517,464.969421 726.034302,464.633240 
	C725.064270,463.478546 724.151917,462.651672 723.172852,461.497955 
	C722.945557,460.523834 722.785034,459.876526 722.739319,458.837463 
	C721.590820,454.960968 720.327576,451.476288 719.064331,447.991577 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M457.030029,789.382935 
	C463.444702,790.149597 469.933197,791.263428 476.703857,792.655945 
	C469.925507,794.553650 463.069244,794.905273 457.030029,789.382935 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M279.677643,462.164673 
	C280.733795,462.317139 281.476105,462.632751 282.471558,463.343323 
	C282.112488,466.126648 281.500305,468.515045 280.559387,470.968140 
	C278.833252,472.697723 277.435913,474.362579 276.017151,476.009918 
	C275.995758,475.992371 275.954895,475.955109 275.954895,475.955109 
	C276.272919,473.894470 276.590942,471.833862 276.952637,469.385986 
	C277.785492,466.775146 278.574677,464.551514 279.677643,462.164673 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M454.609192,787.669189 
	C454.948334,787.856689 454.964752,788.072021 454.993652,788.610596 
	C448.754730,791.180420 443.247284,789.522705 438.084320,785.359497 
	C439.477112,784.969055 440.933838,784.940613 442.825623,784.821838 
	C443.641113,784.762817 444.021576,784.794067 444.879944,785.164490 
	C447.685974,786.238525 450.014008,786.973328 452.342072,787.708130 
	C452.990204,787.704468 453.638367,787.700806 454.609192,787.669189 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M408.874695,774.685303 
	C408.997681,775.146973 408.999756,775.321289 409.001801,775.757324 
	C408.285858,775.986633 407.569916,775.954224 406.433594,775.586365 
	C404.687866,773.815918 403.362549,772.380981 402.037170,770.946045 
	C402.025391,770.208496 402.013580,769.470886 402.048096,768.005981 
	C403.371887,765.161804 404.649353,763.045105 405.952698,761.320557 
	C405.444183,763.569946 404.681305,765.402710 404.479767,767.295349 
	C404.389984,768.138367 405.430664,769.101807 405.958313,770.010559 
	C406.473999,770.582764 406.989716,771.155029 407.736237,771.904907 
	C408.229309,772.854370 408.491547,773.626160 408.874695,774.685303 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M421.047424,780.546265 
	C424.184601,781.276855 427.396912,782.326721 430.814453,783.659058 
	C429.060516,784.560669 427.101288,785.179749 424.947937,785.860229 
	C423.639526,784.151917 422.381012,782.508728 421.047424,780.546265 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M575.218262,784.861633 
	C577.938171,783.196594 581.109314,781.605042 584.663391,780.057251 
	C583.903870,785.559021 579.762268,785.199768 575.218262,784.861633 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M314.227264,731.268555 
	C315.925385,730.410278 317.850800,729.820557 319.776215,729.230896 
	C320.184174,731.194946 320.592133,733.158997 321.000031,735.560303 
	C318.818176,734.510803 316.636353,733.023987 314.227264,731.268555 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M347.050018,339.318756 
	C348.107239,338.099060 349.415192,337.063782 350.845520,336.241364 
	C350.942749,336.185455 351.959564,337.728821 352.548676,338.528412 
	C350.809052,338.870178 349.069427,339.211945 347.050018,339.318756 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M749.399963,646.000000 
	C749.454468,644.445557 749.908936,642.891113 750.686707,641.513428 
	C751.475037,642.327881 751.940063,642.965698 752.405090,643.603455 
	C752.936707,642.735657 753.468384,641.867798 754.000000,641.000000 
	C754.444458,641.000000 754.888916,641.000000 755.666687,641.375000 
	C756.000793,642.500000 756.001648,643.250000 756.002441,644.000000 
	C755.678894,644.100647 755.417053,644.287781 754.810425,644.801392 
	C753.935913,645.361816 753.467957,645.682129 753.000000,646.002441 
	C751.933289,646.001648 750.866638,646.000854 749.399963,646.000000 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M505.375488,267.989777 
	C506.565735,266.526794 508.131042,265.064758 509.848206,263.301361 
	C511.719208,263.001099 513.438416,263.002228 515.588074,263.010010 
	C512.595886,264.674683 509.173218,266.332703 505.375488,267.989777 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M761.000000,637.604248 
	C757.562866,637.784607 755.601746,636.748657 758.646851,633.062500 
	C759.668274,634.401245 760.334106,635.804871 761.000000,637.604248 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M728.349854,651.938354 
	C728.001587,651.250305 728.000793,650.500549 728.000549,649.375977 
	C729.498474,647.274109 729.771851,643.631958 733.770752,645.730164 
	C732.232422,647.958862 730.464844,649.917786 728.349854,651.938354 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M692.039307,401.624329 
	C691.646912,400.711304 690.869446,399.356598 691.050293,398.144928 
	C691.327271,396.289246 692.264587,394.532166 692.962036,392.367218 
	C693.446960,392.761719 694.388428,393.633392 694.247559,394.262939 
	C693.717102,396.633606 692.834351,398.925446 692.039307,401.624329 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M345.137634,745.223083 
	C346.485748,747.270752 347.764526,749.736511 349.044617,752.577759 
	C347.795990,751.833557 346.546021,750.713684 345.150360,749.295654 
	C345.025879,747.878723 345.047089,746.759949 345.137634,745.223083 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M563.224304,788.260376 
	C564.769409,786.993835 566.533020,786.000061 568.671631,785.057800 
	C568.831055,788.955139 566.102112,788.687073 563.224304,788.260376 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M402.021362,771.211792 
	C403.362549,772.380981 404.687866,773.815918 406.075409,775.576416 
	C404.780579,774.834412 403.423523,773.766907 402.026123,772.354614 
	C401.992340,771.832397 401.998932,771.655029 402.021362,771.211792 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M404.702728,334.053497 
	C403.984497,335.054932 402.853546,336.024628 401.357910,336.985962 
	C400.995209,336.233978 400.997223,335.490326 400.999634,334.374512 
	C402.096680,334.008850 403.193359,334.015289 404.702728,334.053497 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M756.342407,643.965942 
	C756.001648,643.250000 756.000793,642.500000 756.000000,641.375000 
	C756.750000,641.000244 757.500000,641.000427 758.625000,641.001526 
	C758.227417,641.978882 757.454895,642.955383 756.342407,643.965942 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M557.190857,789.239563 
	C557.804565,788.689331 558.602295,788.391968 559.738647,788.181641 
	C559.176453,788.676636 558.275696,789.084534 557.190857,789.239563 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M641.359009,752.955444 
	C640.995422,752.803955 640.971252,752.596924 640.878235,752.082764 
	C641.330872,751.200745 641.852356,750.625854 642.692017,750.052734 
	C642.572937,751.002869 642.135681,751.951294 641.359009,752.955444 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M285.663513,692.048035 
	C285.998138,692.748779 285.998688,693.500000 285.998413,694.626831 
	C285.774841,694.034485 285.552155,693.066467 285.663513,692.048035 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M241.041779,599.320801 
	C241.639465,599.452881 242.278320,599.905701 242.958588,600.679321 
	C242.360992,600.547180 241.721970,600.094360 241.041779,599.320801 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M562.997559,265.002441 
	C562.989441,264.559875 562.981262,264.117279 562.971924,263.342896 
	C563.619202,263.458374 564.267700,263.905640 564.959351,264.675232 
	C565.002441,264.997559 565.000000,265.000000 564.666077,265.000244 
	C563.887268,265.001129 563.442444,265.001770 562.997559,265.002441 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M753.171631,646.230835 
	C753.467957,645.682129 753.935913,645.361816 754.701904,645.020752 
	C754.447754,645.486450 753.895447,645.972839 753.171631,646.230835 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M272.698914,642.036804 
	C272.998108,642.443054 272.998657,642.888611 272.998352,643.668335 
	C272.479309,643.768127 271.961090,643.533813 271.442871,643.299438 
	C271.761993,642.891663 272.081116,642.483826 272.698914,642.036804 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M268.042175,628.320923 
	C268.640137,628.452881 269.279053,628.905701 269.959595,629.679321 
	C269.361847,629.547302 268.722504,629.094543 268.042175,628.320923 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M409.860840,332.822693 
	C409.539154,333.356232 409.056854,333.650757 408.276306,333.925476 
	C408.552124,333.465027 409.126160,333.024353 409.860840,332.822693 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M274.679169,638.041260 
	C274.547150,638.639038 274.094330,639.278076 273.320740,639.958557 
	C273.452789,639.360840 273.905579,638.721680 274.679169,638.041260 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M345.007538,518.994019 
	C345.007294,518.552551 345.007050,518.111023 345.359558,517.299438 
	C350.552368,514.410828 350.971100,514.629150 353.001434,521.426575 
	C354.594940,520.523865 356.050262,519.756775 357.446869,518.894531 
	C359.376373,517.703308 361.261719,516.440430 363.166016,515.208252 
	C364.182678,517.138794 365.199371,519.069336 366.517609,521.572510 
	C367.566376,521.572510 369.592010,521.572510 371.874786,521.572510 
	C371.151245,515.720764 374.353882,514.614929 378.847443,514.992188 
	C379.672455,515.061462 380.617462,515.245789 381.315399,514.939819 
	C382.510803,514.415710 383.554901,513.546509 384.662506,512.822144 
	C383.518646,511.883240 382.497406,510.371979 381.211456,510.096466 
	C371.399872,507.994629 371.718262,506.929077 369.818542,499.236603 
	C373.142914,495.000397 376.249786,491.398560 378.927155,487.501678 
	C380.659271,484.980560 383.254883,483.254120 383.277985,479.200287 
	C383.306183,474.254272 383.255951,468.120361 390.264587,466.471649 
	C390.574432,466.398743 390.646820,465.590027 390.976501,465.280151 
	C392.403503,463.938751 393.890503,462.661438 395.345184,461.349182 
	C396.572632,460.241974 397.785522,459.118622 399.346680,457.948669 
	C400.792755,455.929962 401.897003,453.964996 403.373871,451.996002 
	C406.151428,450.646423 408.992584,449.739563 410.874603,447.868103 
	C415.432068,443.336334 418.955750,437.608063 426.393982,437.127197 
	C427.326569,437.066895 428.135162,435.088470 429.000610,433.999390 
	C429.000000,434.000000 428.998779,434.001221 429.358429,433.965332 
	C433.814697,431.621704 437.911285,429.314026 442.302551,427.024109 
	C443.065765,427.351074 443.534271,427.660217 443.803406,428.249634 
	C440.389679,430.984894 437.175323,433.439911 433.778381,436.180115 
	C431.371033,437.941864 429.146271,439.418488 426.559235,440.924011 
	C424.110199,442.614929 422.023438,444.276947 419.714569,445.969513 
	C419.492462,446.000061 419.050598,446.076385 418.705200,446.078766 
	C417.563751,447.045990 416.767700,448.010834 415.836212,449.215210 
	C415.353607,449.882355 415.006470,450.309937 414.309082,450.794891 
	C412.900970,451.837738 411.843109,452.823273 410.435516,453.863342 
	C407.354706,456.908295 404.623566,459.898682 401.642517,463.104462 
	C399.580353,465.199860 397.768097,467.079895 395.617065,468.998413 
	C394.559540,470.043213 393.840729,471.049530 393.088257,472.297241 
	C393.054626,472.538635 392.981293,473.020538 392.635376,473.051941 
	C390.961395,475.100037 389.633270,477.116760 388.238403,479.435883 
	C388.098389,480.144592 388.025116,480.550934 387.636841,481.006409 
	C387.098145,482.029968 386.874420,483.004333 386.622742,484.353668 
	C386.404083,485.190369 386.213379,485.652130 386.023132,486.137939 
	C386.023590,486.161987 385.944641,486.208649 385.812286,486.360077 
	C385.679932,486.511505 385.356598,486.750763 385.049866,486.816132 
	C384.105408,487.869812 383.467651,488.858124 382.470154,489.934357 
	C381.464569,491.746857 380.818756,493.471436 380.172272,495.500366 
	C380.100128,496.203552 380.028687,496.602386 379.590210,497.054382 
	C378.484253,499.218231 377.296936,501.294189 377.130371,503.449066 
	C376.957916,505.679688 377.611420,508.168091 381.406067,507.099731 
	C382.519806,507.068390 383.261414,507.029938 383.988037,507.045044 
	C383.973114,507.098541 383.906250,507.187347 383.960205,507.481445 
	C384.691406,507.717651 385.368622,507.659760 386.377350,507.639648 
	C387.140594,507.783142 387.572327,507.888855 388.019714,508.337463 
	C389.027374,509.437683 390.019379,510.194977 391.242432,510.994751 
	C391.473450,511.037231 391.941010,511.083099 392.002106,511.396362 
	C393.081268,511.626953 394.099365,511.544281 395.352417,511.444763 
	C395.587402,511.427917 396.056610,511.470490 396.205292,511.675507 
	C396.887299,512.290771 397.420624,512.701050 398.004578,513.444336 
	C400.376617,517.548950 401.758301,515.745850 403.012085,512.618225 
	C403.195282,511.869446 403.338776,511.450867 403.825378,511.025665 
	C407.225616,510.064636 409.232788,508.321350 408.862732,504.581482 
	C408.872650,504.378540 408.945312,503.978668 409.299255,503.954132 
	C410.399628,502.896118 411.146088,501.862671 411.896881,500.449158 
	C411.987762,499.060242 412.074280,498.051361 412.487396,496.990662 
	C413.519562,495.935394 414.225128,494.931976 414.968262,493.948822 
	C415.005829,493.969116 414.926178,493.946503 415.201538,493.867340 
	C415.983368,493.524292 416.489838,493.260406 417.150238,493.350006 
	C417.982025,497.450836 418.576447,501.286774 423.992523,500.004883 
	C424.387390,503.411346 422.969635,504.437653 419.615173,503.814667 
	C417.841980,503.485352 415.904419,504.041138 413.406403,504.250549 
	C419.457031,512.557739 419.007690,527.914490 406.223022,530.652588 
	C407.556274,531.103455 408.858215,531.765808 410.228180,531.968750 
	C416.234924,532.858337 418.057617,536.432434 415.105774,541.501953 
	C414.618439,542.338867 414.306885,544.212097 414.692871,544.478638 
	C418.451538,547.074036 417.397369,551.063782 416.787415,554.105469 
	C415.750946,559.274170 418.392914,564.894958 414.436218,569.808838 
	C413.415375,571.076660 414.597870,574.118713 414.789856,576.628723 
	C411.433838,576.893677 408.429626,577.130920 406.706207,577.266968 
	C407.583130,581.216248 408.365417,584.739258 409.197968,588.488525 
	C409.663391,588.198975 410.610901,587.698181 411.459839,587.065125 
	C414.304047,584.944214 416.513489,586.760559 416.620972,589.062683 
	C416.785492,592.586792 415.835571,596.244690 414.856934,599.703857 
	C414.404358,601.303528 412.767242,602.568054 411.020081,603.794067 
	C408.265625,599.933411 405.350891,596.493530 404.224670,592.543640 
	C402.095764,585.077026 398.629517,581.930664 390.956177,583.176880 
	C386.012604,583.979736 381.199158,585.582581 376.326263,586.822327 
	C372.600494,587.770264 370.752686,590.106445 370.588501,594.000244 
	C368.175079,593.698547 366.173187,593.396667 363.694122,593.022888 
	C366.871948,589.908325 369.112366,587.712463 371.882416,584.997559 
	C365.966003,584.997559 361.486664,584.997559 357.007324,584.997559 
	C357.750519,581.877014 354.605194,577.215210 362.069489,576.297974 
	C359.634003,574.021423 357.847198,571.231262 356.601593,571.454956 
	C354.826324,571.773743 353.235657,574.152466 351.968445,575.936951 
	C350.982880,577.324890 350.611694,579.149170 349.446106,582.098694 
	C348.194305,580.021790 347.268158,579.121521 347.097351,578.095520 
	C346.183716,572.608276 345.749573,567.211914 347.039948,561.565125 
	C347.665100,558.829468 345.000793,555.453125 345.252014,552.487671 
	C345.474152,549.865784 347.842438,547.480408 348.923218,544.850952 
	C349.390778,543.713379 349.003693,542.224548 348.962708,540.080688 
	C348.278564,537.855408 347.636566,536.444763 346.823334,534.657959 
	C348.237549,534.370422 349.138641,534.055359 350.049255,534.024719 
	C354.229767,533.884094 355.062073,531.455444 353.089233,528.497742 
	C350.797272,525.061707 347.735291,522.139343 345.007538,518.994019 
M400.339020,565.742249 
	C405.435120,560.541931 404.560425,553.908386 404.874786,547.485718 
	C405.486725,534.982117 394.392059,524.548401 384.067261,523.438110 
	C373.170990,522.266296 363.493103,524.127136 357.011566,533.910645 
	C351.949585,541.551453 351.159943,549.804932 353.855164,558.646851 
	C355.973206,565.595215 365.584015,574.410706 372.703522,575.884521 
	C383.823151,578.186401 392.458862,574.182068 400.339020,565.742249 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M442.007904,427.006317 
	C437.911285,429.314026 433.814697,431.621704 429.357941,433.578583 
	C427.722961,431.020599 426.729279,427.267059 425.106781,426.972076 
	C422.726959,426.539398 420.420807,428.921600 421.456421,432.576782 
	C423.938293,433.044556 426.469757,433.521667 429.001221,433.998779 
	C428.135162,435.088470 427.326569,437.066895 426.393982,437.127197 
	C418.955750,437.608063 415.432068,443.336334 410.874603,447.868103 
	C408.992584,449.739563 406.151428,450.646423 403.373840,451.620758 
	C403.000793,450.498993 403.000397,449.748474 403.288452,448.720520 
	C403.710510,446.338989 404.085846,444.209961 403.872925,442.141541 
	C403.770477,441.146332 402.189301,439.359894 401.906586,439.475098 
	C399.520355,440.447418 397.279602,441.776764 394.594055,442.998016 
	C392.760742,442.721527 391.192535,441.947357 389.929443,442.291870 
	C388.217773,442.758789 385.808533,443.794800 385.400269,445.113525 
	C384.902191,446.722321 385.745483,449.212524 386.872223,450.639679 
	C387.355194,451.251404 390.042053,450.239807 391.680023,449.791840 
	C393.482666,449.298798 395.230621,448.605804 397.003113,448.247864 
	C397.003784,448.495728 397.006104,448.991455 396.859375,449.321777 
	C394.698761,453.198151 393.544586,456.508179 399.004883,458.002441 
	C397.785522,459.118622 396.572632,460.241974 395.345184,461.349182 
	C393.890503,462.661438 392.403503,463.938751 390.976501,465.280151 
	C390.646820,465.590027 390.574432,466.398743 390.264587,466.471649 
	C383.255951,468.120361 383.306183,474.254272 383.277985,479.200287 
	C383.254883,483.254120 380.659271,484.980560 378.927155,487.501678 
	C376.249786,491.398560 373.142914,495.000397 369.818542,499.236603 
	C371.718262,506.929077 371.399872,507.994629 381.211456,510.096466 
	C382.497406,510.371979 383.518646,511.883240 384.662506,512.822144 
	C383.554901,513.546509 382.510803,514.415710 381.315399,514.939819 
	C380.617462,515.245789 379.672455,515.061462 378.847443,514.992188 
	C374.353882,514.614929 371.151245,515.720764 371.874786,521.572510 
	C369.592010,521.572510 367.566376,521.572510 366.517609,521.572510 
	C365.199371,519.069336 364.182678,517.138794 363.166016,515.208252 
	C361.261719,516.440430 359.376373,517.703308 357.446869,518.894531 
	C356.050262,519.756775 354.594940,520.523865 353.001434,521.426575 
	C350.971100,514.629150 350.552368,514.410828 345.359558,516.968384 
	C345.984406,514.575684 347.457214,512.148315 345.412354,509.388428 
	C346.761169,509.643066 347.756683,510.631805 348.775635,510.656555 
	C350.634491,510.701752 352.507263,510.175751 354.374207,509.888031 
	C353.841003,508.348236 353.340179,506.796234 352.765350,505.272125 
	C352.205048,503.786621 351.631042,502.299896 350.944550,500.870605 
	C350.490356,499.924927 349.827179,499.079559 349.499359,498.567230 
	C347.803253,501.371063 346.402832,503.686127 345.002441,506.001221 
	C345.001831,504.668121 344.976135,503.334503 345.005005,502.002045 
	C345.118622,496.760834 347.753204,495.063751 352.852631,496.929871 
	C353.597107,497.202271 354.344086,497.467896 356.000366,498.064758 
	C356.000366,493.404205 355.849518,489.327850 356.073517,485.272156 
	C356.165009,483.615845 356.848236,480.750793 357.644226,480.611053 
	C359.890686,480.216614 363.088715,480.107605 364.545074,481.412018 
	C367.614166,484.160858 369.811646,487.882843 372.357758,491.193756 
	C376.518982,487.584869 376.277740,485.369843 369.918304,476.297821 
	C371.367706,475.777466 372.617645,475.282318 373.896179,474.877625 
	C379.979095,472.952423 381.990723,468.755066 381.424530,462.320587 
	C381.027954,457.813965 382.107971,453.160095 382.680359,448.590973 
	C383.150543,444.837677 383.770203,441.421295 378.931946,439.577118 
	C377.603516,439.070770 377.277954,435.933594 376.492188,434.003632 
	C376.920471,433.652893 377.348785,433.302155 377.777069,432.951416 
	C379.499298,433.630157 381.543304,433.926422 382.886261,435.056091 
	C387.835327,439.218903 393.595947,438.304871 399.114197,437.688873 
	C405.218689,437.007446 405.914825,437.180939 407.097382,443.529327 
	C407.185333,444.001251 407.415405,444.465607 407.665649,444.881622 
	C407.827057,445.149933 408.145966,445.323578 408.602325,445.720612 
	C412.123993,444.623962 414.351227,442.823639 414.167938,438.411133 
	C414.092621,436.598328 415.850586,434.694397 416.830597,432.861877 
	C417.125061,432.311310 417.878113,431.903809 417.963013,431.359406 
	C418.724640,426.473541 421.708588,424.315582 427.991089,424.724365 
	C425.908356,422.801636 424.624695,422.036438 423.960785,420.906982 
	C422.920929,419.137939 421.216431,416.734802 421.732147,415.294342 
	C422.735138,412.492950 425.426086,411.301056 428.738190,412.351227 
	C430.459961,412.897156 432.363861,412.868713 434.185486,413.099701 
	C433.977600,411.422058 433.480103,409.715790 433.642456,408.074768 
	C433.808105,406.400665 434.622070,404.790680 435.152161,403.152649 
	C437.073334,403.914032 439.228210,404.356079 440.842377,405.537140 
	C442.151123,406.494751 442.733398,408.398895 443.781097,409.774109 
	C444.655731,410.922211 445.738037,411.912079 446.728363,412.972015 
	C445.301880,413.645905 443.910553,414.785278 442.441559,414.896210 
	C439.440063,415.122925 437.337769,415.571167 438.547668,419.635864 
	C435.365295,419.886322 432.673523,420.009644 433.050568,423.932281 
	C433.442108,428.005554 436.038269,426.587006 438.449585,426.161072 
	C439.551544,425.966461 440.816742,426.695923 442.007904,427.006317 
M360.020782,501.078766 
	C360.551758,502.336395 361.082733,503.594055 361.717285,505.096985 
	C369.144836,497.419525 368.888123,488.267578 361.325012,484.205902 
	C357.891815,489.335358 358.776428,494.778381 360.020782,501.078766 
M356.926971,502.353546 
	C356.580505,501.765503 356.234070,501.177429 355.887604,500.589386 
	C355.619446,501.094818 355.007263,501.868958 355.150879,502.052856 
	C355.510254,502.513000 356.212311,502.705536 356.926971,502.353546 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M371.000000,594.000000 
	C370.752686,590.106445 372.600494,587.770264 376.326263,586.822327 
	C381.199158,585.582581 386.012604,583.979736 390.956177,583.176880 
	C398.629517,581.930664 402.095764,585.077026 404.224670,592.543640 
	C405.350891,596.493530 408.265625,599.933411 410.628662,603.805603 
	C411.223816,605.114258 411.563202,606.220886 411.961060,608.003662 
	C412.672974,609.136292 413.326385,609.592773 413.979828,610.049194 
	C414.285126,610.510315 414.590424,610.971375 415.065125,612.063904 
	C417.302704,614.693970 415.752045,619.916626 420.990051,619.466248 
	C426.131683,630.051636 436.366241,635.262207 443.896606,643.126038 
	C447.339539,644.786499 450.782471,646.446960 454.817474,648.376648 
	C457.277802,649.459412 459.146057,650.272888 461.322052,651.242188 
	C466.416779,653.278503 471.203766,655.159058 476.402618,657.051147 
	C478.180389,657.108276 479.546326,657.153931 481.163269,657.455933 
	C488.485291,661.962769 496.309021,661.006714 504.389709,660.998779 
	C509.203827,660.723755 513.627014,660.427185 518.450195,660.125488 
	C523.900085,659.070374 528.949890,658.020447 534.372314,656.976929 
	C537.731506,655.405884 540.718079,653.828308 544.757996,651.694275 
	C547.400757,656.364319 549.890198,661.198608 552.792114,665.771118 
	C555.801880,670.513611 556.953491,675.478394 555.939880,680.973694 
	C554.423523,682.457581 552.907166,683.941528 550.774231,685.685242 
	C545.762878,687.268799 541.368164,688.592529 536.973389,689.916260 
	C535.917603,689.944580 534.861755,689.973022 532.994263,690.006165 
	C525.526611,691.139526 518.897827,692.478149 512.208069,693.346191 
	C502.832458,694.562744 493.594299,694.082520 484.350403,691.466980 
	C479.461487,690.083618 474.106018,690.349182 468.961182,689.870239 
	C467.819458,689.737061 466.677765,689.603943 465.241028,688.856812 
	C463.457092,687.388306 462.050537,686.311035 460.462738,685.723877 
	C456.702545,684.333313 452.856293,683.175537 449.044830,681.923706 
	C448.259064,681.604431 447.473297,681.285095 446.143799,680.688110 
	C438.643311,676.293274 431.812408,671.941956 424.698517,668.116943 
	C415.401062,663.117859 408.092407,656.027710 401.418121,648.014343 
	C399.117371,645.252014 395.867249,643.280334 393.046051,640.962158 
	C393.041290,640.976807 393.049042,640.957642 393.046387,640.595093 
	C391.728821,638.767395 390.413971,637.302124 389.095459,635.529785 
	C389.067810,634.813965 389.043793,634.405151 389.017517,633.620544 
	C387.032227,630.476685 385.049164,627.708740 383.033051,624.966797 
	C383.000000,624.992920 383.065216,624.934998 383.065002,624.574829 
	C382.074402,623.096802 381.084045,621.978943 380.093689,620.861145 
	C380.077271,620.447693 380.060883,620.034180 380.026306,618.914429 
	C379.560181,616.290039 379.409088,614.236755 378.608490,612.479370 
	C376.226593,607.250732 373.589325,602.138367 371.048218,596.607971 
	C371.029724,595.490234 371.014862,594.745117 371.000000,594.000000 
M393.921875,634.512512 
	C398.507629,639.766846 402.867615,645.241028 407.725281,650.230652 
	C418.439697,661.236084 430.778839,669.877380 444.687897,676.655884 
	C456.303131,682.316467 468.377747,686.441956 480.944702,687.887329 
	C494.215576,689.413513 507.813232,689.674805 521.132690,688.678467 
	C530.904358,687.947510 540.479553,684.381287 550.095154,681.896301 
	C553.205078,681.092590 555.033203,679.039795 553.695862,675.523132 
	C551.924133,670.864441 550.511475,665.989685 548.080505,661.697144 
	C546.983459,659.759827 543.250793,657.420044 541.653687,657.973694 
	C532.458252,661.161621 523.153442,662.839233 513.438843,663.594299 
	C501.377197,664.531616 489.435394,664.207397 477.814270,661.316650 
	C462.649170,657.544312 448.740173,651.019165 436.172577,641.268555 
	C418.586884,627.624634 406.618805,610.220642 399.267212,589.573853 
	C397.568848,584.804138 395.133972,584.448608 391.518311,585.479797 
	C386.754456,586.838501 382.126587,588.670410 377.427795,590.262024 
	C374.525146,591.245239 373.469757,593.336914 374.295929,596.113281 
	C375.329132,599.585327 376.329346,603.159424 378.024628,606.324829 
	C383.003998,615.622314 388.320374,624.739380 393.921875,634.512512 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M724.995728,640.609131 
	C723.822388,641.152100 722.642273,641.304199 720.811401,641.728943 
	C718.398682,641.823730 716.636353,641.495422 714.875061,641.501343 
	C711.852966,641.511475 708.843506,641.745789 708.659180,646.145813 
	C706.878235,646.524475 705.435669,646.754944 704.040283,646.603760 
	C704.390503,644.701172 704.693481,643.180176 704.945496,641.915161 
	C700.496643,640.082031 696.690491,638.484314 692.861755,636.942688 
	C685.789429,634.094666 682.172302,635.785767 679.831177,642.949585 
	C679.630493,643.563599 679.173950,644.090759 678.863098,644.672607 
	C675.941284,650.140747 676.670410,655.580322 678.964111,661.367432 
	C677.628296,663.976440 676.341553,666.229431 675.031067,668.468445 
	C671.950684,673.731506 667.062927,677.431213 664.231934,683.273926 
	C661.439758,689.036438 658.204529,695.973206 650.105591,697.854736 
	C647.392639,698.485046 645.282654,701.874634 642.986206,704.095154 
	C639.617004,707.352966 636.328064,710.693726 633.004883,713.999084 
	C632.396240,713.732239 631.809875,713.326843 631.174683,713.224548 
	C629.260132,712.915833 627.326599,712.725037 625.400452,712.488281 
	C626.731750,710.955078 628.086609,709.441223 629.376404,707.873840 
	C629.669006,707.518311 629.735657,706.976929 628.754822,706.777283 
	C627.905457,704.163757 626.289429,701.465698 626.563782,698.976013 
	C626.682495,697.898682 630.701965,697.251343 633.557983,696.180603 
	C633.557983,698.472900 633.557983,699.527039 633.557983,701.197876 
	C634.767212,700.269165 635.954346,699.818481 636.147095,699.100708 
	C636.432434,698.037842 636.444641,696.141541 635.819763,695.722046 
	C632.585083,693.550110 631.728149,688.501038 626.581970,688.335693 
	C625.847107,688.312073 625.250061,683.997192 624.227722,680.394165 
	C629.957825,682.217285 634.042053,683.516785 637.570923,684.639587 
	C639.267639,687.876221 640.504944,691.190979 642.680908,693.687561 
	C643.197327,694.280029 646.860291,692.130066 648.529358,691.460693 
	C646.830750,688.382935 645.693298,686.322021 644.555847,684.260986 
	C648.258179,684.276123 651.960510,684.291199 655.662842,684.306335 
	C655.942139,684.826843 656.221436,685.347351 656.500732,685.867859 
	C656.667114,684.359985 656.778503,682.843384 657.014954,681.346619 
	C657.267456,679.748352 657.198059,677.867493 658.039795,676.637268 
	C660.566345,672.944763 662.430481,670.096130 657.756958,666.062622 
	C656.215088,664.731934 654.404785,658.829773 659.882812,656.748596 
	C660.382446,656.558777 660.763611,655.806274 661.007324,655.233154 
	C662.987000,650.578125 665.992981,647.145203 671.119812,645.745972 
	C672.420227,645.391052 673.427673,643.962585 674.006836,643.488403 
	C674.006836,640.434143 673.217102,636.407532 674.147766,635.960144 
	C677.606812,634.297546 679.889221,629.474854 685.494080,632.282043 
	C688.687927,633.881775 693.292114,632.524902 697.237122,632.820679 
	C699.462280,632.987549 702.016846,633.258667 703.750732,634.456238 
	C706.543762,636.385437 708.996704,637.400513 712.490967,636.461121 
	C715.143433,635.748047 718.203552,635.614807 720.861572,636.209656 
	C722.456482,636.566589 723.627502,638.818176 724.995728,640.609131 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M406.030060,370.847595 
	C406.751434,370.857361 407.472839,370.867126 408.552551,370.896423 
	C410.933441,374.771332 412.916718,378.648010 414.987946,382.477112 
	C418.003662,388.052368 417.440460,390.219910 412.488251,393.051361 
	C409.734711,394.625702 408.937439,393.536682 407.807800,391.002228 
	C407.195221,389.627869 404.681122,388.173950 403.169037,388.313995 
	C399.649567,388.639984 396.217529,389.910034 392.250610,390.925385 
	C393.129913,391.943817 394.514191,392.852020 394.761993,394.004517 
	C395.095276,395.554749 394.894592,397.465790 394.195435,398.856018 
	C393.956696,399.330750 391.488403,398.849243 390.097107,398.541321 
	C387.912048,398.057739 385.776459,397.350891 383.619873,396.738586 
	C383.063416,398.726746 381.947296,400.746338 382.057007,402.697021 
	C382.366974,408.208191 380.927460,410.075226 375.533234,408.806244 
	C370.807922,407.694641 370.185944,408.901672 370.767975,413.402344 
	C371.550751,419.455078 371.227997,419.656860 378.247375,422.478088 
	C376.808929,424.866730 375.678345,427.364227 373.988281,429.396484 
	C371.789886,432.040009 369.482056,434.907166 366.581482,436.544922 
	C364.544373,437.695221 361.439392,436.954315 358.811249,437.057922 
	C359.264496,434.696991 359.603394,432.306244 360.208191,429.984772 
	C360.669189,428.215302 361.467407,426.533691 362.115601,424.812988 
	C360.200562,425.260193 358.034393,425.288422 356.428375,426.251129 
	C353.916046,427.757202 351.782654,429.895416 349.635345,431.646332 
	C350.351868,433.226410 351.950562,435.791046 351.584137,436.111938 
	C350.082153,437.427368 348.003510,438.662445 346.093170,438.732666 
	C344.794373,438.780396 343.341888,436.928253 342.115295,435.760559 
	C341.439178,435.116852 341.087616,434.132233 340.283478,432.727722 
	C340.779144,429.763458 341.305237,427.230194 342.449280,425.016266 
	C344.064178,421.891235 346.136230,419.002411 348.365021,415.969910 
	C353.352692,413.398010 358.786163,411.605347 358.897034,404.899414 
	C359.509460,404.111511 360.121887,403.323608 361.081787,402.156799 
	C361.705780,401.577911 361.894043,401.314728 361.994019,400.988434 
	C362.407074,400.966797 362.820160,400.945190 363.908936,400.919922 
	C367.885620,398.414673 372.697327,397.340210 373.001007,392.007141 
	C375.398071,390.346222 377.795135,388.685333 380.906555,386.973755 
	C383.079468,385.608063 384.537964,384.293060 385.996460,382.978027 
	C386.702362,382.949463 387.408264,382.920898 388.839539,382.901672 
	C393.350037,380.997284 396.961884,378.452789 400.970245,377.351562 
	C404.793060,376.301300 406.324280,374.569397 406.030060,370.847595 
z"/>
<path fill="#5FA8C8" opacity="1.000000" stroke="none" 
	d="
M722.624512,459.229248 
	C722.785034,459.876526 722.945557,460.523834 723.106689,461.848969 
	C723.954529,463.766907 724.801819,465.006958 725.758057,466.446198 
	C726.046387,466.910675 726.294128,467.068268 726.610352,467.118225 
	C726.764771,468.787537 726.919250,470.456818 727.007446,472.896606 
	C726.909851,474.421753 726.878540,475.176422 726.779419,476.079590 
	C726.711731,476.228088 726.783203,476.546631 726.603027,476.514923 
	C726.422913,476.483215 726.057373,476.496979 725.735840,476.310425 
	C723.565613,476.150055 721.716919,476.176270 719.441284,476.168152 
	C717.010620,476.105469 715.006775,476.077118 712.780396,475.743591 
	C709.995117,475.033295 707.432556,474.628204 704.554688,474.182373 
	C703.815674,474.138733 703.391846,474.135864 702.766602,473.832550 
	C699.524841,472.825806 697.953369,471.307465 696.585205,468.042725 
	C693.906921,461.652039 689.815186,455.853668 686.243591,449.512268 
	C686.097046,448.815887 686.015442,448.417328 685.935059,447.662903 
	C684.267334,443.915649 682.598450,440.524231 681.287842,437.115417 
	C683.052307,438.445374 684.458618,439.792694 685.953613,441.479401 
	C690.298950,449.974365 694.500183,458.159698 698.852478,466.263916 
	C699.899353,468.213226 701.232727,471.195770 702.804565,471.454651 
	C707.624634,472.248505 712.625000,471.933105 717.554321,472.081879 
	C721.362793,472.196808 723.093567,470.496185 721.487854,466.864685 
	C718.933899,461.088989 716.253479,455.345703 713.223328,449.809387 
	C708.079956,440.412170 702.721375,431.127716 697.219360,421.935913 
	C696.030701,419.950073 693.850830,418.557648 692.116699,416.906860 
	C692.105957,416.921844 692.084229,416.926270 692.087036,416.556427 
	C689.415527,412.404938 686.741089,408.623322 684.017090,404.513336 
	C682.336853,402.756256 680.706116,401.327515 679.059875,399.551025 
	C678.012390,397.811523 676.980469,396.419830 675.833252,394.729584 
	C674.832214,393.932526 673.946472,393.433990 673.030334,392.967743 
	C673.000000,393.000000 673.064026,392.937500 673.021362,392.598572 
	C671.344116,390.797333 669.709534,389.335052 668.061890,387.658447 
	C668.048828,387.444092 667.986633,387.019104 667.866577,386.721252 
	C666.861389,385.922272 665.976196,385.421112 665.086792,384.938263 
	C665.082458,384.956543 665.056946,384.929626 664.966431,384.701111 
	C664.680847,384.185150 664.412537,384.009460 664.055542,383.932831 
	C664.040100,383.920105 664.077148,383.904816 664.035645,383.565948 
	C658.326965,378.485504 652.659790,373.743896 646.778748,368.970764 
	C646.564819,368.939240 646.133057,368.915039 646.061523,368.576019 
	C643.921265,366.452606 641.995483,364.460144 639.756897,362.923370 
	C633.376404,358.543488 630.503296,359.601868 627.732666,367.177185 
	C626.580383,369.195007 625.689331,371.034821 624.552368,373.140503 
	C624.888000,375.133728 624.999451,377.323486 626.148926,378.491943 
	C628.526794,380.908875 631.465881,382.771149 634.158813,384.881042 
	C646.458923,394.517883 658.703735,404.213898 667.966980,417.429413 
	C670.344543,420.176636 672.668640,422.584320 675.092285,425.257751 
	C675.456543,426.025116 675.721130,426.526764 675.965942,427.393982 
	C675.853455,428.238281 675.760864,428.716980 675.343506,429.115784 
	C671.761780,425.617798 668.504761,422.199738 665.160095,418.451294 
	C662.442383,415.680634 659.812256,413.240356 657.154358,410.585876 
	C657.126526,410.371674 657.042236,409.947968 657.022339,409.604248 
	C654.044617,406.478180 651.086731,403.695831 648.100464,400.922729 
	C648.072144,400.931946 648.079834,400.872803 648.028259,400.560730 
	C646.968628,399.519409 645.960632,398.790161 644.717285,397.861664 
	C643.709900,397.033051 642.937744,396.403687 642.101013,395.440552 
	C640.793213,393.794128 639.687256,392.307312 638.282166,391.200134 
	C633.206970,387.201233 628.019897,383.344849 622.897888,379.404877 
	C620.113098,377.262695 619.344666,374.935944 621.731140,371.795258 
	C623.919556,368.915131 626.284119,365.916565 627.470642,362.579010 
	C630.083679,355.228577 635.036072,355.991364 640.825195,360.385284 
	C643.448120,362.376068 646.159058,364.250946 648.924683,366.506500 
	C655.461426,372.249481 662.569336,377.054993 668.202942,383.209900 
	C678.923706,394.922821 689.209534,407.094177 698.874817,419.689911 
	C704.522461,427.050018 708.631775,435.592529 713.410828,443.616425 
	C716.503052,448.808197 719.554688,454.024139 722.624512,459.229248 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M693.992676,475.002441 
	C690.626709,473.500610 687.260742,471.998779 684.751831,470.879333 
	C680.798584,471.869110 677.453308,472.706665 675.472046,473.202728 
	C671.098083,468.412231 667.752869,464.748444 663.909119,460.538574 
	C666.826294,459.394653 669.549255,458.326904 672.532104,457.157227 
	C669.577759,455.319824 667.369507,454.595306 666.166992,453.075165 
	C662.751770,448.757874 659.772705,444.097260 656.539490,439.631500 
	C656.234253,439.209930 655.347778,439.209167 654.398315,438.660522 
	C654.043640,436.539093 654.021851,434.770142 654.391785,432.989014 
	C655.508606,431.965607 656.922241,430.845337 656.783691,429.970947 
	C656.612488,428.889862 655.234070,427.914856 654.225769,427.075806 
	C653.250549,426.264160 652.083069,425.683380 651.420898,424.999939 
	C653.482727,424.585510 655.123596,424.172302 656.764526,423.759155 
	C655.791199,422.253998 654.811584,420.752838 653.847046,419.242096 
	C653.315979,418.410156 652.810120,417.562103 651.955933,416.173645 
	C654.856140,416.508850 656.931641,416.748749 659.301880,417.117859 
	C660.402527,417.491882 661.208313,417.736694 662.009155,417.989502 
	C662.004089,417.997467 662.017639,417.990356 662.005615,418.388733 
	C662.005554,419.847382 662.017456,420.907684 662.015137,422.331421 
	C661.478577,423.992767 660.956116,425.290710 660.295166,426.932922 
	C661.433472,427.334717 662.781982,427.406433 663.424744,428.116760 
	C665.057617,429.921417 667.251099,431.853699 667.642212,434.019836 
	C668.560974,439.107819 668.557556,444.362274 668.927368,449.549377 
	C669.428345,449.571930 669.929260,449.594452 670.430237,449.617004 
	C671.560547,446.853333 672.690857,444.089661 673.810669,441.351654 
	C675.323792,441.945862 676.663086,442.471802 678.029175,443.315918 
	C678.374084,444.418549 678.692383,445.203033 679.046570,446.362427 
	C681.057434,452.771179 683.032349,458.804993 684.993530,464.796936 
	C686.855713,464.096130 688.492615,463.480042 690.078003,462.875916 
	C690.026428,462.887909 690.131592,462.897949 690.092407,463.264526 
	C690.729980,464.416229 691.406616,465.201355 692.306824,466.251434 
	C693.391602,468.666199 694.252930,470.816010 694.849854,473.127441 
	C694.387817,473.860168 694.190247,474.431305 693.992676,475.002441 
z"/>
<path fill="#787878" opacity="1.000000" stroke="none" 
	d="
M494.004120,620.074097 
	C492.926666,619.860107 491.849243,619.646179 490.251770,619.067383 
	C488.798859,615.927551 487.070679,613.157104 487.054901,610.377014 
	C486.877197,579.096802 486.997101,547.815063 486.936005,516.533875 
	C486.927917,512.383972 488.921692,510.854645 492.729279,510.868591 
	C496.888977,510.883789 501.049133,510.829468 505.208435,510.871826 
	C512.666992,510.947784 514.174805,512.166321 515.882935,519.722290 
	C515.082153,519.851868 514.615356,519.866943 514.097046,519.533936 
	C511.510437,514.785706 507.928650,512.467590 502.633789,512.977966 
	C499.832611,513.247986 496.971954,513.158875 494.157104,512.961609 
	C490.932343,512.735535 489.795044,513.974792 489.779572,517.236694 
	C489.721527,529.497070 489.275391,541.756042 489.254547,554.015991 
	C489.225433,571.142395 489.076630,588.282776 489.703156,605.390686 
	C490.068787,615.374390 490.702881,615.222046 501.092621,615.140747 
	C504.070374,615.117371 505.400024,614.135803 505.726196,611.193848 
	C505.786316,610.638550 505.635620,610.174255 505.450043,609.263611 
	C505.487000,587.979980 505.558807,567.142761 505.904449,546.144897 
	C507.389343,543.408020 508.783630,542.070862 511.391327,544.720886 
	C512.479980,545.827209 514.036743,546.472961 515.492249,547.614258 
	C515.709290,548.295898 515.814697,548.691528 515.940002,549.440796 
	C520.242676,555.243835 524.525513,560.693298 528.873047,566.341675 
	C528.937866,566.540649 529.065857,566.939026 529.050110,567.297546 
	C531.632019,570.815735 534.229675,573.975464 536.892029,577.333679 
	C536.956665,577.532227 537.073303,577.933167 537.049072,578.286682 
	C539.176880,580.651917 541.067261,583.532959 543.595642,584.293701 
	C544.787048,584.652222 547.203491,580.939392 549.154785,578.869629 
	C549.244751,578.667908 549.421082,578.262756 549.714844,578.165161 
	C550.965210,577.683411 551.921875,577.299194 552.888916,577.268738 
	C552.482300,578.317932 552.131714,579.064880 551.638306,579.701050 
	C544.027283,589.514160 542.908813,589.484741 535.194214,579.664307 
	C532.300598,575.980835 529.242004,572.426941 526.258606,568.813965 
	C526.258606,568.813965 526.141174,568.807007 526.103760,568.514160 
	C521.979309,562.819580 517.892334,557.417908 513.769531,551.676636 
	C512.237427,550.385437 510.741150,549.433777 509.244843,548.482117 
	C508.804199,549.600159 508.363556,550.718201 507.719910,551.952148 
	C507.666534,552.411987 507.816193,552.755920 507.984467,553.451538 
	C508.082703,554.501160 508.162354,555.199158 508.143372,556.323730 
	C508.103638,558.199829 508.162567,559.649292 508.147705,561.555664 
	C508.135010,577.643188 508.196136,593.273865 508.167419,609.319519 
	C508.613770,614.130005 506.409515,617.082764 502.540894,619.318848 
	C499.395294,619.519043 496.699707,619.796570 494.004120,620.074097 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M533.999695,656.970459 
	C528.949890,658.020447 523.900085,659.070374 518.394104,659.741516 
	C516.059204,657.263306 505.736572,657.331665 504.123474,659.506226 
	C503.874115,659.842346 504.030212,660.479370 503.998779,660.977234 
	C496.309021,661.006714 488.485291,661.962769 481.165405,657.075928 
	C480.033875,652.930054 477.980072,650.511230 474.262726,650.172241 
	C471.193939,649.892395 468.085938,650.042725 464.595947,650.002441 
	C463.131500,650.001648 462.066345,650.000793 460.967407,649.656372 
	C460.289093,647.875183 459.644531,646.437622 459.000000,645.000000 
	C464.533966,645.334229 470.068298,645.662048 475.601440,646.009521 
	C476.421265,646.060974 477.742065,645.925476 477.974396,646.347839 
	C480.567444,651.061096 484.759979,648.785461 488.464233,649.110779 
	C491.750458,649.399292 494.924927,651.143860 498.200256,651.327759 
	C502.889771,651.591187 507.631683,651.092346 512.337585,650.738281 
	C513.217163,650.672119 514.154907,649.173828 514.859558,649.321411 
	C524.423645,651.324158 532.115051,643.640686 541.378540,644.297974 
	C543.824707,644.471497 546.273682,644.605164 548.720337,644.772400 
	C551.789124,644.982056 553.760803,644.513245 553.450500,640.472534 
	C553.260925,638.004089 554.586609,635.419312 555.395020,632.246094 
	C560.162964,635.562012 559.131775,638.885437 557.736572,642.766479 
	C554.904236,647.057373 552.429810,650.791748 549.401367,655.362061 
	C548.747009,653.552063 548.661255,651.853821 547.783691,651.166016 
	C545.315125,649.231506 542.586975,647.578369 539.775208,646.191528 
	C539.402222,646.007629 537.949951,648.011658 536.622986,649.010254 
	C532.802734,651.281128 532.509644,652.680054 534.890381,655.256226 
	C534.523621,655.998657 534.261658,656.484558 533.999695,656.970459 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M334.977783,436.011108 
	C336.606232,436.916382 338.771515,437.433990 339.759308,438.801910 
	C342.385925,442.439301 344.939850,446.264832 346.629395,450.382111 
	C347.392975,452.242889 346.636749,455.330017 345.509827,457.232910 
	C343.445770,460.718231 342.203156,464.080688 343.530212,468.024994 
	C344.323547,470.382904 343.997070,471.895050 341.159851,472.158508 
	C340.189575,472.248596 339.253052,472.894470 338.296265,472.906738 
	C331.746613,472.990570 325.193054,473.073547 318.647278,472.904388 
	C317.333801,472.870483 316.048279,471.753571 314.375977,470.682922 
	C314.352448,468.502869 314.702789,466.775940 315.403076,464.993774 
	C318.777344,461.329712 322.927948,458.162445 324.557037,454.006714 
	C326.891449,448.051849 330.513824,443.956177 335.821259,441.111938 
	C335.511841,439.240936 335.244812,437.626038 334.977783,436.011108 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M554.085754,441.969177 
	C558.937500,444.138794 562.232971,443.086243 565.921021,438.189026 
	C569.817139,433.015564 571.673706,432.432861 576.637939,434.974121 
	C580.296814,435.010651 583.598450,435.010651 586.157532,435.010651 
	C589.230225,437.668579 592.396912,440.037720 595.095703,442.855011 
	C597.409058,445.270050 599.186951,448.198029 601.433411,451.209839 
	C601.765381,451.068817 602.869934,450.599579 604.245972,450.015045 
	C604.245972,455.806854 604.245972,461.164124 604.245972,466.521393 
	C603.761047,466.634186 603.276062,466.746979 602.791138,466.859772 
	C601.951294,465.099945 601.111389,463.340118 600.557800,462.180145 
	C595.131714,466.041931 589.925171,470.813568 583.834412,473.753174 
	C578.741577,476.211121 572.660461,476.289368 567.180725,473.120667 
	C565.524902,472.163147 563.605652,471.661346 561.428589,470.711365 
	C561.050171,470.471893 561.067383,470.007172 561.311157,469.777344 
	C561.871399,465.427887 559.453064,464.592743 556.045410,464.545502 
	C556.351196,461.095245 556.650085,458.053680 556.963623,454.629639 
	C557.082397,450.808746 555.360840,448.807007 552.169556,447.682068 
	C552.864868,445.546722 553.475342,443.757935 554.085754,441.969177 
M571.496826,439.887970 
	C567.564453,442.196655 563.667175,443.947327 562.132324,449.300751 
	C559.350342,459.004150 562.152710,465.366943 572.692444,469.901489 
	C578.633728,472.457611 587.935059,468.175354 591.227905,461.367859 
	C593.813904,456.021637 592.513489,448.243988 588.284607,443.764252 
	C584.427124,439.677948 576.627197,437.573090 571.496826,439.887970 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M516.216980,519.607727 
	C514.174805,512.166321 512.666992,510.947784 505.208435,510.871826 
	C501.049133,510.829468 496.888977,510.883789 492.729279,510.868591 
	C488.921692,510.854645 486.927917,512.383972 486.936005,516.533875 
	C486.997101,547.815063 486.877197,579.096802 487.054901,610.377014 
	C487.070679,613.157104 488.798859,615.927551 489.831268,619.088745 
	C489.158356,619.663330 488.385925,619.851746 487.185333,619.760071 
	C485.871826,618.643677 484.986450,617.807434 484.101074,616.971130 
	C484.080750,582.298096 484.060425,547.625122 484.371155,512.320801 
	C486.783722,510.809906 488.894257,509.120361 490.941650,509.193665 
	C498.978180,509.481384 506.994781,510.324799 515.018799,510.961823 
	C515.353088,511.745483 515.687378,512.529114 516.175293,513.953064 
	C520.506897,519.739502 524.684998,524.885620 528.863098,530.031738 
	C529.038574,530.593079 529.214111,531.154480 529.648865,532.264282 
	C531.243286,534.547302 532.578369,536.281860 533.913513,538.016357 
	C534.092163,538.575928 534.270813,539.135437 534.824097,540.150330 
	C536.111206,541.409912 537.023682,542.214111 537.936218,543.018372 
	C538.110168,543.574585 538.284119,544.130859 538.751465,545.214539 
	C540.435364,547.845947 541.825928,549.949890 543.661987,552.728027 
	C544.329468,551.118103 544.718384,550.179932 545.107300,549.241760 
	C546.116150,548.146606 547.125000,547.051453 548.456055,545.474731 
	C550.163818,543.980042 551.549255,542.966919 552.990173,542.320923 
	C553.114807,543.174744 553.184021,543.661499 553.120422,544.465759 
	C550.590454,547.732178 548.373413,550.864624 545.678528,553.510376 
	C544.938599,554.236816 542.045837,554.123535 541.377930,553.340454 
	C538.163452,549.572021 535.384094,545.433838 532.428223,541.443176 
	C527.032715,534.158569 521.621643,526.885559 516.216980,519.607727 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M602.004883,735.998108 
	C600.095276,730.460449 597.012939,733.890747 594.211060,734.957520 
	C592.650696,735.551697 591.050537,736.041565 589.468323,736.578491 
	C589.122375,734.780823 588.707214,732.992676 588.456360,731.181763 
	C588.305237,730.091248 588.771240,728.791870 588.336243,727.885742 
	C586.579285,724.225525 587.225830,721.976135 591.378235,720.746704 
	C592.199402,720.503540 592.907471,718.416321 592.919617,717.172241 
	C592.958679,713.151794 593.973633,711.964844 597.953369,714.172974 
	C599.856201,715.228699 602.543457,714.812134 604.847900,715.218567 
	C607.320862,715.654663 610.584839,715.471802 612.050110,716.975464 
	C615.410217,720.423706 616.531311,718.815979 617.796936,715.116394 
	C620.390564,717.158508 622.825378,719.075623 625.485596,721.297302 
	C624.477295,723.405334 623.243652,725.208801 622.010071,727.012268 
	C622.010071,727.012268 622.000000,727.000000 621.588745,726.999878 
	C619.400940,727.253113 617.382263,727.051514 615.892456,727.844055 
	C611.157288,730.362854 606.619141,733.252075 602.004883,735.998108 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M622.358459,726.980957 
	C623.243652,725.208801 624.477295,723.405334 625.855774,721.303345 
	C630.301880,721.093262 630.951294,717.165894 632.968262,714.350586 
	C636.328064,710.693726 639.617004,707.352966 642.986206,704.095154 
	C645.282654,701.874634 647.392639,698.485046 650.105591,697.854736 
	C658.204529,695.973206 661.439758,689.036438 664.231934,683.273926 
	C667.062927,677.431213 671.950684,673.731506 675.031067,668.468445 
	C676.341553,666.229431 677.628296,663.976440 679.283691,661.223877 
	C681.660767,657.843567 683.679932,654.969055 685.547363,652.310730 
	C684.979614,650.363525 684.488586,648.679321 684.382568,647.002319 
	C686.513367,647.338013 688.259155,647.666565 690.374207,647.990051 
	C692.228638,647.180420 693.713745,646.375854 694.408752,645.999329 
	C698.043457,646.373291 701.018250,646.679321 703.993042,646.985352 
	C705.435669,646.754944 706.878235,646.524475 709.082520,646.146729 
	C712.562744,646.332153 715.281372,646.664856 718.204773,647.260559 
	C719.272217,647.681580 720.134888,647.839539 720.997559,647.997559 
	C721.996338,650.750916 722.995117,653.504272 724.000610,656.632446 
	C723.029419,658.773621 722.051514,660.539978 721.036804,662.654358 
	C718.036621,668.101807 715.073242,673.201111 712.054932,678.649292 
	C710.550964,680.327820 709.101868,681.657471 707.274109,682.980225 
	C711.303589,675.129333 715.804016,667.335205 720.085205,659.422485 
	C722.623169,654.731689 719.700073,649.428650 714.364075,649.200073 
	C707.721313,648.915527 701.026245,648.699768 694.422485,649.261414 
	C692.017029,649.466003 689.041748,651.477234 687.657654,653.552185 
	C683.845337,659.267395 681.207825,665.786255 677.242798,671.375061 
	C670.890076,680.329285 664.219482,689.120789 656.990784,697.374634 
	C648.309021,707.287476 638.455750,716.050110 627.409485,723.370667 
	C625.770935,724.456482 624.270935,725.751465 622.358459,726.980957 
z"/>
<path fill="#5A5A5A" opacity="1.000000" stroke="none" 
	d="
M718.418274,595.444458 
	C718.418274,595.444458 718.545044,595.850464 718.716797,596.314453 
	C719.504395,597.341858 720.120239,597.905334 720.752563,598.793457 
	C721.477905,599.790955 722.186768,600.463867 723.092712,601.336182 
	C723.755127,602.122681 724.220337,602.709717 724.771057,603.618408 
	C725.233093,604.964783 725.609680,605.989380 725.628479,607.043701 
	C723.996155,606.077454 722.592896,605.208374 721.467163,604.065857 
	C710.772766,593.211670 699.992371,582.437195 689.513611,571.377380 
	C683.070740,564.577271 680.898865,563.915894 688.766235,555.098328 
	C698.572083,544.108032 708.878296,533.565552 718.867310,522.736389 
	C721.318604,520.078979 723.376892,517.059143 726.394226,513.214050 
	C720.028503,513.214050 715.299866,513.086487 710.587280,513.305847 
	C709.442688,513.359192 708.117920,514.341248 707.275818,515.262024 
	C703.603149,519.278259 700.273499,523.623535 696.444946,527.477539 
	C692.476501,531.472412 688.076538,535.038635 683.842896,538.438354 
	C685.066650,536.396851 686.191284,534.601135 687.579773,533.040222 
	C694.386536,525.387817 701.263611,517.797974 708.341980,510.144623 
	C708.568115,510.103241 708.993286,509.928192 709.456543,510.004456 
	C715.316589,510.245636 720.713440,510.410553 726.348022,510.785156 
	C730.224426,511.807129 731.108337,512.919006 728.052673,516.124939 
	C717.548828,527.145264 707.303772,538.413452 697.035522,549.655945 
	C695.538818,551.294556 694.452209,553.307739 693.058777,555.320923 
	C692.941711,555.494263 692.713379,555.844971 692.383667,555.883667 
	C690.998230,557.236389 689.942505,558.550415 688.933105,559.902222 
	C688.979309,559.939941 688.867554,559.898132 688.508301,559.964111 
	C688.096130,562.000427 687.175293,564.775574 688.133179,565.808350 
	C693.719604,571.832092 699.746704,577.447205 705.815918,583.408081 
	C706.275696,583.880676 706.544556,584.145508 706.877380,584.738892 
	C709.189026,587.116699 711.436768,589.166077 713.929810,591.359619 
	C714.675354,591.765625 715.175415,592.027466 715.736389,592.597168 
	C716.670959,593.751526 717.544617,594.597961 718.418274,595.444458 
z"/>
<path fill="#5A5A5A" opacity="1.000000" stroke="none" 
	d="
M666.017212,510.883331 
	C666.017212,510.883331 666.213806,511.188263 666.481262,511.626404 
	C667.027832,514.320496 667.306946,516.576477 667.396484,519.200684 
	C667.306702,520.099060 667.406616,520.629150 667.411560,521.606323 
	C667.384521,530.260254 667.452454,538.467102 667.337280,547.040161 
	C667.408447,549.075195 667.662781,550.744080 667.913818,552.391235 
	C670.651489,551.086670 672.831177,550.048035 675.083435,549.332275 
	C675.060486,550.084412 674.965027,550.513550 674.580078,550.979614 
	C673.811340,551.551575 673.332092,552.086548 672.804565,552.688721 
	C672.756287,552.755859 672.620972,552.851135 672.352539,552.927856 
	C671.690186,553.585632 671.296265,554.166748 670.701416,554.839478 
	C670.500427,554.931152 670.075806,555.053284 669.694580,555.022400 
	C668.550781,554.988892 667.788269,554.986267 666.730835,554.740112 
	C665.965942,552.095520 665.132507,549.700623 665.090637,547.291992 
	C664.931091,538.113281 665.026978,528.930115 665.027161,519.748657 
	C665.027222,513.047729 665.021057,512.868713 658.288391,513.093811 
	C655.516235,513.186584 651.696899,511.241272 650.123413,515.967285 
	C649.610962,516.766846 649.358765,517.454224 649.120361,518.429016 
	C649.123352,519.099792 649.112610,519.483215 649.048950,520.307190 
	C649.044800,522.224915 649.093567,523.702087 649.153198,525.539673 
	C649.167725,526.861694 649.171326,527.823242 649.104614,529.265686 
	C649.066772,534.219971 649.099121,538.693298 649.142212,543.624634 
	C649.145325,565.666626 649.137817,587.250549 649.072144,609.203735 
	C649.468689,610.760986 649.923279,611.949036 650.693054,613.409119 
	C654.992065,614.112000 658.975952,614.542786 663.292542,614.962952 
	C664.082458,614.300049 664.539673,613.647766 665.004456,612.527100 
	C665.040283,600.180115 665.040283,588.301392 665.113647,576.423096 
	C665.136536,572.715393 666.259338,570.058899 670.847778,571.847778 
	C670.809753,572.867615 670.742676,573.550964 670.328491,574.204407 
	C669.057251,575.143005 668.133179,576.111511 667.112427,577.534424 
	C667.204590,583.051147 667.393433,588.113647 667.462891,593.581421 
	C667.354980,596.235291 667.366516,598.483887 667.224121,601.168213 
	C667.132935,603.428406 667.195740,605.252747 667.190674,607.504456 
	C667.084900,609.955627 667.047058,611.979553 666.722900,614.232544 
	C664.444092,620.312683 659.479431,617.646790 655.806519,617.857239 
	C648.766235,618.260681 646.928772,616.192383 646.927673,609.248352 
	C646.923218,579.833740 647.017883,550.418457 646.828552,521.005066 
	C646.800537,516.646912 647.159119,512.985535 651.587524,510.484924 
	C656.698425,510.589630 661.357788,510.736481 666.017212,510.883331 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M424.134491,499.687469 
	C418.576447,501.286774 417.982025,497.450836 417.153992,493.010651 
	C416.990448,491.865173 416.977081,491.412598 416.981018,490.980560 
	C416.998352,491.001129 416.957733,490.963959 417.222137,490.981171 
	C417.663147,491.001740 417.839783,491.005127 418.418884,491.006714 
	C425.236877,491.087128 429.740814,487.031189 432.454834,482.356354 
	C437.271973,474.058929 444.982819,470.532623 452.996338,466.996338 
	C455.243896,470.145874 455.782166,472.023804 451.077240,474.083618 
	C448.574890,475.179138 447.343567,479.747559 446.082123,482.961731 
	C444.550964,486.863007 443.602234,490.992065 442.382751,495.016846 
	C441.917297,496.552979 441.732361,498.324097 440.819092,499.535309 
	C439.758118,500.942413 438.169464,502.353577 436.551178,502.778900 
	C435.663269,503.012268 433.919128,501.436981 433.143402,500.285065 
	C432.147858,498.806671 431.737640,496.934174 431.075256,495.231445 
	C428.808990,496.610992 426.542725,497.990540 424.134491,499.687469 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M601.012329,546.994507 
	C601.068542,556.355347 601.124695,565.716248 600.686646,575.581055 
	C599.828064,577.779785 599.168274,579.471008 599.148499,581.169678 
	C599.038147,590.634094 598.936646,600.103394 599.135254,609.564636 
	C599.236877,614.405273 596.901550,616.132080 592.502930,616.081360 
	C591.174561,616.066040 589.845886,616.079895 588.517395,616.076782 
	C581.339417,616.060059 580.512939,615.142761 581.483521,608.010376 
	C582.057861,608.360474 582.421265,608.553772 582.498718,608.828003 
	C583.969299,614.029236 585.588806,614.772705 592.605347,614.081543 
	C597.503113,613.599060 596.080566,609.969910 596.088013,607.293579 
	C596.165833,579.195923 596.183411,551.097778 596.078552,523.000366 
	C596.065125,519.403015 595.259460,515.808594 594.828552,511.945129 
	C595.509460,511.580292 596.182678,511.483154 596.958496,511.722809 
	C598.368896,514.697693 599.676636,517.335876 601.054077,520.423218 
	C601.426208,522.304260 601.728699,523.736084 602.031189,525.167969 
	C601.870544,525.113098 601.709900,525.058289 600.886902,525.044678 
	C600.035583,532.224365 599.838013,539.362793 599.714783,546.502380 
	C599.712097,546.658936 600.560791,546.830139 601.012329,546.994507 
z"/>
<path fill="#5776A2" opacity="1.000000" stroke="none" 
	d="
M589.548462,747.808167 
	C593.008850,746.564758 596.389404,745.776550 599.352783,744.202698 
	C613.130249,736.885437 625.977112,728.238647 638.282715,718.549194 
	C654.406921,705.853210 667.399048,690.571106 679.095642,673.964661 
	C679.368042,673.577942 679.831848,673.326172 680.596313,673.334961 
	C678.825684,677.440186 677.059021,681.526611 674.420166,684.938599 
	C669.323792,691.528076 663.814758,697.806885 658.311890,704.069641 
	C656.098938,706.588196 653.451904,708.725525 650.691284,711.179688 
	C649.891052,712.539307 649.401917,713.755493 648.685547,714.965698 
	C648.458313,714.959656 648.004883,714.992065 647.649963,715.017822 
	C645.878357,715.853394 644.265381,716.443848 643.075989,717.507812 
	C633.324524,726.231384 622.811768,733.839844 611.325928,740.150513 
	C607.955872,742.002136 605.007996,744.622192 601.469177,746.907043 
	C599.612915,747.444153 598.123535,747.892578 596.700928,748.497803 
	C592.486328,750.290833 588.394348,752.425842 584.077026,753.911682 
	C577.372253,756.219177 570.495789,758.024109 563.757080,760.239929 
	C562.642273,760.606506 561.859131,761.981384 560.602539,762.906006 
	C559.855713,762.947021 559.429077,762.972290 558.557007,762.983948 
	C556.328735,762.847229 554.545959,762.724060 552.890381,762.282410 
	C555.774841,760.463501 558.532043,758.963196 561.575806,757.417847 
	C562.245789,757.322815 562.629272,757.272705 563.268433,757.437134 
	C569.277832,755.921997 575.096130,754.375732 580.756470,752.381226 
	C583.389221,751.453552 585.715576,749.656128 588.180847,748.252930 
	C588.546021,748.135803 588.911194,748.018677 589.548462,747.808167 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M651.136108,510.527069 
	C647.159119,512.985535 646.800537,516.646912 646.828552,521.005066 
	C647.017883,550.418457 646.923218,579.833740 646.927673,609.248352 
	C646.928772,616.192383 648.766235,618.260681 655.806519,617.857239 
	C659.479431,617.646790 664.444092,620.312683 666.972534,614.425537 
	C667.636108,615.205322 667.763672,616.021179 667.841919,617.182678 
	C665.851807,618.742981 663.910950,619.957642 661.970032,621.172302 
	C657.262512,620.788635 652.555054,620.404907 647.274475,619.672668 
	C645.745728,616.207581 644.789978,613.091064 643.834229,609.974487 
	C643.863037,585.969360 643.891846,561.964233 643.931885,537.155518 
	C643.872375,535.886963 643.801636,535.421936 643.730896,534.956970 
	C643.786621,529.291016 643.842346,523.625122 643.928833,517.113220 
	C644.300171,514.493896 644.640808,512.720581 644.981445,510.947205 
	C645.849060,510.800201 646.716614,510.653198 648.192261,510.270264 
	C649.578857,510.198608 650.357483,510.362823 651.136108,510.527069 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M534.372314,656.976990 
	C534.261658,656.484558 534.523621,655.998657 535.217285,655.080811 
	C536.098511,652.766785 536.548035,650.884583 536.997559,649.002441 
	C537.949951,648.011658 539.402222,646.007629 539.775208,646.191528 
	C542.586975,647.578369 545.315125,649.231506 547.783691,651.166016 
	C548.661255,651.853821 548.747009,653.552063 549.401367,655.362061 
	C552.429810,650.791748 554.904236,647.057373 557.688110,643.161499 
	C559.140076,643.341919 560.282532,643.683899 561.625732,644.358643 
	C557.755676,647.816589 556.024780,652.047729 557.127563,656.942871 
	C558.035889,660.975281 559.796753,664.815735 560.803467,668.829712 
	C559.495300,670.013062 558.563416,671.107849 557.631531,672.202637 
	C559.238037,673.342407 560.844543,674.482239 562.451050,675.622070 
	C562.677856,674.042969 562.904602,672.463928 563.490356,670.875977 
	C565.543091,671.516235 567.236816,672.165283 568.993164,673.171387 
	C570.624207,675.733398 571.542236,675.141541 572.364868,672.799866 
	C573.076294,672.763367 573.483154,672.756836 573.890015,672.750305 
	C577.582336,678.916748 577.599548,679.066589 569.610474,679.983032 
	C566.807190,680.304565 563.896545,679.690796 561.110962,678.338257 
	C559.633545,679.219177 558.156189,680.100159 556.309326,680.977417 
	C556.953491,675.478394 555.801880,670.513611 552.792114,665.771118 
	C549.890198,661.198608 547.400757,656.364319 544.757996,651.694275 
	C540.718079,653.828308 537.731506,655.405884 534.372314,656.976990 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M644.000000,438.000000 
	C637.908508,438.319000 631.816956,438.638031 625.738037,438.956390 
	C624.172913,442.435608 626.472107,448.623718 620.417358,447.675781 
	C615.312134,446.876495 617.206787,442.151398 617.308594,437.987885 
	C623.757629,440.196777 625.292542,434.229401 628.740601,431.502563 
	C631.154663,429.593445 630.216431,426.956238 627.846558,425.218964 
	C625.352905,423.391052 623.931641,417.613373 625.355591,414.528229 
	C626.981567,411.005341 629.720825,412.540375 632.612793,413.430786 
	C630.737793,422.474182 640.269653,425.599060 641.774841,432.499298 
	C641.858459,432.882874 643.880310,432.843658 644.961548,433.366394 
	C644.615479,435.154358 644.307739,436.577179 644.000000,438.000000 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M422.002441,671.997559 
	C418.932495,672.497498 415.862549,672.997437 413.071442,673.451965 
	C412.808441,675.244568 412.843872,678.449097 412.226959,678.580017 
	C410.145325,679.021790 407.416595,679.060852 405.682434,678.015015 
	C399.638763,674.370239 399.929108,674.334900 404.763031,669.238647 
	C405.707092,668.243347 405.242920,665.210327 404.461700,663.580566 
	C404.078278,662.780701 401.238770,662.439880 399.941681,662.961975 
	C397.909485,663.779846 396.242554,665.505066 394.199158,667.009216 
	C393.165405,665.861938 392.290527,664.932922 391.462769,663.963806 
	C389.494293,661.658936 387.907166,661.528809 386.014862,664.278870 
	C385.272400,665.357910 383.344910,665.621521 381.957092,666.256470 
	C382.581116,664.847961 383.456451,663.493591 383.774475,662.019104 
	C384.356659,659.319519 384.609680,656.548950 385.324829,653.263184 
	C388.882843,651.137451 389.862030,653.299072 391.122986,655.681335 
	C392.560120,658.396423 394.254486,661.612671 398.430969,657.996094 
	C401.213593,658.479858 403.784576,658.527527 405.876617,659.552612 
	C408.656555,660.914734 411.147888,662.931091 413.607910,664.863953 
	C416.493134,667.131042 419.211609,669.610291 422.002441,671.997559 
z"/>
<path fill="#787878" opacity="1.000000" stroke="none" 
	d="
M581.139343,607.891357 
	C580.512939,615.142761 581.339417,616.060059 588.517395,616.076782 
	C589.845886,616.079895 591.174561,616.066040 592.502930,616.081360 
	C596.901550,616.132080 599.236877,614.405273 599.135254,609.564636 
	C598.936646,600.103394 599.038147,590.634094 599.148499,581.169678 
	C599.168274,579.471008 599.828064,577.779785 600.596985,576.042908 
	C601.182190,584.253601 601.352600,592.506653 601.549072,600.759155 
	C601.615967,603.568298 601.748474,606.375916 601.787415,609.911377 
	C601.504761,612.091064 601.285767,613.543579 600.735046,615.105591 
	C597.957947,616.449097 595.512634,617.683105 592.604370,618.907715 
	C590.657837,618.900513 589.174255,618.906311 587.690735,618.904236 
	C580.382080,618.894104 579.818726,618.385681 578.860840,610.443665 
	C578.772949,599.299133 578.638062,588.614868 578.503174,577.930542 
	C578.577637,576.571289 578.652161,575.212097 578.760742,573.093689 
	C578.798401,571.256409 578.801880,570.178345 578.915405,568.741455 
	C578.960083,567.891541 578.894714,567.400513 578.875977,566.508789 
	C578.806763,565.052612 578.690918,563.997070 578.575073,562.941467 
	C578.647034,561.577698 578.718994,560.213989 578.819275,558.041504 
	C578.854736,555.181641 578.861755,553.130554 578.968140,550.687744 
	C578.823425,549.131348 578.579346,547.966675 578.335327,546.801941 
	C577.792114,546.594788 577.248962,546.387695 576.705811,546.180542 
	C573.574158,550.481445 570.590271,554.900513 567.272156,559.052368 
	C563.415344,563.878296 559.265564,568.469971 555.182678,572.808105 
	C557.154907,569.074646 559.187439,565.696350 561.236084,562.283203 
	C561.252197,562.248413 561.304504,562.192261 561.626587,562.118408 
	C564.652283,558.384705 567.355835,554.724976 570.152222,550.770996 
	C570.402832,550.097046 570.560608,549.717346 570.981689,549.208008 
	C572.231506,547.822083 573.218140,546.565857 574.227051,545.278442 
	C574.249451,545.247192 574.310364,545.200256 574.638062,545.127991 
	C577.938721,542.344604 579.844666,543.014282 580.387939,546.946106 
	C580.807617,549.984070 580.917358,553.064941 581.146362,556.548950 
	C581.132385,558.943787 581.136841,560.916382 581.044617,563.349731 
	C581.002197,567.236450 581.056458,570.662354 581.104614,574.551270 
	C581.112061,585.973328 581.125732,596.932312 581.139343,607.891357 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M405.014648,728.995117 
	C407.098511,728.749390 409.185577,728.527710 411.265167,728.249939 
	C412.771851,728.048645 414.268646,727.772888 415.769836,727.530334 
	C415.856049,728.997864 416.448212,730.680725 415.932556,731.892029 
	C414.451721,735.370300 414.672333,737.164429 419.088348,737.056458 
	C422.832581,736.964905 427.828796,735.389404 426.715515,742.677673 
	C426.520233,743.956116 429.482269,745.716797 430.671722,747.598877 
	C429.239014,747.955688 428.120758,747.968323 426.654907,747.990723 
	C425.846405,748.049072 425.385406,748.097839 424.527161,748.100891 
	C423.077332,747.897034 422.024750,747.738831 420.984863,747.183289 
	C420.773651,744.532654 421.825470,740.933105 418.223297,741.292114 
	C415.543182,741.559204 410.617340,740.472107 411.080383,746.522583 
	C411.021637,747.917664 410.972992,748.935181 410.674438,750.140991 
	C409.978973,751.232239 409.533386,752.135193 408.739502,753.068115 
	C405.927063,757.065491 403.462921,761.032776 400.689514,764.866333 
	C398.931213,763.823303 397.482147,762.913940 396.017609,761.612244 
	C395.942566,760.437927 395.882996,759.655945 395.835754,758.861206 
	C395.848053,758.848511 395.860321,758.813171 396.194153,758.869019 
	C397.657959,758.278870 398.787964,757.632812 400.290894,756.955627 
	C403.822327,755.942017 404.293549,753.572449 403.854980,750.444275 
	C403.891266,749.768005 403.931030,749.382446 404.318665,748.942505 
	C406.646759,747.302124 406.778412,745.962524 404.102417,744.527710 
	C404.007172,739.385986 403.956848,734.707642 404.145935,729.921814 
	C404.595154,729.541321 404.804901,729.268250 405.014648,728.995117 
M408.795166,734.385132 
	C408.576630,735.729187 408.358093,737.073181 408.139557,738.417297 
	C408.883362,738.446899 409.627136,738.476562 410.370941,738.506165 
	C410.079437,737.136658 409.787964,735.767212 408.795166,734.385132 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M726.783203,476.546631 
	C726.783203,476.546631 726.711731,476.228088 727.138977,476.116882 
	C728.044983,476.007019 728.523682,476.008392 729.002441,476.009766 
	C732.592041,484.846680 732.325134,485.796295 724.471252,492.050415 
	C726.125183,492.806702 727.788330,493.567169 729.451477,494.327637 
	C728.799988,495.050873 728.148499,495.774109 727.497009,496.497345 
	C723.333984,497.784698 719.697510,496.673309 717.535767,492.820770 
	C715.789551,489.708771 713.522217,489.109741 710.264648,488.880768 
	C705.590454,488.552185 700.879333,487.755951 696.355286,486.528046 
	C690.376465,484.905304 692.185730,480.496704 693.942627,476.515686 
	C696.057922,477.349091 697.670654,478.159515 699.288818,478.958893 
	C700.672424,479.642334 701.998352,480.642181 703.461609,480.926544 
	C707.576111,481.726166 711.751648,482.207642 715.896362,482.857574 
	C718.006165,483.188446 720.106995,483.958954 722.202148,483.925232 
	C727.455933,483.840759 728.723572,481.637665 726.783203,476.546631 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M366.291473,713.472534 
	C364.066498,714.019653 361.711975,714.276245 359.652618,715.194702 
	C357.549530,716.132751 355.736237,717.720520 353.445007,718.753052 
	C353.586578,717.570862 354.079254,716.659546 354.571930,715.748169 
	C352.366821,716.111389 350.788239,717.248108 349.178314,718.338501 
	C347.813477,719.262939 346.412354,720.133911 344.996338,720.996399 
	C344.965363,720.964294 344.903351,720.900269 344.970886,720.569092 
	C347.235443,718.081848 349.358826,715.844055 351.645447,713.787659 
	C355.863953,709.993774 357.798431,705.307922 355.299835,702.278320 
	C352.696075,699.121216 349.055847,696.818909 345.714050,693.876221 
	C344.653656,692.790283 343.748016,691.971008 342.877808,690.813416 
	C342.299347,689.964600 341.685425,689.454102 341.041260,688.970276 
	C341.010986,688.997009 341.056030,688.930420 341.040741,688.606934 
	C340.311981,687.561096 339.598511,686.838745 338.921967,686.056641 
	C338.958893,685.997009 338.864502,686.101501 338.895935,685.770142 
	C338.324066,684.934021 337.720795,684.429199 337.084045,683.935303 
	C337.050568,683.946228 337.080994,683.882751 337.079407,683.607788 
	C336.426483,682.749451 335.775085,682.166199 334.902344,681.435913 
	C334.489746,680.793945 334.298553,680.299011 334.182739,679.594116 
	C334.258118,679.384094 334.425293,678.970337 334.425293,678.970337 
	C339.069550,678.996460 343.101440,679.113098 344.351807,685.379211 
	C345.014587,688.700562 349.043304,689.347351 352.675354,688.715149 
	C354.063049,688.473633 355.624115,689.228027 357.106140,689.528564 
	C357.038300,690.067078 356.970428,690.605652 356.902588,691.144165 
	C355.049561,692.002686 353.196533,692.861206 351.426208,693.681396 
	C357.095825,699.434570 362.625885,705.046082 368.418762,710.924377 
	C372.638245,708.732605 377.358917,704.637756 380.791962,711.773926 
	C377.154724,712.400024 374.109406,712.855774 370.661316,713.299316 
	C368.936218,713.348999 367.613831,713.410767 366.291473,713.472534 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M574.098999,672.552368 
	C573.483154,672.756836 573.076294,672.763367 571.973633,672.778015 
	C570.495483,672.795532 569.713013,672.804932 568.930603,672.814331 
	C567.236816,672.165283 565.543091,671.516235 563.481079,670.895386 
	C563.112854,670.923523 563.101990,670.916016 563.073853,670.591980 
	C562.416138,669.797546 561.786499,669.327209 561.161011,668.827637 
	C561.165161,668.798401 561.179810,668.741211 561.179810,668.741211 
	C559.796753,664.815735 558.035889,660.975281 557.127563,656.942871 
	C556.024780,652.047729 557.755676,647.816589 561.988159,644.385620 
	C562.444885,643.995422 562.692383,643.836975 563.351685,643.344971 
	C566.003784,641.804077 568.230286,639.388916 570.383179,639.452881 
	C576.901733,639.646484 585.170898,643.629822 586.058533,650.522278 
	C586.225159,651.816956 587.846924,652.924194 588.797241,654.117920 
	C588.802002,655.765198 588.806824,657.412537 588.449036,659.520996 
	C587.478516,660.493286 586.405212,660.947083 586.334290,661.524109 
	C585.570618,667.738098 581.229492,670.257080 575.952759,671.883301 
	C575.404541,672.040344 574.856323,672.197327 574.098999,672.552368 
M562.854492,663.675293 
	C566.935547,668.737244 575.226501,669.565491 580.067627,665.394897 
	C584.975769,661.166565 585.904663,653.769714 582.146240,648.843445 
	C578.355774,643.875366 569.590454,642.634155 564.542725,646.350830 
	C559.312195,650.202148 558.464233,657.020447 562.854492,663.675293 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M370.588501,594.000244 
	C371.014862,594.745117 371.029724,595.490234 370.767761,596.825562 
	C369.327515,601.055786 368.408020,603.261414 363.815063,599.914795 
	C360.993469,597.858887 356.352783,598.299561 351.716766,597.474670 
	C352.803925,601.048645 353.404388,603.022644 354.004883,604.996704 
	C353.247223,605.816711 352.601959,606.802917 351.701691,607.412048 
	C350.708252,608.084167 349.214050,609.055664 348.421234,608.727844 
	C347.371490,608.293884 346.569916,606.822571 346.046753,605.628357 
	C343.858673,600.633545 345.945374,596.844116 350.232056,593.296265 
	C355.306000,592.162903 354.456573,587.800476 355.995117,585.004883 
	C356.163818,585.003601 356.332489,585.002258 356.754211,584.999268 
	C361.486664,584.997559 365.966003,584.997559 371.882416,584.997559 
	C369.112366,587.712463 366.871948,589.908325 363.694122,593.022888 
	C366.173187,593.396667 368.175079,593.698547 370.588501,594.000244 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M731.007324,527.995361 
	C731.618164,528.257935 732.281860,528.830505 732.831970,528.736694 
	C741.835999,527.200928 749.408020,532.196716 757.651062,534.095825 
	C760.787354,534.818359 761.640686,536.629883 760.711731,538.991333 
	C756.489685,538.991333 752.686096,539.065430 748.888794,538.944092 
	C747.809509,538.909607 745.980103,538.536987 745.797058,537.927856 
	C743.819214,531.348450 737.635864,533.550842 734.011353,533.560913 
	C730.428650,533.570984 725.131226,534.888123 723.237549,540.055420 
	C722.978638,540.761841 720.454895,541.019714 719.070496,540.828430 
	C717.583984,540.622925 716.204590,539.642395 714.424072,538.647766 
	C715.800537,537.029541 717.531372,535.763550 720.034607,533.932678 
	C720.034607,533.051086 720.034607,531.076233 720.035034,529.062439 
	C720.035522,529.023499 720.102112,529.057373 720.464783,529.036987 
	C721.552795,528.012817 722.278137,527.009033 723.001709,526.002625 
	C723.000000,526.000000 723.005249,526.003357 723.382080,526.007874 
	C725.196594,526.848267 726.562012,527.886169 728.101624,528.437683 
	C728.913208,528.728455 730.028931,528.170288 731.007324,527.995361 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M422.192230,672.266968 
	C419.211609,669.610291 416.493134,667.131042 413.607910,664.863953 
	C411.147888,662.931091 408.656555,660.914734 405.876617,659.552612 
	C403.784576,658.527527 401.213593,658.479858 398.461792,657.651672 
	C398.379761,656.205811 398.691711,655.105347 399.296143,653.973145 
	C399.727997,653.293457 399.867401,652.645508 399.969360,651.650085 
	C398.290253,650.198547 396.648621,649.094421 395.000763,647.617432 
	C394.346619,645.145569 393.698700,643.046570 393.050812,640.947510 
	C395.867249,643.280334 399.117371,645.252014 401.418121,648.014343 
	C408.092407,656.027710 415.401062,663.117859 424.698517,668.116943 
	C431.812408,671.941956 438.643311,676.293274 445.808228,680.678833 
	C445.645416,681.245911 445.255371,681.825134 444.906494,681.801392 
	C439.422241,681.427368 433.943359,680.975708 428.226715,679.917114 
	C427.328827,678.526550 426.667480,677.760803 426.006104,676.995117 
	C424.798096,675.508850 423.590057,674.022583 422.192230,672.266968 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M427.002472,747.981018 
	C428.120758,747.968323 429.239014,747.955688 430.679871,747.967834 
	C432.122101,747.847046 434.177979,747.429871 434.225891,747.595154 
	C435.883057,753.307556 439.818024,750.254578 443.065887,750.108765 
	C444.257416,750.055298 446.081635,750.470947 446.594696,751.303040 
	C448.736542,754.776672 452.074341,754.863403 455.517944,755.391724 
	C462.029724,756.390747 468.764069,759.549561 474.891571,758.575439 
	C483.542328,757.200195 490.869354,762.767883 499.190796,761.188477 
	C499.928040,761.048523 501.052460,762.948608 501.640503,764.385742 
	C499.857574,765.161926 498.431213,765.453613 497.004852,765.745361 
	C494.016541,765.696350 491.028198,765.647278 487.568542,765.158447 
	C484.498627,759.826843 479.904999,761.465088 475.412292,761.241455 
	C469.860596,760.713928 464.743103,760.379700 459.671509,759.701599 
	C455.976105,759.207520 452.341370,758.259766 448.679565,757.514160 
	C447.378967,757.423462 446.078369,757.332764 444.225464,756.891479 
	C440.689636,755.336121 437.715607,754.107117 434.719727,752.934082 
	C432.151978,751.928711 429.559265,750.987305 426.995422,749.678467 
	C427.009644,748.886597 427.006042,748.433777 427.002472,747.981018 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M432.996338,628.997559 
	C432.799896,628.374268 432.797760,627.459900 432.374054,627.177063 
	C430.318970,625.805359 427.503113,624.570740 429.439392,621.583923 
	C431.377930,618.593811 433.606232,615.819397 438.078278,615.861755 
	C441.619080,615.895325 442.753265,617.874451 443.613403,620.434937 
	C444.290192,622.449646 444.431793,624.639160 445.035675,626.684753 
	C445.624817,628.680481 446.049805,630.982361 447.337097,632.462769 
	C450.422180,636.010803 448.688690,638.676331 446.211365,640.844849 
	C445.238953,638.866089 444.471375,636.342285 443.750183,636.355408 
	C438.271332,636.455566 435.584167,632.880554 432.996338,628.997559 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M555.242920,573.163330 
	C559.265564,568.469971 563.415344,563.878296 567.272156,559.052368 
	C570.590271,554.900513 573.574158,550.481445 576.705811,546.180542 
	C577.248962,546.387695 577.792114,546.594788 578.335327,546.801941 
	C578.579346,547.966675 578.823425,549.131348 578.600952,550.669373 
	C575.138184,551.276917 572.877197,552.342468 572.742554,555.812012 
	C568.370972,562.425964 564.370422,569.328064 559.522278,575.571960 
	C554.766846,581.696533 549.149353,587.151733 543.621216,593.217834 
	C537.841248,587.468567 532.497314,582.645935 527.839661,577.232788 
	C526.357971,575.510864 526.701782,572.218201 526.234436,569.229126 
	C529.242004,572.426941 532.300598,575.980835 535.194214,579.664307 
	C542.908813,589.484741 544.027283,589.514160 551.638306,579.701050 
	C552.131714,579.064880 552.482300,578.317932 553.011475,577.114746 
	C553.123657,576.607056 553.298096,576.254333 553.607117,576.150513 
	C554.345154,575.100159 554.774109,574.153503 555.212097,573.195435 
	C555.221069,573.183960 555.242920,573.163330 555.242920,573.163330 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M707.002441,547.004883 
	C707.876099,549.348755 708.452759,551.864685 709.689209,553.998230 
	C711.832520,557.696655 711.255127,559.401489 707.149902,559.996765 
	C702.559998,560.662292 700.624084,563.376709 701.359375,567.757874 
	C701.763611,570.166138 701.805969,572.635254 701.979858,575.564453 
	C701.948181,576.052002 701.885986,576.142456 701.616516,576.006104 
	C700.542053,575.291809 699.737000,574.713928 698.953491,573.761475 
	C698.271912,572.610718 697.568909,571.834473 696.647888,571.030029 
	C696.429871,571.001953 696.031738,570.815247 696.025757,570.460205 
	C695.944336,567.453308 695.091614,565.611755 691.549927,566.111450 
	C690.403992,563.994934 689.635742,561.946533 688.867554,559.898132 
	C688.867554,559.898132 688.979309,559.939941 689.289429,559.957642 
	C690.842041,558.997925 692.084473,558.020508 693.654236,557.024780 
	C694.648682,556.305359 695.315857,555.604126 696.150024,554.856934 
	C696.317017,554.810913 696.661804,554.844849 697.049561,554.856018 
	C697.943054,553.855347 698.448792,552.843506 699.094299,551.769287 
	C699.234070,551.706970 699.533386,551.771240 699.930115,551.820068 
	C701.533081,550.182007 702.739380,548.495117 704.089722,546.753235 
	C704.233826,546.698181 704.541443,546.674438 704.946289,546.764160 
	C705.901611,546.904236 706.452026,546.954529 707.002441,547.004883 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M514.008972,451.994629 
	C514.006714,451.247101 514.004456,450.499573 514.428528,449.373169 
	C520.748901,450.259521 527.713074,450.000183 532.256531,453.189484 
	C537.520996,456.884827 542.480469,456.911682 547.967529,457.315979 
	C547.296509,458.424957 546.648254,459.212128 546.000000,459.999268 
	C544.007324,460.667816 541.526489,460.802948 540.108948,462.100037 
	C534.818787,466.940735 528.557861,464.428009 522.712585,464.827728 
	C521.965881,464.878815 521.117798,463.447510 520.164673,461.985321 
	C522.039246,459.364136 524.065979,457.460510 526.515320,455.159912 
	C522.229736,454.075256 518.119324,453.034943 514.008972,451.994629 
M528.855896,456.093628 
	C529.692566,456.294708 530.529236,456.495789 531.365906,456.696899 
	C531.417114,456.292999 531.468323,455.889099 531.519531,455.485199 
	C530.597107,455.485199 529.674683,455.485199 528.855896,456.093628 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M354.283875,605.200439 
	C353.404388,603.022644 352.803925,601.048645 351.716766,597.474670 
	C356.352783,598.299561 360.993469,597.858887 363.815063,599.914795 
	C368.408020,603.261414 369.327515,601.055786 370.771423,597.198181 
	C373.589325,602.138367 376.226593,607.250732 378.608490,612.479370 
	C379.409088,614.236755 379.560181,616.290039 379.998657,618.605225 
	C373.423126,620.636719 372.203735,615.022034 370.159912,611.239197 
	C367.955353,607.158813 365.880920,605.448547 362.186981,607.691101 
	C358.306824,610.046753 356.402008,608.488953 354.283875,605.200439 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M334.310486,678.618591 
	C334.425293,678.970337 334.258118,679.384094 333.853210,679.513733 
	C329.380951,673.858948 324.834930,668.348206 321.340271,662.236328 
	C312.540833,646.846741 305.266083,630.721741 300.101929,613.719299 
	C299.447266,611.563843 299.511627,609.190002 299.571716,607.024780 
	C301.559692,611.065674 303.218842,614.999207 304.877991,618.932678 
	C304.899933,619.395142 304.921844,619.857605 304.957520,621.042419 
	C307.863220,628.181274 310.755157,634.597717 313.647064,641.014221 
	C313.725952,641.773804 313.804810,642.533447 314.047974,643.961548 
	C315.074341,645.152466 315.936432,645.674866 316.798553,646.197266 
	C316.798553,646.197266 316.829529,646.643616 316.875427,647.232117 
	C318.565887,650.884766 320.210480,653.948914 321.855072,657.013062 
	C323.746582,659.604980 325.692169,662.159668 327.510132,664.802185 
	C328.680115,666.502747 329.616272,668.362366 330.743317,670.094666 
	C331.682312,671.538025 332.974731,672.795227 333.680359,674.332336 
	C334.207550,675.480713 334.048920,676.943848 334.310486,678.618591 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M512.993408,447.875000 
	C507.980469,447.640503 502.967499,447.405975 497.081360,447.145386 
	C494.486938,447.153656 492.765747,447.188019 490.638916,447.147797 
	C488.406677,447.656677 486.580048,448.240143 484.376984,448.824341 
	C482.998444,448.877014 481.996338,448.928955 480.609375,448.976807 
	C477.816193,449.982788 475.407928,450.992859 472.670685,452.004700 
	C471.905060,452.044250 471.468384,452.082123 470.634644,452.078796 
	C466.629150,453.383179 462.942535,454.556122 459.442322,456.140686 
	C457.427094,457.053009 455.713379,458.631256 453.521698,459.914398 
	C452.128448,460.315155 451.077423,460.711243 449.678345,461.098938 
	C447.840881,462.365479 446.351471,463.640381 444.555695,464.931152 
	C443.847961,465.024445 443.446594,465.101898 442.697021,465.130920 
	C441.882812,465.036957 441.416748,464.991425 440.995850,464.604126 
	C448.919586,457.815033 457.528961,452.909393 467.589813,450.553680 
	C471.543335,449.627960 475.221313,447.525726 479.352478,445.951202 
	C480.109253,445.866486 480.538300,445.789795 481.279358,445.828461 
	C482.030762,445.937378 482.470154,445.930969 483.322815,445.957886 
	C486.913055,445.071899 490.090118,444.152588 493.632385,443.225586 
	C494.981323,443.226807 495.964996,443.235657 497.279755,443.488342 
	C501.759674,443.577972 505.908539,443.423737 510.390503,443.239624 
	C511.393463,443.204895 512.063293,443.200043 512.874634,443.531158 
	C515.003540,444.013062 516.990845,444.159027 519.261230,444.310303 
	C519.920715,444.335693 520.297302,444.355713 520.745728,444.717926 
	C520.882812,445.525421 520.947876,445.990723 520.685181,446.655273 
	C517.902771,447.194611 515.448120,447.534790 512.993408,447.875000 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M299.857117,597.018677 
	C299.924927,599.039856 299.992737,601.061035 299.971069,603.730957 
	C299.712616,604.687500 299.461670,604.897156 298.829681,604.780762 
	C296.719543,595.532654 293.968109,586.579773 293.314789,577.476257 
	C292.300842,563.347656 292.524719,549.114807 292.648071,534.929810 
	C292.682739,530.942871 294.201630,526.968872 295.312744,523.242554 
	C295.723877,524.999084 295.862061,526.502014 296.000214,528.004883 
	C295.981750,529.389160 295.963318,530.773376 295.650452,532.879028 
	C295.587219,551.210449 295.619293,568.827271 296.215729,586.424988 
	C296.336670,589.993225 298.587952,593.489319 299.857117,597.018677 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M633.000000,413.000000 
	C632.731628,410.380646 631.979431,407.696930 632.317017,405.158142 
	C632.741577,401.964966 634.057617,398.890350 635.351807,395.370728 
	C636.186646,394.943329 636.658630,394.909790 637.410156,394.929962 
	C638.138000,395.304962 638.586182,395.626282 639.053406,396.299408 
	C641.029602,398.119507 642.986755,399.587769 644.994751,401.395447 
	C646.038757,403.481293 647.032043,405.227753 648.052124,407.340515 
	C651.111389,414.844086 651.111389,414.844086 645.991150,418.470154 
	C646.983337,420.618744 647.994080,422.807526 648.629272,424.990051 
	C646.937561,423.473755 644.691895,422.068390 644.507629,420.431000 
	C644.169861,417.430450 644.873352,414.258606 645.467590,411.221771 
	C646.119080,407.891998 645.010925,405.695282 641.093689,403.389832 
	C642.118835,410.471588 639.128235,413.364899 633.000000,413.000000 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M450.026398,461.107361 
	C451.077423,460.711243 452.128448,460.315155 453.920410,459.947083 
	C457.775024,458.987885 460.888733,458.000671 464.002441,457.013428 
	C462.510803,458.237122 461.162415,460.211365 459.497864,460.529083 
	C455.972107,461.202057 453.965698,462.956940 453.033722,466.634460 
	C444.982819,470.532623 437.271973,474.058929 432.454834,482.356354 
	C429.740814,487.031189 425.236877,491.087128 418.552246,490.714508 
	C418.888123,489.317657 419.493134,488.211151 420.452728,487.069214 
	C427.247681,484.459808 430.053650,478.675018 433.410095,473.035950 
	C434.155396,472.340515 434.598816,471.719055 435.034668,471.069275 
	C435.027100,471.040955 435.085480,471.046112 435.427094,471.034180 
	C436.520630,470.376862 437.272552,469.731415 438.237549,469.033661 
	C438.450653,468.981354 438.886383,468.929230 439.219543,468.922150 
	C440.043213,468.290344 440.533691,467.665619 441.016388,467.031219 
	C441.008606,467.021576 441.041565,467.023254 441.349548,466.987122 
	C442.120087,466.360443 442.582672,465.769897 443.045227,465.179352 
	C443.446594,465.101898 443.847961,465.024445 444.921936,464.937317 
	C447.071869,463.654236 448.549133,462.380798 450.026398,461.107361 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M335.125092,435.696838 
	C335.244812,437.626038 335.511841,439.240936 335.821259,441.111938 
	C330.513824,443.956177 326.891449,448.051849 324.557037,454.006714 
	C322.927948,458.162445 318.777344,461.329712 315.361755,464.958801 
	C314.970520,464.979065 314.887909,464.911682 314.956482,464.563232 
	C317.892700,458.740173 320.887085,453.326752 323.575348,447.765350 
	C324.947174,444.927399 325.769775,441.823944 327.012573,438.612732 
	C327.745270,437.888428 328.306061,437.391937 329.218689,436.911438 
	C330.350494,435.942841 331.130402,434.958252 331.957123,433.638977 
	C331.986755,432.854218 331.969635,432.404053 331.976929,431.973022 
	C332.001373,431.992126 331.950653,431.962433 332.270538,431.869690 
	C333.049622,430.837158 333.508820,429.897339 334.159058,428.823608 
	C334.589417,428.486725 334.791992,428.251984 335.285797,427.887207 
	C338.423157,424.214600 341.232513,420.640320 344.310791,416.982727 
	C345.001648,416.573212 345.423645,416.247040 346.191162,415.933960 
	C346.993958,415.934021 347.451172,415.921021 347.934509,415.934174 
	C347.960632,415.960327 348.012848,416.012604 348.012848,416.012604 
	C346.136230,419.002411 344.064178,421.891235 342.449280,425.016266 
	C341.305237,427.230194 340.779144,429.763458 339.968994,432.565491 
	C338.398010,433.778717 336.835205,434.580658 335.125092,435.696838 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M704.190308,615.512085 
	C704.190308,615.512085 704.343994,615.251099 704.250977,614.767456 
	C702.780579,612.852112 701.403076,611.420410 700.025635,609.988770 
	C697.101929,606.865051 694.178223,603.741394 691.016113,600.001099 
	C689.185120,597.923035 687.592590,596.461487 685.877686,594.686279 
	C683.523560,592.239502 681.291748,590.106506 679.059937,587.973450 
	C678.966614,587.323914 678.873291,586.674438 678.889221,585.519043 
	C678.998474,585.013245 679.330505,584.658875 679.655518,584.756836 
	C680.847961,585.538025 681.804016,586.134705 682.568848,586.918091 
	C688.741760,593.240662 694.878662,599.598206 701.138245,606.169800 
	C701.517212,606.588074 701.784485,606.781494 702.076294,607.315857 
	C703.748352,609.068420 705.395935,610.480042 707.262207,612.135742 
	C708.502197,613.073181 709.523499,613.766663 710.641174,614.612061 
	C710.737549,614.763977 710.962952,615.044434 710.995483,615.417603 
	C717.392822,619.284119 724.299561,617.823547 730.964417,617.614441 
	C733.913330,617.521912 734.079041,614.088928 731.307129,611.383240 
	C730.978455,610.566406 730.761169,610.021301 730.677795,609.336365 
	C730.811646,609.196533 731.080261,608.917786 731.408081,608.981079 
	C733.362549,610.087769 734.989197,611.131165 736.693848,612.194946 
	C736.771851,612.215210 736.866211,612.084412 736.824463,612.400879 
	C736.742188,613.141479 736.701599,613.565552 736.468872,614.233521 
	C737.196167,617.628540 735.754150,619.238831 732.775269,619.854797 
	C731.551697,620.107910 730.331787,620.378357 729.110168,620.640991 
	C726.007263,620.654968 722.904419,620.668945 719.064453,620.434631 
	C716.876709,620.168884 715.426025,620.151367 713.600464,620.099731 
	C712.475037,620.004700 711.724548,619.943787 710.841431,619.594116 
	C709.487915,618.543091 708.267090,617.780884 706.751465,616.878601 
	C705.701294,616.329651 704.945801,615.920837 704.190308,615.512085 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M525.000000,391.003296 
	C521.174988,392.080627 518.806091,394.230042 518.800537,398.569275 
	C518.799377,399.446442 517.452209,400.321869 515.753174,402.370789 
	C515.342102,399.475830 515.449646,397.980377 514.880737,396.815247 
	C514.217712,395.457489 513.148438,393.640259 511.929535,393.338501 
	C508.628845,392.521271 505.114014,392.603790 501.768555,391.917725 
	C500.114258,391.578461 498.646057,390.331909 497.093658,389.495911 
	C498.229645,388.321442 499.358551,387.139984 500.505188,385.976013 
	C500.942505,385.532013 501.420502,385.128082 501.939392,384.352325 
	C502.000519,383.828918 502.002228,383.659058 502.393311,383.243988 
	C505.253174,383.607422 507.723694,384.216034 510.403870,384.849792 
	C510.613464,384.874939 511.029907,384.944855 511.062408,385.293213 
	C512.736511,386.068878 514.378113,386.496246 516.342773,386.943848 
	C517.093140,387.024780 517.520386,387.085510 518.029297,387.483948 
	C520.407349,388.882202 522.703674,389.942749 525.000000,391.003296 
M509.789764,389.051819 
	C509.386353,389.426056 508.982971,389.800293 508.579559,390.174530 
	C509.846252,390.372284 511.112946,390.570007 512.379639,390.767761 
	C512.431885,390.378510 512.484070,389.989258 512.536316,389.600006 
	C511.857574,389.407074 511.178802,389.214142 509.789764,389.051819 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M469.192383,690.173340 
	C474.106018,690.349182 479.461487,690.083618 484.350403,691.466980 
	C493.594299,694.082520 502.832458,694.562744 512.208069,693.346191 
	C518.897827,692.478149 525.526611,691.139526 532.598877,690.030396 
	C531.900452,691.360596 530.953735,693.570679 529.639893,693.816101 
	C525.435120,694.601685 521.093628,694.651306 516.806274,695.000916 
	C514.362854,695.200134 511.243683,693.906738 510.556244,698.039673 
	C510.481873,698.486938 508.372833,699.139648 507.521454,698.830994 
	C497.147644,695.070862 486.377075,694.238770 475.475189,693.820984 
	C473.418274,693.742188 471.438812,691.643188 469.192383,690.173340 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M300.175262,596.763672 
	C298.587952,593.489319 296.336670,589.993225 296.215729,586.424988 
	C295.619293,568.827271 295.587219,551.210449 295.635864,533.293945 
	C296.342499,535.946838 297.085785,538.900940 297.134399,541.866394 
	C297.259674,549.506531 297.016968,557.152283 297.093079,564.794128 
	C297.102844,565.774597 298.427856,566.687378 298.630829,567.731201 
	C299.170349,570.506226 299.588989,573.325928 299.762268,576.145874 
	C300.010254,580.182129 299.973541,584.235229 300.104431,588.279602 
	C300.193237,591.023926 300.360962,593.765747 300.175262,596.763672 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M710.974060,619.882874 
	C711.724548,619.943787 712.475037,620.004700 713.688416,620.446045 
	C715.747314,621.178406 717.343262,621.530212 718.939270,621.882019 
	C718.781250,622.512878 718.796082,623.562683 718.438660,623.710144 
	C714.573059,625.305115 712.935486,628.094482 714.145325,632.391785 
	C709.999207,633.119568 706.452820,634.280151 704.180115,629.869385 
	C703.781555,629.096008 702.729553,628.483093 701.846436,628.164368 
	C696.276184,626.153381 696.039551,625.704529 697.894470,619.143677 
	C700.356689,620.578674 702.737122,622.024536 705.223328,623.256958 
	C705.359619,623.324524 706.376465,621.615784 707.021362,620.337952 
	C707.059326,619.942017 707.128174,619.874451 707.439453,619.923828 
	C708.825195,619.943115 709.899597,619.913025 710.974060,619.882874 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M529.187012,529.892578 
	C524.684998,524.885620 520.506897,519.739502 516.242859,514.266968 
	C518.791870,514.227600 521.422607,514.701904 524.062805,514.762146 
	C529.309570,514.881897 530.034424,517.190918 526.844482,521.683533 
	C527.331238,521.826538 527.818054,521.969543 528.304871,522.112549 
	C529.575745,520.780945 530.775452,519.369812 532.143372,518.146729 
	C533.010742,517.371155 534.116028,516.449829 535.138977,516.419617 
	C535.722473,516.402344 536.787292,517.999084 536.884949,518.946411 
	C537.187988,521.885925 537.553223,524.881226 533.680969,526.289673 
	C532.082520,526.871094 530.888794,528.565308 529.187012,529.892578 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M371.064087,713.311462 
	C374.109406,712.855774 377.154724,712.400024 380.586487,711.975769 
	C383.609985,712.386841 386.247009,712.766479 389.406128,713.501587 
	C394.105530,718.240601 398.281433,722.625488 402.469910,726.998291 
	C402.556458,727.088684 402.789673,727.038635 402.954468,727.054077 
	C402.954468,727.054077 402.999481,727.000000 402.966736,727.353882 
	C403.258179,728.481567 403.582336,729.255371 403.906494,730.029236 
	C403.956848,734.707642 404.007172,739.385986 404.047821,744.925781 
	C404.015686,746.857117 403.993256,747.927063 403.970795,748.996948 
	C403.931030,749.382446 403.891266,749.768005 403.524933,750.565552 
	C402.104919,752.980713 401.011475,754.983704 399.917969,756.986694 
	C398.787964,757.632812 397.657959,758.278870 396.229279,758.519409 
	C397.099792,755.744141 398.078918,753.256104 399.469147,751.024170 
	C408.415375,736.661194 395.071350,713.000488 376.391327,713.921509 
	C374.629883,714.008362 372.840485,713.528259 371.064087,713.311462 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M589.108154,653.880493 
	C587.846924,652.924194 586.225159,651.816956 586.058533,650.522278 
	C585.170898,643.629822 576.901733,639.646484 570.383179,639.452881 
	C568.230286,639.388916 566.003784,641.804077 563.460327,643.149536 
	C564.198730,632.400696 568.468506,630.800110 578.681335,632.617249 
	C578.187988,637.822815 581.555420,640.587830 585.454102,643.410889 
	C588.676208,645.744080 590.969299,649.126770 589.108154,653.880493 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M667.520386,546.673950 
	C667.452454,538.467102 667.384521,530.260254 667.658752,521.535706 
	C667.862610,520.289551 667.724304,519.560974 667.586060,518.832458 
	C667.306946,516.576477 667.027832,514.320496 666.661926,511.643188 
	C671.101501,510.248627 671.909058,512.801941 671.935974,516.549927 
	C672.003052,525.860168 672.260925,535.169006 672.669617,545.130310 
	C671.104797,546.079468 669.312622,546.376709 667.520386,546.673950 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M478.799866,465.081757 
	C479.973846,466.990356 480.777161,468.797791 481.580505,470.605225 
	C479.728760,471.403412 477.941162,472.509247 476.007050,472.912781 
	C474.099335,473.310791 472.040161,473.069550 470.053101,472.981262 
	C466.595551,472.827667 463.753876,471.918518 463.962341,467.563629 
	C463.992401,466.935883 463.679901,466.295654 463.553741,465.655701 
	C463.304810,464.393250 463.075348,463.126953 462.837830,461.862244 
	C463.987701,462.093475 465.131866,462.359711 466.288757,462.547760 
	C468.020966,462.829285 469.778992,462.973602 471.495331,463.325836 
	C473.821808,463.803314 476.119293,464.422302 478.799866,465.081757 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M513.805359,552.016174 
	C517.892334,557.417908 521.979309,562.819580 526.088867,568.571045 
	C524.051331,568.171631 521.991211,567.422485 520.842896,567.004883 
	C517.498291,568.808899 514.642883,570.348999 511.393677,571.504517 
	C510.951263,567.736084 510.902588,564.352234 510.888733,560.557190 
	C510.910278,558.769470 510.896942,557.393005 510.914520,555.632385 
	C511.898743,554.170898 512.852051,553.093506 513.805359,552.016174 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M563.012756,757.222534 
	C562.629272,757.272705 562.245789,757.322815 561.147095,757.278687 
	C558.499390,757.365540 556.550232,757.442078 554.637451,757.746826 
	C548.085510,758.790771 541.546448,759.914856 535.002441,761.008362 
	C536.403503,760.336731 537.804626,759.665100 539.931335,758.965515 
	C541.771301,757.959167 542.885681,756.980835 544.000000,756.002441 
	C544.444031,756.000916 544.888000,755.999390 546.100830,755.997864 
	C550.913818,755.000183 554.958069,754.002563 559.002258,753.004883 
	C564.879028,751.218689 570.708191,749.244812 576.655396,747.736084 
	C579.120544,747.110657 581.837952,747.479980 584.490234,747.983398 
	C577.364197,751.454163 570.188477,754.338379 563.012756,757.222534 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M667.337280,547.040161 
	C669.312622,546.376709 671.104797,546.079468 672.980652,545.419006 
	C678.688843,538.973572 684.313416,532.891296 690.291809,526.304077 
	C692.809753,523.284180 694.973938,520.769165 697.476440,518.177002 
	C700.246399,515.419617 702.678101,512.739441 705.488037,510.039246 
	C706.616150,510.074860 707.366028,510.130432 708.115845,510.186005 
	C701.263611,517.797974 694.386536,525.387817 687.579773,533.040222 
	C686.191284,534.601135 685.066650,536.396851 683.834595,538.460815 
	C683.848816,538.837219 683.805969,538.855774 683.471436,538.901794 
	C681.519409,541.038391 679.901794,543.128906 678.248108,545.214905 
	C678.212036,545.210327 678.221924,545.282349 677.859741,545.195435 
	C676.777832,546.162476 676.058228,547.216431 675.259155,548.456299 
	C675.179810,548.642151 675.010925,549.009338 675.010925,549.009338 
	C672.831177,550.048035 670.651489,551.086670 667.913818,552.391235 
	C667.662781,550.744080 667.408447,549.075195 667.337280,547.040161 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M574.992004,509.979919 
	C576.770569,508.656097 578.428894,506.450928 580.352234,506.188477 
	C584.602539,505.608521 588.991882,506.142792 593.313843,505.964203 
	C597.113464,505.807220 600.343567,506.245300 603.161011,509.408813 
	C604.627014,511.054810 607.425354,511.514130 609.951477,513.902222 
	C607.695190,514.611572 605.438843,515.320862 602.633545,515.709717 
	C601.710144,514.276672 601.335693,513.164062 600.938171,511.681030 
	C598.433105,509.545593 596.014282,507.143860 592.597839,510.658264 
	C591.381287,511.101105 590.587646,511.412048 589.392639,511.550079 
	C586.399841,511.520660 583.808472,511.664185 580.890259,511.499054 
	C579.373779,511.114105 578.184021,511.037811 576.855713,510.694519 
	C576.142029,510.278320 575.567017,510.129120 574.992004,509.979919 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M510.853882,560.968445 
	C510.902588,564.352234 510.951263,567.736084 511.031647,571.583130 
	C511.052643,576.043274 511.041901,580.040161 511.013062,584.920715 
	C511.017120,587.217163 511.039307,588.630005 511.061493,590.042786 
	C511.054749,596.368408 511.048004,602.694031 511.013794,609.749207 
	C510.956879,610.655396 510.927399,610.832031 510.663269,610.828003 
	C509.704865,610.066284 508.981079,609.485413 508.257294,608.904541 
	C508.196136,593.273865 508.135010,577.643188 508.475769,561.537598 
	C509.536407,561.031311 510.195129,560.999878 510.853882,560.968445 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M578.119263,577.946350 
	C578.638062,588.614868 578.772949,599.299133 578.532959,610.542175 
	C578.130066,613.054993 578.102112,615.009155 578.074097,616.963257 
	C577.047668,615.589722 575.164490,614.236572 575.131348,612.839539 
	C574.884583,602.435364 574.913940,592.020569 575.129395,581.614807 
	C575.154968,580.379822 576.826721,579.178894 578.119263,577.946350 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M752.534119,503.586639 
	C747.318848,503.821716 742.458801,503.917389 737.605469,503.779236 
	C737.063843,503.763855 735.998169,502.271179 736.143982,501.719696 
	C736.580444,500.069397 737.324097,497.313141 738.256775,497.181732 
	C742.910522,496.525879 747.669067,496.452393 752.367310,496.673096 
	C752.646423,496.686188 752.721375,501.042999 752.534119,503.586639 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M399.003662,654.004883 
	C398.691711,655.105347 398.379761,656.205811 398.036957,657.650696 
	C394.254486,661.612671 392.560120,658.396423 391.122986,655.681335 
	C389.862030,653.299072 388.882843,651.137451 385.324860,652.859131 
	C384.666595,651.910706 384.333160,650.821350 384.278900,649.117554 
	C384.705780,647.001160 384.853485,645.499390 385.001221,643.997559 
	C386.747559,643.693420 388.493896,643.389282 390.624542,643.408569 
	C392.138214,648.248230 392.209961,648.324646 395.007019,647.990234 
	C396.648621,649.094421 398.290253,650.198547 399.722229,651.817200 
	C399.342926,652.889465 399.173309,653.447144 399.003662,654.004883 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M741.695190,632.846069 
	C742.293152,636.357422 739.944031,636.026733 737.406311,636.000000 
	C736.219421,633.782837 730.406738,631.171692 728.026550,631.532898 
	C726.052612,631.832458 723.968262,631.485413 721.955017,631.241516 
	C721.835266,631.226929 721.625549,628.944763 721.945923,628.800232 
	C723.883057,627.926453 725.962280,627.367798 729.020630,626.368347 
	C729.020630,625.894043 729.020630,624.313965 729.457275,622.324036 
	C731.584534,621.910034 733.275269,621.905823 734.965942,621.901611 
	C733.606995,627.582581 739.662415,628.849121 741.695190,632.846069 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M726.603027,476.514923 
	C728.723572,481.637665 727.455933,483.840759 722.202148,483.925232 
	C720.106995,483.958954 718.006165,483.188446 715.896362,482.857574 
	C711.751648,482.207642 707.576111,481.726166 703.461609,480.926544 
	C701.998352,480.642181 700.672424,479.642334 699.288818,478.958893 
	C697.670654,478.159515 696.057922,477.349091 694.218994,476.271729 
	C693.995117,476.000000 693.996094,475.501190 693.994385,475.251831 
	C694.190247,474.431305 694.387817,473.860168 695.187012,473.181580 
	C697.193665,473.711365 698.598877,474.348572 700.049255,475.365326 
	C702.205444,479.319580 705.092712,478.262299 708.371521,477.053955 
	C709.476440,477.079926 710.212463,477.122131 711.057861,477.534271 
	C714.116882,477.932800 717.066467,477.961426 720.418701,477.985474 
	C722.566711,477.486267 724.312012,476.991638 726.057373,476.497009 
	C726.057373,476.496979 726.422913,476.483215 726.603027,476.514923 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M513.085327,448.123169 
	C515.448120,447.534790 517.902771,447.194611 521.056152,446.600494 
	C522.497131,446.413818 523.239441,446.481079 524.094299,446.878418 
	C527.683594,448.574982 531.063843,450.335876 534.659668,451.215973 
	C540.098938,452.547241 545.849731,455.175659 551.116577,450.116821 
	C551.244324,451.521515 551.105347,452.747101 550.750916,454.084045 
	C550.268066,454.397430 550.083740,454.658173 549.664673,455.026093 
	C548.896301,455.713409 548.445740,456.352234 547.997375,456.995178 
	C547.999634,456.999298 547.990417,456.994141 547.990417,456.994141 
	C542.480469,456.911682 537.520996,456.884827 532.256531,453.189484 
	C527.713074,450.000183 520.748901,450.259521 514.428589,448.999390 
	C513.727295,448.793457 513.452271,448.582397 513.085327,448.123169 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M601.835510,736.319946 
	C606.619141,733.252075 611.157288,730.362854 615.892456,727.844055 
	C617.382263,727.051514 619.400940,727.253113 621.594727,727.005066 
	C619.671997,728.843384 617.466675,730.890137 614.964783,732.466064 
	C609.312195,736.026611 603.552795,739.424683 597.737183,742.712585 
	C596.683960,743.307922 595.156067,743.063599 593.291443,742.970093 
	C591.437683,743.834106 590.139771,744.934937 588.841797,746.035767 
	C588.409546,743.890015 587.977356,741.744324 587.783569,740.782349 
	C593.225586,739.159241 597.445862,737.900574 601.835510,736.319946 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M300.120728,507.056519 
	C300.054565,508.105377 299.988403,509.154266 299.644928,510.855225 
	C297.961578,514.698608 296.555511,517.889954 295.071167,520.636719 
	C295.383698,517.075012 295.570770,513.914429 296.222778,510.852814 
	C296.873932,507.795135 297.888214,504.800842 298.922058,501.841187 
	C302.091858,492.766693 305.347504,483.722198 308.692108,475.050720 
	C308.893250,475.968384 308.972046,476.501343 309.050812,477.034302 
	C309.015839,477.467896 308.980865,477.901489 308.662292,478.908569 
	C307.210205,483.947266 306.041779,488.412476 304.873352,492.877686 
	C304.873352,492.877686 304.810638,493.347626 304.475128,493.688049 
	C303.083252,496.677368 302.026886,499.326263 300.970520,501.975189 
	C300.949371,502.729645 300.928223,503.484070 300.657806,504.798828 
	C300.312622,505.924957 300.216675,506.490723 300.120728,507.056519 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M708.341980,510.144623 
	C707.366028,510.130432 706.616150,510.074860 705.208191,510.010803 
	C704.363464,509.986938 704.176819,509.971588 703.990234,509.956268 
	C702.122803,507.445190 700.255310,504.934113 698.326843,502.341064 
	C708.027649,499.510834 717.881714,503.013885 727.503845,505.608612 
	C723.078552,505.823120 718.982971,505.668182 714.889587,505.467468 
	C711.657593,505.309021 709.423096,506.410950 708.993286,509.928192 
	C708.993286,509.928192 708.568115,510.103241 708.341980,510.144623 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M704.000000,575.998169 
	C704.590210,573.089417 705.180359,570.180664 705.770569,567.271912 
	C707.406372,569.205627 708.974121,571.202942 710.700317,573.052307 
	C712.008667,574.454041 713.464233,575.734985 714.956543,576.943359 
	C716.204651,577.954102 718.005859,578.541199 718.752625,579.797180 
	C719.100769,580.382568 717.527527,583.124695 716.834167,583.121155 
	C714.904114,583.111267 712.832092,582.449158 711.098694,581.518372 
	C709.747253,580.792725 708.819214,579.278564 706.979492,578.057434 
	C705.504883,577.999146 704.753906,577.998108 704.001404,577.664246 
	C703.999939,576.887024 703.999939,576.442566 704.000000,575.998169 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M709.456543,510.004456 
	C709.423096,506.410950 711.657593,505.309021 714.889587,505.467468 
	C718.982971,505.668182 723.078552,505.823120 727.589050,505.992004 
	C729.420837,506.303650 730.836792,506.621094 732.626343,506.969238 
	C733.000000,507.000000 732.982666,507.020752 732.982666,507.020752 
	C734.316223,509.430725 735.649780,511.840698 736.688354,514.645264 
	C735.928528,515.363708 735.463684,515.687561 734.998840,516.011475 
	C733.565796,516.994019 732.132690,517.976624 730.245911,518.877869 
	C729.792236,518.796509 729.543762,518.553589 729.697937,518.257751 
	C730.697510,516.978149 732.366882,515.848755 732.223816,515.039795 
	C731.883484,513.115112 731.015259,511.051514 729.732727,509.597717 
	C728.667847,508.390533 726.494507,507.229340 726.110291,510.575470 
	C720.713440,510.410553 715.316589,510.245636 709.456543,510.004456 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M549.982422,454.977600 
	C550.083740,454.658173 550.268066,454.397430 551.140625,454.093262 
	C553.480225,454.331451 555.214600,454.671783 556.948975,455.012115 
	C556.650085,458.053680 556.351196,461.095245 556.032715,464.949097 
	C555.636353,469.630646 558.009949,470.225952 561.067383,470.007172 
	C561.067383,470.007172 561.050171,470.471893 561.038757,470.703308 
	C559.733032,474.335297 556.354492,473.526947 555.835815,471.774261 
	C554.392456,466.896576 550.708740,464.813690 547.019409,462.406036 
	C546.539001,462.092560 546.380920,461.285248 546.036743,460.352966 
	C546.648254,459.212128 547.296509,458.424957 547.967529,457.315979 
	C547.990417,456.994141 547.999634,456.999298 548.320251,456.957001 
	C549.088074,456.269012 549.535217,455.623322 549.982422,454.977600 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M697.138062,518.254150 
	C694.973938,520.769165 692.809753,523.284180 690.343201,525.950256 
	C690.318481,524.327942 690.596985,522.554688 690.873840,520.781189 
	C691.440979,517.147766 691.193359,513.904907 687.245850,512.100708 
	C685.346558,511.232635 682.487244,509.993500 684.813660,507.645447 
	C686.014893,506.432983 689.285583,506.640564 691.386108,507.159119 
	C692.923035,507.538574 694.076538,509.470825 695.649292,511.205750 
	C692.057678,514.690186 696.803162,516.054810 697.138062,518.254150 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M442.302551,427.024109 
	C440.816742,426.695923 439.551544,425.966461 438.449585,426.161072 
	C436.038269,426.587006 433.442108,428.005554 433.050568,423.932281 
	C432.673523,420.009644 435.365295,419.886322 438.584991,419.998993 
	C439.445282,419.998749 439.889313,419.999237 440.666443,419.999817 
	C443.614838,420.749756 446.230164,421.499664 448.926239,422.962830 
	C449.321411,424.451935 449.635895,425.227814 449.953735,426.306488 
	C449.942322,427.010895 449.927521,427.412445 449.731812,428.095093 
	C448.684265,428.525848 447.817627,428.675507 446.737549,428.589661 
	C445.683624,428.225922 444.843231,428.097626 444.002808,427.969360 
	C443.534271,427.660217 443.065765,427.351074 442.302551,427.024109 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M449.064178,682.292603 
	C452.856293,683.175537 456.702545,684.333313 460.462738,685.723877 
	C462.050537,686.311035 463.457092,687.388306 465.009979,688.602051 
	C463.359406,689.833069 461.729584,691.102356 459.911835,691.489624 
	C456.653625,692.183838 453.284973,692.389282 449.949921,692.599609 
	C449.796906,692.609192 448.978638,690.165405 449.391663,689.721436 
	C451.790741,687.142578 450.380646,684.948669 449.064178,682.292603 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M713.036377,566.648926 
	C710.392639,566.557129 709.475586,565.683472 711.352356,563.076965 
	C715.281311,557.620544 718.938354,551.968323 723.058899,545.876343 
	C723.453918,546.816650 725.137390,548.818909 724.778137,550.338257 
	C723.617615,555.246277 722.436157,560.362915 716.854004,562.692383 
	C715.353394,563.318604 714.320435,565.065430 713.036377,566.648926 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M534.687866,761.053589 
	C541.546448,759.914856 548.085510,758.790771 554.637451,757.746826 
	C556.550232,757.442078 558.499390,757.365540 560.860596,757.323608 
	C558.532043,758.963196 555.774841,760.463501 552.609863,762.304077 
	C551.826599,762.631592 551.451050,762.618835 551.075500,762.606079 
	C545.561340,763.188232 540.044739,763.748108 534.533813,764.359741 
	C530.285156,764.831299 526.043701,765.367859 521.373291,765.512207 
	C520.971191,764.789551 520.994873,764.430237 521.406616,764.041931 
	C522.831543,763.977417 523.868469,763.941895 525.315491,763.946472 
	C528.189880,763.089539 530.654053,762.192505 533.118286,761.295410 
	C533.536621,761.229919 533.955017,761.164368 534.687866,761.053589 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M478.993896,453.995239 
	C482.621674,454.015259 486.256714,454.181000 489.874451,454.000214 
	C492.016327,453.893219 494.130859,453.238953 496.257690,452.830750 
	C496.490723,453.455109 496.723755,454.079468 496.956757,454.703827 
	C492.984619,456.460510 489.075500,458.386230 485.013000,459.900452 
	C483.071838,460.623962 480.768433,461.020294 478.763885,460.705811 
	C477.394043,460.490936 476.257172,458.791168 475.150940,457.078339 
	C476.521759,455.599365 477.757812,454.797272 478.993896,453.995239 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M432.625305,628.995117 
	C435.584167,632.880554 438.271332,636.455566 443.750183,636.355408 
	C444.471375,636.342285 445.238953,638.866089 445.991882,640.604980 
	C445.344513,641.482605 444.690277,641.970093 443.966309,642.791870 
	C436.366241,635.262207 426.131683,630.051636 421.034058,619.148499 
	C422.033783,618.323364 423.021515,618.162415 424.009216,618.001404 
	C425.285492,620.757324 426.298523,623.685974 427.946167,626.198364 
	C428.805206,627.508240 430.782227,628.084900 432.625305,628.995117 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M479.183411,453.658234 
	C477.757812,454.797272 476.521759,455.599365 475.143005,456.697662 
	C472.298035,456.995392 469.595734,456.996887 466.501038,456.651855 
	C467.749664,454.910248 469.390686,453.515106 471.031708,452.119965 
	C471.468384,452.082123 471.905060,452.044250 472.889832,452.273071 
	C475.956696,451.353455 478.475464,450.167175 480.994232,448.980896 
	C481.996338,448.928955 482.998444,448.877014 484.805115,448.883972 
	C487.421295,448.369385 489.232910,447.795868 491.044525,447.222351 
	C492.765747,447.188019 494.486938,447.153656 496.629456,447.231689 
	C496.858521,450.890533 494.183990,450.661469 491.708771,451.036652 
	C487.576324,451.662994 483.482819,452.546173 479.183411,453.658234 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M514.959106,510.618652 
	C506.994781,510.324799 498.978180,509.481384 490.941650,509.193665 
	C488.894257,509.120361 486.783722,510.809906 484.383209,511.853333 
	C482.895386,507.833160 486.484497,506.722046 488.831726,506.420563 
	C494.841858,505.648682 501.011353,505.281067 507.029846,505.788025 
	C509.759644,506.017975 512.282593,508.703766 514.959106,510.618652 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M513.804382,452.278442 
	C518.119324,453.034943 522.229736,454.075256 526.515320,455.159912 
	C524.065979,457.460510 522.039246,459.364136 520.003845,461.635376 
	C517.702759,460.527832 515.090759,459.355225 513.270691,457.432922 
	C512.512024,456.631531 513.439148,454.234009 513.804382,452.278442 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M345.005554,519.385620 
	C347.735291,522.139343 350.797272,525.061707 353.089233,528.497742 
	C355.062073,531.455444 354.229767,533.884094 350.049255,534.024719 
	C349.138641,534.055359 348.237549,534.370422 346.823334,534.657959 
	C347.636566,536.444763 348.278564,537.855408 348.962097,539.631836 
	C345.136414,538.819580 343.695404,535.817322 345.978882,532.885498 
	C348.602325,529.517212 348.794830,527.141907 346.112396,523.984436 
	C345.265472,522.987549 345.346954,521.201904 345.005554,519.385620 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M524.905334,763.906311 
	C523.868469,763.941895 522.831543,763.977417 521.249268,763.809814 
	C520.067749,764.160095 519.431580,764.713562 518.795410,765.267029 
	C517.079956,765.355896 515.364563,765.444763 513.171509,765.112427 
	C511.881012,764.666809 511.068207,764.642334 509.832916,764.713562 
	C508.262665,764.763123 507.114838,764.716980 505.967041,764.670776 
	C506.814728,763.470825 507.588928,761.297363 508.521851,761.226990 
	C515.151733,760.726685 521.814087,760.656494 528.509399,761.005737 
	C527.336853,762.343567 526.121094,763.124939 524.905334,763.906311 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M389.019745,633.996399 
	C389.043793,634.405151 389.067810,634.813965 389.062927,635.898132 
	C389.355591,637.715088 389.677185,638.856567 389.998779,639.998108 
	C387.626404,639.834839 384.539581,640.630615 385.531738,636.243958 
	C385.642731,635.753113 384.102722,634.631592 383.148621,634.198730 
	C376.906982,631.367065 376.653076,630.713501 380.258667,624.496704 
	C381.422760,624.480774 382.243988,624.707886 383.065216,624.934998 
	C383.065216,624.934998 383.000000,624.992920 383.011108,625.352661 
	C385.021423,628.473755 387.020569,631.235046 389.019745,633.996399 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M694.957520,497.306946 
	C692.741089,495.348816 690.444702,493.053223 692.992371,490.966309 
	C694.808167,489.478943 698.036072,489.577881 700.664490,489.384338 
	C701.015930,489.358429 702.137756,491.925262 701.858826,493.027252 
	C701.426086,494.737305 700.355774,496.514221 699.039001,497.678192 
	C698.357544,498.280548 696.564575,497.625366 694.957520,497.306946 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M667.582275,593.176086 
	C667.393433,588.113647 667.204590,583.051147 667.519409,577.499390 
	C669.115356,576.920349 670.207520,576.830566 671.487793,576.775024 
	C671.675842,576.809204 672.045532,576.914246 672.066223,577.258606 
	C672.448914,578.183350 672.810852,578.763672 673.142090,579.689331 
	C673.015808,580.953857 672.920227,581.873047 672.587891,582.810791 
	C672.351135,582.829346 671.879456,582.884644 671.513306,582.971558 
	C669.379700,585.335266 668.947876,587.464111 671.830444,589.225769 
	C671.829651,590.166687 671.828857,591.107605 671.476196,592.452332 
	C669.943726,592.962769 668.763000,593.069458 667.582275,593.176086 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M345.001892,506.375916 
	C346.402832,503.686127 347.803253,501.371063 349.499359,498.567230 
	C349.827179,499.079559 350.490356,499.924927 350.944550,500.870605 
	C351.631042,502.299896 352.205048,503.786621 352.765350,505.272125 
	C353.340179,506.796234 353.841003,508.348236 354.374207,509.888031 
	C352.507263,510.175751 350.634491,510.701752 348.775635,510.656555 
	C347.756683,510.631805 346.761169,509.643066 345.378784,509.043884 
	C345.002106,508.249390 345.001740,507.500000 345.001892,506.375916 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M402.973328,726.636047 
	C402.789673,727.038635 402.556458,727.088684 402.469910,726.998291 
	C398.281433,722.625488 394.105530,718.240601 389.827972,713.509888 
	C398.331879,713.201477 402.463043,717.267517 402.973328,726.636047 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M609.251892,395.808380 
	C611.935791,391.511719 614.003174,393.215820 615.387207,396.219452 
	C616.241333,398.072937 616.251404,400.315308 616.636780,402.363342 
	C609.083374,403.822327 608.863159,403.633545 609.251892,395.808380 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M734.991577,516.404175 
	C735.463684,515.687561 735.928528,515.363708 736.690552,515.017456 
	C736.987793,514.995117 736.998962,514.999878 737.005127,515.354065 
	C737.674194,516.472168 738.337097,517.236084 739.000000,518.000000 
	C740.314392,519.073547 741.628723,520.147034 742.943115,521.220581 
	C741.172180,522.683044 739.538574,524.429626 737.530029,525.400513 
	C737.051208,525.632019 734.702637,523.440857 734.327698,522.061340 
	C733.898499,520.482727 734.711243,518.566528 734.991577,516.404175 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M651.587524,510.484924 
	C650.357483,510.362823 649.578857,510.198608 648.395386,510.006317 
	C650.861328,504.799591 656.077576,506.458191 660.339539,506.248932 
	C662.169983,506.159058 664.132141,508.750946 666.025818,510.503876 
	C661.357788,510.736481 656.698425,510.589630 651.587524,510.484924 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M563.268433,757.437134 
	C570.188477,754.338379 577.364197,751.454163 584.769531,748.271729 
	C585.802490,747.985474 586.605835,747.997498 587.794983,748.131226 
	C585.715576,749.656128 583.389221,751.453552 580.756470,752.381226 
	C575.096130,754.375732 569.277832,755.921997 563.268433,757.437134 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M299.128754,605.008545 
	C299.461670,604.897156 299.712616,604.687500 300.008881,604.162354 
	C302.530273,605.790405 305.678070,607.184265 307.123901,609.597717 
	C308.855133,612.487671 309.109711,616.244202 305.263611,618.891113 
	C303.218842,614.999207 301.559692,611.065674 299.511322,606.709961 
	C299.124329,605.861389 299.126526,605.434937 299.128754,605.008545 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M601.066772,614.996155 
	C601.285767,613.543579 601.504761,612.091064 602.026550,610.311401 
	C604.835510,612.460388 609.134094,613.637512 608.692566,619.021423 
	C607.181213,618.427246 606.085999,617.996704 606.179138,618.033325 
	C602.700623,619.412109 600.061279,620.458252 597.377075,620.934692 
	C598.968750,618.847778 602.742737,618.817505 601.066772,614.996155 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M644.365051,438.038513 
	C644.307739,436.577179 644.615479,435.154358 645.411560,433.365936 
	C648.599976,433.000641 651.299988,433.000946 654.000000,433.001221 
	C654.021851,434.770142 654.043640,436.539093 654.032715,438.653992 
	C653.250610,439.000366 652.501221,439.000763 651.042969,439.000854 
	C649.888611,439.000763 649.443054,439.001007 648.997559,439.001221 
	C647.575073,438.693146 646.152588,438.385071 644.365051,438.038513 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M576.204529,672.190552 
	C581.229492,670.257080 585.570618,667.738098 586.334290,661.524109 
	C586.405212,660.947083 587.478516,660.493286 588.440369,659.932861 
	C591.355713,666.811401 584.479248,673.841797 576.204529,672.190552 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M663.019836,466.047791 
	C663.028503,468.072998 663.395569,470.093262 662.577332,471.327881 
	C661.919189,472.320801 659.826721,472.653473 658.370239,472.654297 
	C657.918030,472.654541 657.127747,470.618256 657.101990,469.500275 
	C657.073425,468.262421 657.622009,466.956055 658.132507,465.771393 
	C659.611938,462.337769 661.237305,462.157440 663.019836,466.047791 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M424.271118,617.790161 
	C423.021515,618.162415 422.033783,618.323364 421.002075,618.802063 
	C415.752045,619.916626 417.302704,614.693970 415.098938,612.354126 
	C420.995087,610.402710 423.915497,612.101318 424.271118,617.790161 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M537.257324,690.230957 
	C541.368164,688.592529 545.762878,687.268799 550.549683,685.938232 
	C551.555847,687.246216 552.170105,688.561035 553.097229,690.545593 
	C547.613342,690.545593 542.577332,690.545593 537.257324,690.230957 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M662.044312,621.501221 
	C663.910950,619.957642 665.851807,618.742981 668.242554,617.150696 
	C669.114502,615.323792 669.627563,613.893677 669.939697,612.421082 
	C670.603271,609.291504 671.162231,606.139709 671.764099,602.997070 
	C671.735352,608.105591 672.603455,613.472351 671.243225,618.197632 
	C670.687012,620.129883 666.567200,624.100891 662.044312,621.501221 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M295.149445,521.081238 
	C296.555511,517.889954 297.961578,514.698608 299.628662,511.249084 
	C301.701813,515.455383 299.855408,525.571777 296.394897,527.990723 
	C295.862061,526.502014 295.723877,524.999084 295.299927,522.922974 
	C295.059204,521.926941 295.104340,521.504089 295.149445,521.081238 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M313.984009,640.832581 
	C310.755157,634.597717 307.863220,628.181274 304.978882,621.389038 
	C305.150818,621.031311 305.401031,620.986328 305.467316,621.076477 
	C309.801331,626.974304 314.073395,632.904358 313.984009,640.832581 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M534.234375,537.874146 
	C532.578369,536.281860 531.243286,534.547302 529.874023,532.459106 
	C532.993286,531.217590 536.146667,530.329773 539.300049,529.441956 
	C539.697327,529.736145 540.094604,530.030396 540.491882,530.324646 
	C538.513000,532.793762 536.534119,535.262817 534.234375,537.874146 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M712.956970,567.380005 
	C714.093750,566.721985 715.185669,566.227905 716.281677,566.218750 
	C717.768494,566.206238 719.258362,566.565002 720.746948,566.764526 
	C719.971008,568.395447 719.601379,570.708740 718.311646,571.476807 
	C716.845947,572.349670 714.557678,571.841248 712.018921,571.962219 
	C712.401123,570.167786 712.657532,568.963867 712.956970,567.380005 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M715.675537,592.289307 
	C715.175415,592.027466 714.675354,591.765625 713.996643,590.999207 
	C712.795288,588.696045 711.772522,586.897400 710.749756,585.098816 
	C718.417725,584.861755 719.129578,585.347473 719.497314,591.738525 
	C717.922729,592.164124 716.799133,592.226746 715.675537,592.289307 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M470.634644,452.078796 
	C469.390686,453.515106 467.749664,454.910248 466.050598,456.646057 
	C465.550354,456.991241 465.108063,456.995758 464.334106,457.006836 
	C460.888733,458.000671 457.775024,458.987885 454.262634,459.942413 
	C455.713379,458.631256 457.427094,457.053009 459.442322,456.140686 
	C462.942535,454.556122 466.629150,453.383179 470.634644,452.078796 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M488.999908,382.000000 
	C487.298523,381.591217 485.059662,381.692352 484.049561,380.627716 
	C483.073730,379.599243 482.836731,377.204803 483.296295,375.728210 
	C483.573090,374.838867 485.799744,374.556366 487.734650,374.366699 
	C488.546051,377.154816 488.772980,379.577423 488.999908,382.000000 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M489.349365,381.961365 
	C488.772980,379.577423 488.546051,377.154816 488.159576,374.366730 
	C488.164520,373.448059 488.329010,372.894867 488.734344,372.172913 
	C491.399170,373.653229 493.823151,375.302246 496.608032,377.331238 
	C496.978912,378.144989 496.988861,378.578735 496.998779,379.012512 
	C494.565460,379.982574 492.132111,380.952637 489.349365,381.961365 
z"/>
<path fill="#787878" opacity="1.000000" stroke="none" 
	d="
M601.224487,546.565491 
	C600.560791,546.830139 599.712097,546.658936 599.714783,546.502380 
	C599.838013,539.362793 600.035583,532.224365 600.632568,525.056274 
	C601.172546,532.063293 601.304565,539.099915 601.224487,546.565491 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M697.476440,518.177002 
	C696.803162,516.054810 692.057678,514.690186 695.956482,511.355774 
	C698.410889,510.700104 700.806580,510.389191 703.596191,510.017273 
	C704.176819,509.971588 704.363464,509.986938 704.829956,510.030762 
	C702.678101,512.739441 700.246399,515.419617 697.476440,518.177002 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M578.805420,569.100220 
	C578.801880,570.178345 578.798401,571.256409 578.623413,572.704834 
	C576.736511,572.129761 575.021057,571.184387 573.305664,570.238953 
	C574.833984,567.817993 576.362305,565.396973 578.232788,562.958740 
	C578.690918,563.997070 578.806763,565.052612 578.604614,566.564087 
	C578.459595,567.713440 578.632507,568.406799 578.805420,569.100220 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M578.119019,617.301208 
	C578.102112,615.009155 578.130066,613.054993 578.485962,611.002380 
	C579.818726,618.385681 580.382080,618.894104 587.690735,618.904236 
	C589.174255,618.906311 590.657837,618.900513 592.590698,619.237549 
	C593.044312,620.014465 593.048584,620.452026 593.052856,620.889648 
	C589.660095,620.949524 586.267273,621.009460 582.333130,620.825317 
	C581.601746,620.288757 581.334534,620.170593 580.990173,620.226746 
	C580.048035,619.364258 579.105957,618.501709 578.119019,617.301208 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M671.786011,602.706787 
	C671.162231,606.139709 670.603271,609.291504 669.939697,612.421082 
	C669.627563,613.893677 669.114502,615.323792 668.291870,616.805115 
	C667.763672,616.021179 667.636108,615.205322 667.258911,614.196411 
	C667.047058,611.979553 667.084900,609.955627 667.525024,607.382690 
	C668.247070,604.957458 668.566956,603.081299 669.254150,601.159180 
	C670.354919,601.159729 671.088379,601.206360 671.821777,601.252930 
	C671.817200,601.640747 671.812622,602.028625 671.786011,602.706787 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M560.998779,400.748230 
	C558.379211,400.189026 555.760864,399.380005 553.073303,398.549561 
	C553.739624,393.654877 557.169739,395.076019 559.574707,395.287994 
	C560.186829,395.341949 560.580505,397.875458 561.038452,399.634216 
	C561.002441,399.998779 560.999939,400.498413 560.998779,400.748230 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M622.297241,404.643188 
	C623.195435,402.819916 623.944214,401.348328 624.692932,399.876740 
	C625.568359,400.335022 626.443787,400.793304 627.319214,401.251556 
	C627.462341,403.468109 627.765991,405.696228 627.607422,407.890991 
	C627.577209,408.309509 625.204224,409.183075 624.805847,408.832123 
	C623.672546,407.833740 623.001038,406.311188 622.297241,404.643188 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M671.883545,600.780273 
	C671.088379,601.206360 670.354919,601.159729 669.012207,601.048706 
	C668.061218,600.900330 667.719666,600.816406 667.378052,600.732483 
	C667.366516,598.483887 667.354980,596.235291 667.462891,593.581421 
	C668.763000,593.069458 669.943726,592.962769 671.476440,592.805176 
	C671.867371,595.271973 671.906372,597.789795 671.883545,600.780273 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M508.167419,609.319519 
	C508.981079,609.485413 509.704865,610.066284 510.694885,611.206909 
	C511.022278,612.524231 511.083435,613.281921 511.144592,614.039612 
	C511.066071,614.763184 510.987549,615.486694 510.529358,616.592773 
	C509.409790,617.969238 508.669952,618.963318 507.930115,619.957397 
	C506.502808,620.067749 505.075470,620.178040 503.258240,620.098633 
	C502.909241,619.737976 502.950073,619.567078 502.990936,619.396179 
	C506.409515,617.082764 508.613770,614.130005 508.167419,609.319519 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M735.172852,621.633057 
	C733.275269,621.905823 731.584534,621.910034 729.473145,621.904907 
	C729.051514,621.687012 729.050659,621.478271 729.079956,620.955261 
	C730.331787,620.378357 731.551697,620.107910 732.775269,619.854797 
	C735.754150,619.238831 737.196167,617.628540 736.860291,614.256531 
	C737.961426,614.042297 738.478943,614.048828 739.206665,614.073853 
	C739.416931,614.092285 739.839050,614.096375 739.839050,614.096375 
	C739.860779,615.757324 739.882507,617.418335 739.864136,619.488403 
	C738.342529,620.386475 736.861084,620.875488 735.172852,621.633057 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M538.224976,542.831055 
	C537.023682,542.214111 536.111206,541.409912 535.063721,540.329102 
	C536.529724,539.259766 538.062439,538.101196 539.752441,537.785950 
	C541.192932,537.517273 542.861084,538.083252 544.322693,538.586914 
	C544.684448,538.711609 544.635803,540.026917 544.775879,540.794922 
	C543.486450,541.015564 542.172485,541.145996 540.916199,541.488342 
	C540.073120,541.718079 539.311707,542.248047 538.224976,542.831055 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M355.607269,585.004761 
	C354.456573,587.800476 355.306000,592.162903 350.381653,592.989929 
	C348.942139,587.879883 350.268341,585.848083 355.607269,585.004761 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M305.262085,492.872070 
	C306.041779,488.412476 307.210205,483.947266 308.635376,479.233734 
	C310.168274,482.428802 308.269684,490.559814 305.262085,492.872070 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M720.034607,529.101379 
	C720.034607,531.076233 720.034607,533.051086 720.034607,533.932678 
	C717.531372,535.763550 715.800537,537.029541 714.032349,538.645264 
	C713.273804,539.012268 712.552429,539.029419 711.402527,539.014893 
	C710.973938,538.983276 710.837341,538.866577 710.911987,538.535889 
	C714.002563,535.170654 717.018616,532.135986 720.034607,529.101379 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M675.155640,599.529297 
	C675.057800,597.021240 675.057800,594.924133 675.057800,592.514160 
	C680.188416,592.667114 679.007446,596.244385 678.875488,598.744080 
	C678.734619,601.411621 677.126648,601.526672 675.155640,599.529297 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M573.105713,555.895020 
	C572.877197,552.342468 575.138184,551.276917 578.501648,551.061035 
	C578.861755,553.130554 578.854736,555.181641 578.689148,557.649109 
	C576.843384,557.369568 575.156128,556.673767 573.105713,555.895020 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M731.004028,527.747192 
	C730.028931,528.170288 728.913208,528.728455 728.101624,528.437683 
	C726.562012,527.886169 725.196594,526.848267 723.493408,525.691528 
	C725.333252,523.098267 727.438477,520.825928 729.543762,518.553589 
	C729.543762,518.553589 729.792236,518.796509 729.916809,518.918579 
	C730.028564,519.474670 730.015747,519.908813 729.698975,520.822021 
	C727.224182,523.962036 728.362793,525.692627 730.997559,527.002747 
	C730.997559,527.002747 731.000732,527.499023 731.004028,527.747192 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M309.392273,476.986084 
	C308.972046,476.501343 308.893250,475.968384 308.737000,474.777039 
	C308.707153,473.749573 308.754822,473.380554 309.150208,472.892029 
	C311.294617,470.152252 313.091248,467.531982 314.887909,464.911682 
	C314.887909,464.911682 314.970520,464.979065 315.011841,465.014038 
	C314.702789,466.775940 314.352448,468.502869 313.996765,470.612427 
	C312.572205,472.976013 311.152954,474.956940 309.392273,476.986084 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M672.824646,582.792297 
	C672.920227,581.873047 673.015808,580.953857 673.315308,579.667786 
	C673.519165,579.300964 673.837891,579.158813 674.059326,579.321167 
	C674.569824,579.948120 674.858887,580.412781 675.058228,581.178894 
	C675.685059,581.925598 676.401672,582.370789 677.126221,582.831238 
	C677.134094,582.846497 677.166382,582.861450 677.075378,583.229126 
	C676.982361,584.102844 676.980347,584.608887 676.978333,585.114868 
	C675.306641,586.220093 673.634949,587.325378 671.896851,588.828247 
	C668.947876,587.464111 669.379700,585.335266 671.646362,583.186523 
	C672.541016,583.392029 672.936523,583.469482 673.332031,583.546936 
	C673.162903,583.295410 672.993774,583.043823 672.824646,582.792297 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M558.542969,753.003296 
	C554.958069,754.002563 550.913818,755.000183 546.433838,755.996460 
	C547.231628,754.998901 548.359436,753.367554 549.721313,753.140442 
	C552.440857,752.686768 555.288818,753.003296 558.542969,753.003296 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M497.333832,379.041565 
	C496.988861,378.578735 496.978912,378.144989 496.951599,377.387451 
	C496.934204,377.063721 497.062836,376.937805 497.458557,376.963806 
	C501.967621,379.601379 506.080933,382.213013 510.194244,384.824646 
	C507.723694,384.216034 505.253174,383.607422 502.397034,382.989197 
	C500.563934,381.676575 499.116425,380.373566 497.333832,379.041565 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M552.934753,541.953857 
	C551.549255,542.966919 550.163818,543.980042 548.467651,545.102966 
	C547.943848,540.012878 551.594238,537.520813 555.676392,535.062378 
	C556.074585,535.072510 556.182373,535.176880 556.092407,535.506592 
	C554.982727,537.871521 553.963074,539.906677 552.954224,541.946899 
	C552.965088,541.951965 552.934753,541.953857 552.934753,541.953857 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M643.465820,610.077026 
	C644.789978,613.091064 645.745728,616.207581 646.856140,619.646606 
	C644.273499,622.284973 642.681702,619.902405 642.393005,618.080872 
	C641.991394,615.547974 642.804321,612.822571 643.465820,610.077026 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M420.669556,407.545959 
	C420.170746,409.122925 419.809692,410.331482 419.448608,411.540009 
	C418.950409,411.601593 418.452240,411.663147 417.954041,411.724731 
	C417.682251,409.657532 417.270264,407.591888 417.247375,405.521973 
	C417.242340,405.063385 418.611725,404.589600 419.348206,404.122803 
	C419.834595,405.141083 420.320984,406.159332 420.669556,407.545959 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M560.945068,526.955383 
	C563.364319,523.966675 565.783569,520.977966 568.614136,518.008057 
	C569.025330,518.026855 569.098572,518.101624 568.934204,518.380371 
	C568.223267,520.119080 567.676697,521.578979 567.075684,523.330261 
	C566.647461,524.063354 566.273743,524.505005 565.562073,524.966370 
	C563.856140,525.696594 562.488037,526.407227 561.076782,527.076660 
	C561.033508,527.035583 560.945068,526.955383 560.945068,526.955383 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M458.688232,644.868774 
	C459.644531,646.437622 460.289093,647.875183 460.969238,649.928040 
	C461.008057,650.724304 461.011200,650.905334 461.014313,651.086365 
	C459.146057,650.272888 457.277802,649.459412 455.191772,648.393433 
	C455.228302,646.126831 454.774628,643.404968 458.688232,644.868774 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M322.147766,656.817444 
	C320.210480,653.948914 318.565887,650.884766 316.913483,647.452515 
	C321.569672,648.627563 321.687408,652.809021 322.147766,656.817444 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M505.578857,764.756836 
	C507.114838,764.716980 508.262665,764.763123 509.803711,764.982727 
	C510.147125,765.327454 510.097321,765.498840 510.047485,765.670166 
	C505.870514,765.917297 501.693542,766.164490 497.260712,766.078491 
	C498.431213,765.453613 499.857574,765.161926 501.658386,764.771240 
	C503.085449,764.729126 504.138031,764.786011 505.578857,764.756836 
z"/>
<path fill="#787878" opacity="1.000000" stroke="none" 
	d="
M600.961182,512.051453 
	C601.335693,513.164062 601.710144,514.276672 602.256714,515.706238 
	C602.354126,517.115784 602.279480,518.208374 601.979614,519.697388 
	C601.497864,520.053894 601.241150,520.013977 600.984436,519.973999 
	C599.676636,517.335876 598.368896,514.697693 597.301880,511.617188 
	C598.682129,511.467010 599.821655,511.759216 600.961182,512.051453 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M544.910217,548.922119 
	C544.718384,550.179932 544.329468,551.118103 543.661987,552.728027 
	C541.825928,549.949890 540.435364,547.845947 538.991211,545.390198 
	C540.862732,546.226379 542.787964,547.414368 544.910217,548.922119 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M749.999023,514.015381 
	C750.657715,515.057800 750.939514,516.054749 751.221313,517.051758 
	C749.860718,517.163391 748.393005,517.617249 747.185852,517.239136 
	C746.602600,517.056458 746.509216,515.309753 746.193970,514.271240 
	C747.336731,514.170776 748.479431,514.070312 749.999023,514.015381 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M707.001221,547.002441 
	C706.452026,546.954529 705.901611,546.904236 705.049072,546.439148 
	C706.777100,543.638428 708.807190,541.252502 710.837280,538.866577 
	C710.837341,538.866577 710.973938,538.983276 711.042725,539.043396 
	C710.768738,540.818420 710.426025,542.533325 709.719116,544.648804 
	C708.570862,545.700378 707.786865,546.351440 707.002808,547.002441 
	C707.002808,547.002441 707.000000,547.000000 707.001221,547.002441 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M601.054077,520.423218 
	C601.241150,520.013977 601.497864,520.053894 602.145386,520.079834 
	C603.904419,520.587646 605.272522,521.109436 606.640686,521.631226 
	C605.276917,522.716248 603.913147,523.801331 602.290283,525.027161 
	C601.728699,523.736084 601.426208,522.304260 601.054077,520.423218 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M384.991028,643.615173 
	C384.853485,645.499390 384.705780,647.001160 384.278442,648.751465 
	C380.437927,647.384277 382.350494,644.444031 382.299744,641.978882 
	C383.193451,642.396851 384.087158,642.814819 384.991028,643.615173 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M301.329895,501.927460 
	C302.026886,499.326263 303.083252,496.677368 304.471130,493.925049 
	C305.329742,497.112274 305.193756,500.146729 301.329895,501.927460 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M707.344971,547.052734 
	C707.786865,546.351440 708.570862,545.700378 709.676270,545.024658 
	C714.021179,545.943787 713.460083,548.073425 711.388062,551.049133 
	C710.016541,549.586731 708.851868,548.344849 707.344971,547.052734 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M678.799561,588.161133 
	C681.291748,590.106506 683.523560,592.239502 685.877686,594.686279 
	C683.513062,592.782898 681.026123,590.565857 678.799561,588.161133 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M395.000793,647.617432 
	C392.209961,648.324646 392.138214,648.248230 391.003815,643.365967 
	C390.999054,642.555542 390.999329,642.111084 391.341125,641.332031 
	C392.138153,640.984131 392.593597,640.970886 393.049042,640.957642 
	C393.049042,640.957642 393.041290,640.976807 393.046051,640.962158 
	C393.698700,643.046570 394.346619,645.145569 395.000793,647.617432 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M593.445190,620.925964 
	C593.048584,620.452026 593.044312,620.014465 593.053711,619.246948 
	C595.512634,617.683105 597.957947,616.449097 600.734985,615.105591 
	C602.742737,618.817505 598.968750,618.847778 597.162781,620.650452 
	C595.941467,620.944763 594.889526,620.953491 593.445190,620.925964 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M574.787354,510.027771 
	C575.567017,510.129120 576.142029,510.278320 576.838379,511.028870 
	C575.936401,513.046631 574.913025,514.463013 573.565308,515.882324 
	C572.610901,516.027039 571.980835,516.168823 571.258423,515.960815 
	C572.165344,513.776917 573.164612,511.942780 574.163818,510.108643 
	C574.163818,510.108643 574.582764,510.075562 574.787354,510.027771 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M556.020752,535.020142 
	C555.472961,531.301575 556.720642,528.619141 560.631836,527.109985 
	C560.945068,526.955383 561.033508,527.035583 561.041565,527.523010 
	C561.229187,528.886780 561.408752,529.763123 561.575073,530.805725 
	C561.561890,530.971985 561.340820,531.221924 560.965515,531.154541 
	C559.120911,532.450317 557.651611,533.813599 556.182373,535.176880 
	C556.182373,535.176880 556.074585,535.072510 556.020752,535.020142 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M685.987244,595.368530 
	C687.592590,596.461487 689.185120,597.923035 690.843384,599.704468 
	C688.866150,600.263306 686.823242,600.502197 684.270264,600.800720 
	C684.918396,598.874939 685.446411,597.306030 685.987244,595.368530 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M710.515686,584.982056 
	C711.772522,586.897400 712.795288,588.696045 713.751221,590.855042 
	C711.436768,589.166077 709.189026,587.116699 707.236206,584.629883 
	C708.023743,584.113342 708.516418,584.034302 709.009155,583.955200 
	C709.433289,584.258545 709.857483,584.561890 710.515686,584.982056 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M573.775146,510.102112 
	C573.164612,511.942780 572.165344,513.776917 571.196289,516.151245 
	C571.226501,516.691467 571.116516,517.078247 570.837341,517.140625 
	C570.071655,517.502563 569.585083,517.802124 569.098572,518.101624 
	C569.098572,518.101624 569.025330,518.026855 568.988037,517.989136 
	C568.876343,514.455933 568.576904,510.833405 573.775146,510.102112 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M410.129639,719.679199 
	C408.912476,723.459595 407.038269,720.006531 404.250763,721.106140 
	C405.455750,719.112610 406.246704,717.430786 406.466461,717.502380 
	C407.693146,717.902039 408.781647,718.725891 410.129639,719.679199 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M502.540894,619.318848 
	C502.950073,619.567078 502.909241,619.737976 502.835876,620.173767 
	C500.008514,620.478577 497.213623,620.518372 494.211426,620.316162 
	C496.699707,619.796570 499.395294,619.519043 502.540894,619.318848 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M644.785706,510.704102 
	C644.640808,512.720581 644.300171,514.493896 643.855347,516.653687 
	C640.208862,513.917175 640.383911,512.544189 644.785706,510.704102 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M525.315491,763.946472 
	C526.121094,763.124939 527.336853,762.343567 528.776917,761.304932 
	C530.103149,761.064819 531.205200,761.082031 532.712769,761.197327 
	C530.654053,762.192505 528.189880,763.089539 525.315491,763.946472 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M631.397461,391.984253 
	C630.044434,390.535065 629.058167,389.091187 628.396301,387.334900 
	C630.536682,388.625183 632.352783,390.227936 634.118469,391.881104 
	C634.068115,391.931458 633.967468,392.032166 633.967468,392.032166 
	C633.233032,392.017944 632.498657,392.003754 631.397461,391.984253 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M708.947632,583.604187 
	C708.516418,584.034302 708.023743,584.113342 707.172241,584.301392 
	C706.544556,584.145508 706.275696,583.880676 705.920532,583.073242 
	C705.074341,581.168274 704.314514,579.805847 703.666748,578.331787 
	C703.778809,578.220215 704.002930,577.997070 704.002930,577.997070 
	C704.753906,577.998108 705.504883,577.999146 706.631592,578.000061 
	C707.633606,579.751038 708.259888,581.502136 708.947632,583.604187 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M409.328918,399.509033 
	C410.342865,400.192871 411.037720,401.048767 411.732574,401.904694 
	C410.905701,402.208557 410.078857,402.512421 409.251984,402.816284 
	C409.171265,401.771240 409.090546,400.726166 409.328918,399.509033 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M393.046387,640.595093 
	C392.593597,640.970886 392.138153,640.984131 391.341370,640.998718 
	C390.898621,640.676636 390.711182,640.414978 390.218201,640.106567 
	C389.677185,638.856567 389.355591,637.715088 389.066559,636.205200 
	C390.413971,637.302124 391.728821,638.767395 393.046387,640.595093 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M722.847168,588.145264 
	C723.175598,586.985046 723.750732,586.070068 724.325867,585.155090 
	C724.831848,585.660339 725.337769,586.165527 725.843750,586.670776 
	C724.927124,587.244019 724.010498,587.817322 722.847168,588.145264 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M699.773560,610.176758 
	C701.403076,611.420410 702.780579,612.852112 704.199341,614.625122 
	C702.667603,613.432556 701.094543,611.898621 699.773560,610.176758 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M724.685547,603.296753 
	C724.220337,602.709717 723.755127,602.122681 723.139648,600.984558 
	C723.010193,599.963135 723.031006,599.492859 723.051819,599.022522 
	C723.051819,599.022522 723.517212,598.960876 723.750488,598.935181 
	C724.747620,599.293396 725.511536,599.677307 726.607971,600.314819 
	C726.940491,600.568359 726.995117,600.995117 726.992920,601.329529 
	C726.972473,602.108704 726.954285,602.553528 726.557251,603.019958 
	C725.680847,603.126648 725.183167,603.211731 724.685547,603.296753 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M588.947815,746.245483 
	C590.139771,744.934937 591.437683,743.834106 592.887634,743.030457 
	C592.057617,744.429810 591.075562,745.531982 589.863525,746.905396 
	C589.388794,746.977539 589.195496,746.737061 588.947815,746.245483 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M508.291138,619.973755 
	C508.669952,618.963318 509.409790,617.969238 510.500366,616.955566 
	C511.973938,619.021301 511.673920,619.438049 508.291138,619.973755 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M704.135498,615.918213 
	C704.945801,615.920837 705.701294,616.329651 706.738647,617.237000 
	C707.056396,618.448486 707.092285,619.161499 707.128174,619.874451 
	C707.128174,619.874451 707.059326,619.942017 707.024780,619.975891 
	C706.020386,618.781250 705.050537,617.552795 704.135498,615.918213 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M511.413025,589.960266 
	C511.039307,588.630005 511.017120,587.217163 511.028198,585.380798 
	C514.001465,586.210815 513.458008,587.962097 511.413025,589.960266 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M543.810913,755.757690 
	C542.885681,756.980835 541.771301,757.959167 540.327271,758.962646 
	C541.205627,757.829468 542.413696,756.671143 543.810913,755.757690 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M730.956421,526.622803 
	C728.362793,525.692627 727.224182,523.962036 729.695068,521.148193 
	C730.301819,522.744324 730.608521,524.493591 730.956421,526.622803 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M483.840210,617.142639 
	C484.986450,617.807434 485.871826,618.643677 486.869873,619.770508 
	C484.327820,621.029053 483.354645,619.913696 483.840210,617.142639 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M383.065002,624.574829 
	C382.243988,624.707886 381.422760,624.480774 380.302673,624.125610 
	C380.010986,623.214600 380.018188,622.431580 380.059509,621.254883 
	C381.084045,621.978943 382.074402,623.096802 383.065002,624.574829 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M718.940186,621.561523 
	C717.343262,621.530212 715.747314,621.178406 714.063354,620.480225 
	C715.426025,620.151367 716.876709,620.168884 718.600830,620.394897 
	C718.896545,620.815979 718.918823,621.028503 718.940186,621.561523 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M715.736389,592.597168 
	C716.799133,592.226746 717.922729,592.164124 719.462769,592.147095 
	C719.544495,593.079346 719.209839,593.966125 718.646729,595.148682 
	C717.544617,594.597961 716.670959,593.751526 715.736389,592.597168 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M637.130615,394.876282 
	C636.658630,394.909790 636.186646,394.943329 635.359802,394.981567 
	C634.667297,394.223328 634.329712,393.460297 633.979736,392.364746 
	C633.967468,392.032166 634.068115,391.931458 634.435791,391.961243 
	C635.579163,392.952759 636.354919,393.914520 637.130615,394.876282 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M511.474487,613.961670 
	C511.083435,613.281921 511.022278,612.524231 510.929504,611.387634 
	C510.927399,610.832031 510.956879,610.655396 511.035187,610.213867 
	C511.324158,611.260620 511.564270,612.572205 511.474487,613.961670 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M404.145935,729.921814 
	C403.582336,729.255371 403.258179,728.481567 402.938873,727.375366 
	C403.602631,727.477539 404.261505,727.912109 404.967529,728.670898 
	C404.804901,729.268250 404.595154,729.541321 404.145935,729.921814 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M732.990417,507.008698 
	C733.144897,506.157440 733.309021,505.297485 733.473083,504.437531 
	C733.837402,504.565857 734.201782,504.694214 734.566101,504.822540 
	C734.260925,505.517975 733.955750,506.213379 733.316650,506.964783 
	C732.982666,507.020752 733.000000,507.000000 732.990417,507.008698 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M722.912354,598.805054 
	C723.031006,599.492859 723.010193,599.963135 722.942505,600.785095 
	C722.186768,600.463867 721.477905,599.790955 721.061401,598.629089 
	C721.572266,598.060913 721.790771,597.981812 722.009277,597.902649 
	C722.263794,598.130981 722.518372,598.359314 722.912354,598.805054 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M728.997803,475.664246 
	C728.523682,476.008392 728.044983,476.007019 727.206665,475.968384 
	C726.878540,475.176422 726.909851,474.421753 727.077759,473.311096 
	C727.807373,473.742950 728.400330,474.530853 728.997803,475.664246 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M738.994995,517.646240 
	C738.337097,517.236084 737.674194,516.472168 737.008057,515.354126 
	C737.666565,515.764160 738.328247,516.528320 738.994995,517.646240 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M316.820129,645.832764 
	C315.936432,645.674866 315.074341,645.152466 314.083679,644.342102 
	C314.917297,644.525513 315.879517,644.996887 316.820129,645.832764 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M721.957031,597.557007 
	C721.790771,597.981812 721.572266,598.060913 721.044861,598.304443 
	C720.120239,597.905334 719.504395,597.341858 718.868774,596.463257 
	C719.867554,596.502502 720.886169,596.856873 721.957031,597.557007 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M426.029541,677.317017 
	C426.667480,677.760803 427.328827,678.526550 427.995117,679.646118 
	C427.350983,679.212952 426.701965,678.425903 426.029541,677.317017 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M413.944885,609.718140 
	C413.326385,609.592773 412.672974,609.136292 411.974182,608.356262 
	C412.589203,608.484131 413.249603,608.935547 413.944885,609.718140 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M643.463257,535.012085 
	C643.801636,535.421936 643.872375,535.886963 643.849243,536.696167 
	C643.568848,536.382690 643.382263,535.724915 643.463257,535.012085 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M703.668884,575.998169 
	C703.999939,576.442566 703.999939,576.887024 704.001404,577.664246 
	C704.002930,577.997070 703.778809,578.220215 703.407471,578.179871 
	C702.652710,577.473816 702.269348,576.808167 701.885986,576.142456 
	C701.885986,576.142456 701.948181,576.052002 701.982056,576.006348 
	C702.456543,575.973145 702.897156,575.985657 703.668884,575.998169 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M677.265991,585.114136 
	C676.980347,584.608887 676.982361,584.102844 677.384949,583.229126 
	C678.300476,583.460571 678.815491,584.059753 679.330505,584.658875 
	C679.330505,584.658875 678.998474,585.013245 678.834229,585.195129 
	C678.297791,585.289185 677.925720,585.201294 677.265991,585.114136 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M726.931824,466.979309 
	C726.294128,467.068268 726.046387,466.910675 725.889893,466.130890 
	C725.972412,465.397949 726.032227,465.179504 726.091980,464.961090 
	C726.517517,464.969421 726.942993,464.977783 727.687927,464.984192 
	C727.755981,465.601593 727.504639,466.220978 726.931824,466.979309 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M649.036743,439.300049 
	C649.443054,439.001007 649.888611,439.000763 650.668274,439.000885 
	C650.768188,439.519745 650.533936,440.038269 650.299683,440.556793 
	C649.891724,440.237488 649.483826,439.918213 649.036743,439.300049 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M300.405029,507.006104 
	C300.216675,506.490723 300.312622,505.924957 300.636017,505.175568 
	C300.805420,505.646545 300.747375,506.301147 300.405029,507.006104 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M707.607178,494.045471 
	C707.210022,494.287842 706.814880,494.286194 706.421936,494.255951 
	C706.410889,494.255096 706.423401,493.948792 706.424927,493.784912 
	C706.819824,493.789093 707.214661,493.793274 707.607178,494.045471 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M581.111511,620.400635 
	C581.334534,620.170593 581.601746,620.288757 581.871277,620.798584 
	C581.671082,620.934387 581.431763,620.787231 581.111511,620.400635 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M510.403870,384.849792 
	C506.080933,382.213013 501.967621,379.601379 497.522705,376.900665 
	C493.958405,373.192291 490.587769,369.685669 487.520752,365.931030 
	C480.500763,357.337280 476.798462,347.649139 474.495239,336.544250 
	C472.131531,325.147858 474.753143,314.731384 476.456909,304.156616 
	C477.366272,298.512665 481.396301,292.929810 485.215271,288.309021 
	C489.909546,282.629211 495.383972,277.325897 501.369049,273.045410 
	C509.927338,266.924561 519.701721,263.761810 530.472290,262.764282 
	C539.298401,261.946838 547.516663,264.229126 555.993347,265.147217 
	C558.161743,265.382050 560.183533,266.970032 562.970581,267.976379 
	C564.111511,268.015137 564.554565,268.013824 564.997559,268.012512 
	C565.379150,268.078339 565.760681,268.144165 566.553284,268.541931 
	C570.973511,271.587524 574.982727,274.301208 579.219360,277.188629 
	C579.961487,277.911499 580.476135,278.460632 581.011230,279.353638 
	C582.323242,280.849121 583.614807,282.000763 584.978516,283.488617 
	C586.705750,285.882050 588.360901,287.939301 590.031616,290.354126 
	C592.949829,295.900696 595.852417,301.089722 598.755005,306.278778 
	C598.810608,307.271881 598.866211,308.265015 598.943604,309.959442 
	C598.980408,311.106171 598.995422,311.551544 598.988098,312.414856 
	C599.161987,313.972687 599.345520,315.114899 599.556519,316.252075 
	C600.828674,323.108337 602.060547,329.815247 599.314941,336.791565 
	C596.737915,343.339874 595.109070,350.261353 593.018860,357.317810 
	C592.944885,358.018890 592.915100,358.420807 592.572083,358.903412 
	C591.830078,359.650696 591.401245,360.317291 590.943420,361.246124 
	C590.918396,361.684235 590.922302,361.860077 590.866638,362.241211 
	C590.807068,362.446472 590.728638,362.866608 590.728638,362.866608 
	C590.435730,362.962372 590.203308,363.138092 589.623535,363.676270 
	C588.807251,364.635193 588.398804,365.311584 587.990356,365.987976 
	C587.632996,366.694580 587.275574,367.401154 586.564575,368.489319 
	C585.700134,369.530701 585.189331,370.190521 584.678589,370.850342 
	C584.678589,370.850342 584.296936,370.814453 583.773438,370.889526 
	C582.447083,371.941711 581.644165,372.918823 580.598022,374.008911 
	C579.897949,374.400513 579.441162,374.679169 578.658875,374.986633 
	C577.863953,375.650970 577.394531,376.286499 576.951965,376.958069 
	C576.978821,376.994141 576.917175,376.920258 576.572266,376.918365 
	C575.397644,377.545563 574.567871,378.174652 573.420654,378.825897 
	C572.401245,379.234558 571.699402,379.621063 570.660095,380.025757 
	C569.466614,380.971771 568.610596,381.899628 567.461243,382.840454 
	C566.780212,382.908081 566.392517,382.962738 565.648560,383.030273 
	C562.857056,384.345978 560.421936,385.648773 557.986816,386.951569 
	C557.559998,386.988556 557.133118,387.025543 556.021057,387.056091 
	C554.219788,387.352234 553.103821,387.654755 551.659363,387.954590 
	C550.894592,387.985748 550.458313,388.019592 549.587524,388.013031 
	C538.751282,387.697144 528.349426,387.421692 517.947632,387.146240 
	C517.520386,387.085510 517.093140,387.024780 516.128052,386.682007 
	C514.070068,385.914917 512.549988,385.429901 511.029907,384.944855 
	C511.029907,384.944855 510.613464,384.874939 510.403870,384.849792 
M544.165405,266.005676 
	C542.442749,266.032104 540.720093,266.058533 538.335022,265.983734 
	C537.890442,266.013306 537.445862,266.042877 536.075562,265.929932 
	C530.467773,266.301392 524.337646,265.347870 519.357483,267.311279 
	C507.850403,271.847961 497.279083,278.202484 489.230652,288.173340 
	C482.235291,296.839539 478.315216,306.717621 477.013794,317.514282 
	C475.702057,328.396759 476.083191,339.197479 480.967285,349.533813 
	C484.686279,357.404419 488.925568,364.971436 495.517914,370.524078 
	C505.356506,378.811005 516.353699,385.412109 529.775513,385.721558 
	C537.383484,385.897003 545.286194,386.375793 552.547424,384.617279 
	C559.082092,383.034668 564.818604,378.361053 571.095764,375.458557 
	C578.888672,371.855255 583.338318,364.995026 587.737183,358.372406 
	C595.618469,346.506866 598.661438,333.149750 596.866699,318.974030 
	C596.422241,315.463806 594.420837,312.150696 593.195923,308.164307 
	C593.152649,307.776825 593.109375,307.389343 593.578430,306.566376 
	C592.960938,304.216492 592.819763,301.591919 591.648682,299.561279 
	C583.945190,286.202972 573.875427,275.318939 558.972351,269.857178 
	C555.370667,268.537201 551.662781,267.506958 547.266418,266.160797 
	C546.511169,266.183716 545.755859,266.206635 544.165405,266.005676 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M722.739319,458.837463 
	C719.554688,454.024139 716.503052,448.808197 713.410828,443.616425 
	C708.631775,435.592529 704.522461,427.050018 698.874817,419.689911 
	C689.209534,407.094177 678.923706,394.922821 668.202942,383.209900 
	C662.569336,377.054993 655.461426,372.249481 648.997070,366.431458 
	C648.974854,366.028259 649.119202,365.882690 649.452881,365.966003 
	C651.487488,366.743317 653.188416,367.437347 654.889404,368.131409 
	C656.044617,368.992279 657.199829,369.853180 658.796265,371.185059 
	C663.445374,375.190063 667.653381,378.724121 671.861328,382.258148 
	C671.861328,382.258148 671.850708,382.639191 671.935242,383.154053 
	C672.673950,384.144012 673.328125,384.619141 673.982300,385.094269 
	C674.259460,385.219543 674.494019,385.400085 674.841309,386.170349 
	C676.318115,387.819489 677.639587,388.934265 678.961060,390.049011 
	C678.961060,390.049011 678.979675,390.040985 678.968018,390.045746 
	C684.082947,395.956177 689.209534,401.861847 694.638672,407.907532 
	C694.941284,408.047607 694.956055,408.024536 694.982178,408.383148 
	C700.091980,418.147583 709.023865,425.185211 711.794128,436.062561 
	C712.029419,436.986542 713.821228,437.514038 714.889954,438.225708 
	C715.142517,439.286255 715.395142,440.346802 715.814636,442.031982 
	C716.640015,443.467010 717.298523,444.277435 717.957031,445.087830 
	C718.280151,445.852417 718.603333,446.617004 718.995361,447.686584 
	C720.327576,451.476288 721.590820,454.960968 722.739319,458.837463 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M488.987854,416.000000 
	C487.798218,413.956940 486.608582,411.913910 484.984070,409.123993 
	C486.795959,408.730042 488.392822,408.052979 489.968536,408.098969 
	C495.906769,408.272339 501.840454,408.645508 507.770477,409.026794 
	C509.214020,409.119629 511.377075,409.024139 511.954193,409.870758 
	C517.130493,417.464569 525.596558,415.072357 532.608643,417.013062 
	C535.044983,417.687378 537.444641,418.494415 539.500061,419.617554 
	C535.567566,419.427185 532.013000,418.604828 528.419617,418.366150 
	C524.952759,418.135803 521.445801,418.507690 517.596802,418.701111 
	C516.508057,418.722168 515.778931,418.657074 515.020386,418.199249 
	C513.488342,417.200684 511.998138,416.110199 510.481171,416.071716 
	C503.320740,415.890076 496.152985,415.996368 488.987854,416.000000 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M580.990784,279.009766 
	C580.476135,278.460632 579.961487,277.911499 579.228027,276.811218 
	C579.011719,275.505615 579.014282,274.751221 579.016785,273.996826 
	C583.356873,273.761932 587.696960,273.527008 592.023682,273.292816 
	C593.418457,277.452942 593.418457,277.452942 587.585266,280.539978 
	C593.854858,289.150513 593.854858,289.150513 599.081787,286.426544 
	C601.005127,287.390411 602.611389,288.195312 604.608704,289.429565 
	C605.004761,291.570679 605.009705,293.282471 605.034058,295.314819 
	C605.372192,296.424957 605.690979,297.214508 606.007324,298.336121 
	C606.002502,299.110901 606.000061,299.553619 605.997559,299.996338 
	C605.442810,301.131134 604.888062,302.265930 604.599731,302.855743 
	C602.913757,302.127472 601.281555,301.422455 599.045776,300.658478 
	C597.227234,298.344757 596.255798,295.903748 594.719421,293.894745 
	C593.513916,292.318298 591.609802,291.276062 590.016052,289.996521 
	C588.360901,287.939301 586.705750,285.882050 584.985168,283.128479 
	C583.610046,281.291351 582.300415,280.150543 580.990784,279.009766 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M637.410156,394.929962 
	C636.354919,393.914520 635.579163,392.952759 634.486145,391.910889 
	C632.352783,390.227936 630.536682,388.625183 628.371765,386.990479 
	C628.022888,386.958496 627.588806,386.900665 627.302856,386.553162 
	C625.676270,385.136108 624.335693,384.066559 622.995117,382.997040 
	C620.346313,380.670959 617.697449,378.344879 615.425903,376.350067 
	C619.530701,369.416138 623.276367,363.088928 627.443909,356.348328 
	C630.263123,355.586182 632.660522,355.237427 635.057861,354.888672 
	C635.485107,354.931580 635.912415,354.974487 636.859253,355.299927 
	C639.582581,356.729553 641.786377,357.876709 643.990173,359.023865 
	C643.990173,359.023865 644.416199,359.095947 644.699951,359.445435 
	C646.362183,361.824158 647.740723,363.853424 649.119202,365.882690 
	C649.119202,365.882690 648.974854,366.028259 648.902466,366.103333 
	C646.159058,364.250946 643.448120,362.376068 640.825195,360.385284 
	C635.036072,355.991364 630.083679,355.228577 627.470642,362.579010 
	C626.284119,365.916565 623.919556,368.915131 621.731140,371.795258 
	C619.344666,374.935944 620.113098,377.262695 622.897888,379.404877 
	C628.019897,383.344849 633.206970,387.201233 638.282166,391.200134 
	C639.687256,392.307312 640.793213,393.794128 641.712524,395.516907 
	C640.603821,395.933868 639.819153,395.940735 639.034424,395.947571 
	C638.586182,395.626282 638.138000,395.304962 637.410156,394.929962 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M550.849854,449.937714 
	C545.849731,455.175659 540.098938,452.547241 534.659668,451.215973 
	C531.063843,450.335876 527.683594,448.574982 524.149109,446.570557 
	C524.142944,445.731354 524.194458,445.529999 524.640991,445.315613 
	C526.353027,445.330627 527.669983,445.358704 529.208496,445.616302 
	C532.657959,447.174683 535.885864,448.503510 539.410889,449.887939 
	C540.108521,449.973267 540.509033,450.002960 541.185913,450.302490 
	C546.017151,451.759338 548.939392,450.668213 549.224609,445.348938 
	C549.291260,444.105713 550.094360,442.860260 550.708313,441.694672 
	C556.350769,430.982330 555.472717,429.151398 543.947266,426.040680 
	C539.460999,424.829834 535.170776,422.892883 531.188232,421.191071 
	C539.063904,422.692169 546.542603,424.288879 554.314697,426.141113 
	C554.775452,430.606506 554.942749,434.816376 555.110107,439.026215 
	C554.823303,439.805023 554.536499,440.583832 554.167725,441.665894 
	C553.475342,443.757935 552.864868,445.546722 552.059814,447.946350 
	C551.526672,449.017365 551.188293,449.477539 550.849854,449.937714 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M591.124451,362.914032 
	C590.728638,362.866608 590.807068,362.446472 591.086731,362.134491 
	C591.649414,361.640137 591.844360,361.389313 592.212585,360.876068 
	C592.611145,360.062286 592.748169,359.442505 592.885254,358.822693 
	C592.915100,358.420807 592.944885,358.018890 593.357849,357.263062 
	C595.600647,353.427887 597.607483,350.015381 599.275208,346.444489 
	C600.706726,343.379211 601.750671,340.132904 602.966064,336.966675 
	C603.499023,342.405609 600.449829,348.161926 604.487488,353.342560 
	C604.889343,353.858246 604.368469,355.241455 604.051575,356.143219 
	C602.645081,360.146149 601.156799,364.120361 599.496948,368.654327 
	C597.196533,367.012573 594.358398,364.987000 591.124451,362.914032 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M605.014648,294.994232 
	C605.009705,293.282471 605.004761,291.570679 604.998657,289.431274 
	C605.316711,287.670715 606.313660,285.931580 605.810242,285.091522 
	C604.839478,283.471497 602.640198,282.588806 601.665283,280.969818 
	C600.758484,279.464142 600.781982,277.398254 600.395874,275.579010 
	C601.065918,275.328735 601.735901,275.078430 602.405884,274.828156 
	C604.873474,277.928223 606.754395,282.058960 609.950867,283.878510 
	C614.363342,286.390198 614.466492,289.811066 613.862854,293.461853 
	C613.245483,297.195587 611.357727,300.495911 606.301270,300.125000 
	C606.000061,299.553619 606.002502,299.110901 606.319641,298.291504 
	C608.752441,295.467285 606.492798,295.447449 605.014648,294.994232 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M602.973999,336.597656 
	C601.750671,340.132904 600.706726,343.379211 599.275208,346.444489 
	C597.607483,350.015381 595.600647,353.427887 593.401978,356.963867 
	C595.109070,350.261353 596.737915,343.339874 599.314941,336.791565 
	C602.060547,329.815247 600.828674,323.108337 599.556519,316.252075 
	C599.345520,315.114899 599.161987,313.972687 599.331055,312.443207 
	C600.785400,312.382019 601.874329,312.710419 602.963318,313.038849 
	C602.966431,313.473450 602.969543,313.908051 602.752258,314.818237 
	C602.688721,315.862793 602.845581,316.431732 603.002441,317.000702 
	C603.005737,317.443359 603.009033,317.885986 603.009033,319.108002 
	C603.336365,322.925079 603.666992,325.962799 603.997559,329.000519 
	C603.997559,329.750305 603.997559,330.500061 603.752869,331.793365 
	C603.349243,332.895203 603.190308,333.453552 603.031311,334.011871 
	C603.014832,334.750793 602.998413,335.489746 602.973999,336.597656 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M592.822632,377.703918 
	C594.307861,377.992889 595.434509,378.250336 596.561279,378.507812 
	C595.784363,379.360931 595.120850,380.378418 594.195679,381.016479 
	C593.418884,381.552155 592.313416,381.953400 591.394531,381.882507 
	C587.011475,381.544434 584.596436,382.676666 584.809753,387.853821 
	C584.941284,391.044952 581.788940,391.610840 577.549805,389.459869 
	C579.308777,382.029602 586.325012,376.484314 592.822632,377.703918 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M554.021301,425.885559 
	C546.542603,424.288879 539.063904,422.692169 530.891479,421.159424 
	C529.800049,421.187592 529.402344,421.151825 528.784973,420.799347 
	C527.298218,420.426910 526.031067,420.371124 524.354004,420.292236 
	C522.297180,420.216675 520.650269,420.164215 518.894958,419.823425 
	C518.509888,419.228394 518.233154,418.921692 517.956482,418.614990 
	C521.445801,418.507690 524.952759,418.135803 528.419617,418.366150 
	C532.013000,418.604828 535.567566,419.427185 539.573975,419.987244 
	C544.057129,420.983643 548.105408,421.987061 552.702026,423.276184 
	C553.507324,424.336487 553.764282,425.111023 554.021301,425.885559 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M488.978394,416.291412 
	C496.152985,415.996368 503.320740,415.890076 510.481171,416.071716 
	C511.998138,416.110199 513.488342,417.200684 514.623169,418.317902 
	C512.167114,419.074921 510.078766,419.320496 507.558960,419.644348 
	C505.088470,419.672333 503.049377,419.622040 500.670197,419.344421 
	C498.366486,419.045593 496.369049,419.172791 494.448883,418.845978 
	C492.580933,418.528046 490.792145,417.744904 488.968597,417.166046 
	C488.968719,416.971649 488.968842,416.777252 488.978394,416.291412 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M518.029297,387.483948 
	C528.349426,387.421692 538.751282,387.697144 549.548950,388.321045 
	C549.297058,389.447540 548.649353,390.225647 548.001648,391.003723 
	C546.612671,391.009918 545.223694,391.016144 543.017212,391.069031 
	C538.466614,391.092346 534.733582,391.068970 531.000549,391.045593 
	C529.286072,391.029938 527.571594,391.014252 525.428589,391.000916 
	C522.703674,389.942749 520.407349,388.882202 518.029297,387.483948 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M531.045166,391.420044 
	C534.733582,391.068970 538.466614,391.092346 542.600708,391.081299 
	C541.983337,393.365662 541.565674,396.414337 539.820862,397.850433 
	C536.257080,400.783691 533.122192,398.434875 531.045166,391.420044 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M554.314697,426.141113 
	C553.764282,425.111023 553.507324,424.336487 553.131958,423.272736 
	C553.245178,422.761169 553.495789,422.328278 553.705688,422.347229 
	C558.896545,422.815674 559.240967,427.083374 559.925659,430.765991 
	C560.584656,434.310364 561.955444,438.622986 555.483154,439.016296 
	C554.942749,434.816376 554.775452,430.606506 554.314697,426.141113 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M566.004883,383.017365 
	C566.392517,382.962738 566.780212,382.908081 567.835632,382.900635 
	C569.003113,382.967834 569.502747,382.987854 570.002441,383.007874 
	C570.454712,385.012238 571.088806,386.997833 571.297791,389.027222 
	C571.446350,390.469299 571.542419,392.721100 570.737915,393.279083 
	C569.332092,394.254150 567.215149,394.203918 565.396240,394.583313 
	C565.241577,393.295074 565.086853,392.006866 565.281555,390.314941 
	C565.933167,389.585938 566.235413,389.260651 566.537598,388.935364 
	C565.691711,388.625214 564.845886,388.315063 564.000000,388.004883 
	C563.679077,387.905731 563.417480,387.722076 563.290710,386.966003 
	C564.245789,385.324493 565.125366,384.170929 566.004883,383.017365 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M475.997803,417.989716 
	C477.385498,417.992157 478.773224,417.994629 480.915680,417.993195 
	C482.446808,417.658142 483.223175,417.326996 483.999542,416.995880 
	C484.792542,417.001312 485.585571,417.006744 486.709412,417.391479 
	C486.693024,418.565582 486.345825,419.360352 485.709381,420.186401 
	C485.035187,420.267151 484.650269,420.316650 483.813477,420.240479 
	C481.092590,420.290955 478.785461,420.265442 476.562531,420.685516 
	C471.998840,421.547852 467.480255,422.648895 463.025391,423.352234 
	C463.738403,422.038849 464.369080,421.024902 464.999786,420.010986 
	C464.999786,420.010986 465.000336,420.000336 465.427612,420.001404 
	C469.235901,419.331573 472.616852,418.660645 475.997803,417.989716 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M609.923645,313.035217 
	C613.604309,313.799469 613.344360,316.225220 612.569397,318.473846 
	C612.237610,319.436340 610.222534,320.822357 610.016418,320.663208 
	C608.561584,319.540314 606.756470,318.223877 606.352966,316.640533 
	C606.128418,315.759216 608.383057,314.246246 609.923645,313.035217 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M464.561035,420.006683 
	C464.369080,421.024902 463.738403,422.038849 462.707916,423.437073 
	C461.872437,423.849640 461.436768,423.877899 460.743713,423.647400 
	C456.974304,424.260345 453.462341,425.131989 449.950348,426.003662 
	C449.635895,425.227814 449.321411,424.451935 449.007324,423.341705 
	C454.045898,422.005676 459.084106,421.004028 464.561035,420.006683 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M671.882324,381.877930 
	C667.653381,378.724121 663.445374,375.190063 659.063660,371.358459 
	C666.445923,370.527069 668.769775,376.517120 671.882324,381.877930 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M590.031616,290.354095 
	C591.609802,291.276062 593.513916,292.318298 594.719421,293.894745 
	C596.255798,295.903748 597.227234,298.344757 598.715088,300.800842 
	C598.966431,302.466522 598.944885,303.930878 598.839111,305.837006 
	C595.852417,301.089722 592.949829,295.900696 590.031616,290.354095 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M485.998627,420.155121 
	C486.345825,419.360352 486.693024,418.565582 487.101807,417.453461 
	C487.564209,417.136902 487.965027,417.137695 488.667206,417.152283 
	C490.792145,417.744904 492.580933,418.528046 494.448883,418.845978 
	C496.369049,419.172791 498.366486,419.045593 500.660797,419.702118 
	C500.986206,420.524170 500.980896,420.761169 500.514160,420.987183 
	C495.741364,421.062256 491.430023,421.148285 486.899078,421.086792 
	C486.452545,420.677887 486.225586,420.416504 485.998627,420.155121 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M649.452881,365.966003 
	C647.740723,363.853424 646.362183,361.824158 644.915161,359.463531 
	C648.217163,361.873016 651.587585,364.613892 654.923706,367.743042 
	C653.188416,367.437347 651.487488,366.743317 649.452881,365.966003 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M578.901672,273.779297 
	C579.014282,274.751221 579.011719,275.505615 579.000610,276.637451 
	C574.982727,274.301208 570.973511,271.587524 566.843384,268.559326 
	C567.227600,268.168549 567.732666,268.092255 568.830322,268.272339 
	C572.281128,270.017212 575.139343,271.505707 577.997559,272.994232 
	C578.319946,273.100922 578.582947,273.290100 578.901672,273.779297 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M577.993408,272.618835 
	C575.139343,271.505707 572.281128,270.017212 569.211975,268.261230 
	C573.721863,266.248657 576.176453,267.409180 577.993408,272.618835 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M604.293091,328.771057 
	C603.666992,325.962799 603.336365,322.925079 603.015259,319.439941 
	C608.534363,321.358582 605.845276,325.067413 604.293091,328.771057 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M475.721375,417.704102 
	C472.616852,418.660645 469.235901,419.331573 465.432922,420.001373 
	C467.567566,415.415985 471.683258,417.132629 475.721375,417.704102 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M548.263794,391.285400 
	C548.649353,390.225647 549.297058,389.447540 549.983398,388.361450 
	C550.458313,388.019592 550.894592,387.985748 551.882568,388.239227 
	C553.621155,388.682404 554.808167,388.838257 555.995117,388.994141 
	C554.839355,393.724213 551.375488,391.754028 548.263794,391.285400 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M634.955383,354.520782 
	C632.660522,355.237427 630.263123,355.586182 627.476685,355.981445 
	C627.374573,355.087219 627.697266,353.323273 627.943115,353.333893 
	C630.253845,353.433929 632.551453,353.837219 634.955383,354.520782 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M643.959351,358.654114 
	C641.786377,357.876709 639.582581,356.729553 637.181030,355.313477 
	C641.037476,352.396423 642.744202,354.780487 643.959351,358.654114 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M578.984375,374.957764 
	C579.441162,374.679169 579.897949,374.400513 580.957642,374.047729 
	C582.039246,373.981781 582.517822,373.989990 582.996460,373.998230 
	C581.243286,375.661102 579.490173,377.323944 577.365479,378.642822 
	C576.968323,377.839294 576.942749,377.379761 576.917175,376.920258 
	C576.917175,376.920258 576.978821,376.994141 577.286255,376.949799 
	C578.057251,376.256226 578.520813,375.606995 578.984375,374.957764 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M565.648560,383.030273 
	C565.125366,384.170929 564.245789,385.324493 563.180664,386.749481 
	C561.457947,387.173615 559.920715,387.326294 558.185181,387.215271 
	C560.421936,385.648773 562.857056,384.345978 565.648560,383.030273 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M570.997559,380.007568 
	C571.699402,379.621063 572.401245,379.234558 573.736633,378.897034 
	C574.582825,378.972412 574.795471,378.998779 575.008057,379.025146 
	C573.921570,380.016113 572.835144,381.007050 571.382629,381.670135 
	C571.010254,380.897369 571.003906,380.452454 570.997559,380.007568 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M678.943970,389.698517 
	C677.639587,388.934265 676.318115,387.819489 674.960815,386.359436 
	C676.258972,387.125427 677.592896,388.236725 678.943970,389.698517 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M623.028503,383.344177 
	C624.335693,384.066559 625.676270,385.136108 627.084351,386.537994 
	C625.788574,385.810669 624.425232,384.750977 623.028503,383.344177 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M602.938416,312.679138 
	C601.874329,312.710419 600.785400,312.382019 599.353394,312.025269 
	C598.995422,311.551544 598.980408,311.106171 599.001709,310.328156 
	C600.329895,310.770172 601.621704,311.544800 602.938416,312.679138 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M583.262451,373.782166 
	C582.517822,373.989990 582.039246,373.981781 581.200928,373.934753 
	C581.644165,372.918823 582.447083,371.941711 583.584961,370.924530 
	C583.789490,371.778351 583.658997,372.672241 583.262451,373.782166 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M556.005554,388.689117 
	C554.808167,388.838257 553.621155,388.682404 552.210999,388.241913 
	C553.103821,387.654755 554.219788,387.352234 555.704102,387.109833 
	C556.053589,387.574677 556.034790,387.979401 556.005554,388.689117 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M570.660095,380.025757 
	C571.003906,380.452454 571.010254,380.897369 571.021301,381.676239 
	C570.906860,382.326172 570.711548,382.584442 570.221191,382.896454 
	C569.502747,382.987854 569.003113,382.967834 568.129028,382.887634 
	C568.610596,381.899628 569.466614,380.971771 570.660095,380.025757 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M575.338379,379.015900 
	C574.795471,378.998779 574.582825,378.972412 574.054199,378.874878 
	C574.567871,378.174652 575.397644,377.545563 576.572266,376.918365 
	C576.942749,377.379761 576.968323,377.839294 576.992065,378.643311 
	C576.549744,378.994080 576.109192,379.000366 575.338379,379.015900 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M564.999512,267.635437 
	C564.554565,268.013824 564.111511,268.015137 563.336975,268.000305 
	C563.003296,267.239075 563.001099,266.494019 562.998230,265.375702 
	C563.442444,265.001770 563.887268,265.001129 564.665405,265.000854 
	C564.999695,265.753601 565.000610,266.506012 564.999512,267.635437 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M564.020874,388.302795 
	C564.845886,388.315063 565.691711,388.625214 566.537598,388.935364 
	C566.235413,389.260651 565.933167,389.585938 565.317932,389.957397 
	C564.683838,389.535919 564.362793,389.068268 564.020874,388.302795 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M483.801025,416.742035 
	C483.223175,417.326996 482.446808,417.658142 481.331360,417.980042 
	C481.862366,417.476562 482.732452,416.982391 483.801025,416.742035 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M673.949951,384.757568 
	C673.328125,384.619141 672.673950,384.144012 671.977478,383.339874 
	C672.596008,383.480865 673.256775,383.950867 673.949951,384.757568 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M585.054688,370.863617 
	C585.189331,370.190521 585.700134,369.530701 586.532593,368.786194 
	C586.379822,369.426636 585.905334,370.151764 585.054688,370.863617 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M588.328857,365.954346 
	C588.398804,365.311584 588.807251,364.635193 589.525513,363.875122 
	C589.445984,364.501190 589.056641,365.210907 588.328857,365.954346 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M603.229126,316.853699 
	C602.845581,316.431732 602.688721,315.862793 602.753113,315.144226 
	C603.134888,315.565277 603.295288,316.135986 603.229126,316.853699 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M603.320923,333.976868 
	C603.190308,333.453552 603.349243,332.895203 603.752869,332.168274 
	C603.868530,332.647064 603.739502,333.294464 603.320923,333.976868 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M439.987366,323.593445 
	C432.304321,323.019012 425.641571,326.389282 418.426819,329.039734 
	C422.019073,326.704773 425.899750,324.184021 430.006500,322.111145 
	C431.693695,321.259552 433.896973,321.012634 435.811371,321.166107 
	C437.243958,321.280975 438.589844,322.477753 439.987366,323.593445 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M446.956329,336.693726 
	C445.937714,334.358917 444.941742,331.603210 443.953552,328.426636 
	C444.967194,330.761475 445.973083,333.517151 446.956329,336.693726 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M417.664124,329.036224 
	C417.139404,329.864258 416.247833,330.680542 415.160583,331.331970 
	C415.742371,330.452881 416.519806,329.738678 417.664124,329.036224 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M263.861725,574.557739 
	C259.893127,574.800293 259.904724,577.464844 260.000000,580.595276 
	C259.387299,581.611145 258.774597,582.222351 258.161926,582.833557 
	C257.461761,582.013306 256.005188,580.929077 256.184326,580.415649 
	C257.336395,577.113892 258.846649,573.937073 260.239288,570.719177 
	C260.668243,570.884216 261.097198,571.049194 261.526123,571.214233 
	C261.420898,570.915771 261.355286,570.378113 261.204712,570.353149 
	C253.976791,569.155273 253.385025,564.151733 253.940933,558.206238 
	C254.265289,554.737000 253.764404,551.192322 254.064514,547.718689 
	C254.380753,544.058411 253.532669,541.758484 249.539154,540.926819 
	C246.533020,540.300720 246.448135,538.351135 248.351669,536.145386 
	C250.138901,534.074402 252.379837,532.185425 253.485382,529.785278 
	C255.090744,526.300110 256.005524,522.464172 256.921783,518.706482 
	C257.460785,516.495789 257.432709,514.146912 257.657349,511.860565 
	C249.443802,509.486115 247.973831,501.791931 245.227875,495.411560 
	C243.594284,491.615784 246.059769,488.417938 250.614334,485.803986 
	C251.451523,488.271484 252.093842,490.616272 253.050522,492.824951 
	C253.684326,494.288269 254.768616,495.556458 255.651260,496.911987 
	C256.749542,495.696991 258.449677,494.640289 258.819611,493.233765 
	C259.567596,490.389679 259.946106,487.331757 259.747253,484.411713 
	C259.665710,483.214294 257.808624,481.349792 256.562500,481.172668 
	C253.294769,480.708191 249.917511,480.945038 246.586395,481.017456 
	C243.923218,481.075348 241.650726,480.499817 242.118851,477.286102 
	C242.335480,475.798828 243.690002,474.477295 244.528610,473.080627 
	C246.030075,474.504303 247.575912,475.885498 249.004898,477.378510 
	C249.411804,477.803650 249.479111,478.553833 250.313416,480.802521 
	C251.440536,469.197723 259.737549,473.898956 264.987915,472.671783 
	C266.966400,472.209351 269.880463,475.749603 272.850739,477.798035 
	C272.979309,477.706360 274.131927,476.884583 275.619690,476.008972 
	C275.954895,475.955109 275.995758,475.992371 276.007721,476.414337 
	C275.003906,480.219055 273.988159,483.601807 272.603882,487.063721 
	C268.508453,493.956299 267.624908,501.457275 266.589294,509.047485 
	C263.102631,510.408569 261.954895,512.716187 261.923157,516.140747 
	C261.870514,521.815125 261.367188,527.487732 260.955444,533.153931 
	C260.890869,534.042603 260.228943,534.872314 259.987518,535.768127 
	C259.740753,536.683899 259.474121,537.665405 259.557953,538.587036 
	C259.976929,543.194519 260.655884,547.782288 260.952942,552.395691 
	C261.187439,556.037415 260.767212,559.729370 261.126007,563.350647 
	C261.253876,564.641296 262.817047,565.789612 263.725037,567.002869 
	C263.792236,569.373901 263.859436,571.744873 263.861725,574.557739 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M262.615173,590.004150 
	C263.433472,595.157227 263.918060,600.313660 264.279236,605.478638 
	C264.389313,607.053223 264.159729,608.651611 264.042847,610.619507 
	C259.005249,611.062256 260.831909,615.503479 260.292725,617.745605 
	C259.364471,621.605774 259.965515,625.817871 259.721893,629.869995 
	C259.663513,630.841003 258.947998,632.415894 258.286133,632.558472 
	C257.259277,632.779541 255.408234,632.356567 254.977386,631.598267 
	C253.309723,628.662964 251.362228,625.606201 250.814255,622.375000 
	C249.759186,616.153564 249.585190,609.782715 248.945923,602.305420 
	C251.076157,603.489807 252.164062,604.008728 253.161789,604.664734 
	C256.783142,607.046082 259.671478,605.761353 259.941132,601.424438 
	C260.104340,598.800049 259.510101,596.113464 259.811188,593.519226 
	C259.955811,592.272827 261.386017,591.175537 262.615173,590.004150 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M361.061462,359.153778 
	C359.912048,360.360809 358.762634,361.567841 357.309387,362.897156 
	C356.538544,358.734375 356.513397,354.339020 355.478699,350.195709 
	C354.470001,346.156555 356.264709,343.681793 359.009888,341.767639 
	C362.191711,339.548981 364.621887,341.731262 366.584625,344.144226 
	C368.748169,346.803955 368.751038,349.542999 366.350861,352.135956 
	C365.341644,353.226227 364.317383,354.302551 363.149963,355.692627 
	C363.000000,356.000000 362.998596,356.000183 362.702759,356.147125 
	C361.958466,357.247314 361.509949,358.200531 361.061462,359.153778 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M315.689514,403.057434 
	C313.719269,406.497162 311.381714,409.958984 309.117828,413.468323 
	C308.477814,414.460449 308.053070,415.591431 307.737671,417.123535 
	C307.660065,418.084534 307.374420,418.580750 306.748932,419.098877 
	C304.630371,420.450409 302.851654,421.779999 301.072937,423.109619 
	C304.803955,415.705505 307.739410,407.733826 315.689514,403.057434 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M293.958344,436.959961 
	C294.113983,431.691925 295.245392,426.909546 300.603455,424.360962 
	C300.310303,426.471832 299.652435,428.231110 298.691162,430.153809 
	C296.932495,432.552277 295.477234,434.787323 294.022003,437.022339 
	C294.022003,437.022339 293.979797,436.980530 293.958344,436.959961 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M298.994568,429.990417 
	C299.652435,428.231110 300.310303,426.471832 300.988953,424.357178 
	C301.009766,424.001831 301.047760,423.555054 301.060364,423.332336 
	C302.851654,421.779999 304.630371,420.450409 306.726807,419.456177 
	C305.046448,423.205200 303.048370,426.618866 300.707672,430.018005 
	C299.908203,429.999084 299.451385,429.994751 298.994568,429.990417 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M373.747955,350.073090 
	C371.989441,352.035370 369.865845,354.002960 367.394989,356.000824 
	C369.159424,354.046844 371.271149,352.062622 373.747955,350.073090 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M324.694611,393.066284 
	C323.597412,394.689575 322.150940,396.325989 320.361206,397.999512 
	C321.460449,396.384247 322.902893,394.731842 324.694611,393.066284 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M396.804749,337.093048 
	C396.015076,338.390930 394.864075,339.681458 393.371094,341.008423 
	C394.167236,339.725128 395.305328,338.405396 396.804749,337.093048 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M366.616516,356.028687 
	C365.902496,357.025085 364.786774,358.003754 363.335602,358.628540 
	C362.999634,357.516510 362.999115,356.758362 362.998596,356.000183 
	C362.998596,356.000183 363.000000,356.000000 363.000000,355.998779 
	C364.071625,356.002014 365.143219,356.006500 366.616516,356.028687 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M346.624878,372.003357 
	C345.883026,372.988220 344.777252,373.974884 343.343048,374.991150 
	C344.096710,374.015564 345.178833,373.010345 346.624878,372.003357 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M379.745728,347.079926 
	C379.592712,347.938843 379.086792,348.823029 378.282227,349.818298 
	C378.453339,348.987946 378.923065,348.046570 379.745728,347.079926 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M326.706024,391.054199 
	C326.585327,391.667786 326.136627,392.312225 325.370178,392.999817 
	C325.494324,392.390350 325.936157,391.737732 326.706024,391.054199 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M334.719727,383.060669 
	C334.595337,383.674835 334.138062,384.316589 333.365356,385.018097 
	C333.495575,384.414642 333.941223,383.751465 334.719727,383.060669 
z"/>
<path fill="#787878" opacity="1.000000" stroke="none" 
	d="
M806.058716,568.000000 
	C806.065613,550.849243 806.055298,534.198486 806.088928,517.547791 
	C806.100159,511.998077 807.208435,510.949280 812.869995,510.857697 
	C815.199524,510.820007 817.550598,511.014648 819.855652,510.766815 
	C826.434814,510.059448 827.783264,511.181183 827.789673,517.891235 
	C827.807678,536.705688 827.849670,555.520325 827.779236,574.334473 
	C827.760010,579.474487 827.418884,584.615356 827.150757,589.751953 
	C826.844971,595.609863 829.000854,598.001343 835.031738,598.016846 
	C848.685120,598.051880 862.338989,597.955322 875.992371,597.991028 
	C881.618896,598.005737 882.713074,599.096802 882.825134,604.711670 
	C883.154846,621.229675 881.956970,618.953918 869.366516,618.961426 
	C851.383728,618.972046 833.400940,618.952087 815.418152,618.955139 
	C808.210938,618.956360 806.126526,616.893738 806.101562,609.460999 
	C806.055786,595.807434 806.069641,582.153687 806.058716,568.000000 
M825.201111,545.500000 
	C825.203552,538.171570 825.287537,530.842102 825.190430,523.515015 
	C825.022888,510.870789 826.943115,513.128845 814.313599,512.689758 
	C810.875671,512.570129 809.491150,513.832275 809.461365,517.208618 
	C809.382507,526.153076 808.998596,535.097107 809.039673,544.039856 
	C809.136658,565.158875 809.530701,586.276978 809.548889,607.395691 
	C809.555603,615.164246 809.227539,615.184448 816.946289,615.191895 
	C832.602722,615.206970 848.259155,615.225952 863.915466,615.179688 
	C867.898804,615.167969 871.894775,615.071655 875.860046,614.727173 
	C878.940247,614.459534 880.345642,612.688782 880.371216,609.353760 
	C880.423340,602.548279 878.962402,600.768921 871.991638,600.763916 
	C859.334045,600.754761 846.676453,600.883606 834.018738,600.918213 
	C826.442444,600.938965 825.102478,599.571960 825.113708,591.967651 
	C825.136169,576.811768 825.172913,561.655884 825.201111,545.500000 
z"/>
<path fill="#175292" opacity="1.000000" stroke="none" 
	d="
M648.004883,714.992065 
	C648.004883,714.992065 648.458313,714.959656 649.053833,714.918335 
	C650.100342,713.596802 650.551392,712.316589 651.002441,711.036316 
	C653.451904,708.725525 656.098938,706.588196 658.311890,704.069641 
	C663.814758,697.806885 669.323792,691.528076 674.420166,684.938599 
	C677.059021,681.526611 678.825684,677.440186 680.932739,673.279602 
	C680.879150,672.900757 680.892700,672.915894 681.257446,672.919189 
	C683.928772,669.607544 686.462097,666.424744 688.489685,662.947083 
	C691.623047,657.572815 694.275330,652.019226 702.316895,654.874329 
	C704.386719,655.609131 706.940369,654.886230 709.255371,655.056274 
	C710.973145,655.182556 712.664368,655.669800 715.470520,656.207581 
	C713.292664,659.328308 711.659729,661.668213 709.974060,663.617310 
	C709.374756,661.218750 708.828186,659.210938 708.304138,657.285828 
	C705.651367,657.549072 703.366943,657.899231 701.073303,657.976196 
	C697.005127,658.112610 689.659424,664.270935 689.020935,668.313965 
	C688.312622,672.798889 684.647034,676.811523 686.902405,681.815125 
	C687.111328,682.278687 686.449402,683.376953 685.914551,683.893494 
	C682.807312,686.894287 679.900879,690.217834 676.388733,692.657959 
	C671.041443,696.372925 670.613770,702.249023 668.084778,707.201111 
	C668.634583,707.620178 669.184326,708.039246 669.734131,708.458374 
	C673.824951,704.016785 677.915710,699.575195 682.432434,694.671204 
	C682.029724,694.439575 683.009277,694.735107 683.593079,695.385803 
	C685.977112,698.042847 688.598633,695.705200 688.453979,694.357849 
	C687.760803,687.898132 691.199768,684.800110 695.986206,681.431702 
	C698.530151,679.641418 699.260193,675.273865 700.931580,671.816162 
	C701.297668,671.941833 702.752441,672.441284 703.129211,672.570618 
	C705.230957,670.616089 706.639343,669.306335 708.046997,668.386963 
	C707.067322,671.883118 706.088318,674.988892 705.109375,678.094666 
	C705.109375,678.094666 704.994934,678.515320 704.577637,678.757874 
	C703.027161,680.154480 701.779114,681.218018 700.783569,682.480652 
	C698.422852,685.474792 696.268982,688.633606 693.874817,691.599426 
	C688.748840,697.949341 683.574036,704.261841 678.300232,710.488831 
	C677.125366,711.875977 675.459656,712.847351 673.646484,714.013123 
	C665.249573,717.214905 659.553223,722.631592 656.921570,731.281067 
	C656.623657,731.763672 656.364563,731.937073 655.610474,732.011536 
	C648.419434,730.874817 645.081909,735.039673 641.820923,740.027100 
	C640.405457,742.191956 637.377747,743.241150 635.360596,745.099121 
	C634.286926,746.088135 633.806885,747.721558 633.030640,749.031982 
	C633.002380,749.000000 633.064575,749.059143 632.658203,749.037354 
	C631.168701,749.007935 630.085571,749.000305 628.968628,748.645996 
	C626.548096,742.593994 633.643921,741.728149 634.542297,738.196594 
	C632.115051,736.924255 630.035706,735.834229 627.113403,734.302368 
	C629.409546,733.133423 630.407471,732.844421 631.120972,732.226807 
	C636.087585,727.928040 641.067505,723.638550 645.866150,719.156494 
	C646.939392,718.154114 647.310547,716.400085 648.004883,714.992065 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M647.649963,715.017822 
	C647.310547,716.400085 646.939392,718.154114 645.866150,719.156494 
	C641.067505,723.638550 636.087585,727.928040 631.120972,732.226807 
	C630.407471,732.844421 629.409546,733.133423 627.113403,734.302368 
	C630.035706,735.834229 632.115051,736.924255 634.542297,738.196594 
	C633.643921,741.728149 626.548096,742.593994 628.613647,748.686340 
	C626.972412,749.589783 625.363647,750.863159 624.382996,750.489197 
	C620.490173,749.005066 619.115967,751.547791 617.624634,753.740173 
	C618.511597,755.340271 619.265747,756.700745 619.864624,758.370117 
	C616.743835,761.014160 613.778259,763.349426 610.812744,765.684631 
	C610.172546,765.850952 609.532410,766.017273 608.572632,766.264038 
	C603.367981,767.963196 598.473511,769.554077 593.600891,771.209290 
	C589.550232,772.585266 585.525391,774.036987 580.856567,775.678955 
	C575.476990,774.276062 570.839600,772.084961 565.960693,771.137207 
	C554.945557,768.997559 554.633911,769.372253 551.866516,778.450684 
	C548.344116,777.797974 544.729187,777.128052 541.114258,776.458130 
	C541.019897,775.689392 540.925537,774.920593 540.831177,774.151794 
	C543.035400,773.102051 545.372070,772.260132 547.415588,770.958191 
	C551.365173,768.441711 555.149536,765.665833 559.002441,762.997559 
	C559.429077,762.972290 559.855713,762.947021 560.817261,763.206421 
	C563.545654,763.661926 565.738525,763.840088 567.932617,764.002014 
	C572.845459,764.364868 577.758972,764.719788 582.649902,765.076294 
	C579.604675,758.809326 579.604980,758.811523 586.749023,757.843018 
	C587.070374,757.799500 587.411377,757.708374 587.686218,757.544006 
	C592.360107,754.749146 597.047913,751.975464 601.649353,749.065369 
	C602.028625,748.825623 601.808289,747.637512 601.866577,746.890015 
	C605.007996,744.622192 607.955872,742.002136 611.325928,740.150513 
	C622.811768,733.839844 633.324524,726.231384 643.075989,717.507812 
	C644.265381,716.443848 645.878357,715.853394 647.649963,715.017822 
z"/>
<path fill="#5776A2" opacity="1.000000" stroke="none" 
	d="
M611.174744,765.765381 
	C613.778259,763.349426 616.743835,761.014160 620.185913,758.222046 
	C624.796448,754.863159 628.930481,751.961182 633.064575,749.059143 
	C633.064575,749.059143 633.002380,749.000000 633.392456,749.005371 
	C641.202515,743.345825 648.622375,737.680908 656.042297,732.016052 
	C656.364563,731.937073 656.623657,731.763672 657.267395,731.234131 
	C661.233948,727.740906 664.877747,724.632568 668.226807,721.233826 
	C670.381897,719.046692 672.103882,716.432800 674.019897,714.010132 
	C675.459656,712.847351 677.125366,711.875977 678.300232,710.488831 
	C683.574036,704.261841 688.748840,697.949341 693.874817,691.599426 
	C696.268982,688.633606 698.422852,685.474792 700.783569,682.480652 
	C701.779114,681.218018 703.027161,680.154480 704.496826,678.960327 
	C703.040833,682.232117 701.620667,685.821594 699.392639,688.808594 
	C688.689331,703.158020 677.998169,717.618530 664.141968,729.136475 
	C653.861694,737.681885 643.097778,745.683411 632.193665,753.426392 
	C625.661865,758.064514 618.445435,761.738708 611.174744,765.765381 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M706.998535,690.559570 
	C704.338867,693.870117 701.673889,696.733459 699.021423,699.608459 
	C698.351868,700.334229 697.471802,700.994080 697.115295,701.859985 
	C694.587708,707.998962 691.841125,713.899292 684.856750,716.231201 
	C684.589661,716.320312 684.248596,716.696411 684.232727,716.956543 
	C683.572693,727.785278 671.038025,729.090942 666.962097,737.843262 
	C665.208374,737.664429 664.112793,737.334656 663.156128,736.703369 
	C664.183777,734.910034 665.072510,733.418274 665.961182,731.926514 
	C667.756348,730.041992 669.551514,728.157471 671.972046,726.096252 
	C673.063354,725.267090 673.529175,724.614502 673.995056,723.961975 
	C674.280701,723.825256 674.524902,723.635315 675.204956,723.167969 
	C677.396423,720.872681 679.110657,718.801453 680.824890,716.730225 
	C685.061707,711.419006 689.241516,706.060913 693.551697,700.809875 
	C697.683594,695.776062 701.950989,690.853333 706.476685,685.751221 
	C706.862244,687.119324 706.930054,688.618042 706.998535,690.559570 
z"/>
<path fill="#5776A2" opacity="1.000000" stroke="none" 
	d="
M705.447021,678.033997 
	C706.088318,674.988892 707.067322,671.883118 708.058899,668.167847 
	C708.071472,667.558350 708.051697,667.119751 708.357178,667.040955 
	C709.080566,666.323303 709.498535,665.684448 709.952026,664.787109 
	C710.000671,664.355042 710.013733,664.181580 710.026855,664.008118 
	C711.659729,661.668213 713.292664,659.328308 715.470520,656.207581 
	C712.664368,655.669800 710.973145,655.182556 709.255371,655.056274 
	C706.940369,654.886230 704.386719,655.609131 702.316895,654.874329 
	C694.275330,652.019226 691.623047,657.572815 688.489685,662.947083 
	C686.462097,666.424744 683.928772,669.607544 681.258667,672.908264 
	C682.700745,669.616394 685.129517,666.528381 686.195251,663.025635 
	C689.435791,652.375366 696.590759,650.510437 706.291931,652.884949 
	C708.819092,653.503540 711.695984,652.754761 714.182312,653.452881 
	C715.493469,653.820984 717.492798,656.554199 717.166077,657.199463 
	C713.601929,664.240356 709.639038,671.079346 705.447021,678.033997 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M663.017273,737.004883 
	C664.112793,737.334656 665.208374,737.664429 666.653198,737.997070 
	C666.297791,741.870056 666.045532,746.155151 660.022644,744.135254 
	C659.124023,743.833862 657.858337,744.627014 656.380005,744.958130 
	C655.673340,744.180176 655.349060,743.357849 654.979553,742.201172 
	C656.030884,740.979980 657.127563,740.093201 658.892883,739.087402 
	C660.380432,738.331909 661.199219,737.695374 662.267883,737.045166 
	C662.684204,737.022644 662.850708,737.013733 663.017273,737.004883 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M680.500610,716.832886 
	C679.110657,718.801453 677.396423,720.872681 675.333923,722.973328 
	C676.715881,720.980347 678.446106,718.957947 680.500610,716.832886 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M663.156128,736.703369 
	C662.850708,737.013733 662.684204,737.022644 661.967346,737.007080 
	C661.218445,736.946289 661.019958,736.910034 660.821411,736.873657 
	C662.308044,735.251770 663.794617,733.629883 665.621216,731.967285 
	C665.072510,733.418274 664.183777,734.910034 663.156128,736.703369 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M660.514771,736.939331 
	C661.019958,736.910034 661.218445,736.946289 661.717529,737.020752 
	C661.199219,737.695374 660.380432,738.331909 659.191528,738.942505 
	C659.283630,738.279297 659.745850,737.642090 660.514771,736.939331 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M673.670898,723.997437 
	C673.529175,724.614502 673.063354,725.267090 672.263306,725.939331 
	C672.401672,725.316956 672.874146,724.674927 673.670898,723.997437 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M558.557007,762.983948 
	C555.149536,765.665833 551.365173,768.441711 547.415588,770.958191 
	C545.372070,772.260132 543.035400,773.102051 540.831177,774.151794 
	C540.925537,774.920593 541.019897,775.689392 541.114258,776.458130 
	C544.729187,777.128052 548.344116,777.797974 551.866516,778.450684 
	C554.633911,769.372253 554.945557,768.997559 565.960693,771.137207 
	C570.839600,772.084961 575.476990,774.276062 580.601074,775.947815 
	C577.920227,777.001282 574.862244,778.009766 571.181213,778.720886 
	C567.674316,777.494263 564.790466,776.564941 561.299500,775.440002 
	C562.591003,778.532166 563.304749,780.241089 564.018555,781.950012 
	C550.093262,784.079285 536.459106,788.568115 521.183716,787.077759 
	C515.842102,787.369812 511.401489,787.634033 506.985352,788.120361 
	C506.276398,788.198425 505.676636,789.267883 505.026367,789.879028 
	C502.639069,789.902466 500.251801,789.925903 497.327911,789.535522 
	C492.493317,788.435059 488.195312,787.748474 483.897308,787.061890 
	C472.276764,788.255310 461.162933,785.322266 450.116241,782.518066 
	C441.711060,780.384338 433.539154,777.339600 425.235016,774.792908 
	C423.678528,774.315491 421.894226,774.531067 420.380554,773.975708 
	C418.107544,773.141724 415.980682,771.909363 414.232788,769.832336 
	C412.306244,769.443909 410.379700,769.055481 408.453125,768.666992 
	C409.299286,766.840210 410.237640,765.049316 410.967346,763.177185 
	C411.801544,761.036987 412.450165,758.824402 413.179352,756.643311 
	C414.812561,751.757996 417.144012,751.425354 421.951538,753.096436 
	C433.342224,757.055664 444.018066,763.008301 456.471008,764.090820 
	C471.582916,765.404480 486.588715,767.901672 501.674103,769.584717 
	C505.225281,769.980896 508.929565,769.317444 512.532288,768.883362 
	C525.290344,767.346191 538.492615,768.794983 550.869873,762.941223 
	C551.451050,762.618835 551.826599,762.631592 552.482666,762.622681 
	C554.545959,762.724060 556.328735,762.847229 558.557007,762.983948 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M448.804810,757.833313 
	C452.341370,758.259766 455.976105,759.207520 459.671509,759.701599 
	C464.743103,760.379700 469.860596,760.713928 475.534729,761.577759 
	C479.099792,763.208313 482.088562,764.456604 485.077332,765.704834 
	C475.672607,764.290710 466.240753,763.029541 456.880005,761.367737 
	C454.126526,760.879028 451.574463,759.255737 448.804810,757.833313 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M411.090485,746.144958 
	C410.617340,740.472107 415.543182,741.559204 418.223297,741.292114 
	C421.825470,740.933105 420.773651,744.532654 420.945984,747.466187 
	C420.911926,748.334045 420.929443,748.521667 420.946960,748.709351 
	C419.934937,748.634155 418.922882,748.558960 417.477966,748.036743 
	C415.060211,747.108093 413.075348,746.626526 411.090485,746.144958 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M409.087769,753.038086 
	C409.533386,752.135193 409.978973,751.232239 410.973999,750.039429 
	C412.680603,749.487732 413.837769,749.225891 414.994934,748.964050 
	C412.001434,755.713013 409.007935,762.461914 405.986389,769.610718 
	C405.430664,769.101807 404.389984,768.138367 404.479767,767.295349 
	C404.681305,765.402710 405.444183,763.569946 405.974243,760.956299 
	C407.009216,757.812622 408.048492,755.425354 409.087769,753.038086 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M421.105316,749.041138 
	C420.929443,748.521667 420.911926,748.334045 420.933289,747.863525 
	C422.024750,747.738831 423.077332,747.897034 424.587616,748.406006 
	C425.689423,749.177063 426.333527,749.597290 426.977600,750.017578 
	C429.559265,750.987305 432.151978,751.928711 434.719727,752.934082 
	C437.715607,754.107117 440.689636,755.336121 443.833923,756.860352 
	C443.243958,757.358337 442.353241,757.892822 441.763702,757.661072 
	C434.904083,754.965271 428.089905,752.153748 421.105316,749.041138 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M564.377075,781.932007 
	C563.304749,780.241089 562.591003,778.532166 561.299500,775.440002 
	C564.790466,776.564941 567.674316,777.494263 570.771362,778.710449 
	C568.901611,779.969604 566.818604,780.941833 564.377075,781.932007 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M505.485382,789.922363 
	C505.676636,789.267883 506.276398,788.198425 506.985352,788.120361 
	C511.401489,787.634033 515.842102,787.369812 520.726562,787.067871 
	C518.914124,788.063232 516.725525,789.453308 514.365356,789.868835 
	C511.637238,790.349182 508.758026,789.971130 505.485382,789.922363 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M483.994293,787.429077 
	C488.195312,787.748474 492.493317,788.435059 496.882111,789.495850 
	C492.657562,789.312073 487.845428,791.840088 483.994293,787.429077 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M485.447021,765.763916 
	C482.088562,764.456604 479.099792,763.208313 475.988586,761.623718 
	C479.904999,761.465088 484.498627,759.826843 487.148682,765.025269 
	C486.738983,765.495544 486.277832,765.659241 485.447021,765.763916 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M415.393463,748.950317 
	C413.837769,749.225891 412.680603,749.487732 411.223907,749.851135 
	C410.972992,748.935181 411.021637,747.917664 411.080383,746.522583 
	C413.075348,746.626526 415.060211,747.108093 417.118042,747.913513 
	C416.724670,748.470459 416.258331,748.703552 415.393463,748.950317 
z"/>
<path fill="#A2B9D4" opacity="1.000000" stroke="none" 
	d="
M452.187378,787.405518 
	C450.014008,786.973328 447.685974,786.238525 445.164856,785.201233 
	C447.325439,785.633545 449.679047,786.368286 452.187378,787.405518 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M519.108154,765.474854 
	C519.431580,764.713562 520.067749,764.160095 520.861267,763.838745 
	C520.994873,764.430237 520.971191,764.789551 520.922546,765.415161 
	C520.405396,765.681824 519.913208,765.682251 519.108154,765.474854 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M510.424622,765.700806 
	C510.097321,765.498840 510.147125,765.327454 510.226166,764.886963 
	C511.068207,764.642334 511.881012,764.666809 512.794312,764.892090 
	C512.197144,765.305664 511.499451,765.518555 510.424622,765.700806 
z"/>
<path fill="#5FA8C8" opacity="1.000000" stroke="none" 
	d="
M301.050293,430.032532 
	C303.048370,426.618866 305.046448,423.205200 307.066650,419.434265 
	C307.374420,418.580750 307.660065,418.084534 307.997314,417.296326 
	C310.385437,414.888306 312.961182,412.979919 315.012512,410.616364 
	C320.535248,404.252899 325.710022,397.586975 331.246124,391.235657 
	C345.889832,374.435577 363.987213,361.824646 382.636749,350.069977 
	C390.686462,344.996307 399.652954,341.377228 408.918945,337.033813 
	C410.455353,336.336243 411.275848,335.702271 412.096344,335.068359 
	C414.815277,334.081635 417.534210,333.094910 420.939209,332.012726 
	C422.825684,331.024963 424.026093,330.132690 425.226532,329.240417 
	C426.842407,329.150330 428.458313,329.060211 430.886017,328.967499 
	C435.141388,328.138306 438.714722,326.717621 441.040710,331.006104 
	C441.328888,332.103668 441.617065,333.201263 441.696442,334.863464 
	C442.733002,338.609924 443.978333,341.791748 445.223663,344.973572 
	C445.010803,346.822052 444.797974,348.670502 443.994385,350.808228 
	C442.867004,351.653076 442.330353,352.208618 441.793732,352.764191 
	C440.687195,352.761139 439.580688,352.758087 437.948364,352.933350 
	C436.931244,353.733459 436.439941,354.355255 435.948669,354.977051 
	C432.433258,355.825745 428.917908,356.674500 424.901245,357.807739 
	C423.894775,358.694672 423.389587,359.297058 422.884399,359.899475 
	C421.188934,360.242676 419.493500,360.585846 417.105835,361.019104 
	C415.935944,361.737061 415.458221,362.364929 414.980499,362.992798 
	C414.980499,362.992798 414.537109,363.082581 413.936737,363.117798 
	C410.545654,364.765717 407.754974,366.378418 404.964264,367.991089 
	C402.864288,368.899597 400.764313,369.808075 398.015076,370.895935 
	C396.529846,372.005371 395.693909,372.935394 394.857910,373.865417 
	C394.467896,373.924194 394.077850,373.983002 393.071167,374.090179 
	C391.923279,374.722229 391.392059,375.305878 390.860840,375.889557 
	C389.783020,376.527222 388.705170,377.164886 387.027252,377.955688 
	C385.936432,378.723389 385.445709,379.337952 384.954987,379.952545 
	C382.004761,381.722168 379.054565,383.491760 375.627869,385.196899 
	C375.151428,385.132416 374.925446,384.923676 374.968689,384.588837 
	C375.363403,383.189850 375.714844,382.125702 376.307068,380.973572 
	C377.018555,380.682465 377.489288,380.479340 378.198944,380.423096 
	C382.033875,377.814789 385.629913,375.059601 389.565430,372.254272 
	C391.347107,371.166504 392.789276,370.128937 394.530182,368.937408 
	C396.897217,367.883148 398.965546,366.982849 401.390320,366.047058 
	C405.559998,363.720734 409.373230,361.429962 413.492096,359.135620 
	C414.204437,359.108124 414.611176,359.084167 415.398376,359.062073 
	C423.366333,356.280853 430.994659,353.599976 438.498260,350.606567 
	C439.647339,350.148163 440.220978,348.247345 441.070312,346.609314 
	C440.410095,343.127686 439.740326,340.050446 439.073547,336.575073 
	C439.057037,331.318573 435.989258,330.093506 432.126007,331.184174 
	C428.264862,332.274231 424.653748,334.250000 420.591034,335.886108 
	C419.157684,336.265503 418.066345,336.596313 416.591370,336.935883 
	C411.080292,339.585815 405.952789,342.227020 400.462250,344.881653 
	C396.930939,347.199371 393.762665,349.503693 390.442719,351.767944 
	C390.291016,351.727905 389.989319,351.814178 389.601807,351.820801 
	C386.784393,352.812592 384.354523,353.797699 381.574707,354.845581 
	C373.689728,360.204590 365.954163,365.241638 358.676666,370.870819 
	C351.550629,376.382935 344.622955,382.217041 338.107483,388.433136 
	C332.368591,393.908264 327.319336,400.106262 321.946167,406.310272 
	C321.923523,407.069244 321.920227,407.502930 321.927429,407.967773 
	C321.937897,407.998871 321.939026,407.936554 321.629517,408.066620 
	C319.518097,410.137299 317.455872,411.897095 315.959900,414.050323 
	C309.942657,422.711304 304.050537,431.460938 298.237030,440.260284 
	C297.111420,441.964020 296.545471,444.037476 295.367767,445.962097 
	C293.429443,446.334869 291.844666,446.685913 290.259949,447.036987 
	C290.979218,445.469849 291.698486,443.902710 293.016327,442.103577 
	C296.093353,437.925262 298.571838,433.978912 301.050293,430.032532 
z"/>
<path fill="#5D8BB2" opacity="1.000000" stroke="none" 
	d="
M345.845612,415.920898 
	C345.423645,416.247040 345.001648,416.573212 343.964203,416.985168 
	C341.299103,418.435638 339.249451,419.800385 337.158508,420.800232 
	C338.004456,418.892975 338.674988,417.163605 339.816528,415.840637 
	C344.709442,410.170013 349.593597,404.476501 354.797058,399.097107 
	C356.783752,397.043243 359.617676,395.808929 362.238434,394.425171 
	C362.743164,394.831665 363.074951,395.014130 363.406738,395.196533 
	C360.767639,397.958771 358.128601,400.721039 355.387695,403.797424 
	C355.285828,404.111542 355.124878,404.153564 354.788269,404.131805 
	C353.981384,404.754974 353.511078,405.399902 352.981262,406.233185 
	C352.921692,406.421539 352.858948,406.811646 352.552246,406.890747 
	C351.779419,407.609375 351.313324,408.248932 350.645935,408.921936 
	C350.444641,408.955383 350.054077,409.073486 349.705017,409.075256 
	C348.595276,410.083496 347.834625,411.090027 346.969635,412.420715 
	C346.525421,413.803558 346.185516,414.862244 345.845612,415.920898 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M405.812561,370.625519 
	C406.324280,374.569397 404.793060,376.301300 400.970245,377.351562 
	C396.961884,378.452789 393.350037,380.997284 389.192780,382.901794 
	C389.388214,381.904968 389.714386,380.555511 390.563080,379.989319 
	C395.505798,376.692139 400.571167,373.578888 405.812561,370.625519 
z"/>
<path fill="#5FA8C8" opacity="1.000000" stroke="none" 
	d="
M363.732666,395.122467 
	C363.074951,395.014130 362.743164,394.831665 362.357666,394.098755 
	C364.207794,392.032593 366.111572,390.516937 368.312469,388.880432 
	C369.111511,387.875061 369.613434,386.990479 370.361572,385.844482 
	C372.046997,385.363220 373.486237,385.143433 374.925446,384.923676 
	C374.925446,384.923676 375.151428,385.132416 375.269348,385.235474 
	C371.610992,388.575165 367.834778,391.811768 363.732666,395.122467 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M358.595520,404.891144 
	C358.786163,411.605347 353.352692,413.398010 348.365021,415.969910 
	C348.012848,416.012604 347.960632,415.960327 347.966675,415.572449 
	C349.103790,414.207733 350.460205,413.394470 351.315186,412.217072 
	C352.729706,410.269104 353.821014,408.086395 355.065002,405.693848 
	C355.095978,404.974579 355.110413,404.564087 355.124878,404.153564 
	C355.124878,404.153564 355.285828,404.111542 355.371521,404.125610 
	C356.402802,404.387390 357.348389,404.635132 358.595520,404.891144 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M372.692322,392.137329 
	C372.697327,397.340210 367.885620,398.414673 364.219116,400.915100 
	C366.696960,398.031799 369.540314,395.149689 372.692322,392.137329 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M441.287292,330.791199 
	C438.714722,326.717621 435.141388,328.138306 431.288879,328.923645 
	C433.453735,327.958313 435.985718,326.623260 438.626221,326.354736 
	C439.443848,326.271545 440.556366,329.087585 441.287292,330.791199 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M445.435577,344.736267 
	C443.978333,341.791748 442.733002,338.609924 441.745178,335.202545 
	C443.217651,338.151001 444.432587,341.324982 445.435577,344.736267 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M405.329834,367.963989 
	C407.754974,366.378418 410.545654,364.765717 413.715698,363.165955 
	C411.295166,364.764862 408.495270,366.350861 405.329834,367.963989 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M385.648315,383.006744 
	C384.537964,384.293060 383.079468,385.608063 381.255615,386.929535 
	C382.360260,385.635834 383.830231,384.335632 385.648315,383.006744 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M424.864716,329.192719 
	C424.026093,330.132690 422.825684,331.024963 421.276245,331.914215 
	C422.119110,330.989105 423.311005,330.067078 424.864716,329.192719 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M395.209656,373.881836 
	C395.693909,372.935394 396.529846,372.005371 397.705231,371.064209 
	C397.216919,372.001465 396.389160,372.949829 395.209656,373.881836 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M411.735168,335.041656 
	C411.275848,335.702271 410.455353,336.336243 409.276581,336.952759 
	C409.736847,336.295197 410.555420,335.655090 411.735168,335.041656 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M362.702759,356.147125 
	C362.999115,356.758362 362.999634,357.516510 363.004730,358.654724 
	C362.575836,359.058258 362.142334,359.081787 361.385162,359.129517 
	C361.509949,358.200531 361.958466,357.247314 362.702759,356.147125 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M442.127991,352.807007 
	C442.330353,352.208618 442.867004,351.653076 443.716248,351.088562 
	C443.506653,351.669678 442.984467,352.259766 442.127991,352.807007 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M391.189606,375.898621 
	C391.392059,375.305878 391.923279,374.722229 392.780884,374.147217 
	C392.577606,374.739807 392.047974,375.323761 391.189606,375.898621 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M436.273865,354.961456 
	C436.439941,354.355255 436.931244,353.733459 437.751648,353.108887 
	C437.586853,353.719360 437.092957,354.332611 436.273865,354.961456 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M385.276550,379.935638 
	C385.445709,379.337952 385.936432,378.723389 386.755371,378.111603 
	C386.588440,378.715820 386.093262,379.317261 385.276550,379.935638 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M415.301697,362.967468 
	C415.458221,362.364929 415.935944,361.737061 416.742767,361.095093 
	C416.588867,361.701355 416.105896,362.321747 415.301697,362.967468 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M423.218292,359.900513 
	C423.389587,359.297058 423.894775,358.694672 424.718964,358.058350 
	C424.542694,358.650146 424.047455,359.275848 423.218292,359.900513 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M361.765503,401.086731 
	C361.894043,401.314728 361.705780,401.577911 361.207397,401.884857 
	C361.067261,401.653015 361.251068,401.384094 361.765503,401.086731 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M714.902588,437.863220 
	C713.821228,437.514038 712.029419,436.986542 711.794128,436.062561 
	C709.023865,425.185211 700.091980,418.147583 694.978394,408.392303 
	C695.712524,408.301941 696.794128,408.323547 697.192505,408.856140 
	C702.189514,415.537323 707.273132,422.168304 711.909973,429.096313 
	C713.514832,431.494141 713.947327,434.676636 714.902588,437.863220 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M717.969238,444.721680 
	C717.298523,444.277435 716.640015,443.467010 715.964111,442.328186 
	C716.624939,442.784973 717.303223,443.570221 717.969238,444.721680 
z"/>
<path fill="#5D8BB2" opacity="1.000000" stroke="none" 
	d="
M290.169312,447.404480 
	C291.844666,446.685913 293.429443,446.334869 295.442078,446.332031 
	C295.216187,448.426117 294.562408,450.172028 293.600525,452.088684 
	C290.874451,457.740997 288.384766,463.192261 286.058380,468.712341 
	C283.674774,474.368286 280.778687,479.923065 279.299988,485.821198 
	C276.395020,497.408051 274.317230,509.202332 271.620178,521.181885 
	C270.556915,530.635498 269.115417,539.819885 269.141388,549.000122 
	C269.178253,562.035461 270.079498,575.084839 271.105988,588.089172 
	C271.354401,591.236023 273.539917,594.229980 274.825256,597.708252 
	C274.820312,599.917725 274.696930,601.725220 274.864410,603.505432 
	C275.040283,605.374329 275.494965,607.216919 275.864166,609.453003 
	C276.281799,611.553528 276.663086,613.271973 277.172058,615.368835 
	C278.553436,626.520996 282.663483,636.356262 287.138641,646.331177 
	C286.907806,646.802917 286.789459,646.978210 286.671082,647.153442 
	C285.325867,645.786133 283.268738,644.645264 282.750000,643.015015 
	C279.160065,631.732483 275.587067,620.427673 272.584320,608.979004 
	C267.172363,588.344604 265.433228,567.256042 266.132294,545.981262 
	C266.343536,539.552307 267.300629,533.147888 267.857483,525.929932 
	C267.686462,524.381470 267.574097,523.635315 267.553406,522.565857 
	C267.623138,521.796509 267.601196,521.350525 267.926941,520.690918 
	C268.317780,517.945374 268.360840,515.413391 268.528748,512.538513 
	C268.690063,511.466949 268.726532,510.738220 269.097290,509.849243 
	C270.150879,507.071777 270.870178,504.454559 271.651367,501.530518 
	C271.743317,500.807678 271.773376,500.391632 272.111694,499.792389 
	C272.485138,498.685059 272.550354,497.760925 272.691345,496.531311 
	C272.811707,495.808136 272.856262,495.390503 273.225006,494.794312 
	C274.102234,492.908051 274.677185,491.207062 275.204437,489.491425 
	C277.107697,483.298096 278.994873,477.099854 280.888153,470.903442 
	C281.500305,468.515045 282.112488,466.126648 282.773743,463.375244 
	C285.241486,457.932159 287.660095,452.852081 290.169312,447.404480 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M321.181427,705.887207 
	C321.181427,705.887207 321.017792,705.479858 321.029846,704.902100 
	C319.335266,701.237732 317.628662,698.151062 316.286987,695.078308 
	C318.871674,696.142029 321.276398,696.927246 323.260620,698.314087 
	C325.362183,699.783020 327.136749,701.754639 328.922974,703.631531 
	C331.542297,706.383789 333.764099,709.595276 336.686523,711.952515 
	C339.011871,713.828125 345.538910,710.210510 344.814911,707.644897 
	C343.898712,704.398315 342.854431,700.609192 340.549652,698.475281 
	C337.466675,695.620728 334.502655,693.119263 331.761292,689.677246 
	C328.008423,684.965271 327.296692,680.130737 326.296600,675.174622 
	C328.873077,678.430908 330.885223,681.782227 333.462311,684.620544 
	C337.712799,689.301819 342.328430,693.651611 346.870789,698.337646 
	C346.951416,698.534241 347.118683,698.924866 347.084351,699.269409 
	C347.735809,700.020325 348.421600,700.426697 349.353943,700.994812 
	C350.119873,701.741150 350.639252,702.325806 351.069214,703.306946 
	C350.484802,710.854980 340.616180,719.296875 333.259827,718.801514 
	C333.221924,718.773743 333.143829,718.721558 333.150726,718.366211 
	C329.165558,713.969666 325.173492,709.928406 321.181427,705.887207 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M267.579224,520.904480 
	C267.601196,521.350525 267.623138,521.796509 267.193848,522.598877 
	C261.897064,526.045044 264.076721,530.790161 263.908844,534.982178 
	C263.904388,545.346741 263.899933,555.711365 263.810242,566.539429 
	C262.817047,565.789612 261.253876,564.641296 261.126007,563.350647 
	C260.767212,559.729370 261.187439,556.037415 260.952942,552.395691 
	C260.655884,547.782288 259.976929,543.194519 259.557953,538.587036 
	C259.474121,537.665405 259.740753,536.683899 259.987518,535.768127 
	C260.228943,534.872314 260.890869,534.042603 260.955444,533.153931 
	C261.367188,527.487732 261.870514,521.815125 261.923157,516.140747 
	C261.954895,512.716187 263.102631,510.408569 266.823608,509.059692 
	C267.417267,509.032776 267.885223,509.045135 267.984070,509.249023 
	C268.222382,509.745056 268.449066,509.930573 268.763000,510.009491 
	C268.726532,510.738220 268.690063,511.466949 268.184235,512.586914 
	C264.601166,515.567688 265.403320,518.224365 267.579224,520.904480 
z"/>
<path fill="#5D8BB2" opacity="1.000000" stroke="none" 
	d="
M315.922028,695.064453 
	C317.628662,698.151062 319.335266,701.237732 320.969727,704.690918 
	C316.671967,700.134155 311.720520,695.656616 308.367218,690.197388 
	C300.671387,677.668640 293.741852,664.668457 286.559692,651.826904 
	C286.207123,651.196594 286.410095,650.255493 286.900635,649.364563 
	C288.613586,650.202087 289.780212,651.134155 290.956909,652.045654 
	C290.966949,652.025208 290.937408,652.035034 290.936890,652.442627 
	C291.976776,655.733337 292.777893,658.733337 294.114380,661.472046 
	C296.059753,665.458496 298.268280,669.331543 300.583710,673.119019 
	C304.887817,680.159485 309.323730,687.120300 313.795624,694.055664 
	C314.161072,694.622498 315.220947,694.741638 315.958801,695.053772 
	C315.959442,695.038757 315.922028,695.064453 315.922028,695.064453 
z"/>
<path fill="#5D8BB2" opacity="1.000000" stroke="none" 
	d="
M321.102051,706.268921 
	C325.173492,709.928406 329.165558,713.969666 332.811951,718.356201 
	C328.650330,715.471436 324.834351,712.241211 321.018402,709.011047 
	C321.018402,709.011047 321.007507,708.995483 321.000885,708.986450 
	C321.003723,708.201782 321.013184,707.426208 321.102051,706.268921 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M264.208496,534.754517 
	C264.076721,530.790161 261.897064,526.045044 267.102173,522.922180 
	C267.574097,523.635315 267.686462,524.381470 267.746368,525.503418 
	C266.631989,528.761780 265.570068,531.644287 264.208496,534.754517 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M290.946838,652.066162 
	C289.780212,651.134155 288.613586,650.202087 287.141296,649.124390 
	C286.817657,648.571167 286.799652,648.163513 286.726379,647.454651 
	C286.789459,646.978210 286.907806,646.802917 287.458771,646.397156 
	C288.909882,648.133118 289.928375,650.099670 290.946838,652.066162 
z"/>
<path fill="#5D8BB2" opacity="1.000000" stroke="none" 
	d="
M370.661316,713.299316 
	C372.840485,713.528259 374.629883,714.008362 376.391327,713.921509 
	C395.071350,713.000488 408.415375,736.661194 399.469147,751.024170 
	C398.078918,753.256104 397.099792,755.744141 395.895447,758.463501 
	C395.860321,758.813171 395.848053,758.848511 395.504700,758.918213 
	C391.787323,761.401733 388.413300,763.815613 385.039276,766.229492 
	C384.632385,766.255920 384.225525,766.282349 383.102020,766.232056 
	C378.642426,766.285339 374.830780,766.915222 371.174591,766.413208 
	C367.022766,765.843201 363.009949,764.260803 358.936707,763.118530 
	C358.936707,763.118530 358.532196,762.975220 358.332977,762.895752 
	C355.487579,758.332275 352.886536,753.820862 350.185608,749.370117 
	C344.949585,740.741821 349.784607,724.780945 358.748474,719.121155 
	C361.245331,717.544617 363.602295,715.746521 366.157471,713.761292 
	C367.613831,713.410767 368.936218,713.348999 370.661316,713.299316 
M381.187744,764.074280 
	C383.202026,763.015747 385.235413,761.991821 387.227417,760.892944 
	C397.266846,755.354431 402.715027,738.414856 396.606018,729.321655 
	C386.357452,714.066772 371.853455,710.325989 356.445770,724.200317 
	C354.472565,725.977173 352.690186,728.630310 352.113770,731.171082 
	C350.287872,739.219543 350.056793,746.977844 355.657043,754.291931 
	C360.554199,760.687744 372.646973,766.196960 381.187744,764.074280 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M358.919678,763.445923 
	C363.009949,764.260803 367.022766,765.843201 371.174591,766.413208 
	C374.830780,766.915222 378.642426,766.285339 382.802704,766.296204 
	C382.684113,766.942383 382.148254,767.447754 380.912292,768.018555 
	C376.465637,768.073059 372.719086,768.062134 368.972504,768.051147 
	C367.237396,767.709534 365.502258,767.367981 363.180542,766.750610 
	C361.731079,766.323059 360.868195,766.171204 360.005310,766.019348 
	C359.637756,765.270630 359.270203,764.521973 358.919678,763.445923 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M369.032532,768.434021 
	C372.719086,768.062134 376.465637,768.073059 380.616608,768.065430 
	C380.462128,768.862305 379.903229,769.677734 379.174377,770.757080 
	C375.725647,770.173218 371.625641,773.018066 369.032532,768.434021 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M360.043518,766.341919 
	C360.868195,766.171204 361.731079,766.323059 362.795898,766.740112 
	C362.025818,766.891785 361.053802,766.778137 360.043518,766.341919 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M321.002625,709.358276 
	C324.834351,712.241211 328.650330,715.471436 332.805054,718.711548 
	C333.143829,718.721558 333.221924,718.773743 333.189362,719.160889 
	C334.523773,723.643188 337.311340,723.628540 339.957550,721.287292 
	C344.133698,717.592529 348.104858,713.619751 351.788177,709.434937 
	C353.486481,707.505432 354.565430,704.767334 351.158630,702.910400 
	C350.639252,702.325806 350.119873,701.741150 349.322998,700.684326 
	C348.403229,699.783020 347.760956,699.353943 347.118683,698.924866 
	C347.118683,698.924866 346.951416,698.534241 346.934570,697.989502 
	C346.568085,696.344116 346.218445,695.243469 345.868805,694.142822 
	C349.055847,696.818909 352.696075,699.121216 355.299835,702.278320 
	C357.798431,705.307922 355.863953,709.993774 351.645447,713.787659 
	C349.358826,715.844055 347.235443,718.081848 344.624847,720.632324 
	C336.876282,726.117310 336.876282,726.117310 328.987793,720.030396 
	C327.903595,718.708069 326.819397,717.385742 325.407959,716.016846 
	C323.716095,713.881958 322.351471,711.793762 321.002625,709.358276 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M726.995117,600.995117 
	C726.995117,600.995117 726.940491,600.568359 726.927246,600.354980 
	C727.835449,599.434998 728.729126,598.161682 729.682922,598.114929 
	C735.260925,597.841858 740.965332,602.390015 741.928223,608.045166 
	C742.259216,609.989258 746.720398,613.351379 740.225586,614.058838 
	C739.839050,614.096375 739.416931,614.092285 739.166382,613.737000 
	C738.232605,612.949280 737.549438,612.516846 736.866211,612.084412 
	C736.866211,612.084412 736.771851,612.215210 736.807922,611.865845 
	C736.870056,611.057190 736.896118,610.597839 736.944702,609.746887 
	C735.618469,608.250366 734.269775,607.145386 732.662476,605.925171 
	C731.915283,605.249146 731.426636,604.688416 730.951599,603.731750 
	C729.641785,602.555603 728.318481,601.775330 726.995117,600.995117 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M753.625000,641.000000 
	C753.468384,641.867798 752.936707,642.735657 752.405090,643.603455 
	C751.940063,642.965698 751.475037,642.327881 751.005005,641.345093 
	C751.750000,641.000000 752.500000,641.000000 753.625000,641.000000 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M328.989990,720.410034 
	C336.876282,726.117310 336.876282,726.117310 344.557312,720.963562 
	C344.903351,720.900269 344.965363,720.964294 344.949341,721.347412 
	C343.645233,722.858765 342.336304,723.964172 341.074768,725.121338 
	C339.693268,726.388550 338.355560,727.703430 336.998779,728.997559 
	C335.932434,728.998352 334.866089,728.999146 333.399872,729.000000 
	C331.664062,726.263245 330.328156,723.526489 328.989990,720.410034 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M336.999359,729.452515 
	C338.355560,727.703430 339.693268,726.388550 341.074768,725.121338 
	C342.336304,723.964172 343.645233,722.858765 344.980286,721.379517 
	C346.412354,720.133911 347.813477,719.262939 349.178314,718.338501 
	C350.788239,717.248108 352.366821,716.111389 354.571930,715.748169 
	C354.079254,716.659546 353.586578,717.570862 353.084900,718.816406 
	C352.077484,720.871277 351.079132,722.591980 349.655396,724.695679 
	C345.356598,727.722839 345.180878,728.446350 347.798645,730.972839 
	C346.903320,733.067810 346.007965,735.162720 345.089478,737.627563 
	C335.802185,737.887268 337.128693,739.124146 336.999359,729.452515 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M337.735840,741.276367 
	C338.452087,742.226013 338.979828,742.881348 339.352051,743.615417 
	C340.069214,745.029724 340.698425,746.488586 341.364075,747.929016 
	C339.044769,748.345093 336.649475,749.283691 334.437378,748.962097 
	C333.289764,748.795288 332.322479,746.474487 331.542358,744.985962 
	C330.930664,743.818726 330.715179,742.439819 330.354279,741.146423 
	C330.037628,740.011597 329.763458,738.864868 329.470520,737.723389 
	C330.812592,737.831421 332.298187,737.614868 333.460663,738.128906 
	C334.936584,738.781738 336.150330,740.027466 337.735840,741.276367 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M408.739502,753.068115 
	C408.048492,755.425354 407.009216,757.812622 405.948364,760.564087 
	C404.649353,763.045105 403.371887,765.161804 402.030334,767.637512 
	C401.966187,767.996460 401.472473,767.996582 401.230957,767.618958 
	C400.992554,766.494202 400.995667,765.747131 400.998779,765.000000 
	C403.462921,761.032776 405.927063,757.065491 408.739502,753.068115 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M400.689514,764.866333 
	C400.995667,765.747131 400.992554,766.494202 400.984131,767.614563 
	C399.238647,768.296631 397.498505,768.605469 395.379761,768.957153 
	C394.999878,767.276978 394.998505,765.553894 395.102356,763.178589 
	C395.417206,762.248718 395.692352,762.074829 396.033081,762.004578 
	C397.482147,762.913940 398.931213,763.823303 400.689514,764.866333 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M348.108948,730.781250 
	C345.180878,728.446350 345.356598,727.722839 349.579803,725.036743 
	C349.426178,726.859680 348.922729,728.724670 348.108948,730.781250 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M718.000000,646.997559 
	C715.281372,646.664856 712.562744,646.332153 709.420837,645.998535 
	C708.843506,641.745789 711.852966,641.511475 714.875061,641.501343 
	C716.636353,641.495422 718.398682,641.823730 720.580322,642.002075 
	C721.000000,642.751221 721.000000,643.500000 721.000000,644.623169 
	C721.000000,644.997559 721.000000,645.000000 720.677856,645.025635 
	C719.570496,645.699951 718.785217,646.348755 718.000000,646.997559 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M300.707672,430.018005 
	C298.571838,433.978912 296.093353,437.925262 293.262939,441.880280 
	C293.182159,440.487366 293.453308,439.085754 293.873230,437.353271 
	C295.477234,434.787323 296.932495,432.552277 298.691162,430.153839 
	C299.451385,429.994751 299.908203,429.999084 300.707672,430.018005 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M396.017609,761.612244 
	C395.692352,762.074829 395.417206,762.248718 395.096313,762.747925 
	C394.180450,763.222107 393.375824,763.474670 391.949738,763.881592 
	C390.220703,765.028870 389.113129,766.021790 388.005554,767.014771 
	C387.151489,766.918762 386.297424,766.822754 385.241302,766.478149 
	C388.413300,763.815613 391.787323,761.401733 395.492432,758.930908 
	C395.882996,759.655945 395.942566,760.437927 396.017609,761.612244 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M388.220337,767.280396 
	C389.113129,766.021790 390.220703,765.028870 391.658051,764.016235 
	C391.888733,766.265869 391.792480,768.538086 388.220337,767.280396 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M718.204773,647.260620 
	C718.785217,646.348755 719.570496,645.699951 720.676636,645.025635 
	C720.998108,645.749451 720.998718,646.498840 720.998413,647.622925 
	C720.134888,647.839539 719.272217,647.681580 718.204773,647.260620 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M726.034302,464.633240 
	C726.032227,465.179504 725.972412,465.397949 725.780884,465.931702 
	C724.801819,465.006958 723.954529,463.766907 723.173462,462.175812 
	C724.151917,462.651672 725.064270,463.478546 726.034302,464.633240 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M280.559387,470.968140 
	C278.994873,477.099854 277.107697,483.298096 275.204437,489.491425 
	C274.677185,491.207062 274.102234,492.908051 272.954773,494.560974 
	C272.564362,491.998993 272.768372,489.491760 272.972382,486.984558 
	C273.988159,483.601807 275.003906,480.219055 276.029114,476.431885 
	C277.435913,474.362579 278.833252,472.697723 280.559387,470.968140 
z"/>
<path fill="#5FA8C8" opacity="1.000000" stroke="none" 
	d="
M400.081543,566.000732 
	C392.458862,574.182068 383.823151,578.186401 372.703522,575.884521 
	C365.584015,574.410706 355.973206,565.595215 353.855164,558.646851 
	C351.159943,549.804932 351.949585,541.551453 357.011566,533.910645 
	C363.493103,524.127136 373.170990,522.266296 384.067261,523.438110 
	C394.392059,524.548401 405.486725,534.982117 404.874786,547.485718 
	C404.560425,553.908386 405.435120,560.541931 400.081543,566.000732 
M375.914917,526.892334 
	C374.403229,527.522339 372.945374,528.413818 371.370728,528.737793 
	C363.893799,530.275879 355.767426,540.972656 356.618042,550.512024 
	C357.263306,557.748474 364.461090,568.482971 371.506592,570.820740 
	C379.384735,573.434692 393.044708,568.542969 397.380707,561.227783 
	C399.065704,558.385071 400.425262,555.168213 401.112122,551.946777 
	C403.879730,538.966919 388.653717,526.109497 375.914917,526.892334 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M446.950989,428.825134 
	C447.817627,428.675507 448.684265,428.525848 450.132874,428.181030 
	C451.239319,428.127319 451.763794,428.268707 452.216187,428.693970 
	C452.009583,429.553009 451.875031,430.128174 451.499817,430.836639 
	C450.792786,431.259186 450.326416,431.548431 449.497437,431.906433 
	C446.135559,433.287140 443.075806,434.477905 440.150909,435.938660 
	C435.464142,438.279419 430.866669,440.799011 426.162628,443.169189 
	C426.093781,443.095367 425.957520,442.946472 426.085876,442.692139 
	C426.450012,441.923584 426.685791,441.409363 426.921539,440.895081 
	C429.146271,439.418488 431.371033,437.941864 434.142944,436.178650 
	C438.777100,433.536407 442.864044,431.180756 446.950989,428.825134 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M379.957245,497.001190 
	C380.028687,496.602386 380.100128,496.203552 380.452148,495.356934 
	C381.508423,493.974274 382.284119,493.039490 383.396729,492.037567 
	C384.128571,491.224121 384.523468,490.477783 385.166077,489.515900 
	C385.394745,488.450500 385.375671,487.600647 385.356598,486.750763 
	C385.356598,486.750763 385.679932,486.511505 385.962006,486.559265 
	C386.244080,486.606995 386.716522,486.743744 386.859589,486.876892 
	C387.002655,487.010071 387.287079,487.278229 387.217224,487.660156 
	C386.537659,490.240173 386.436279,492.782623 385.223846,494.572449 
	C381.448547,500.145691 382.502808,503.501648 389.227112,505.325500 
	C390.643738,505.723541 391.815643,505.865265 393.179626,506.265686 
	C394.282349,507.786194 395.192993,509.047943 396.078156,510.599365 
	C396.054016,511.082825 396.055298,511.276672 396.056610,511.470490 
	C396.056610,511.470490 395.587402,511.427917 395.145508,511.244354 
	C394.526276,510.990784 394.348969,510.920776 394.086060,510.524902 
	C392.962524,509.542358 391.924622,508.885620 390.662445,508.091064 
	C389.626831,507.966980 388.815460,507.980774 388.004089,507.994568 
	C387.572327,507.888855 387.140594,507.783142 386.172272,507.427429 
	C385.059235,507.180756 384.482727,507.184052 383.906250,507.187347 
	C383.906250,507.187347 383.973114,507.098541 383.961670,506.716431 
	C382.895477,505.249786 381.840759,504.165283 380.809448,502.672363 
	C380.540985,500.509735 380.249115,498.755463 379.957245,497.001190 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M412.160767,497.042480 
	C412.074280,498.051361 411.987762,499.060242 411.569397,500.528412 
	C410.473480,501.984680 409.709381,502.981659 408.945312,503.978638 
	C408.945312,503.978668 408.872650,504.378540 408.555664,504.682739 
	C406.637207,506.360626 405.035736,507.734314 403.252075,508.802612 
	C402.963287,508.286041 402.856659,508.074799 402.832825,507.585876 
	C403.015991,506.935333 403.116364,506.562408 403.553589,506.109406 
	C404.912018,504.007904 405.933624,501.986450 407.018677,499.768860 
	C407.082123,499.572662 407.184753,499.173309 407.530273,499.112915 
	C409.660706,495.786102 411.445648,492.519653 413.423828,489.197510 
	C413.617096,489.141815 413.988892,488.988220 414.295624,488.907104 
	C415.374969,488.529724 416.147583,488.233429 416.963196,488.314117 
	C416.990051,489.448700 416.973877,490.206329 416.957733,490.963959 
	C416.957733,490.963959 416.998352,491.001129 416.644440,491.044983 
	C415.835724,492.041412 415.380951,492.993958 414.926147,493.946503 
	C414.926178,493.946503 415.005829,493.969116 414.632812,493.990692 
	C413.560120,495.022339 412.860443,496.032410 412.160767,497.042480 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M403.000000,448.997955 
	C403.000397,449.748474 403.000793,450.498993 403.001221,451.624756 
	C401.897003,453.964996 400.792755,455.929962 399.346680,457.948669 
	C393.544586,456.508179 394.698761,453.198151 397.287109,449.323730 
	C399.574371,448.996185 401.287201,448.997070 403.000000,448.997955 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M392.981262,473.020538 
	C392.981293,473.020538 393.054626,472.538635 393.416260,472.246094 
	C394.503876,470.955658 395.229889,469.957794 395.955872,468.959961 
	C397.768097,467.079895 399.580353,465.199860 401.954559,463.135406 
	C402.932098,462.942047 403.347687,462.933136 403.824219,463.262756 
	C403.913788,464.057037 403.942444,464.512848 403.886292,465.156189 
	C403.801483,465.343689 403.674988,465.735382 403.366333,465.830017 
	C401.981384,467.215637 400.905090,468.506622 399.699677,470.005371 
	C399.372650,470.477966 399.131927,470.695435 398.520325,470.932892 
	C396.506805,472.710938 394.821381,474.421692 393.066437,476.065002 
	C392.996887,475.997528 392.857330,475.863098 392.899658,475.510437 
	C392.955078,474.445343 392.968170,473.732941 392.981262,473.020538 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M379.590210,497.054382 
	C380.249115,498.755463 380.540985,500.509735 380.584137,502.877991 
	C380.568268,504.692200 380.801086,505.892395 381.033936,507.092621 
	C377.611420,508.168091 376.957916,505.679688 377.130371,503.449066 
	C377.296936,501.294189 378.484253,499.218231 379.590210,497.054382 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M446.737518,428.589661 
	C442.864044,431.180756 438.777100,433.536407 434.325562,435.893494 
	C437.175323,433.439911 440.389679,430.984894 443.803406,428.249634 
	C444.843231,428.097626 445.683624,428.225922 446.737518,428.589661 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M403.763306,462.924225 
	C403.347687,462.933136 402.932098,462.942047 402.204468,462.920013 
	C404.623566,459.898682 407.354706,456.908295 410.570862,453.987732 
	C411.055939,454.057556 411.319061,454.307739 411.290771,454.336060 
	C411.262482,454.364349 411.293060,454.436646 411.003479,454.657715 
	C409.115082,456.834290 407.516266,458.789856 405.867065,461.151123 
	C405.831573,461.829041 405.846466,462.101196 405.877838,462.371582 
	C405.894348,462.369843 405.852325,462.345032 405.792419,462.434143 
	C405.732544,462.523254 405.683105,462.732178 405.362549,462.726746 
	C404.615753,462.788971 404.189545,462.856598 403.763306,462.924225 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M414.659302,450.737549 
	C415.006470,450.309937 415.353607,449.882355 416.178040,449.197723 
	C417.453705,447.985931 418.252167,447.031158 419.050598,446.076385 
	C419.050598,446.076385 419.492462,446.000061 420.066284,445.966064 
	C422.412598,444.936859 424.185059,443.941650 425.957520,442.946472 
	C425.957520,442.946472 426.093781,443.095367 426.049622,443.481079 
	C422.322540,446.524567 418.639557,449.182373 414.900848,451.559509 
	C414.783203,451.098419 414.721252,450.917999 414.659302,450.737549 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M387.951843,480.957245 
	C388.025116,480.550934 388.098389,480.144592 388.534698,479.341431 
	C390.217590,477.917450 391.537476,476.890289 392.857361,475.863098 
	C392.857330,475.863098 392.996887,475.997528 392.990509,476.400116 
	C392.251129,478.447876 391.518127,480.093048 390.690216,481.970520 
	C390.402710,482.473419 390.210083,482.743958 389.694977,483.004700 
	C388.943329,483.020996 388.514160,483.047058 388.049255,482.721375 
	C387.992981,481.898834 387.972412,481.428040 387.951843,480.957245 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M403.434235,509.107971 
	C405.035736,507.734314 406.637207,506.360626 408.545776,504.885712 
	C409.232788,508.321350 407.225616,510.064636 403.709900,510.818848 
	C403.251312,510.618683 403.088287,510.175354 403.160889,509.903320 
	C403.300446,509.456848 403.367340,509.282410 403.434235,509.107971 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M426.085876,442.692139 
	C424.185059,443.941650 422.412598,444.936859 420.288391,445.935486 
	C422.023438,444.276947 424.110199,442.614929 426.559265,440.924011 
	C426.685791,441.409363 426.450012,441.923584 426.085876,442.692139 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M392.899658,475.510437 
	C391.537476,476.890289 390.217590,477.917450 388.601440,479.039062 
	C389.633270,477.116760 390.961395,475.100037 392.635376,473.051941 
	C392.968170,473.732941 392.955078,474.445343 392.899658,475.510437 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M403.088287,510.175354 
	C403.088287,510.175354 403.251312,510.618683 403.366791,510.825500 
	C403.338776,511.450867 403.195282,511.869446 402.595276,512.632690 
	C400.743805,513.022034 399.348877,513.066711 397.953949,513.111328 
	C397.420624,512.701050 396.887299,512.290771 396.205292,511.675507 
	C396.055298,511.276672 396.054016,511.082825 396.451111,510.760834 
	C398.187469,510.686493 399.525421,510.740326 401.157837,510.789673 
	C401.997650,510.581879 402.542969,510.378601 403.088287,510.175354 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M398.004578,513.444336 
	C399.348877,513.066711 400.743805,513.022034 402.555573,512.962891 
	C401.758301,515.745850 400.376617,517.548950 398.004578,513.444336 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M383.059814,492.104675 
	C382.284119,493.039490 381.508423,493.974274 380.452820,495.052551 
	C380.818756,493.471436 381.464569,491.746857 382.518799,489.977234 
	C382.927277,489.932190 383.020325,490.015747 383.013062,490.363678 
	C383.023804,491.175964 383.041809,491.640320 383.059814,492.104675 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M388.084991,483.073151 
	C388.514160,483.047058 388.943329,483.020996 389.719788,483.384399 
	C389.140442,484.941986 388.213745,486.110107 387.287079,487.278229 
	C387.287079,487.278229 387.002655,487.010071 386.740295,486.661591 
	C386.477966,486.313110 386.022675,486.113892 386.022675,486.113892 
	C386.213379,485.652130 386.404083,485.190369 386.874573,484.219177 
	C387.464569,483.497589 387.774780,483.285370 388.084991,483.073151 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M381.406067,507.099731 
	C380.801086,505.892395 380.568268,504.692200 380.560730,503.286377 
	C381.840759,504.165283 382.895477,505.249786 383.976624,506.662903 
	C383.261414,507.029938 382.519806,507.068390 381.406067,507.099731 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M388.019714,508.337463 
	C388.815460,507.980774 389.626831,507.966980 390.686615,508.428406 
	C390.960480,509.586456 390.985931,510.269379 391.011383,510.952271 
	C390.019379,510.194977 389.027374,509.437683 388.019714,508.337463 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M385.049866,486.816132 
	C385.375671,487.600647 385.394745,488.450500 385.019226,489.369751 
	C384.089813,489.631287 383.555054,489.823517 383.020325,490.015747 
	C383.020325,490.015747 382.927277,489.932190 382.878601,489.889282 
	C383.467651,488.858124 384.105408,487.869812 385.049866,486.816132 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M391.242432,510.994751 
	C390.985931,510.269379 390.960480,509.586456 390.910858,508.566223 
	C391.924622,508.885620 392.962524,509.542358 393.715454,510.584473 
	C392.933960,511.007599 392.437500,511.045319 391.941010,511.083069 
	C391.941010,511.083099 391.473450,511.037231 391.242432,510.994751 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M418.705200,446.078766 
	C418.252167,447.031158 417.453705,447.985931 416.313477,448.958191 
	C416.767700,448.010834 417.563751,447.045990 418.705200,446.078766 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M414.309082,450.794891 
	C414.721252,450.917999 414.783203,451.098419 414.579895,451.696442 
	C413.393951,452.809692 412.473206,453.505341 411.495544,454.224304 
	C411.438629,454.247620 411.319061,454.307739 411.319061,454.307739 
	C411.319061,454.307739 411.055939,454.057556 410.920593,453.933167 
	C411.843109,452.823273 412.900970,451.837738 414.309082,450.794891 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M409.299255,503.954102 
	C409.709381,502.981659 410.473480,501.984680 411.565063,500.908447 
	C411.146088,501.862671 410.399628,502.896118 409.299255,503.954102 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M412.487396,496.990662 
	C412.860443,496.032410 413.560120,495.022339 414.595215,493.970398 
	C414.225128,494.931976 413.519562,495.935394 412.487396,496.990662 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M395.617096,468.998413 
	C395.229889,469.957794 394.503876,470.955658 393.449890,472.004700 
	C393.840729,471.049530 394.559540,470.043213 395.617096,468.998413 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M392.002106,511.396362 
	C392.437500,511.045319 392.933960,511.007599 393.801025,510.910309 
	C394.348969,510.920776 394.526276,510.990784 394.910522,511.261200 
	C394.099365,511.544281 393.081268,511.626953 392.002106,511.396362 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M388.049255,482.721375 
	C387.774780,483.285370 387.464569,483.497589 386.902527,483.844238 
	C386.874420,483.004333 387.098145,482.029968 387.636841,481.006409 
	C387.972412,481.428040 387.992981,481.898834 388.049255,482.721375 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M415.201538,493.867340 
	C415.380951,492.993958 415.835724,492.041412 416.627106,491.024414 
	C416.977081,491.412598 416.990448,491.865173 417.000061,492.657135 
	C416.489838,493.260406 415.983368,493.524292 415.201538,493.867340 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M383.960205,507.481445 
	C384.482727,507.184052 385.059235,507.180756 385.840790,507.389648 
	C385.368622,507.659760 384.691406,507.717651 383.960205,507.481445 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M386.023132,486.137939 
	C386.022675,486.113892 386.477966,486.313110 386.597229,486.528442 
	C386.716522,486.743744 386.244080,486.606995 386.094360,486.407837 
	C385.944641,486.208649 386.023590,486.161987 386.023132,486.137939 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M360.002838,500.666992 
	C358.776428,494.778381 357.891815,489.335358 361.325012,484.205902 
	C368.888123,488.267578 369.144836,497.419525 361.717285,505.096985 
	C361.082733,503.594055 360.551758,502.336395 360.002838,500.666992 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M397.002441,448.000000 
	C395.230621,448.605804 393.482666,449.298798 391.680023,449.791840 
	C390.042053,450.239807 387.355194,451.251404 386.872223,450.639679 
	C385.745483,449.212524 384.902191,446.722321 385.400269,445.113525 
	C385.808533,443.794800 388.217773,442.758789 389.929443,442.291870 
	C391.192535,441.947357 392.760742,442.721527 394.747620,443.308167 
	C395.868988,445.079407 396.435699,446.539703 397.002441,448.000000 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M397.003113,448.247864 
	C396.435699,446.539703 395.868988,445.079407 395.148682,443.308960 
	C397.279602,441.776764 399.520355,440.447418 401.906586,439.475098 
	C402.189301,439.359894 403.770477,441.146332 403.872925,442.141541 
	C404.085846,444.209961 403.710510,446.338989 403.288483,448.720520 
	C401.287201,448.997070 399.574371,448.996185 397.433838,448.993408 
	C397.006104,448.991455 397.003784,448.495728 397.003113,448.247864 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M429.000610,433.999390 
	C426.469757,433.521667 423.938293,433.044556 421.456421,432.576782 
	C420.420807,428.921600 422.726959,426.539398 425.106781,426.972076 
	C426.729279,427.267059 427.722961,431.020599 428.998291,433.614502 
	C428.998779,434.001221 429.000000,434.000000 429.000610,433.999390 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M356.851929,502.679504 
	C356.212311,502.705536 355.510254,502.513000 355.150879,502.052856 
	C355.007263,501.868958 355.619446,501.094818 355.887634,500.589386 
	C356.234070,501.177429 356.580505,501.765503 356.851929,502.679504 
z"/>
<path fill="#5776A2" opacity="1.000000" stroke="none" 
	d="
M393.713318,634.219788 
	C388.320374,624.739380 383.003998,615.622314 378.024628,606.324829 
	C376.329346,603.159424 375.329132,599.585327 374.295929,596.113281 
	C373.469757,593.336914 374.525146,591.245239 377.427795,590.262024 
	C382.126587,588.670410 386.754456,586.838501 391.518311,585.479797 
	C395.133972,584.448608 397.568848,584.804138 399.267212,589.573853 
	C406.618805,610.220642 418.586884,627.624634 436.172577,641.268555 
	C448.740173,651.019165 462.649170,657.544312 477.814270,661.316650 
	C489.435394,664.207397 501.377197,664.531616 513.438843,663.594299 
	C523.153442,662.839233 532.458252,661.161621 541.653687,657.973694 
	C543.250793,657.420044 546.983459,659.759827 548.080505,661.697144 
	C550.511475,665.989685 551.924133,670.864441 553.695862,675.523132 
	C555.033203,679.039795 553.205078,681.092590 550.095154,681.896301 
	C540.479553,684.381287 530.904358,687.947510 521.132690,688.678467 
	C507.813232,689.674805 494.215576,689.413513 480.944702,687.887329 
	C468.377747,686.441956 456.303131,682.316467 444.687897,676.655884 
	C430.778839,669.877380 418.439697,661.236084 407.725281,650.230652 
	C402.867615,645.241028 398.507629,639.766846 393.713318,634.219788 
M380.196594,593.005432 
	C376.678436,596.240051 377.231476,598.578308 381.875031,600.581482 
	C381.875031,600.581482 381.910858,600.969849 381.895508,601.797791 
	C382.540833,604.234009 383.186127,606.670288 383.869904,609.871887 
	C384.848572,610.955017 385.827209,612.038147 386.851074,613.930115 
	C385.969910,618.225769 388.661377,620.327332 391.910583,622.851379 
	C392.890533,624.307922 393.870514,625.764404 394.854950,627.983032 
	C398.596069,633.361511 402.017517,639.005127 406.184998,644.030029 
	C409.218750,647.687988 413.200989,650.571411 416.845398,653.701233 
	C417.641968,654.385315 418.827667,654.597473 419.784241,655.118652 
	C421.033813,655.799438 422.976440,656.313232 423.335144,657.345642 
	C425.748291,664.290649 432.746124,665.960449 437.884430,668.798523 
	C445.525909,673.019165 452.919952,678.644897 462.230438,679.335022 
	C462.840363,679.380310 463.384491,680.028564 464.011688,680.182251 
	C465.595947,680.570496 467.209167,680.841797 468.812622,681.150269 
	C470.885010,681.548950 473.035339,681.712097 475.016418,682.379517 
	C478.865295,683.676147 482.136444,687.323059 487.061829,685.861145 
	C488.570557,686.247070 490.072296,686.931091 491.589264,686.966614 
	C497.704498,687.109924 503.826996,687.166870 509.941650,687.027954 
	C513.670532,686.943237 517.390015,686.443420 521.865234,686.031616 
	C531.541321,683.272766 541.217407,680.513855 550.779663,677.787415 
	C549.731873,674.669678 549.337769,671.918884 547.962219,669.807556 
	C546.281372,667.227600 544.071838,663.768494 541.558838,663.245300 
	C537.920593,662.487854 533.417480,663.187683 529.986206,664.780273 
	C525.143188,667.027954 520.425781,668.230347 515.160889,667.921997 
	C512.686584,667.777039 510.170410,668.317017 507.699371,668.152832 
	C499.359375,667.598633 490.916687,667.497742 482.733490,666.011169 
	C475.825806,664.756226 468.729187,663.870728 462.329865,659.983398 
	C456.310852,656.327087 449.479553,654.029358 443.138550,650.867249 
	C441.477448,650.038940 440.406494,648.101807 438.900146,646.875854 
	C430.774963,640.262939 422.060699,634.257202 414.647491,626.931091 
	C410.435883,622.768982 408.399597,616.435852 405.203278,611.190369 
	C404.571198,610.153076 403.115234,609.617737 401.721985,608.147522 
	C400.728882,604.059814 401.510254,599.378784 397.451599,596.295410 
	C396.835175,595.827087 397.140564,594.145447 397.041901,592.257568 
	C395.691986,586.536377 392.560181,589.909668 389.082764,590.888611 
	C386.366119,591.548706 383.649445,592.208801 380.196594,593.005432 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M464.995300,650.002441 
	C468.085938,650.042725 471.193939,649.892395 474.262726,650.172241 
	C477.980072,650.511230 480.033875,652.930054 480.914398,656.819580 
	C479.546326,657.153931 478.180389,657.108276 476.399872,656.660461 
	C475.577087,652.450745 472.651245,652.428162 469.828400,651.934570 
	C468.155884,651.642090 466.602325,650.669312 464.995300,650.002441 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M504.389740,660.998779 
	C504.030212,660.479370 503.874115,659.842346 504.123474,659.506226 
	C505.736572,657.331665 516.059204,657.263306 517.994019,659.746704 
	C513.627014,660.427185 509.203827,660.723755 504.389740,660.998779 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M464.595947,650.002441 
	C466.602325,650.669312 468.155884,651.642090 469.828400,651.934570 
	C472.651245,652.428162 475.577087,652.450745 475.988007,656.649048 
	C471.203766,655.159058 466.416779,653.278503 461.322052,651.242188 
	C461.011200,650.905334 461.008057,650.724304 461.003052,650.271606 
	C462.066345,650.000793 463.131500,650.001648 464.595947,650.002441 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M389.017517,633.620544 
	C387.020569,631.235046 385.021423,628.473755 383.044189,625.326538 
	C385.049164,627.708740 387.032227,630.476685 389.017517,633.620544 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M683.997559,646.995117 
	C684.488586,648.679321 684.979614,650.363525 685.547363,652.310730 
	C683.679932,654.969055 681.660767,657.843567 679.322021,660.861572 
	C676.670410,655.580322 675.941284,650.140747 678.863098,644.672607 
	C679.173950,644.090759 679.630493,643.563599 679.831177,642.949585 
	C682.172302,635.785767 685.789429,634.094666 692.861755,636.942688 
	C696.690491,638.484314 700.496643,640.082031 704.945496,641.915161 
	C704.693481,643.180176 704.390503,644.701172 704.040283,646.603760 
	C701.018250,646.679321 698.043457,646.373291 694.408752,645.999329 
	C693.713745,646.375854 692.228638,647.180420 690.428772,647.641235 
	C690.722473,646.138733 691.330933,644.980103 691.939392,643.821472 
	C691.521423,643.334290 691.103516,642.847046 690.685547,642.359863 
	C688.456238,643.904968 686.226868,645.450012 683.997559,646.995117 
z"/>
<path fill="#107CB2" opacity="1.000000" stroke="none" 
	d="
M675.985718,427.028442 
	C675.721130,426.526764 675.456543,426.025116 675.066956,424.904846 
	C672.599121,421.887451 670.256287,419.488678 667.913452,417.089905 
	C658.703735,404.213898 646.458923,394.517883 634.158813,384.881042 
	C631.465881,382.771149 628.526794,380.908875 626.148926,378.491943 
	C624.999451,377.323486 624.888000,375.133728 624.926636,373.211365 
	C626.998291,374.284607 628.367554,375.665222 629.917786,376.798462 
	C633.444397,379.376465 637.205750,381.652771 640.594116,384.394653 
	C643.400696,386.665741 645.640076,389.638336 648.449097,391.905762 
	C650.697510,393.720673 653.342834,396.012451 655.955383,396.211670 
	C661.894958,396.664551 663.095642,400.056152 664.845093,404.970001 
	C667.447876,412.280579 676.920776,415.332214 678.135437,424.064087 
	C678.224182,424.701599 679.567932,425.163879 680.327393,425.708801 
	C681.250061,426.370911 682.534668,426.833710 683.031250,427.731812 
	C685.158386,431.579681 686.898438,435.647034 689.124268,439.431671 
	C689.819641,440.614136 691.597351,441.160004 693.051758,442.109680 
	C695.011047,438.053040 696.696838,434.562592 698.555786,430.713654 
	C694.740112,430.065491 692.016052,429.602783 690.669556,429.374054 
	C691.237976,424.507233 691.682739,420.699585 692.127502,416.891907 
	C693.850830,418.557648 696.030701,419.950073 697.219360,421.935913 
	C702.721375,431.127716 708.079956,440.412170 713.223328,449.809387 
	C716.253479,455.345703 718.933899,461.088989 721.487854,466.864685 
	C723.093567,470.496185 721.362793,472.196808 717.554321,472.081879 
	C712.625000,471.933105 707.624634,472.248505 702.804565,471.454651 
	C701.232727,471.195770 699.899353,468.213226 698.852478,466.263916 
	C694.500183,458.159698 690.298950,449.974365 685.990479,441.076355 
	C685.552307,433.706055 681.120789,430.104126 675.985718,427.028442 
z"/>
<path fill="#068CC3" opacity="1.000000" stroke="none" 
	d="
M692.116699,416.906860 
	C691.682739,420.699585 691.237976,424.507233 690.669556,429.374054 
	C692.016052,429.602783 694.740112,430.065491 698.555786,430.713654 
	C696.696838,434.562592 695.011047,438.053040 693.051758,442.109680 
	C691.597351,441.160004 689.819641,440.614136 689.124268,439.431671 
	C686.898438,435.647034 685.158386,431.579681 683.031250,427.731812 
	C682.534668,426.833710 681.250061,426.370911 680.327393,425.708801 
	C679.567932,425.163879 678.224182,424.701599 678.135437,424.064087 
	C676.920776,415.332214 667.447876,412.280579 664.845093,404.970001 
	C663.095642,400.056152 661.894958,396.664551 655.955383,396.211670 
	C653.342834,396.012451 650.697510,393.720673 648.449097,391.905762 
	C645.640076,389.638336 643.400696,386.665741 640.594116,384.394653 
	C637.205750,381.652771 633.444397,379.376465 629.917786,376.798462 
	C628.367554,375.665222 626.998291,374.284607 625.172546,372.945496 
	C625.689331,371.034821 626.580383,369.195007 628.084229,367.146545 
	C629.351868,366.487640 630.283630,366.185333 630.616455,365.562866 
	C632.670837,361.720306 634.793945,361.185272 638.219910,364.375580 
	C640.387329,366.393829 643.464111,367.435394 646.133057,368.915039 
	C646.133057,368.915039 646.564819,368.939240 646.827515,369.308319 
	C648.316711,371.182800 649.321472,372.970734 650.811584,374.140167 
	C655.128967,377.528198 659.641296,380.667755 664.077148,383.904816 
	C664.077148,383.904816 664.040100,383.920105 664.139771,384.166107 
	C664.435364,384.705505 664.707886,384.878021 665.056946,384.929626 
	C665.056946,384.929626 665.082458,384.956543 665.102661,385.292175 
	C666.077454,386.091583 667.032043,386.555328 667.986633,387.019104 
	C667.986633,387.019104 668.048828,387.444092 668.061401,388.018066 
	C669.737366,390.040527 671.400696,391.489014 673.064026,392.937500 
	C673.064026,392.937500 673.000000,393.000000 673.056335,393.318176 
	C674.057983,394.100281 675.003235,394.564209 675.948547,395.028137 
	C676.980469,396.419830 678.012390,397.811523 679.062744,399.911133 
	C680.057861,402.419830 681.034485,404.220612 681.969482,406.380493 
	C680.523071,412.135406 685.348938,416.975739 692.084229,416.926270 
	C692.084229,416.926270 692.105957,416.921844 692.116699,416.906860 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M665.247742,418.781647 
	C668.504761,422.199738 671.761780,425.617798 675.424316,429.402649 
	C675.914917,430.157562 676.000061,430.545776 676.069214,431.288208 
	C677.665161,433.443024 679.277039,435.243652 680.898560,437.066650 
	C680.908203,437.088989 680.929565,437.132812 680.929565,437.132812 
	C682.598450,440.524231 684.267334,443.915649 685.590820,447.632629 
	C682.826294,444.978333 680.407227,441.998444 677.722839,438.817444 
	C673.310364,433.076172 669.163208,427.535980 665.022827,421.627869 
	C664.999207,420.526093 664.968811,419.792328 665.014771,418.988220 
	C665.091125,418.917938 665.247742,418.781647 665.247742,418.781647 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M700.004028,474.985779 
	C698.598877,474.348572 697.193665,473.711365 695.451416,473.019989 
	C694.252930,470.816010 693.391602,468.666199 692.304810,465.895325 
	C691.430054,464.482178 690.780823,463.690063 690.131592,462.897949 
	C690.131592,462.897949 690.026428,462.887909 690.138306,462.551758 
	C689.877625,460.832642 689.632385,459.398071 689.098022,458.080719 
	C688.526123,456.670502 687.681702,455.370789 686.940674,453.661804 
	C686.719543,452.137238 686.513977,450.973694 686.308472,449.810120 
	C689.815186,455.853668 693.906921,461.652039 696.585205,468.042725 
	C697.953369,471.307465 699.524841,472.825806 702.559082,474.076782 
	C701.703308,474.742859 700.853699,474.864319 700.004028,474.985779 
z"/>
<path fill="#107CB2" opacity="1.000000" stroke="none" 
	d="
M692.087036,416.556427 
	C685.348938,416.975739 680.523071,412.135406 682.284058,406.348572 
	C683.115662,405.585571 683.591187,405.213623 684.066711,404.841675 
	C686.741089,408.623322 689.415527,412.404938 692.087036,416.556427 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M664.035645,383.565948 
	C659.641296,380.667755 655.128967,377.528198 650.811584,374.140167 
	C649.321472,372.970734 648.316711,371.182800 647.041504,369.339844 
	C652.659790,373.743896 658.326965,378.485504 664.035645,383.565948 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M648.025330,406.974213 
	C647.032043,405.227753 646.038757,403.481293 645.040894,401.348969 
	C645.036316,400.963104 645.127380,400.871246 645.494263,400.902344 
	C646.600769,400.913208 647.340271,400.893005 648.079834,400.872803 
	C648.079834,400.872803 648.072144,400.931946 648.085327,401.280518 
	C650.358765,405.177124 652.059692,409.326630 657.042236,409.947968 
	C657.042236,409.947968 657.126526,410.371674 657.209106,410.925598 
	C658.865845,413.646851 660.440002,415.814178 662.014160,417.981506 
	C661.208313,417.736694 660.402527,417.491882 659.154968,416.816681 
	C655.150635,413.248962 651.587952,410.111572 648.025330,406.974213 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M646.061523,368.575989 
	C643.464111,367.435394 640.387329,366.393829 638.219910,364.375580 
	C634.793945,361.185272 632.670837,361.720306 630.616455,365.562866 
	C630.283630,366.185333 629.351868,366.487640 628.345398,366.968567 
	C630.503296,359.601868 633.376404,358.543488 639.756897,362.923370 
	C641.995483,364.460144 643.921265,366.452606 646.061523,368.575989 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M676.085205,430.933960 
	C676.000061,430.545776 675.914917,430.157562 675.749023,429.482544 
	C675.760864,428.716980 675.853455,428.238281 675.965942,427.393982 
	C681.120789,430.104126 685.552307,433.706055 685.901855,440.736938 
	C684.458618,439.792694 683.052307,438.445374 681.287842,437.115417 
	C680.929565,437.132812 680.908203,437.088989 680.919434,436.709442 
	C679.315430,434.531250 677.700317,432.732605 676.085205,430.933960 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M686.243530,449.512268 
	C686.513977,450.973694 686.719543,452.137238 686.606445,453.606079 
	C683.862122,451.270050 681.436401,448.628784 679.010620,445.987488 
	C678.692383,445.203033 678.374084,444.418549 678.031006,442.917755 
	C678.000183,441.140442 677.994141,440.079498 677.988159,439.018524 
	C680.407227,441.998444 682.826294,444.978333 685.589600,447.988495 
	C686.015442,448.417328 686.097046,448.815887 686.243530,449.512268 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M657.022339,409.604248 
	C652.059692,409.326630 650.358765,405.177124 648.113647,401.271301 
	C651.086731,403.695831 654.044617,406.478180 657.022339,409.604248 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M720.016052,477.990021 
	C717.066467,477.961426 714.116882,477.932800 711.358765,477.463196 
	C712.034546,476.697693 712.518799,476.373230 713.002991,476.048767 
	C715.006775,476.077118 717.010620,476.105469 719.468384,476.463989 
	C719.953552,477.192749 719.984802,477.591400 720.016052,477.990021 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M700.049255,475.365326 
	C700.853699,474.864319 701.703308,474.742859 702.760498,474.377197 
	C703.391846,474.135864 703.815674,474.138733 704.633179,474.490295 
	C706.018738,475.582733 707.010681,476.326508 708.002563,477.070251 
	C705.092712,478.262299 702.205444,479.319580 700.049255,475.365326 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M667.966980,417.429443 
	C670.256287,419.488678 672.599121,421.887451 674.967346,424.639130 
	C672.668640,422.584320 670.344543,420.176636 667.966980,417.429443 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M662.009155,417.989502 
	C660.440002,415.814178 658.865845,413.646851 657.236938,411.139771 
	C659.812256,413.240356 662.442383,415.680634 665.160095,418.451294 
	C665.247742,418.781647 665.091125,418.917938 664.707764,418.932892 
	C663.555542,418.628693 662.786560,418.309509 662.017639,417.990356 
	C662.017639,417.990356 662.004089,417.997467 662.009155,417.989502 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M639.053406,396.299438 
	C639.819153,395.940735 640.603821,395.933868 641.777100,395.850647 
	C642.937744,396.403687 643.709900,397.033051 644.715881,398.214417 
	C645.008911,399.468018 645.068176,400.169617 645.127380,400.871246 
	C645.127380,400.871246 645.036316,400.963104 644.990112,401.009583 
	C642.986755,399.587769 641.029602,398.119507 639.053406,396.299438 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M684.017090,404.513336 
	C683.591187,405.213623 683.115662,405.585571 682.325623,405.989441 
	C681.034485,404.220612 680.057861,402.419830 679.078308,400.258911 
	C680.706116,401.327515 682.336853,402.756256 684.017090,404.513336 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M708.371521,477.053955 
	C707.010681,476.326508 706.018738,475.582733 704.948364,474.531036 
	C707.432556,474.628204 709.995117,475.033295 712.780396,475.743591 
	C712.518799,476.373230 712.034546,476.697693 711.249390,477.093262 
	C710.212463,477.122131 709.476440,477.079926 708.371521,477.053955 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M673.021362,392.598572 
	C671.400696,391.489014 669.737366,390.040527 668.074463,388.232422 
	C669.709534,389.335052 671.344116,390.797333 673.021362,392.598572 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M720.418701,477.985474 
	C719.984802,477.591400 719.953552,477.192749 719.895264,476.498291 
	C721.716919,476.176270 723.565613,476.150055 725.735840,476.310425 
	C724.312012,476.991638 722.566711,477.486267 720.418701,477.985474 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M645.494263,400.902344 
	C645.068176,400.169617 645.008911,399.468018 644.951172,398.413635 
	C645.960632,398.790161 646.968628,399.519409 648.028259,400.560730 
	C647.340271,400.893005 646.600769,400.913208 645.494263,400.902344 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M667.866577,386.721252 
	C667.032043,386.555328 666.077454,386.091583 665.106934,385.273895 
	C665.976196,385.421112 666.861389,385.922272 667.866577,386.721252 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M675.833252,394.729584 
	C675.003235,394.564209 674.057983,394.100281 673.086670,393.285889 
	C673.946472,393.433990 674.832214,393.932526 675.833252,394.729584 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M664.966431,384.701111 
	C664.707886,384.878021 664.435364,384.705505 664.155151,384.178833 
	C664.412537,384.009460 664.680847,384.185150 664.966431,384.701111 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M654.391724,432.989014 
	C651.299988,433.000946 648.599976,433.000641 645.449951,433.000793 
	C643.880310,432.843658 641.858459,432.882874 641.774841,432.499298 
	C640.269653,425.599060 630.737793,422.474182 633.000000,413.436981 
	C639.128235,413.364899 642.118835,410.471588 641.093689,403.389832 
	C645.010925,405.695282 646.119080,407.891998 645.467590,411.221771 
	C644.873352,414.258606 644.169861,417.430450 644.507629,420.431000 
	C644.691895,422.068390 646.937561,423.473755 648.961670,424.990906 
	C650.113220,424.999115 650.556580,425.000183 651.000000,425.001221 
	C652.083069,425.683380 653.250549,426.264160 654.225769,427.075806 
	C655.234070,427.914856 656.612488,428.889862 656.783691,429.970947 
	C656.922241,430.845337 655.508606,431.965607 654.391724,432.989014 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M677.722839,438.817444 
	C677.994141,440.079498 678.000183,441.140442 678.004333,442.599609 
	C676.663086,442.471802 675.323792,441.945862 673.810669,441.351654 
	C672.690857,444.089661 671.560547,446.853333 670.430237,449.617004 
	C669.929260,449.594452 669.428345,449.571930 668.927368,449.549377 
	C668.557556,444.362274 668.560974,439.107819 667.642212,434.019836 
	C667.251099,431.853699 665.057617,429.921417 663.424744,428.116760 
	C662.781982,427.406433 661.433472,427.334717 660.295166,426.932922 
	C660.956116,425.290710 661.478577,423.992767 662.387573,422.339905 
	C663.521484,421.988556 664.268738,421.992218 665.015991,421.995850 
	C669.163208,427.535980 673.310364,433.076172 677.722839,438.817444 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M651.420898,424.999939 
	C650.556580,425.000183 650.113220,424.999115 649.337341,424.997192 
	C647.994080,422.807526 646.983337,420.618744 645.991150,418.470154 
	C651.111389,414.844086 651.111389,414.844086 648.052124,407.340515 
	C651.587952,410.111572 655.150635,413.248962 658.860168,416.687500 
	C656.931641,416.748749 654.856140,416.508850 651.955933,416.173645 
	C652.810120,417.562103 653.315979,418.410156 653.847046,419.242096 
	C654.811584,420.752838 655.791199,422.253998 656.764526,423.759155 
	C655.123596,424.172302 653.482727,424.585510 651.420898,424.999939 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M679.046570,446.362427 
	C681.436401,448.628784 683.862122,451.270050 686.622070,453.967102 
	C687.681702,455.370789 688.526123,456.670502 689.098022,458.080719 
	C689.632385,459.398071 689.877625,460.832642 690.189819,462.539795 
	C688.492615,463.480042 686.855713,464.096130 684.993530,464.796936 
	C683.032349,458.804993 681.057434,452.771179 679.046570,446.362427 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M665.022827,421.627869 
	C664.268738,421.992218 663.521484,421.988556 662.401733,421.976440 
	C662.017456,420.907684 662.005554,419.847382 662.005615,418.388733 
	C662.786560,418.309509 663.555542,418.628693 664.631470,419.003174 
	C664.968811,419.792328 664.999207,420.526093 665.022827,421.627869 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M690.092407,463.264526 
	C690.780823,463.690063 691.430054,464.482178 692.081299,465.630371 
	C691.406616,465.201355 690.729980,464.416229 690.092407,463.264526 
z"/>
<path fill="#070707" opacity="1.000000" stroke="none" 
	d="
M581.217041,511.807678 
	C583.808472,511.664185 586.399841,511.520660 589.375732,511.886292 
	C590.920837,512.330505 592.081421,512.265564 593.505188,512.181213 
	C594.119202,512.178833 594.469971,512.195862 594.820801,512.212830 
	C595.259460,515.808594 596.065125,519.403015 596.078552,523.000366 
	C596.183411,551.097778 596.165833,579.195923 596.088013,607.293579 
	C596.080566,609.969910 597.503113,613.599060 592.605347,614.081543 
	C585.588806,614.772705 583.969299,614.029236 582.498718,608.828003 
	C582.421265,608.553772 582.057861,608.360474 581.483521,608.010376 
	C581.125732,596.932312 581.112061,585.973328 581.470215,574.424316 
	C583.052002,570.093445 584.339050,566.347656 581.141296,562.888977 
	C581.136841,560.916382 581.132385,558.943787 581.510986,556.406860 
	C582.231384,552.631042 582.435913,549.398560 582.940918,546.213684 
	C583.523376,542.539795 581.561462,540.901001 578.423340,540.443909 
	C574.862305,539.925293 574.539734,542.617371 574.310364,545.200256 
	C574.310364,545.200256 574.249451,545.247192 573.863037,545.214844 
	C572.557190,546.567566 571.637756,547.952637 570.718384,549.337708 
	C570.560608,549.717346 570.402832,550.097046 569.784546,550.755493 
	C566.650879,554.753601 563.977661,558.472900 561.304504,562.192261 
	C561.304504,562.192261 561.252197,562.248413 560.874268,562.232910 
	C557.942627,566.097778 555.388916,569.978027 552.495361,573.972351 
	C551.244141,575.478516 550.332581,576.870605 549.421021,578.262756 
	C549.421082,578.262756 549.244751,578.667908 548.795776,578.871826 
	C546.613159,579.957275 544.879456,580.838806 543.466980,581.557007 
	C541.208252,580.276794 539.140747,579.104980 537.073303,577.933167 
	C537.073303,577.933167 536.956665,577.532227 536.939575,576.973877 
	C534.303589,573.256653 531.684692,570.097839 529.065857,566.939026 
	C529.065857,566.939026 528.937866,566.540649 528.942261,565.970764 
	C528.269836,564.139648 527.720520,562.786438 526.890137,561.636047 
	C525.058655,559.098633 522.108948,556.905945 521.418640,554.124878 
	C520.565918,550.689514 518.968933,549.449768 515.920105,549.087097 
	C515.814697,548.691528 515.709290,548.295898 515.651855,547.271851 
	C514.592041,545.117188 513.759644,543.194458 512.297241,542.178833 
	C510.896851,541.206238 508.031250,540.261108 507.262573,540.942688 
	C506.070587,541.999451 506.112091,544.447388 505.630615,546.305542 
	C505.558807,567.142761 505.487000,587.979980 505.349274,609.660278 
	C505.360657,610.763855 505.437988,611.024353 505.515320,611.284851 
	C505.400024,614.135803 504.070374,615.117371 501.092621,615.140747 
	C490.702881,615.222046 490.068787,615.374390 489.703156,605.390686 
	C489.076630,588.282776 489.225433,571.142395 489.254547,554.015991 
	C489.275391,541.756042 489.721527,529.497070 489.779572,517.236694 
	C489.795044,513.974792 490.932343,512.735535 494.157104,512.961609 
	C496.971954,513.158875 499.832611,513.247986 502.633789,512.977966 
	C507.928650,512.467590 511.510437,514.785706 514.044312,519.893188 
	C522.843323,532.906799 531.576294,545.262939 540.584534,557.414917 
	C541.230530,558.286255 544.675476,558.430420 545.478210,557.606445 
	C549.111389,553.877380 552.419006,549.794067 555.513672,545.594910 
	C556.834045,543.803223 557.347290,541.416626 558.320557,539.153198 
	C558.413818,539.005920 558.635010,538.735779 559.006897,538.817871 
	C561.541016,536.424988 563.802734,534.029175 565.846008,531.459656 
	C571.021973,524.950562 576.099854,518.363525 581.217041,511.807678 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M558.227356,539.300415 
	C557.347290,541.416626 556.834045,543.803223 555.513672,545.594910 
	C552.419006,549.794067 549.111389,553.877380 545.478210,557.606445 
	C544.675476,558.430420 541.230530,558.286255 540.584534,557.414917 
	C531.576294,545.262939 522.843323,532.906799 514.095947,520.241211 
	C514.615356,519.866943 515.082153,519.851868 515.882935,519.722290 
	C521.621643,526.885559 527.032715,534.158569 532.428223,541.443176 
	C535.384094,545.433838 538.163452,549.572021 541.377930,553.340454 
	C542.045837,554.123535 544.938599,554.236816 545.678528,553.510376 
	C548.373413,550.864624 550.590454,547.732178 553.431030,544.378662 
	C554.288818,543.040405 554.703247,542.106628 555.276123,540.894775 
	C556.365479,540.177856 557.296448,539.739136 558.227356,539.300415 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M515.940002,549.440796 
	C518.968933,549.449768 520.565918,550.689514 521.418640,554.124878 
	C522.108948,556.905945 525.058655,559.098633 526.890137,561.636047 
	C527.720520,562.786438 528.269836,564.139648 528.877441,565.771790 
	C524.525513,560.693298 520.242676,555.243835 515.940002,549.440796 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M505.904480,546.144897 
	C506.112091,544.447388 506.070587,541.999451 507.262573,540.942688 
	C508.031250,540.261108 510.896851,541.206238 512.297241,542.178833 
	C513.759644,543.194458 514.592041,545.117188 515.540222,546.985718 
	C514.036743,546.472961 512.479980,545.827209 511.391327,544.720886 
	C508.783630,542.070862 507.389343,543.408020 505.904480,546.144897 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M537.049072,578.286682 
	C539.140747,579.104980 541.208252,580.276794 543.466980,581.557007 
	C544.879456,580.838806 546.613159,579.957275 548.705811,579.073608 
	C547.203491,580.939392 544.787048,584.652222 543.595642,584.293701 
	C541.067261,583.532959 539.176880,580.651917 537.049072,578.286682 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M513.769531,551.676636 
	C512.852051,553.093506 511.898743,554.170898 510.584717,555.623779 
	C509.563293,555.965271 508.902649,555.931213 508.242004,555.897156 
	C508.162354,555.199158 508.082703,554.501160 507.995850,553.134521 
	C507.966705,552.255981 507.944794,552.046143 507.922913,551.836243 
	C508.363556,550.718201 508.804199,549.600159 509.244843,548.482117 
	C510.741150,549.433777 512.237427,550.385437 513.769531,551.676636 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M529.050110,567.297546 
	C531.684692,570.097839 534.303589,573.256653 536.874939,576.775269 
	C534.229675,573.975464 531.632019,570.815735 529.050110,567.297546 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M508.143341,556.323730 
	C508.902649,555.931213 509.563293,555.965271 510.553772,556.007935 
	C510.896942,557.393005 510.910278,558.769470 510.888733,560.557190 
	C510.195129,560.999878 509.536407,561.031311 508.549561,561.080811 
	C508.162567,559.649292 508.103638,558.199829 508.143341,556.323730 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M549.714844,578.165161 
	C550.332581,576.870605 551.244141,575.478516 552.602905,574.060791 
	C553.050171,574.035217 553.266174,574.211060 553.205200,574.551086 
	C553.195496,575.345520 553.246765,575.799927 553.298096,576.254333 
	C553.298096,576.254333 553.123657,576.607056 553.001099,576.760986 
	C551.921875,577.299194 550.965210,577.683411 549.714844,578.165161 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M505.726196,611.193848 
	C505.437988,611.024353 505.360657,610.763855 505.384125,610.106689 
	C505.635620,610.174255 505.786316,610.638550 505.726196,611.193848 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M507.719910,551.952148 
	C507.944794,552.046143 507.966705,552.255981 507.977234,552.782898 
	C507.816193,552.755920 507.666534,552.411987 507.719910,551.952148 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M536.622986,649.010254 
	C536.548035,650.884583 536.098511,652.766785 535.322021,654.824341 
	C532.509644,652.680054 532.802734,651.281128 536.622986,649.010254 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M571.783936,439.638428 
	C576.627197,437.573090 584.427124,439.677948 588.284607,443.764252 
	C592.513489,448.243988 593.813904,456.021637 591.227905,461.367859 
	C587.935059,468.175354 578.633728,472.457611 572.692444,469.901489 
	C562.152710,465.366943 559.350342,459.004150 562.132324,449.300751 
	C563.667175,443.947327 567.564453,442.196655 571.783936,439.638428 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M551.116577,450.116821 
	C551.188293,449.477539 551.526672,449.017365 551.974854,448.292908 
	C555.360840,448.807007 557.082397,450.808746 556.963623,454.629639 
	C555.214600,454.671783 553.480225,454.331451 551.356201,453.981873 
	C551.105347,452.747101 551.244324,451.521515 551.116577,450.116821 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M561.311157,469.777344 
	C558.009949,470.225952 555.636353,469.630646 556.025757,465.357788 
	C559.453064,464.592743 561.871399,465.427887 561.311157,469.777344 
z"/>
<path fill="#787878" opacity="1.000000" stroke="none" 
	d="
M555.117615,541.172852 
	C554.703247,542.106628 554.288818,543.040405 553.563843,544.061218 
	C553.184021,543.661499 553.114807,543.174744 552.990173,542.320923 
	C552.934753,541.953857 552.965088,541.951965 553.238464,541.875977 
	C554.047058,541.590942 554.582336,541.381897 555.117615,541.172852 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M684.382568,647.002319 
	C686.226868,645.450012 688.456238,643.904968 690.685547,642.359863 
	C691.103516,642.847046 691.521423,643.334290 691.939392,643.821472 
	C691.330933,644.980103 690.722473,646.138733 690.059448,647.646240 
	C688.259155,647.666565 686.513367,647.338013 684.382568,647.002319 
z"/>
<path fill="#070707" opacity="1.000000" stroke="none" 
	d="
M675.083435,549.332275 
	C675.010925,549.009338 675.179810,548.642151 675.577576,548.403076 
	C676.724243,547.203430 677.473083,546.242920 678.221924,545.282349 
	C678.221924,545.282349 678.212036,545.210327 678.590820,545.182251 
	C680.581665,543.054749 682.193848,540.955261 683.806030,538.855835 
	C683.805969,538.855774 683.848816,538.837219 683.857056,538.814819 
	C688.076538,535.038635 692.476501,531.472412 696.444946,527.477539 
	C700.273499,523.623535 703.603149,519.278259 707.275818,515.262024 
	C708.117920,514.341248 709.442688,513.359192 710.587280,513.305847 
	C715.299866,513.086487 720.028503,513.214050 726.394226,513.214050 
	C723.376892,517.059143 721.318604,520.078979 718.867310,522.736389 
	C708.878296,533.565552 698.572083,544.108032 688.766235,555.098328 
	C680.898865,563.915894 683.070740,564.577271 689.513611,571.377380 
	C699.992371,582.437195 710.772766,593.211670 721.467163,604.065857 
	C722.592896,605.208374 723.996155,606.077454 725.765503,607.309265 
	C726.464905,607.886475 726.669556,608.227844 726.656982,608.867676 
	C727.407715,610.065918 728.375610,610.965637 729.277222,612.249329 
	C729.305115,613.139771 729.399292,613.646240 729.060303,614.207642 
	C726.122131,614.480408 723.617004,614.698364 721.043945,614.639526 
	C720.276978,614.544922 719.578125,614.726990 718.441284,614.902832 
	C715.656616,614.945862 713.309753,614.995117 710.962952,615.044434 
	C710.962952,615.044434 710.737549,614.763977 710.780518,614.229309 
	C709.506287,612.257629 708.189087,610.820618 706.819702,609.309814 
	C706.767517,609.236084 706.607727,609.151733 706.654907,608.826660 
	C706.096436,608.074341 705.490784,607.646973 704.869385,607.184082 
	C704.853699,607.148560 704.780273,607.123108 704.811157,606.775208 
	C703.534912,605.342712 702.227722,604.258118 700.655090,602.958374 
	C694.232971,596.174866 688.076294,589.606506 681.816711,582.842285 
	C681.525269,582.390198 681.272034,582.260010 680.720703,582.037964 
	C679.601440,580.903625 678.715271,579.987244 677.835022,578.749695 
	C677.217834,577.968140 676.594727,577.507812 675.944397,576.742004 
	C675.545288,575.985657 675.173401,575.534912 674.813721,574.740051 
	C673.490234,573.434448 672.154480,572.472961 670.818787,571.511414 
	C666.259338,570.058899 665.136536,572.715393 665.113647,576.423096 
	C665.040283,588.301392 665.040283,600.180115 664.696472,612.592896 
	C663.907227,613.742554 663.433533,614.358032 662.959839,614.973511 
	C658.975952,614.542786 654.992065,614.112000 650.851929,613.055908 
	C650.173889,611.231873 649.652100,610.033203 649.130249,608.834534 
	C649.137817,587.250549 649.145325,565.666626 649.501587,543.466675 
	C651.073608,538.092468 652.308044,533.333801 649.174927,528.784790 
	C649.171326,527.823242 649.167725,526.861694 649.475952,525.406921 
	C649.559143,523.231323 649.330505,521.548950 649.101807,519.866577 
	C649.112610,519.483215 649.123352,519.099792 649.425415,518.330566 
	C649.938965,517.248169 650.161255,516.551636 650.383545,515.855164 
	C651.696899,511.241272 655.516235,513.186584 658.288391,513.093811 
	C665.021057,512.868713 665.027222,513.047729 665.027161,519.748657 
	C665.026978,528.930115 664.931091,538.113281 665.090637,547.291992 
	C665.132507,549.700623 665.965942,552.095520 666.760559,555.072998 
	C668.082092,555.450684 669.078979,555.252014 670.075806,555.053284 
	C670.075806,555.053284 670.500427,554.931152 671.026489,554.866455 
	C671.908691,554.151611 672.264832,553.501343 672.620972,552.851135 
	C672.620972,552.851135 672.756287,552.755859 673.127014,552.751221 
	C673.955017,552.145264 674.412292,551.544006 674.869629,550.942749 
	C674.965027,550.513550 675.060486,550.084412 675.083435,549.332275 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M710.911926,538.535889 
	C708.807190,541.252502 706.777100,543.638428 704.644165,546.349426 
	C704.541443,546.674438 704.233826,546.698181 703.743042,546.799561 
	C702.012573,548.524353 700.773010,550.147827 699.533386,551.771240 
	C699.533386,551.771240 699.234070,551.706970 698.747131,551.830261 
	C697.727356,552.917297 697.194580,553.881042 696.661804,554.844849 
	C696.661804,554.844849 696.317017,554.810913 695.796143,554.856140 
	C694.575439,554.983398 693.875610,555.065491 693.175842,555.147583 
	C694.452209,553.307739 695.538818,551.294556 697.035522,549.655945 
	C707.303772,538.413452 717.548828,527.145264 728.052673,516.124939 
	C731.108337,512.919006 730.224426,511.807129 726.348022,510.785156 
	C726.494507,507.229340 728.667847,508.390533 729.732727,509.597717 
	C731.015259,511.051514 731.883484,513.115112 732.223816,515.039795 
	C732.366882,515.848755 730.697510,516.978149 729.697937,518.257751 
	C727.438477,520.825928 725.333252,523.098267 723.116638,525.687012 
	C723.005249,526.003357 723.000000,526.000000 722.706604,526.107605 
	C721.642822,527.162598 720.872437,528.109985 720.102051,529.057373 
	C720.102112,529.057373 720.035522,529.023499 720.035034,529.062439 
	C717.018616,532.135986 714.002563,535.170654 710.911926,538.535889 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M701.616516,576.006104 
	C702.269348,576.808167 702.652710,577.473816 703.295410,578.291443 
	C704.314514,579.805847 705.074341,581.168274 705.729553,582.865540 
	C699.746704,577.447205 693.719604,571.832092 688.133179,565.808350 
	C687.175293,564.775574 688.096130,562.000427 688.508301,559.964111 
	C689.635742,561.946533 690.403992,563.994934 691.694824,566.392456 
	C693.488892,568.099426 694.760315,569.457336 696.031738,570.815247 
	C696.031738,570.815247 696.429871,571.001953 696.699585,571.350708 
	C697.623535,572.511597 698.277771,573.323792 698.932007,574.135986 
	C699.737000,574.713928 700.542053,575.291809 701.616516,576.006104 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M726.874207,608.569214 
	C726.669556,608.227844 726.464905,607.886475 726.123291,607.279602 
	C725.609680,605.989380 725.233093,604.964783 724.771057,603.618408 
	C725.183167,603.211731 725.680847,603.126648 726.688782,603.239685 
	C727.461670,603.651306 727.724243,603.864807 728.009155,604.416138 
	C729.047729,606.141907 730.063965,607.529846 731.080261,608.917786 
	C731.080261,608.917786 730.811646,609.196533 730.374878,609.244507 
	C728.916748,609.051453 727.895508,608.810364 726.874207,608.569214 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M693.326904,557.043030 
	C692.084473,558.020508 690.842041,558.997925 689.243225,559.919922 
	C689.942505,558.550415 690.998230,557.236389 692.552246,556.175903 
	C693.142639,556.633911 693.234802,556.838501 693.326904,557.043030 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M693.654236,557.024780 
	C693.234802,556.838501 693.142639,556.633911 692.881958,556.137207 
	C692.713379,555.844971 692.941711,555.494263 693.058777,555.320923 
	C693.875610,555.065491 694.575439,554.983398 695.629150,554.902100 
	C695.315857,555.604126 694.648682,556.305359 693.654236,557.024780 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M649.104614,529.265686 
	C652.308044,533.333801 651.073608,538.092468 649.490967,543.008667 
	C649.099121,538.693298 649.066772,534.219971 649.104614,529.265686 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M667.224121,601.168213 
	C667.719666,600.816406 668.061218,600.900330 668.644775,601.094727 
	C668.566956,603.081299 668.247070,604.957458 667.592896,606.955383 
	C667.195740,605.252747 667.132935,603.428406 667.224121,601.168213 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M671.299744,576.740845 
	C670.207520,576.830566 669.115356,576.920349 667.616089,577.045105 
	C668.133179,576.111511 669.057251,575.143005 670.354065,574.208557 
	C670.726807,574.242676 670.770996,574.264648 670.805298,574.579102 
	C670.992981,575.509277 671.146362,576.125000 671.299744,576.740845 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M670.847778,571.847778 
	C672.154480,572.472961 673.490234,573.434448 674.475464,574.692932 
	C673.006958,574.748108 671.888977,574.506409 670.770996,574.264648 
	C670.770996,574.264648 670.726807,574.242676 670.701172,574.238525 
	C670.742676,573.550964 670.809753,572.867615 670.847778,571.847778 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M649.048950,520.307190 
	C649.330505,521.548950 649.559143,523.231323 649.465088,525.046448 
	C649.093567,523.702087 649.044800,522.224915 649.048950,520.307190 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M649.072144,609.203735 
	C649.652100,610.033203 650.173889,611.231873 650.536804,612.783813 
	C649.923279,611.949036 649.468689,610.760986 649.072144,609.203735 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M669.694580,555.022400 
	C669.078979,555.252014 668.082092,555.450684 667.055542,555.316467 
	C667.788269,554.986267 668.550781,554.988892 669.694580,555.022400 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M674.580078,550.979614 
	C674.412292,551.544006 673.955017,552.145264 673.175293,552.684021 
	C673.332092,552.086548 673.811340,551.551575 674.580078,550.979614 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M672.352539,552.927856 
	C672.264832,553.501343 671.908691,554.151611 671.227478,554.774841 
	C671.296265,554.166748 671.690186,553.585632 672.352539,552.927856 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M650.123352,515.967285 
	C650.161255,516.551636 649.938965,517.248169 649.411621,518.043152 
	C649.358765,517.454224 649.610962,516.766846 650.123352,515.967285 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M667.396484,519.200684 
	C667.724304,519.560974 667.862610,520.289551 667.753723,521.088623 
	C667.406616,520.629150 667.306702,520.099060 667.396484,519.200684 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M663.292480,614.962891 
	C663.433533,614.358032 663.907227,613.742554 664.688965,613.061279 
	C664.539673,613.647766 664.082458,614.300049 663.292480,614.962891 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M417.222137,490.981171 
	C416.973877,490.206329 416.990051,489.448700 417.024719,488.113647 
	C417.043274,487.536194 417.179840,487.139191 417.530090,487.130157 
	C418.582397,487.074646 419.284485,487.028137 420.014221,487.012604 
	C420.041931,487.043549 420.098175,487.104645 420.098145,487.104645 
	C419.493134,488.211151 418.888123,489.317657 418.149750,490.716370 
	C417.839783,491.005127 417.663147,491.001740 417.222137,490.981171 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M594.828552,511.945129 
	C594.469971,512.195862 594.119202,512.178833 593.552612,511.896423 
	C593.231506,511.262817 593.126160,510.894562 593.020813,510.526337 
	C596.014282,507.143860 598.433105,509.545593 600.938171,511.681030 
	C599.821655,511.759216 598.682129,511.467010 597.199219,511.280426 
	C596.182678,511.483154 595.509460,511.580292 594.828552,511.945129 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M601.469177,746.907043 
	C601.808289,747.637512 602.028625,748.825623 601.649353,749.065369 
	C597.047913,751.975464 592.360107,754.749146 587.686218,757.544006 
	C587.411377,757.708374 587.070374,757.799500 586.749023,757.843018 
	C579.604980,758.811523 579.604675,758.809326 582.649902,765.076294 
	C577.758972,764.719788 572.845459,764.364868 567.932617,764.002014 
	C565.738525,763.840088 563.545654,763.661926 561.137451,763.190674 
	C561.859131,761.981384 562.642273,760.606506 563.757080,760.239929 
	C570.495789,758.024109 577.372253,756.219177 584.077026,753.911682 
	C588.394348,752.425842 592.486328,750.290833 596.700928,748.497803 
	C598.123535,747.892578 599.612915,747.444153 601.469177,746.907043 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M650.691284,711.179688 
	C650.551392,712.316589 650.100342,713.596802 649.281006,714.924438 
	C649.401917,713.755493 649.891052,712.539307 650.691284,711.179688 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M563.122070,670.904175 
	C562.904602,672.463928 562.677856,674.042969 562.451050,675.622070 
	C560.844543,674.482239 559.238037,673.342407 557.631531,672.202637 
	C558.563416,671.107849 559.495300,670.013062 560.803467,668.829712 
	C561.179810,668.741211 561.165161,668.798401 561.144653,669.171631 
	C561.783447,670.001892 562.442688,670.458984 563.101990,670.916016 
	C563.101990,670.916016 563.112854,670.923523 563.122070,670.904175 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M568.993164,673.171448 
	C569.713013,672.804932 570.495483,672.795532 571.669128,672.807983 
	C571.542236,675.141541 570.624207,675.733398 568.993164,673.171448 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M574.638062,545.127991 
	C574.539734,542.617371 574.862305,539.925293 578.423340,540.443909 
	C581.561462,540.901001 583.523376,542.539795 582.940918,546.213684 
	C582.435913,549.398560 582.231384,552.631042 581.529541,555.984619 
	C580.917358,553.064941 580.807617,549.984070 580.387939,546.946106 
	C579.844666,543.014282 577.938721,542.344604 574.638062,545.127991 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M552.942627,573.946777 
	C555.388916,569.978027 557.942627,566.097778 560.858154,562.267761 
	C559.187439,565.696350 557.154907,569.074646 555.182678,572.808105 
	C555.242920,573.163330 555.221069,573.183960 554.927734,573.205994 
	C554.178345,573.555664 553.722229,573.883362 553.266174,574.211060 
	C553.266174,574.211060 553.050171,574.035217 552.942627,573.946777 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M561.626587,562.118408 
	C563.977661,558.472900 566.650879,554.753601 569.691772,551.049805 
	C567.355835,554.724976 564.652283,558.384705 561.626587,562.118408 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M581.044617,563.349731 
	C584.339050,566.347656 583.052002,570.093445 581.476318,573.961304 
	C581.056458,570.662354 581.002197,567.236450 581.044617,563.349731 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M570.981628,549.208008 
	C571.637756,547.952637 572.557190,546.567566 573.840698,545.246094 
	C573.218140,546.565857 572.231506,547.822083 570.981628,549.208008 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M578.915405,568.741455 
	C578.632507,568.406799 578.459595,567.713440 578.558044,566.964722 
	C578.894714,567.400513 578.960083,567.891541 578.915405,568.741455 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M400.290894,756.955627 
	C401.011475,754.983704 402.104919,752.980713 403.528381,750.856323 
	C404.293549,753.572449 403.822327,755.942017 400.290894,756.955627 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M409.145813,734.391418 
	C409.787964,735.767212 410.079437,737.136658 410.370941,738.506226 
	C409.627136,738.476562 408.883362,738.446899 408.139526,738.417236 
	C408.358093,737.073181 408.576630,735.729187 409.145813,734.391418 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M404.318665,748.942505 
	C403.993256,747.927063 404.015686,746.857117 404.092712,745.389160 
	C406.778412,745.962524 406.646759,747.302124 404.318665,748.942505 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M426.995422,749.678467 
	C426.333527,749.597290 425.689423,749.177063 424.984863,748.451721 
	C425.385406,748.097839 425.846405,748.049072 426.654907,747.990723 
	C427.006042,748.433777 427.009644,748.886597 426.995422,749.678467 
z"/>
<path fill="#5D8BB2" opacity="1.000000" stroke="none" 
	d="
M345.714050,693.876221 
	C346.218445,695.243469 346.568085,696.344116 346.853943,697.792969 
	C342.328430,693.651611 337.712799,689.301819 333.462311,684.620544 
	C330.885223,681.782227 328.873077,678.430908 326.297180,675.156494 
	C325.987183,675.002991 325.960754,675.014832 325.960815,674.712036 
	C325.635712,673.940735 325.310577,673.472351 324.982361,672.702515 
	C324.649963,671.935669 324.320679,671.470276 323.964478,670.657532 
	C322.954773,669.215149 321.971954,668.120117 320.994873,667.011292 
	C321.000580,666.997559 320.973846,667.010681 320.940491,666.687744 
	C320.301819,665.813416 319.696442,665.262085 319.112549,664.368652 
	C319.049835,663.347839 318.965576,662.669067 318.876221,661.654053 
	C317.909363,660.566589 316.947632,659.815308 315.981934,658.740112 
	C315.667267,657.617676 315.356537,656.819031 315.023804,655.692993 
	C314.334106,654.579102 313.666443,653.792603 312.704529,652.805054 
	C310.697632,649.069275 308.929871,645.559937 307.288727,641.992371 
	C305.812775,638.783997 304.448914,635.522827 303.083862,632.264832 
	C301.081512,627.485840 299.122131,622.688904 297.127563,617.539429 
	C297.075256,616.454407 297.039825,615.729675 297.023621,614.592773 
	C295.873627,608.469727 294.696564,602.760376 293.538879,597.047180 
	C293.060150,594.684692 292.622925,592.313782 292.110016,589.560059 
	C291.082825,583.352112 289.430176,577.548950 289.275635,571.706116 
	C288.881317,556.798523 289.155365,541.873169 289.396423,526.683716 
	C290.116486,525.428589 290.839722,524.492249 291.047974,523.452759 
	C292.141541,517.994629 292.916443,512.467102 294.163055,507.046722 
	C295.587189,500.854523 297.305573,494.725006 299.066711,488.617462 
	C299.903412,485.715759 301.136078,482.928284 302.423889,479.846191 
	C303.449585,477.747864 304.240753,475.892365 305.193573,473.698151 
	C307.938446,467.252197 310.521667,461.144989 313.365234,454.832886 
	C313.783417,453.770508 313.941223,452.913025 314.341492,451.874023 
	C315.072052,450.804840 315.560181,449.917145 316.369690,448.996765 
	C317.148193,448.327179 317.605377,447.690369 318.328552,446.819702 
	C320.406616,443.062134 322.218719,439.538391 324.187988,435.719971 
	C327.261475,431.327606 330.177856,427.229950 333.433411,423.081207 
	C334.846558,422.687103 335.920502,422.344055 336.980042,422.361328 
	C336.296356,424.476288 335.627075,426.230896 334.957794,427.985535 
	C334.791992,428.251984 334.589417,428.486725 333.818817,428.881287 
	C332.841919,430.036072 332.396271,430.999268 331.950653,431.962433 
	C331.950653,431.962433 332.001373,431.992126 331.653625,432.005066 
	C330.855774,432.680786 330.405670,433.343628 329.806213,434.283722 
	C329.393524,435.339142 329.130219,436.117279 328.866882,436.895447 
	C328.306061,437.391937 327.745270,437.888428 326.710999,438.766846 
	C321.171204,448.238739 316.040497,457.293854 311.088867,466.445862 
	C310.000366,468.457825 309.547882,470.813904 308.802490,473.011505 
	C308.754822,473.380554 308.707153,473.749573 308.614624,474.392334 
	C305.347504,483.722198 302.091858,492.766693 298.922058,501.841187 
	C297.888214,504.800842 296.873932,507.795135 296.222778,510.852814 
	C295.570770,513.914429 295.383698,517.075012 295.071167,520.636719 
	C295.104340,521.504089 295.059204,521.926941 295.026917,522.669312 
	C294.201630,526.968872 292.682739,530.942871 292.648071,534.929810 
	C292.524719,549.114807 292.300842,563.347656 293.314789,577.476257 
	C293.968109,586.579773 296.719543,595.532654 298.829681,604.780762 
	C299.126526,605.434937 299.124329,605.861389 299.182495,606.602539 
	C299.511627,609.190002 299.447266,611.563843 300.101929,613.719299 
	C305.266083,630.721741 312.540833,646.846741 321.340271,662.236328 
	C324.834930,668.348206 329.380951,673.858948 333.777832,679.723755 
	C334.298553,680.299011 334.489746,680.793945 334.864136,681.807861 
	C335.725250,682.845520 336.403107,683.364136 337.080994,683.882751 
	C337.080994,683.882751 337.050568,683.946228 337.055176,684.261902 
	C337.661377,685.085510 338.262939,685.593506 338.864502,686.101501 
	C338.864502,686.101501 338.958893,685.997009 338.923950,686.405762 
	C339.611359,687.519836 340.333710,688.225159 341.056030,688.930420 
	C341.056030,688.930420 341.010986,688.997009 341.029785,689.297852 
	C341.646545,690.116394 342.244446,690.634033 342.842346,691.151672 
	C343.748016,691.971008 344.653656,692.790283 345.714050,693.876221 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M337.079407,683.607788 
	C336.403107,683.364136 335.725250,682.845520 335.085541,681.954895 
	C335.775085,682.166199 336.426483,682.749451 337.079407,683.607788 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M341.040741,688.606934 
	C340.333710,688.225159 339.611359,687.519836 338.887024,686.465454 
	C339.598511,686.838745 340.311981,687.561096 341.040741,688.606934 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M338.895935,685.770142 
	C338.262939,685.593506 337.661377,685.085510 337.088684,684.250977 
	C337.720795,684.429199 338.324066,684.934021 338.895935,685.770142 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M342.877808,690.813416 
	C342.244446,690.634033 341.646545,690.116394 341.060059,689.271118 
	C341.685425,689.454102 342.299347,689.964600 342.877808,690.813416 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M562.661072,663.356201 
	C558.464233,657.020447 559.312195,650.202148 564.542725,646.350830 
	C569.590454,642.634155 578.355774,643.875366 582.146240,648.843445 
	C585.904663,653.769714 584.975769,661.166565 580.067627,665.394897 
	C575.226501,669.565491 566.935547,668.737244 562.661072,663.356201 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M563.073853,670.591980 
	C562.442688,670.458984 561.783447,670.001892 561.140503,669.200806 
	C561.786499,669.327209 562.416138,669.797546 563.073853,670.591980 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M720.464722,529.036987 
	C720.872437,528.109985 721.642822,527.162598 722.708313,526.110229 
	C722.278137,527.009033 721.552795,528.012817 720.464722,529.036987 
z"/>
<path fill="#F8FBF9" opacity="1.000000" stroke="none" 
	d="
M399.296143,653.973145 
	C399.173309,653.447144 399.342926,652.889465 399.759674,652.164612 
	C399.867401,652.645508 399.727997,653.293457 399.296143,653.973145 
z"/>
<path fill="#787878" opacity="1.000000" stroke="none" 
	d="
M553.205200,574.551086 
	C553.722229,573.883362 554.178345,573.555664 554.918823,573.217468 
	C554.774109,574.153503 554.345154,575.100159 553.607117,576.150513 
	C553.246765,575.799927 553.195496,575.345520 553.205200,574.551086 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M699.930115,551.820068 
	C700.773010,550.147827 702.012573,548.524353 703.598938,546.854614 
	C702.739380,548.495117 701.533081,550.182007 699.930115,551.820068 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M696.025757,570.460205 
	C694.760315,569.457336 693.488892,568.099426 692.072510,566.460510 
	C695.091614,565.611755 695.944336,567.453308 696.025757,570.460205 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M697.049561,554.856018 
	C697.194580,553.881042 697.727356,552.917297 698.607361,551.892578 
	C698.448792,552.843506 697.943054,553.855347 697.049561,554.856018 
z"/>
<path fill="#EEEEEE" opacity="1.000000" stroke="none" 
	d="
M698.953491,573.761475 
	C698.277771,573.323792 697.623535,572.511597 696.917603,571.378784 
	C697.568909,571.834473 698.271912,572.610718 698.953491,573.761475 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M528.804077,455.789429 
	C529.674683,455.485199 530.597107,455.485199 531.519531,455.485199 
	C531.468323,455.889099 531.417114,456.292999 531.365906,456.696899 
	C530.529236,456.495789 529.692566,456.294708 528.804077,455.789429 
z"/>
<path fill="#0E71AC" opacity="1.000000" stroke="none" 
	d="
M392.987549,506.006989 
	C391.815643,505.865265 390.643738,505.723541 389.236877,504.944946 
	C390.041260,502.331299 391.119507,500.373718 392.105591,498.370758 
	C392.714905,497.133148 393.641907,495.822968 393.632233,494.551422 
	C393.600128,490.319733 393.209106,486.090759 393.108032,481.577271 
	C394.847198,479.197479 396.433929,477.101166 398.367676,474.967865 
	C401.471466,473.281647 404.228210,471.632416 406.954620,470.325500 
	C406.028015,471.930786 405.131775,473.193726 404.274994,474.401093 
	C407.756134,478.267517 414.127625,478.851013 417.605896,475.824646 
	C420.659698,473.167603 420.661743,469.741486 417.910217,466.312134 
	C420.666992,463.290436 423.362976,460.335419 424.993896,458.547791 
	C429.948639,458.891693 435.195404,460.881073 437.496918,459.088654 
	C442.841217,454.926544 448.761627,452.074341 454.390808,448.571930 
	C459.928192,445.126740 465.533630,443.353088 471.969116,442.866058 
	C477.052704,442.481323 482.416412,442.568848 486.447876,437.882935 
	C489.000336,434.916107 496.053284,437.209412 497.780426,441.075317 
	C497.557098,441.100525 497.108490,441.128418 496.649048,441.088623 
	C493.111755,441.668640 490.033936,442.288483 486.586700,442.929230 
	C485.478088,442.984528 484.738861,443.018921 483.642517,443.045227 
	C482.512726,443.929138 481.740051,444.821136 480.967407,445.713135 
	C480.538300,445.789795 480.109253,445.866486 479.184631,445.699860 
	C478.116608,445.304291 477.544067,445.152008 476.811768,444.774292 
	C476.092712,444.994690 475.533478,445.440521 474.562195,445.912476 
	C472.774719,445.986816 471.399261,446.035034 469.660706,446.060791 
	C468.203003,447.008209 467.108459,447.978088 465.611176,448.969055 
	C459.092346,451.292450 452.638123,452.973907 446.954193,456.069641 
	C441.138428,459.237244 435.829407,463.500275 430.762421,467.826996 
	C426.484894,471.479645 422.871429,475.909943 418.597351,480.037964 
	C411.947571,482.131042 408.349548,487.140991 405.809143,492.624817 
	C402.990631,498.708954 400.245300,504.384613 392.987549,506.006989 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M470.023834,446.083252 
	C471.399261,446.035034 472.774719,445.986816 474.859344,445.927307 
	C476.036194,445.610596 476.503876,445.305145 476.971558,444.999695 
	C477.544067,445.152008 478.116608,445.304291 478.856934,445.707886 
	C475.221313,447.525726 471.543335,449.627960 467.589813,450.553680 
	C457.528961,452.909393 448.919586,457.815033 441.005310,464.609314 
	C440.969666,464.956238 440.951782,464.950073 440.588623,464.932312 
	C437.809204,466.933838 435.392944,468.953186 432.982635,470.981079 
	C432.988617,470.989624 432.973511,470.978119 432.619385,471.002625 
	C430.740448,472.207062 429.199921,473.367249 427.694336,474.571198 
	C424.910767,476.796997 422.147736,479.048431 419.255371,480.971558 
	C419.077759,480.434662 419.020874,480.215363 418.963989,479.996033 
	C422.871429,475.909943 426.484894,471.479645 430.762421,467.826996 
	C435.829407,463.500275 441.138428,459.237244 446.954193,456.069641 
	C452.638123,452.973907 459.092346,451.292450 465.970764,448.983917 
	C467.830017,448.012878 468.926941,447.048065 470.023834,446.083252 
z"/>
<path fill="#0E71AC" opacity="1.000000" stroke="none" 
	d="
M528.987000,445.386749 
	C527.669983,445.358704 526.353027,445.330627 524.313538,445.098145 
	C522.618652,444.721069 521.646240,444.548401 520.673828,444.375763 
	C520.297302,444.355713 519.920715,444.335693 519.199402,444.026428 
	C518.586182,443.471710 518.317749,443.206177 517.954041,442.564392 
	C515.571716,441.792511 513.284668,441.396851 511.057739,440.642120 
	C511.689850,439.732544 512.250244,438.725250 512.835693,438.710480 
	C515.400391,438.645874 518.135193,439.480316 520.505005,438.849426 
	C524.438965,437.802185 527.083801,438.660492 530.203796,441.330048 
	C535.135620,445.550018 540.562561,444.725098 545.508362,440.329865 
	C546.196228,439.718567 547.749634,440.081238 548.804382,440.298035 
	C548.139709,441.754913 547.572998,442.906769 546.658081,444.096191 
	C543.703674,447.255402 540.379883,446.928680 536.564697,446.062622 
	C533.783325,445.824280 531.385132,445.605530 528.987000,445.386749 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M497.108521,441.128418 
	C497.108490,441.128418 497.557098,441.100525 498.239929,441.082428 
	C502.581787,441.086487 506.240814,441.108643 509.926239,441.485901 
	C509.987579,442.317200 510.022491,442.793365 510.057404,443.269531 
	C505.908539,443.423737 501.759674,443.577972 497.300537,443.132141 
	C497.029694,442.064178 497.069092,441.596283 497.108521,441.128418 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M483.999634,443.053314 
	C484.738861,443.018921 485.478088,442.984528 487.033569,442.958740 
	C489.655609,443.055969 491.461395,443.144623 493.267181,443.233246 
	C490.090118,444.152588 486.913055,445.071899 483.368713,445.651611 
	C483.334167,444.559052 483.666901,443.806183 483.999634,443.053314 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M493.632385,443.225586 
	C491.461395,443.144623 489.655609,443.055969 487.402954,442.937836 
	C490.033936,442.288483 493.111755,441.668640 496.649048,441.088623 
	C497.069092,441.596283 497.029694,442.064178 496.969482,442.888275 
	C495.964996,443.235657 494.981323,443.226807 493.632385,443.225586 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M480.609375,448.976807 
	C478.475464,450.167175 475.956696,451.353455 473.218781,452.271332 
	C475.407928,450.992859 477.816193,449.982788 480.609375,448.976807 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M510.390503,443.239624 
	C510.022491,442.793365 509.987579,442.317200 510.199799,441.441101 
	C510.630493,441.027832 510.814026,441.014526 510.997559,441.001221 
	C513.284668,441.396851 515.571716,441.792511 517.513489,442.584564 
	C515.689819,443.052368 514.211426,443.123779 512.733093,443.195190 
	C512.063293,443.200043 511.393463,443.204895 510.390503,443.239624 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M512.874634,443.531158 
	C514.211426,443.123779 515.689819,443.052368 517.608704,442.960815 
	C518.317749,443.206177 518.586182,443.471710 518.916382,444.021118 
	C516.990845,444.159027 515.003540,444.013062 512.874634,443.531158 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M490.638916,447.147766 
	C489.232910,447.795868 487.421295,448.369385 485.181549,448.883270 
	C486.580048,448.240143 488.406677,447.656677 490.638916,447.147766 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M449.678345,461.098938 
	C448.549133,462.380798 447.071869,463.654236 445.228333,464.921448 
	C446.351471,463.640381 447.840881,462.365479 449.678345,461.098938 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M483.642517,443.045227 
	C483.666901,443.806183 483.334167,444.559052 482.955505,445.618256 
	C482.470154,445.930969 482.030762,445.937378 481.279358,445.828491 
	C481.740051,444.821136 482.512726,443.929138 483.642517,443.045227 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M520.745728,444.717926 
	C521.646240,444.548401 522.618652,444.721069 523.918518,445.111206 
	C524.194458,445.529999 524.142944,445.731354 524.036621,446.240540 
	C523.239441,446.481079 522.497131,446.413818 521.383850,446.401306 
	C520.947876,445.990723 520.882812,445.525421 520.745728,444.717926 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M442.697021,465.130920 
	C442.582672,465.769897 442.120087,466.360443 441.335266,466.641235 
	C440.992584,465.871002 440.972168,465.410553 440.951782,464.950073 
	C440.951782,464.950073 440.969666,464.956238 440.960205,464.951050 
	C441.416748,464.991425 441.882812,465.036957 442.697021,465.130920 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M433.108215,473.109894 
	C430.053650,478.675018 427.247681,484.459808 420.452759,487.069214 
	C420.098175,487.104645 420.041931,487.043549 420.145203,486.702057 
	C424.497864,481.905975 428.747223,477.451355 433.024597,473.024933 
	C433.052582,473.053131 433.108215,473.109894 433.108215,473.109894 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M440.588623,464.932312 
	C440.972168,465.410553 440.992584,465.871002 441.027283,466.677368 
	C441.041565,467.023254 441.008606,467.021576 440.695068,467.054230 
	C439.883118,467.700989 439.384766,468.315125 438.886383,468.929230 
	C438.886383,468.929230 438.450653,468.981354 437.915466,469.032959 
	C436.615356,469.738403 435.850433,470.392273 435.085480,471.046112 
	C435.085480,471.046112 435.027100,471.040955 434.690552,471.037994 
	C433.894897,471.014191 433.435791,470.993347 432.976654,470.972504 
	C435.392944,468.953186 437.809204,466.933838 440.588623,464.932312 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M435.427094,471.034180 
	C435.850433,470.392273 436.615356,469.738403 437.702393,469.085266 
	C437.272552,469.731415 436.520630,470.376862 435.427094,471.034180 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M439.219543,468.922150 
	C439.384766,468.315125 439.883118,467.700989 440.702820,467.063873 
	C440.533691,467.665619 440.043213,468.290344 439.219543,468.922150 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M432.982635,470.981079 
	C433.435791,470.993347 433.894897,471.014191 434.698120,471.066315 
	C434.598816,471.719055 434.155396,472.340515 433.410095,473.035950 
	C433.108215,473.109894 433.052582,473.053131 433.038452,472.688141 
	C433.007385,471.874817 432.990448,471.426453 432.973511,470.978119 
	C432.973511,470.978119 432.988617,470.989624 432.982635,470.981079 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M309.150208,472.892029 
	C309.547882,470.813904 310.000366,468.457825 311.088867,466.445862 
	C316.040497,457.293854 321.171204,448.238739 326.539062,438.994629 
	C325.769775,441.823944 324.947174,444.927399 323.575348,447.765350 
	C320.887085,453.326752 317.892700,458.740173 314.956482,464.563232 
	C313.091248,467.531982 311.294617,470.152252 309.150208,472.892029 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M355.048523,406.002594 
	C353.821014,408.086395 352.729706,410.269104 351.315186,412.217072 
	C350.460205,413.394470 349.103790,414.207733 347.940552,415.546326 
	C347.451172,415.921021 346.993958,415.934021 346.191162,415.933960 
	C346.185516,414.862244 346.525421,413.803558 347.300842,412.363831 
	C348.508942,411.013031 349.281525,410.043274 350.054077,409.073486 
	C350.054077,409.073486 350.444641,408.955383 350.997253,408.923584 
	C351.986237,408.198395 352.422577,407.505005 352.858948,406.811646 
	C352.858948,406.811646 352.921692,406.421539 353.315308,406.230957 
	C354.155457,406.027802 354.601990,406.015198 355.048523,406.002594 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M335.285797,427.887207 
	C335.627075,426.230896 336.296356,424.476288 337.029755,422.151947 
	C337.093903,421.582245 337.199799,421.165100 337.199799,421.165100 
	C339.249451,419.800385 341.299103,418.435638 343.695312,417.068481 
	C341.232513,420.640320 338.423157,424.214600 335.285797,427.887207 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M329.218719,436.911438 
	C329.130219,436.117279 329.393524,435.339142 330.133057,434.281250 
	C331.042938,433.992218 331.476654,433.982910 331.910339,433.973633 
	C331.130402,434.958252 330.350494,435.942841 329.218719,436.911438 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M332.270538,431.869690 
	C332.396271,430.999268 332.841919,430.036072 333.627808,429.015228 
	C333.508820,429.897339 333.049622,430.837158 332.270538,431.869690 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M331.957123,433.638977 
	C331.476654,433.982910 331.042938,433.992218 330.282410,434.003967 
	C330.405670,433.343628 330.855774,432.680786 331.629211,431.985962 
	C331.969635,432.404053 331.986755,432.854218 331.957123,433.638977 
z"/>
<path fill="#5A5A5A" opacity="1.000000" stroke="none" 
	d="
M670.805298,574.579102 
	C671.888977,574.506409 673.006958,574.748108 674.463257,575.036987 
	C675.173401,575.534912 675.545288,575.985657 675.970825,577.054138 
	C676.626038,578.138184 677.227600,578.604553 677.829102,579.070923 
	C678.715271,579.987244 679.601440,580.903625 680.828613,582.223022 
	C681.371033,582.851868 681.621033,582.989258 681.919678,583.038208 
	C688.076294,589.606506 694.232971,596.174866 700.666748,603.302551 
	C700.971375,604.556335 700.998962,605.250671 701.026550,605.945068 
	C694.878662,599.598206 688.741760,593.240662 682.568848,586.918091 
	C681.804016,586.134705 680.847961,585.538025 679.655518,584.756836 
	C678.815491,584.059753 678.300476,583.460571 677.475952,582.861450 
	C677.166382,582.861450 677.134094,582.846497 677.115845,582.515137 
	C676.447754,581.748352 675.797852,581.312866 675.147949,580.877441 
	C674.858887,580.412781 674.569824,579.948120 674.122681,578.978760 
	C673.324951,577.954163 672.685242,577.434204 672.045532,576.914246 
	C672.045532,576.914246 671.675842,576.809204 671.487793,576.775024 
	C671.146362,576.125000 670.992981,575.509277 670.805298,574.579102 
z"/>
<path fill="#5A5A5A" opacity="1.000000" stroke="none" 
	d="
M710.995483,615.417603 
	C713.309753,614.995117 715.656616,614.945862 718.812866,614.953003 
	C720.118835,614.978394 720.615417,614.947327 721.111938,614.916260 
	C723.617004,614.698364 726.122131,614.480408 729.327026,614.114624 
	C730.352051,613.310425 730.677246,612.653992 731.105286,611.910522 
	C731.208130,611.823425 731.418579,611.654968 731.418579,611.654968 
	C734.079041,614.088928 733.913330,617.521912 730.964417,617.614441 
	C724.299561,617.823547 717.392822,619.284119 710.995483,615.417603 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M731.408081,608.981079 
	C730.063965,607.529846 729.047729,606.141907 728.378235,604.386719 
	C729.462646,604.055542 730.200378,604.091614 730.938049,604.127686 
	C731.426636,604.688416 731.915283,605.249146 732.808167,606.211426 
	C734.448975,607.788208 735.685608,608.963318 736.922180,610.138489 
	C736.896118,610.597839 736.870056,611.057190 736.729858,611.845581 
	C734.989197,611.131165 733.362549,610.087769 731.408081,608.981079 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M706.871826,609.383606 
	C708.189087,610.820618 709.506287,612.257629 710.684204,614.077393 
	C709.523499,613.766663 708.502197,613.073181 707.257080,611.819641 
	C706.979431,610.634277 706.925659,610.008911 706.871826,609.383606 
z"/>
<path fill="#5A5A5A" opacity="1.000000" stroke="none" 
	d="
M706.819702,609.309814 
	C706.925659,610.008911 706.979431,610.634277 707.038391,611.575684 
	C705.395935,610.480042 703.748352,609.068420 702.419312,607.319092 
	C703.418640,607.028625 704.099487,607.075867 704.780273,607.123108 
	C704.780273,607.123108 704.853699,607.148560 704.921143,607.463379 
	C705.528320,608.236084 706.068054,608.693909 706.607727,609.151733 
	C706.607727,609.151733 706.767517,609.236084 706.819702,609.309814 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M704.811157,606.775208 
	C704.099487,607.075867 703.418640,607.028625 702.394775,606.978149 
	C701.784485,606.781494 701.517212,606.588074 701.138245,606.169800 
	C700.998962,605.250671 700.971375,604.556335 700.932251,603.517700 
	C702.227722,604.258118 703.534912,605.342712 704.811157,606.775208 
z"/>
<path fill="#5A5A5A" opacity="1.000000" stroke="none" 
	d="
M726.656982,608.867676 
	C727.895508,608.810364 728.916748,609.051453 730.240967,609.384399 
	C730.761169,610.021301 730.978455,610.566406 731.307129,611.383240 
	C731.418579,611.654968 731.208130,611.823425 730.827148,611.888916 
	C730.078674,611.924744 729.711121,611.895081 729.343567,611.865417 
	C728.375610,610.965637 727.407715,610.065918 726.656982,608.867676 
z"/>
<path fill="#BFD4E8" opacity="1.000000" stroke="none" 
	d="
M707.439453,619.923828 
	C707.092285,619.161499 707.056396,618.448486 707.033325,617.377136 
	C708.267090,617.780884 709.487915,618.543091 710.841431,619.594116 
	C709.899597,619.913025 708.825195,619.943115 707.439453,619.923828 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M736.824463,612.400879 
	C737.549438,612.516846 738.232605,612.949280 738.956116,613.718506 
	C738.478943,614.048828 737.961426,614.042297 737.052490,614.012695 
	C736.701599,613.565552 736.742188,613.141479 736.824463,612.400879 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M510.144897,389.036499 
	C511.178802,389.214142 511.857574,389.407074 512.536316,389.600006 
	C512.484070,389.989258 512.431885,390.378510 512.379639,390.767761 
	C511.112946,390.570007 509.846252,390.372284 508.579590,390.174561 
	C508.982971,389.800293 509.386353,389.426056 510.144897,389.036499 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M511.062408,385.293213 
	C512.549988,385.429901 514.070068,385.914917 515.804932,386.661774 
	C514.378113,386.496246 512.736511,386.068878 511.062408,385.293213 
z"/>
<path fill="#5A5A5A" opacity="1.000000" stroke="none" 
	d="
M683.471497,538.901855 
	C682.193848,540.955261 680.581665,543.054749 678.626831,545.186890 
	C679.901794,543.128906 681.519409,541.038391 683.471497,538.901855 
z"/>
<path fill="#5A5A5A" opacity="1.000000" stroke="none" 
	d="
M677.859741,545.195435 
	C677.473083,546.242920 676.724243,547.203430 675.656982,548.217163 
	C676.058228,547.216431 676.777832,546.162476 677.859741,545.195435 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M580.890259,511.499023 
	C576.099854,518.363525 571.021973,524.950562 565.846008,531.459656 
	C563.802734,534.029175 561.541016,536.424988 558.955200,538.444092 
	C559.468079,535.732849 560.404419,533.477417 561.340820,531.221924 
	C561.340820,531.221924 561.561890,530.971985 561.902466,530.705688 
	C563.462036,528.608459 564.681030,526.777588 565.899963,524.946716 
	C566.273743,524.505005 566.647461,524.063354 567.420044,523.329285 
	C569.150574,521.465637 570.482300,519.894287 572.101318,518.244995 
	C572.888977,517.404419 573.389282,516.641907 573.889526,515.879395 
	C574.913025,514.463013 575.936401,513.046631 576.977051,511.295837 
	C578.184021,511.037811 579.373779,511.114105 580.890259,511.499023 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M592.597839,510.658264 
	C593.126160,510.894562 593.231506,511.262817 593.289429,511.915833 
	C592.081421,512.265564 590.920837,512.330505 589.777100,512.059204 
	C590.587646,511.412048 591.381287,511.101105 592.597839,510.658264 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M549.664673,455.026093 
	C549.535217,455.623322 549.088074,456.269012 548.317993,456.952881 
	C548.445740,456.352234 548.896301,455.713409 549.664673,455.026093 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M452.288269,428.410095 
	C451.763794,428.268707 451.239319,428.127319 450.313782,427.899963 
	C449.927521,427.412445 449.942322,427.010895 449.953735,426.306458 
	C453.462341,425.131989 456.974304,424.260345 460.538757,423.941467 
	C458.391968,425.724304 456.192749,426.954346 453.707642,428.216888 
	C453.043976,428.302979 452.666107,428.356537 452.288269,428.410095 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M672.066223,577.258606 
	C672.685242,577.434204 673.324951,577.954163 673.901306,578.816406 
	C673.837891,579.158813 673.519165,579.300964 673.346008,579.322510 
	C672.810852,578.763672 672.448914,578.183350 672.066223,577.258606 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M672.587891,582.810791 
	C672.993774,583.043823 673.162903,583.295410 673.332031,583.546936 
	C672.936523,583.469482 672.541016,583.392029 672.012451,583.099609 
	C671.879456,582.884644 672.351135,582.829346 672.587891,582.810791 
z"/>
<path fill="#A4A4A4" opacity="1.000000" stroke="none" 
	d="
M675.058228,581.178894 
	C675.797852,581.312866 676.447754,581.748352 677.107910,582.499878 
	C676.401672,582.370789 675.685059,581.925598 675.058228,581.178894 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M560.965515,531.154541 
	C560.404419,533.477417 559.468079,535.732849 558.583374,538.362061 
	C558.635010,538.735779 558.413818,539.005920 558.320557,539.153198 
	C557.296448,539.739136 556.365479,540.177856 555.276123,540.894714 
	C554.582336,541.381897 554.047058,541.590942 553.227600,541.870850 
	C553.963074,539.906677 554.982727,537.871521 556.092407,535.506592 
	C557.651611,533.813599 559.120911,532.450317 560.965515,531.154541 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M571.814026,518.322998 
	C570.482300,519.894287 569.150574,521.465637 567.474487,523.037903 
	C567.676697,521.578979 568.223267,520.119080 568.934204,518.380371 
	C569.585083,517.802124 570.071655,517.502563 570.979736,517.469360 
	C571.538879,517.931580 571.676453,518.127319 571.814026,518.322998 
z"/>
<path fill="#C0C0C0" opacity="1.000000" stroke="none" 
	d="
M565.562073,524.966370 
	C564.681030,526.777588 563.462036,528.608459 561.915710,530.539429 
	C561.408752,529.763123 561.229187,528.886780 561.084839,527.564087 
	C562.488037,526.407227 563.856140,525.696594 565.562073,524.966370 
z"/>
<path fill="#787878" opacity="1.000000" stroke="none" 
	d="
M572.101318,518.244995 
	C571.676453,518.127319 571.538879,517.931580 571.258911,517.406982 
	C571.116516,517.078247 571.226501,516.691467 571.288635,516.501038 
	C571.980835,516.168823 572.610901,516.027039 573.565247,515.882324 
	C573.389282,516.641907 572.888977,517.404419 572.101318,518.244995 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M730.951599,603.731750 
	C730.200378,604.091614 729.462646,604.055542 728.355896,604.048950 
	C727.724243,603.864807 727.461670,603.651306 727.067627,603.218018 
	C726.954285,602.553528 726.972473,602.108704 726.992920,601.329529 
	C728.318481,601.775330 729.641785,602.555603 730.951599,603.731750 
z"/>
<path fill="#0796C9" opacity="1.000000" stroke="none" 
	d="
M593.066101,307.001862 
	C593.109375,307.389343 593.152649,307.776825 593.113770,308.949219 
	C593.024353,319.452179 593.112915,329.172089 592.920105,338.886475 
	C592.885925,340.610199 591.681274,342.310730 590.972534,343.716949 
	C590.365845,343.206482 589.803650,343.001068 589.241516,342.795654 
	C589.167297,343.870239 589.093079,344.944855 589.010376,346.370972 
	C588.341919,347.486053 587.681946,348.249542 586.627563,348.922760 
	C585.822937,346.840546 585.455994,344.838470 584.989807,342.859741 
	C584.557129,341.023499 584.018677,339.212219 583.526794,337.389954 
	C582.000854,338.143768 580.307617,338.678131 578.979126,339.690918 
	C574.283081,343.270996 571.428101,342.536041 568.919678,337.391174 
	C568.218506,335.953064 566.089111,334.163086 564.838562,334.319763 
	C563.434814,334.495636 562.112549,336.575562 561.088989,338.057831 
	C560.484009,338.933960 560.858887,340.544098 560.197632,341.310455 
	C559.241333,342.418823 557.800415,343.549744 556.457031,343.700806 
	C555.766357,343.778534 554.139587,341.754120 554.141418,340.681702 
	C554.148865,336.263245 551.336609,335.900452 548.119934,336.022125 
	C540.674011,336.303680 533.222961,336.556824 525.794067,337.097382 
	C524.191040,337.214020 522.019592,338.080566 521.237976,339.315552 
	C518.919678,342.978607 516.383667,342.027802 514.397644,339.702850 
	C510.800873,335.492279 506.696838,336.347290 502.772949,338.234283 
	C496.613312,341.196411 494.408295,340.922241 488.993225,334.943817 
	C489.753540,334.549957 490.477203,334.259766 491.102661,333.827484 
	C491.737122,333.388977 492.274628,332.810150 492.832031,332.312927 
	C489.769379,328.087128 487.647614,328.287109 485.171997,332.520416 
	C484.021973,334.486969 482.402039,336.178772 480.993713,337.526764 
	C481.097900,324.120911 479.115021,310.884918 486.487671,298.991638 
	C488.009064,296.537354 489.334045,293.952209 490.976532,291.584839 
	C492.383179,289.557343 493.886566,287.485931 495.748810,285.908020 
	C499.989227,282.315063 504.373108,278.868591 508.897095,275.640686 
	C512.243469,273.253052 515.854858,271.218536 519.449097,269.206818 
	C520.673828,268.521301 522.191711,268.126587 523.600220,268.049805 
	C526.748779,267.878265 529.929199,268.176758 533.062744,267.899689 
	C534.418884,267.779755 535.690979,266.710510 537.001282,266.072479 
	C537.445862,266.042877 537.890442,266.013306 538.742798,266.389465 
	C541.273193,268.392395 543.317810,269.181885 545.000610,266.229553 
	C545.755859,266.206635 546.511169,266.183716 547.730225,266.576050 
	C550.771179,268.727478 553.319092,270.509247 555.933838,272.186890 
	C558.477783,273.819092 560.906006,275.981659 563.708679,276.791565 
	C570.707581,278.814117 577.009094,281.187347 577.416870,290.063324 
	C583.062927,289.250275 585.961792,291.976410 586.850891,296.577240 
	C587.690125,300.920685 589.194885,304.594666 593.066101,307.001862 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M536.538452,266.001221 
	C535.690979,266.710510 534.418884,267.779755 533.062744,267.899689 
	C529.929199,268.176758 526.748779,267.878265 523.600220,268.049805 
	C522.191711,268.126587 520.673828,268.521301 519.449097,269.206818 
	C515.854858,271.218536 512.243469,273.253052 508.897095,275.640686 
	C504.373108,278.868591 499.989227,282.315063 495.748810,285.908020 
	C493.886566,287.485931 492.383179,289.557343 490.976532,291.584839 
	C489.334045,293.952209 488.009064,296.537354 486.487671,298.991638 
	C479.115021,310.884918 481.097900,324.120911 480.991943,337.935547 
	C482.587585,341.942078 483.804352,345.356659 485.884064,348.122345 
	C488.054657,351.008789 491.022369,353.307159 493.713623,355.784393 
	C494.630951,356.628754 496.139618,357.010498 496.733398,357.993164 
	C498.557709,361.012512 499.817535,364.387573 501.758759,367.318420 
	C505.230865,372.560547 510.075531,375.338379 516.803833,374.535339 
	C516.633484,372.675476 516.491577,371.126434 516.351990,369.602509 
	C522.559570,368.585388 526.181519,370.741577 525.939636,375.058655 
	C525.573364,381.594025 525.912781,381.957520 532.575745,382.014099 
	C535.235840,382.036713 537.908569,381.854919 540.554138,382.052460 
	C545.939941,382.454590 550.479187,381.853241 550.802673,374.981476 
	C550.812195,374.779419 551.299805,374.599915 551.564453,374.409882 
	C554.075867,374.900818 556.438049,374.513611 558.797668,374.111664 
	C560.556824,373.811981 562.355530,373.083862 564.061829,373.262085 
	C568.639587,373.740204 573.526123,370.661957 573.879150,365.982971 
	C574.268494,360.821503 577.288574,358.348022 581.526978,356.755890 
	C585.188293,355.380554 587.075806,352.932770 587.021973,349.013062 
	C587.681946,348.249542 588.341919,347.486053 589.328613,346.326080 
	C590.109192,345.293762 590.563171,344.657867 591.017090,344.022003 
	C591.681274,342.310730 592.885925,340.610199 592.920105,338.886475 
	C593.112915,329.172089 593.024353,319.452179 593.083374,309.240540 
	C594.420837,312.150696 596.422241,315.463806 596.866699,318.974030 
	C598.661438,333.149750 595.618469,346.506866 587.737183,358.372406 
	C583.338318,364.995026 578.888672,371.855255 571.095764,375.458557 
	C564.818604,378.361053 559.082092,383.034668 552.547424,384.617279 
	C545.286194,386.375793 537.383484,385.897003 529.775513,385.721558 
	C516.353699,385.412109 505.356506,378.811005 495.517914,370.524078 
	C488.925568,364.971436 484.686279,357.404419 480.967285,349.533813 
	C476.083191,339.197479 475.702057,328.396759 477.013794,317.514282 
	C478.315216,306.717621 482.235291,296.839539 489.230652,288.173340 
	C497.279083,278.202484 507.850403,271.847961 519.357483,267.311279 
	C524.337646,265.347870 530.467773,266.301392 536.538452,266.001221 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M593.322266,306.784119 
	C589.194885,304.594666 587.690125,300.920685 586.850891,296.577240 
	C585.961792,291.976410 583.062927,289.250275 577.416870,290.063324 
	C577.009094,281.187347 570.707581,278.814117 563.708679,276.791565 
	C560.906006,275.981659 558.477783,273.819092 555.933838,272.186890 
	C553.319092,270.509247 550.771179,268.727478 548.098877,266.667389 
	C551.662781,267.506958 555.370667,268.537201 558.972351,269.857178 
	C573.875427,275.318939 583.945190,286.202972 591.648682,299.561279 
	C592.819763,301.591919 592.960938,304.216492 593.322266,306.784119 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M544.583008,266.117615 
	C543.317810,269.181885 541.273193,268.392395 539.073975,266.440063 
	C540.720093,266.058533 542.442749,266.032104 544.583008,266.117615 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M581.011230,279.353638 
	C582.300415,280.150543 583.610046,281.291351 584.912964,282.792267 
	C583.614807,282.000763 582.323242,280.849121 581.011230,279.353638 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M578.658875,374.986603 
	C578.520813,375.606995 578.057251,376.256226 577.259399,376.913727 
	C577.394531,376.286499 577.863953,375.650970 578.658875,374.986603 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M592.572083,358.903412 
	C592.748169,359.442505 592.611145,360.062286 591.968079,360.856659 
	C591.462097,361.031219 590.972351,360.983856 590.972351,360.983887 
	C591.401245,360.317291 591.830078,359.650696 592.572083,358.903412 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M590.943420,361.246094 
	C590.972351,360.983856 591.462097,361.031219 591.706604,361.050629 
	C591.844360,361.389313 591.649414,361.640137 591.146301,361.929230 
	C590.922302,361.860077 590.918396,361.684235 590.943420,361.246094 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M507.990387,419.566101 
	C510.078766,419.320496 512.167114,419.074921 514.652710,418.710663 
	C515.778931,418.657074 516.508057,418.722168 517.596802,418.701111 
	C518.233154,418.921692 518.509888,419.228394 518.761841,420.055756 
	C518.452332,420.792755 518.167419,421.009125 517.442139,421.170166 
	C514.005554,421.085602 511.009399,421.056335 508.009277,420.661804 
	C508.000305,420.053070 507.995361,419.809601 507.990387,419.566101 
z"/>
<path fill="#FAFBFA" opacity="1.000000" stroke="none" 
	d="
M605.034058,295.314819 
	C606.492798,295.447449 608.752441,295.467285 606.322021,297.959442 
	C605.690979,297.214508 605.372192,296.424957 605.034058,295.314819 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M517.882568,421.225494 
	C518.167419,421.009125 518.452332,420.792755 518.870239,420.344055 
	C520.650269,420.164215 522.297180,420.216675 524.485474,420.583435 
	C526.352783,420.970490 527.678650,421.043243 529.004578,421.116028 
	C529.402344,421.151825 529.800049,421.187592 530.494507,421.255035 
	C535.170776,422.892883 539.460999,424.829834 543.947266,426.040680 
	C555.472717,429.151398 556.350769,430.982330 550.708313,441.694672 
	C550.094360,442.860260 549.291260,444.105713 549.224609,445.348938 
	C548.939392,450.668213 546.017151,451.759338 541.443115,450.018799 
	C544.567688,448.987579 547.822449,448.624695 547.006287,444.058624 
	C547.572998,442.906769 548.139709,441.754913 549.001831,439.967682 
	C550.015259,436.821930 550.733276,434.311523 551.502075,431.623627 
	C541.094604,428.620544 531.745178,425.954865 522.421753,423.201202 
	C520.851196,422.737366 519.392761,421.893677 517.882568,421.225494 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M546.658081,444.096191 
	C547.822449,448.624695 544.567688,448.987579 541.166870,449.748962 
	C540.509033,450.002960 540.108521,449.973267 539.331116,449.580200 
	C538.285461,448.171967 537.616699,447.127106 536.947876,446.082214 
	C540.379883,446.928680 543.703674,447.255402 546.658081,444.096191 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M536.564697,446.062622 
	C537.616699,447.127106 538.285461,448.171967 539.034058,449.524597 
	C535.885864,448.503510 532.657959,447.174683 529.208496,445.616302 
	C531.385132,445.605530 533.783325,445.824280 536.564697,446.062622 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M528.784973,420.799347 
	C527.678650,421.043243 526.352783,420.970490 524.895386,420.606537 
	C526.031067,420.371124 527.298218,420.426910 528.784973,420.799347 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M507.558960,419.644348 
	C507.995361,419.809601 508.000305,420.053070 507.751556,420.941772 
	C505.323761,421.390717 503.149689,421.194427 500.975616,420.998108 
	C500.980896,420.761169 500.986206,420.524170 501.000885,419.929504 
	C503.049377,419.622040 505.088470,419.672333 507.558960,419.644348 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M452.216187,428.693970 
	C452.666107,428.356537 453.043976,428.302979 453.958130,428.453064 
	C460.445801,427.017090 466.399994,425.387390 472.347656,423.734283 
	C476.325012,422.628815 480.293091,421.490021 484.265320,420.366150 
	C484.650269,420.316650 485.035187,420.267151 485.709381,420.186401 
	C486.225586,420.416504 486.452545,420.677887 486.795349,421.426086 
	C485.981873,422.541534 485.133942,423.482330 484.110291,423.749359 
	C475.918427,425.886230 467.712830,427.979675 459.466583,429.893280 
	C456.963348,430.474213 454.319794,430.450287 451.740479,430.703369 
	C451.875031,430.128174 452.009583,429.553009 452.216187,428.693970 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M483.813477,420.240479 
	C480.293091,421.490021 476.325012,422.628815 472.347656,423.734283 
	C466.399994,425.387390 460.445801,427.017090 454.243958,428.420593 
	C456.192749,426.954346 458.391968,425.724304 460.796143,424.200195 
	C461.436768,423.877899 461.872437,423.849640 462.625610,423.736542 
	C467.480255,422.648895 471.998840,421.547852 476.562531,420.685516 
	C478.785461,420.265442 481.092590,420.290955 483.813477,420.240479 
z"/>
<path fill="#107CB2" opacity="1.000000" stroke="none" 
	d="
M451.499817,430.836670 
	C454.319794,430.450287 456.963348,430.474213 459.466583,429.893280 
	C467.712830,427.979675 475.918427,425.886230 484.110291,423.749359 
	C485.133942,423.482330 485.981873,422.541534 487.014923,421.573608 
	C491.430023,421.148285 495.741364,421.062256 500.514160,420.987152 
	C503.149689,421.194427 505.323761,421.390717 507.755554,421.307037 
	C511.009399,421.056335 514.005554,421.085602 517.442139,421.170166 
	C519.392761,421.893677 520.851196,422.737366 522.421753,423.201202 
	C531.745178,425.954865 541.094604,428.620544 551.502075,431.623627 
	C550.733276,434.311523 550.015259,436.821930 549.099731,439.662659 
	C547.749634,440.081238 546.196228,439.718567 545.508362,440.329865 
	C540.562561,444.725098 535.135620,445.550018 530.203796,441.330048 
	C527.083801,438.660492 524.438965,437.802185 520.505005,438.849426 
	C518.135193,439.480316 515.400391,438.645874 512.835693,438.710480 
	C512.250244,438.725250 511.689850,439.732544 511.057739,440.642120 
	C510.814026,441.014526 510.630493,441.027832 510.173370,441.085968 
	C506.240814,441.108643 502.581787,441.086487 498.463257,441.057251 
	C496.053284,437.209412 489.000336,434.916107 486.447876,437.882935 
	C482.416412,442.568848 477.052704,442.481323 471.969116,442.866058 
	C465.533630,443.353088 459.928192,445.126740 454.390808,448.571930 
	C448.761627,452.074341 442.841217,454.926544 437.496918,459.088654 
	C435.195404,460.881073 429.948639,458.891693 424.993896,458.547791 
	C423.362976,460.335419 420.666992,463.290436 417.910217,466.312134 
	C420.661743,469.741486 420.659698,473.167603 417.605896,475.824646 
	C414.127625,478.851013 407.756134,478.267517 404.274994,474.401093 
	C405.131775,473.193726 406.028015,471.930786 406.991455,469.982544 
	C410.373688,465.533142 413.688690,461.769012 417.370911,458.000275 
	C419.230164,457.099457 420.760071,456.260101 422.206757,455.295959 
	C426.059631,452.728302 429.631561,449.043121 434.464996,452.623901 
	C439.039948,449.739868 435.833893,446.161438 436.416443,443.001099 
	C439.975403,443.430725 441.511108,442.230591 441.168579,438.649353 
	C442.996796,437.378448 444.710571,436.495453 446.220734,435.345123 
	C447.552002,434.331085 448.655823,433.018463 449.860077,431.837677 
	C450.326416,431.548431 450.792786,431.259186 451.499817,430.836670 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M272.603882,487.063721 
	C272.768372,489.491760 272.564362,491.998993 272.630585,494.739502 
	C272.856262,495.390503 272.811707,495.808136 272.407898,496.625092 
	C271.966888,498.008118 271.885162,498.991882 271.803436,499.975616 
	C271.773376,500.391632 271.743317,500.807678 271.346985,501.655334 
	C269.948883,504.406372 268.917053,506.725739 267.885223,509.045135 
	C267.885223,509.045135 267.417267,509.032776 267.182922,509.020569 
	C267.624908,501.457275 268.508453,493.956299 272.603882,487.063721 
z"/>
<path fill="#0E0E0E" opacity="1.000000" stroke="none" 
	d="
M825.202454,546.000000 
	C825.172913,561.655884 825.136169,576.811768 825.113708,591.967651 
	C825.102478,599.571960 826.442444,600.938965 834.018738,600.918213 
	C846.676453,600.883606 859.334045,600.754761 871.991638,600.763916 
	C878.962402,600.768921 880.423340,602.548279 880.371216,609.353760 
	C880.345642,612.688782 878.940247,614.459534 875.860046,614.727173 
	C871.894775,615.071655 867.898804,615.167969 863.915466,615.179688 
	C848.259155,615.225952 832.602722,615.206970 816.946289,615.191895 
	C809.227539,615.184448 809.555603,615.164246 809.548889,607.395691 
	C809.530701,586.276978 809.136658,565.158875 809.039673,544.039856 
	C808.998596,535.097107 809.382507,526.153076 809.461365,517.208618 
	C809.491150,513.832275 810.875671,512.570129 814.313599,512.689758 
	C826.943115,513.128845 825.022888,510.870789 825.190430,523.515015 
	C825.287537,530.842102 825.203552,538.171570 825.202454,546.000000 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M709.974060,663.617310 
	C710.013733,664.181580 710.000671,664.355042 709.635498,664.825500 
	C708.872864,665.788208 708.462280,666.453979 708.051697,667.119751 
	C708.051697,667.119751 708.071472,667.558350 708.059570,667.777466 
	C706.639343,669.306335 705.230957,670.616089 703.129211,672.570618 
	C702.752441,672.441284 701.297668,671.941833 700.931580,671.816162 
	C699.260193,675.273865 698.530151,679.641418 695.986206,681.431702 
	C691.199768,684.800110 687.760803,687.898132 688.453979,694.357849 
	C688.598633,695.705200 685.977112,698.042847 683.593079,695.385803 
	C683.009277,694.735107 682.029724,694.439575 682.432434,694.671204 
	C677.915710,699.575195 673.824951,704.016785 669.734131,708.458374 
	C669.184326,708.039246 668.634583,707.620178 668.084778,707.201111 
	C670.613770,702.249023 671.041443,696.372925 676.388733,692.657959 
	C679.900879,690.217834 682.807312,686.894287 685.914551,683.893494 
	C686.449402,683.376953 687.111328,682.278687 686.902405,681.815125 
	C684.647034,676.811523 688.312622,672.798889 689.020935,668.313965 
	C689.659424,664.270935 697.005127,658.112610 701.073303,657.976196 
	C703.366943,657.899231 705.651367,657.549072 708.304138,657.285828 
	C708.828186,659.210938 709.374756,661.218750 709.974060,663.617310 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M655.610474,732.011536 
	C648.622375,737.680908 641.202515,743.345825 633.420715,749.037354 
	C633.806885,747.721558 634.286926,746.088135 635.360596,745.099121 
	C637.377747,743.241150 640.405457,742.191956 641.820923,740.027100 
	C645.081909,735.039673 648.419434,730.874817 655.610474,732.011536 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M673.646545,714.013184 
	C672.103882,716.432800 670.381897,719.046692 668.226807,721.233826 
	C664.877747,724.632568 661.233948,727.740906 657.369263,731.019287 
	C659.553223,722.631592 665.249573,717.214905 673.646545,714.013184 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M632.658203,749.037354 
	C628.930481,751.961182 624.796448,754.863159 620.341125,757.913208 
	C619.265747,756.700745 618.511597,755.340271 617.624634,753.740173 
	C619.115967,751.547791 620.490173,749.005066 624.382996,750.489197 
	C625.363647,750.863159 626.972412,749.589783 628.647461,749.033081 
	C630.085571,749.000305 631.168701,749.007935 632.658203,749.037354 
z"/>
<path fill="#175292" opacity="1.000000" stroke="none" 
	d="
M708.357178,667.040955 
	C708.462280,666.453979 708.872864,665.788208 709.599976,665.084045 
	C709.498535,665.684448 709.080566,666.323303 708.357178,667.040955 
z"/>
<path fill="#107CB2" opacity="1.000000" stroke="none" 
	d="
M337.158508,420.800232 
	C337.199799,421.165100 337.093903,421.582245 337.044189,421.791626 
	C335.920502,422.344055 334.846558,422.687103 333.203857,422.789185 
	C328.651611,421.037598 326.593079,424.016083 325.217010,426.542297 
	C321.670319,433.053345 321.024902,433.639130 313.643188,432.497284 
	C312.762451,435.020142 308.793884,436.779297 311.741638,440.379272 
	C311.843597,440.503815 309.903503,442.890015 309.507629,442.732574 
	C307.610565,441.978119 305.917755,440.710144 304.152344,439.624664 
	C305.128204,438.359650 306.074158,437.069824 307.094635,435.841858 
	C307.502136,435.351532 308.248596,435.095520 308.544189,434.567017 
	C311.520203,429.245300 314.381866,423.858765 317.424347,418.576050 
	C317.871033,417.800446 319.094727,417.472290 320.011169,416.904724 
	C320.960022,418.912964 321.673096,420.422241 322.386169,421.931488 
	C322.955597,421.930115 323.525024,421.928741 324.094421,421.927338 
	C324.492065,420.106628 325.507660,418.153320 325.151611,416.494385 
	C324.519165,413.547668 323.055145,410.779449 321.939026,407.936554 
	C321.939026,407.936554 321.937897,407.998871 322.281067,407.983521 
	C323.250153,407.617523 323.876099,407.266846 324.502045,406.916138 
	C323.656555,406.605774 322.811035,406.295410 321.965515,405.985046 
	C327.319336,400.106262 332.368591,393.908264 338.107483,388.433136 
	C344.622955,382.217041 351.550629,376.382935 358.676666,370.870819 
	C365.954163,365.241638 373.689728,360.204590 381.651489,355.226624 
	C386.067963,357.866577 388.373718,356.779236 389.989319,351.814178 
	C389.989319,351.814178 390.291016,351.727905 390.832001,351.857178 
	C394.483704,353.683411 397.594421,355.380371 401.305054,357.404602 
	C400.405457,358.362518 399.689606,359.849091 398.774384,359.984070 
	C391.763977,361.018280 384.767975,363.978607 378.772644,360.561707 
	C376.569153,362.436890 375.246338,364.043091 373.547241,364.921265 
	C367.742218,367.921509 361.574310,370.316956 359.765961,377.739288 
	C359.521942,378.740906 357.354706,379.687378 355.961182,379.902344 
	C353.642242,380.260071 350.502441,379.932312 351.320465,383.237305 
	C351.714569,384.829620 354.663666,386.005524 356.666931,386.893890 
	C357.689667,387.347443 359.178467,386.750092 360.364166,386.636597 
	C361.726135,379.311218 364.232025,377.105408 370.579071,378.116333 
	C372.515381,378.424713 374.243073,380.043091 376.066284,381.061554 
	C375.714844,382.125702 375.363403,383.189850 374.968689,384.588837 
	C373.486237,385.143433 372.046997,385.363220 370.003479,385.855988 
	C368.937897,387.086395 368.476624,388.043823 368.015350,389.001282 
	C366.111572,390.516937 364.207794,392.032593 362.184753,393.874695 
	C359.617676,395.808929 356.783752,397.043243 354.797058,399.097107 
	C349.593597,404.476501 344.709442,410.170013 339.816528,415.840637 
	C338.674988,417.163605 338.004456,418.892975 337.158508,420.800232 
z"/>
<path fill="#068CC3" opacity="1.000000" stroke="none" 
	d="
M376.307068,380.973572 
	C374.243073,380.043091 372.515381,378.424713 370.579071,378.116333 
	C364.232025,377.105408 361.726135,379.311218 360.364166,386.636597 
	C359.178467,386.750092 357.689667,387.347443 356.666931,386.893890 
	C354.663666,386.005524 351.714569,384.829620 351.320465,383.237305 
	C350.502441,379.932312 353.642242,380.260071 355.961182,379.902344 
	C357.354706,379.687378 359.521942,378.740906 359.765961,377.739288 
	C361.574310,370.316956 367.742218,367.921509 373.547241,364.921265 
	C375.246338,364.043091 376.569153,362.436890 378.772644,360.561707 
	C384.767975,363.978607 391.763977,361.018280 398.774384,359.984070 
	C399.689606,359.849091 400.405457,358.362518 401.305054,357.404602 
	C397.594421,355.380371 394.483704,353.683411 390.983704,351.897217 
	C393.762665,349.503693 396.930939,347.199371 400.855621,344.893463 
	C404.222046,344.010681 406.992676,343.434631 409.399292,342.167206 
	C412.096375,340.746857 414.463989,338.700867 416.974976,336.927124 
	C418.066345,336.596313 419.157684,336.265503 420.843445,336.203125 
	C423.365540,336.570679 425.994019,337.523590 427.097107,336.618164 
	C431.418365,333.071075 435.154907,334.919556 439.070587,336.973236 
	C439.740326,340.050446 440.410095,343.127686 440.688171,346.640259 
	C436.357880,347.720734 432.405945,348.296570 428.487823,349.048737 
	C427.129028,349.309540 425.808746,349.915009 424.552856,350.530334 
	C423.099365,351.242432 421.761414,352.778961 420.329132,352.835266 
	C416.043396,353.003845 415.173615,355.617828 415.017914,359.060211 
	C414.611176,359.084167 414.204437,359.108124 413.048340,359.085754 
	C405.759674,358.961151 402.282990,361.134827 401.033875,366.082520 
	C398.965546,366.982849 396.897217,367.883148 394.179993,368.795105 
	C390.064240,368.952484 385.890625,367.656708 384.647278,373.146667 
	C380.924194,374.456604 378.142334,376.182739 377.960022,380.276245 
	C377.489288,380.479340 377.018555,380.682465 376.307068,380.973572 
z"/>
<path fill="#0E71AC" opacity="1.000000" stroke="none" 
	d="
M321.629517,408.066620 
	C323.055145,410.779449 324.519165,413.547668 325.151611,416.494385 
	C325.507660,418.153320 324.492065,420.106628 324.094421,421.927338 
	C323.525024,421.928741 322.955597,421.930115 322.386169,421.931488 
	C321.673096,420.422241 320.960022,418.912964 320.011169,416.904724 
	C319.094727,417.472290 317.871033,417.800446 317.424347,418.576050 
	C314.381866,423.858765 311.520203,429.245300 308.544189,434.567017 
	C308.248596,435.095520 307.502136,435.351532 307.094635,435.841858 
	C306.074158,437.069824 305.128204,438.359650 304.152344,439.624664 
	C305.917755,440.710144 307.610565,441.978119 309.507629,442.732574 
	C309.903503,442.890015 311.843597,440.503815 311.741638,440.379272 
	C308.793884,436.779297 312.762451,435.020142 313.643188,432.497284 
	C321.024902,433.639130 321.670319,433.053345 325.217010,426.542297 
	C326.593079,424.016083 328.651611,421.037598 332.864624,422.840271 
	C330.177856,427.229950 327.261475,431.327606 323.814117,435.721680 
	C316.793182,439.450073 315.838837,441.467438 318.062622,447.053528 
	C317.605377,447.690369 317.148193,448.327179 316.007568,448.994568 
	C313.041290,449.277435 312.222778,450.121765 314.099060,452.055511 
	C313.941223,452.913025 313.783417,453.770508 313.007446,454.868042 
	C311.749115,455.595551 311.058258,456.029572 310.477661,456.579926 
	C308.063721,458.867859 306.307587,462.555298 301.830841,460.187286 
	C301.391052,459.954620 299.704468,461.097565 299.302582,461.939148 
	C297.197083,466.348114 300.125580,472.346741 305.031921,474.036865 
	C304.240753,475.892365 303.449585,477.747864 302.054535,479.846588 
	C296.383942,483.311188 293.225677,487.622467 292.858643,493.879486 
	C292.799194,494.893524 290.942657,496.415771 289.741089,496.608673 
	C287.830627,496.915344 285.772308,496.300934 283.391602,496.032776 
	C282.025238,492.039551 281.495941,487.668823 285.162842,483.742615 
	C285.577667,483.298462 285.959442,482.560638 285.916687,481.992096 
	C285.419800,475.387146 288.978729,470.069031 291.190521,464.272064 
	C292.677246,460.375549 293.041962,456.050964 293.908630,451.917908 
	C294.562408,450.172028 295.216187,448.426117 295.795654,446.310303 
	C296.545471,444.037476 297.111420,441.964020 298.237030,440.260284 
	C304.050537,431.460938 309.942657,422.711304 315.959900,414.050323 
	C317.455872,411.897095 319.518097,410.137299 321.629517,408.066620 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M415.398376,359.062073 
	C415.173615,355.617828 416.043396,353.003845 420.329132,352.835266 
	C421.761414,352.778961 423.099365,351.242432 424.552856,350.530334 
	C425.808746,349.915009 427.129028,349.309540 428.487823,349.048737 
	C432.405945,348.296570 436.357880,347.720734 440.678650,347.044678 
	C440.220978,348.247345 439.647339,350.148163 438.498260,350.606567 
	C430.994659,353.599976 423.366333,356.280853 415.398376,359.062073 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M439.073547,336.575073 
	C435.154907,334.919556 431.418365,333.071075 427.097107,336.618164 
	C425.994019,337.523590 423.365540,336.570679 421.185486,336.154541 
	C424.653748,334.250000 428.264862,332.274231 432.126007,331.184174 
	C435.989258,330.093506 439.057037,331.318573 439.073547,336.575073 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M416.591370,336.935852 
	C414.463989,338.700867 412.096375,340.746857 409.399292,342.167206 
	C406.992676,343.434631 404.222046,344.010681 401.218658,344.880035 
	C405.952789,342.227020 411.080292,339.585815 416.591370,336.935852 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M401.390320,366.047028 
	C402.282990,361.134827 405.759674,358.961151 412.742737,359.089294 
	C409.373230,361.429962 405.559998,363.720734 401.390320,366.047028 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M378.198944,380.423096 
	C378.142334,376.182739 380.924194,374.456604 385.010620,373.111664 
	C386.875183,372.723114 388.050568,372.513794 389.225922,372.304443 
	C385.629913,375.059601 382.033875,377.814789 378.198944,380.423096 
z"/>
<path fill="#068CC3" opacity="1.000000" stroke="none" 
	d="
M389.601807,351.820801 
	C388.373718,356.779236 386.067963,357.866577 382.001404,355.163879 
	C384.354523,353.797699 386.784393,352.812592 389.601807,351.820801 
z"/>
<path fill="#0E71AC" opacity="1.000000" stroke="none" 
	d="
M389.565430,372.254272 
	C388.050568,372.513794 386.875183,372.723114 385.336487,372.967468 
	C385.890625,367.656708 390.064240,368.952484 393.881287,368.949066 
	C392.789276,370.128937 391.347107,371.166504 389.565430,372.254272 
z"/>
<path fill="#0E71AC" opacity="1.000000" stroke="none" 
	d="
M321.946198,406.310303 
	C322.811035,406.295410 323.656555,406.605774 324.502045,406.916138 
	C323.876099,407.266846 323.250153,407.617523 322.270569,407.952423 
	C321.920227,407.502930 321.923523,407.069244 321.946198,406.310303 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M349.705017,409.075256 
	C349.281525,410.043274 348.508942,411.013031 347.405151,412.039673 
	C347.834625,411.090027 348.595276,410.083496 349.705017,409.075256 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M352.552216,406.890747 
	C352.422577,407.505005 351.986237,408.198395 351.198547,408.890137 
	C351.313324,408.248932 351.779419,407.609375 352.552216,406.890747 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M355.065002,405.693848 
	C354.601990,406.015198 354.155457,406.027802 353.374878,406.042603 
	C353.511078,405.399902 353.981384,404.754974 354.788269,404.131805 
	C355.110413,404.564087 355.095978,404.974579 355.065002,405.693848 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M368.312469,388.880432 
	C368.476624,388.043823 368.937897,387.086395 369.757263,386.117432 
	C369.613434,386.990479 369.111511,387.875061 368.312469,388.880432 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M315.045807,656.020447 
	C315.356537,656.819031 315.667267,657.617676 316.027008,659.066162 
	C317.011139,660.474182 317.946228,661.232178 318.881348,661.990234 
	C318.965576,662.669067 319.049835,663.347839 319.113525,664.722412 
	C319.719940,665.949036 320.346893,666.479858 320.973846,667.010681 
	C320.973846,667.010681 321.000580,666.997559 321.035400,667.354004 
	C322.043915,668.808594 323.017639,669.906738 323.991364,671.004822 
	C324.320679,671.470276 324.649963,671.935669 324.759979,672.874878 
	C325.014069,673.903992 325.487427,674.459412 325.960754,675.014832 
	C325.960754,675.014832 325.987183,675.002991 325.986633,675.021118 
	C327.296692,680.130737 328.008423,684.965271 331.761292,689.677246 
	C334.502655,693.119263 337.466675,695.620728 340.549652,698.475281 
	C342.854431,700.609192 343.898712,704.398315 344.814911,707.644897 
	C345.538910,710.210510 339.011871,713.828125 336.686523,711.952515 
	C333.764099,709.595276 331.542297,706.383789 328.922974,703.631531 
	C327.136749,701.754639 325.362183,699.783020 323.260620,698.314087 
	C321.276398,696.927246 318.871674,696.142029 316.286987,695.078308 
	C315.922028,695.064453 315.959442,695.038757 315.972321,694.625916 
	C315.762299,692.614258 316.038605,690.676758 315.225586,689.478210 
	C312.464935,685.408569 309.251740,681.649292 306.352600,677.668640 
	C305.115631,675.970154 304.102325,674.076599 303.204010,672.169617 
	C300.248016,665.894470 297.478943,659.528992 294.388397,653.322510 
	C293.988586,652.519592 292.123688,652.446289 290.937408,652.035034 
	C290.937408,652.035034 290.966949,652.025208 290.956909,652.045654 
	C289.928375,650.099670 288.909882,648.133118 287.571259,646.100586 
	C282.663483,636.356262 278.553436,626.520996 277.515656,615.312744 
	C278.437775,613.819458 278.935577,612.476196 279.899872,611.769348 
	C281.285950,610.753235 283.011688,610.200378 284.592407,609.449829 
	C284.759369,611.224243 285.199371,613.032104 284.987579,614.760132 
	C284.866577,615.747192 283.340607,616.508301 283.052399,617.540161 
	C282.495636,619.533264 281.385956,622.783936 282.231049,623.512939 
	C285.961517,626.731079 287.000092,630.943542 288.083557,635.289368 
	C288.129059,635.471924 288.651581,635.535522 289.568329,635.898926 
	C290.352631,634.742065 291.487244,633.576904 292.025696,632.181641 
	C293.845978,627.464966 295.454803,622.666687 297.144440,617.899597 
	C299.122131,622.688904 301.081512,627.485840 303.083862,632.264832 
	C304.448914,635.522827 305.812775,638.783997 307.288727,641.992371 
	C308.929871,645.559937 310.697632,649.069275 312.713470,653.161011 
	C313.693054,654.485474 314.369415,655.252991 315.045807,656.020447 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M293.600555,452.088684 
	C293.041962,456.050964 292.677246,460.375549 291.190521,464.272064 
	C288.978729,470.069031 285.419800,475.387146 285.916687,481.992096 
	C285.959442,482.560638 285.577667,483.298462 285.162842,483.742615 
	C281.495941,487.668823 282.025238,492.039551 283.391602,496.032776 
	C285.772308,496.300934 287.830627,496.915344 289.741089,496.608673 
	C290.942657,496.415771 292.799194,494.893524 292.858643,493.879486 
	C293.225677,487.622467 296.383942,483.311188 301.820007,480.089417 
	C301.136078,482.928284 299.903412,485.715759 299.066711,488.617462 
	C297.305573,494.725006 295.587189,500.854523 294.163055,507.046722 
	C292.916443,512.467102 292.141541,517.994629 291.047974,523.452759 
	C290.839722,524.492249 290.116486,525.428589 289.003357,526.756226 
	C287.584656,529.116516 286.392639,531.072510 286.077118,533.160889 
	C285.117554,539.512085 284.981689,546.019958 283.622070,552.270386 
	C281.884216,560.259521 279.950104,568.091187 282.695312,576.286743 
	C281.275299,576.286743 279.904419,575.989136 278.726776,576.340027 
	C274.414856,577.624695 272.566193,575.953186 273.312805,571.399231 
	C273.951202,567.505432 274.959442,563.564209 274.837646,559.674255 
	C274.486267,548.455200 273.680542,537.249207 272.962830,526.043945 
	C272.852234,524.317200 272.264343,522.621033 271.897186,520.910767 
	C274.317230,509.202332 276.395020,497.408051 279.299988,485.821198 
	C280.778687,479.923065 283.674774,474.368286 286.058380,468.712341 
	C288.384766,463.192261 290.874451,457.740997 293.600555,452.088684 
z"/>
<path fill="#175292" opacity="1.000000" stroke="none" 
	d="
M271.620178,521.181885 
	C272.264343,522.621033 272.852234,524.317200 272.962830,526.043945 
	C273.680542,537.249207 274.486267,548.455200 274.837646,559.674255 
	C274.959442,563.564209 273.951202,567.505432 273.312805,571.399231 
	C272.566193,575.953186 274.414856,577.624695 278.726776,576.340027 
	C279.904419,575.989136 281.275299,576.286743 282.695312,576.286743 
	C279.950104,568.091187 281.884216,560.259521 283.622070,552.270386 
	C284.981689,546.019958 285.117554,539.512085 286.077118,533.160889 
	C286.392639,531.072510 287.584656,529.116516 288.768951,527.027466 
	C289.155365,541.873169 288.881317,556.798523 289.275635,571.706116 
	C289.430176,577.548950 291.082825,583.352112 291.734680,589.610535 
	C290.725250,590.820251 289.323120,591.746338 289.451721,592.341919 
	C290.994598,599.487732 292.712921,606.598083 294.565338,613.670593 
	C294.726532,614.286072 296.161255,614.568054 297.004395,615.004883 
	C297.039825,615.729675 297.075256,616.454407 297.127563,617.539429 
	C295.454803,622.666687 293.845978,627.464966 292.025696,632.181641 
	C291.487244,633.576904 290.352631,634.742065 289.568329,635.898926 
	C288.651581,635.535522 288.129059,635.471924 288.083557,635.289368 
	C287.000092,630.943542 285.961517,626.731079 282.231049,623.512939 
	C281.385956,622.783936 282.495636,619.533264 283.052399,617.540161 
	C283.340607,616.508301 284.866577,615.747192 284.987579,614.760132 
	C285.199371,613.032104 284.759369,611.224243 284.592407,609.449829 
	C283.011688,610.200378 281.285950,610.753235 279.899872,611.769348 
	C278.935577,612.476196 278.437775,613.819458 277.387939,614.934448 
	C276.663086,613.271973 276.281799,611.553528 276.242554,609.377563 
	C278.587067,604.803040 278.103638,596.083923 275.464447,593.328552 
	C275.229950,594.805237 275.032349,596.049744 274.834717,597.294250 
	C273.539917,594.229980 271.354401,591.236023 271.105988,588.089172 
	C270.079498,575.084839 269.178253,562.035461 269.141388,549.000122 
	C269.115417,539.819885 270.556915,530.635498 271.620178,521.181885 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M274.825256,597.708252 
	C275.032349,596.049744 275.229950,594.805237 275.464447,593.328552 
	C278.103638,596.083923 278.587067,604.803040 276.206238,608.995605 
	C275.494965,607.216919 275.040283,605.374329 274.864410,603.505432 
	C274.696930,601.725220 274.820312,599.917725 274.825256,597.708252 
z"/>
<path fill="#D9EEF4" opacity="1.000000" stroke="none" 
	d="
M267.926941,520.690918 
	C265.403320,518.224365 264.601166,515.567688 268.059387,512.929688 
	C268.360840,515.413391 268.317780,517.945374 267.926941,520.690918 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M267.984070,509.249023 
	C268.917053,506.725739 269.948883,504.406372 271.285095,501.962158 
	C270.870178,504.454559 270.150879,507.071777 269.097290,509.849243 
	C268.449066,509.930573 268.222382,509.745056 267.984070,509.249023 
z"/>
<path fill="#A9CAE2" opacity="1.000000" stroke="none" 
	d="
M272.111694,499.792389 
	C271.885162,498.991882 271.966888,498.008118 272.332092,496.930603 
	C272.550354,497.760925 272.485138,498.685059 272.111694,499.792389 
z"/>
<path fill="#5D8BB2" opacity="1.000000" stroke="none" 
	d="
M351.069214,703.306946 
	C354.565430,704.767334 353.486481,707.505432 351.788177,709.434937 
	C348.104858,713.619751 344.133698,717.592529 339.957550,721.287292 
	C337.311340,723.628540 334.523773,723.643188 333.227264,719.188721 
	C340.616180,719.296875 350.484802,710.854980 351.069214,703.306946 
z"/>
<path fill="#5D8BB2" opacity="1.000000" stroke="none" 
	d="
M347.084351,699.269409 
	C347.760956,699.353943 348.403229,699.783020 349.076447,700.522583 
	C348.421600,700.426697 347.735809,700.020325 347.084351,699.269409 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M290.936890,652.442627 
	C292.123688,652.446289 293.988586,652.519592 294.388397,653.322510 
	C297.478943,659.528992 300.248016,665.894470 303.204010,672.169617 
	C304.102325,674.076599 305.115631,675.970154 306.352600,677.668640 
	C309.251740,681.649292 312.464935,685.408569 315.225586,689.478210 
	C316.038605,690.676758 315.762299,692.614258 315.971680,694.640930 
	C315.220947,694.741638 314.161072,694.622498 313.795624,694.055664 
	C309.323730,687.120300 304.887817,680.159485 300.583710,673.119019 
	C298.268280,669.331543 296.059753,665.458496 294.114380,661.472046 
	C292.777893,658.733337 291.976776,655.733337 290.936890,652.442627 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M380.783752,764.126709 
	C372.646973,766.196960 360.554199,760.687744 355.657043,754.291931 
	C350.056793,746.977844 350.287872,739.219543 352.113770,731.171082 
	C352.690186,728.630310 354.472565,725.977173 356.445770,724.200317 
	C371.853455,710.325989 386.357452,714.066772 396.606018,729.321655 
	C402.715027,738.414856 397.266846,755.354431 387.227417,760.892944 
	C385.235413,761.991821 383.202026,763.015747 380.783752,764.126709 
z"/>
<path fill="#F9F9F7" opacity="1.000000" stroke="none" 
	d="
M736.944702,609.746887 
	C735.685608,608.963318 734.448975,607.788208 733.066772,606.326782 
	C734.269775,607.145386 735.618469,608.250366 736.944702,609.746887 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M376.341309,526.877686 
	C388.653717,526.109497 403.879730,538.966919 401.112122,551.946777 
	C400.425262,555.168213 399.065704,558.385071 397.380707,561.227783 
	C393.044708,568.542969 379.384735,573.434692 371.506592,570.820740 
	C364.461090,568.482971 357.263306,557.748474 356.618042,550.512024 
	C355.767426,540.972656 363.893799,530.275879 371.370728,528.737793 
	C372.945374,528.413818 374.403229,527.522339 376.341309,526.877686 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M449.497437,431.906433 
	C448.655823,433.018463 447.552002,434.331085 446.220734,435.345123 
	C444.710571,436.495453 442.996796,437.378448 440.795410,438.678375 
	C438.472809,439.650299 436.726257,440.321503 434.724945,441.157867 
	C433.954376,442.200195 433.438599,443.077332 432.556213,443.962830 
	C427.126617,447.646027 422.063629,451.320862 416.648529,455.034302 
	C414.814026,455.663483 413.331635,456.254059 411.821716,456.469421 
	C411.687164,455.606140 411.580170,455.118011 411.551117,454.533386 
	C411.629028,454.436920 411.552460,454.200989 411.552460,454.200989 
	C412.473206,453.505341 413.393951,452.809692 414.635620,451.977112 
	C418.639557,449.182373 422.322540,446.524567 426.118469,443.554871 
	C430.866669,440.799011 435.464142,438.279419 440.150909,435.938660 
	C443.075806,434.477905 446.135559,433.287140 449.497437,431.906433 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M418.597382,480.037964 
	C419.020874,480.215363 419.077759,480.434662 419.166504,481.245789 
	C419.118011,482.215668 419.037628,482.593689 418.605133,482.990631 
	C416.831665,485.002441 415.410278,486.995331 413.988892,488.988220 
	C413.988892,488.988220 413.617096,489.141815 413.052551,489.141510 
	C410.720215,492.485229 408.952484,495.829254 407.184753,499.173309 
	C407.184753,499.173309 407.082123,499.572662 406.656738,499.799927 
	C405.226501,502.081299 404.221619,504.135406 403.216736,506.189484 
	C403.116364,506.562408 403.015991,506.935333 402.523621,507.667480 
	C401.708893,508.949188 401.286133,509.871674 400.863342,510.794159 
	C399.525421,510.740326 398.187469,510.686493 396.476562,510.471191 
	C395.192993,509.047943 394.282349,507.786194 393.179626,506.265686 
	C400.245300,504.384613 402.990631,498.708954 405.809143,492.624817 
	C408.349548,487.140991 411.947571,482.131042 418.597382,480.037964 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M392.955658,481.860748 
	C393.209106,486.090759 393.600128,490.319733 393.632233,494.551422 
	C393.641907,495.822968 392.714905,497.133148 392.105591,498.370758 
	C391.119507,500.373718 390.041260,502.331299 388.992126,504.688629 
	C382.502808,503.501648 381.448547,500.145691 385.223846,494.572449 
	C386.436279,492.782623 386.537659,490.240173 387.217224,487.660156 
	C388.213745,486.110107 389.140442,484.941986 390.042297,483.394165 
	C390.210083,482.743958 390.402710,482.473419 391.049744,482.046143 
	C391.987976,481.879852 392.471832,481.870300 392.955658,481.860748 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M383.013062,490.363647 
	C383.555054,489.823517 384.089813,489.631287 384.771454,489.585266 
	C384.523468,490.477783 384.128571,491.224121 383.396729,492.037567 
	C383.041809,491.640320 383.023804,491.175964 383.013062,490.363647 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M407.530243,499.112915 
	C408.952484,495.829254 410.720215,492.485229 412.859253,489.197205 
	C411.445648,492.519653 409.660706,495.786102 407.530243,499.112915 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M403.553589,506.109406 
	C404.221619,504.135406 405.226501,502.081299 406.593323,499.996124 
	C405.933624,501.986450 404.912018,504.007904 403.553589,506.109406 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M414.295624,488.907104 
	C415.410278,486.995331 416.831665,485.002441 418.623260,483.326416 
	C418.388947,484.808563 417.784363,485.973877 417.179810,487.139160 
	C417.179840,487.139191 417.043274,487.536194 416.981750,487.736694 
	C416.147583,488.233429 415.374969,488.529724 414.295624,488.907104 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M401.157837,510.789673 
	C401.286133,509.871674 401.708893,508.949188 402.440857,507.945099 
	C402.856659,508.074799 402.963287,508.286041 403.252075,508.802612 
	C403.367340,509.282410 403.300446,509.456848 403.160889,509.903320 
	C402.542969,510.378601 401.997650,510.581879 401.157837,510.789673 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M411.849243,456.844604 
	C413.331635,456.254059 414.814026,455.663483 416.646698,455.410461 
	C416.999207,456.500305 417.001434,457.252594 417.003693,458.004883 
	C413.688690,461.769012 410.373688,465.533142 407.021851,469.640228 
	C404.228210,471.632416 401.471466,473.281647 398.390106,474.611755 
	C398.326477,473.150269 398.587433,472.007904 398.848389,470.865570 
	C399.131927,470.695435 399.372650,470.477966 400.064819,470.053345 
	C401.597717,468.507538 402.636353,467.121460 403.674988,465.735382 
	C403.674988,465.735382 403.801483,465.343689 404.233826,465.137268 
	C405.005157,464.197937 405.344116,463.465057 405.683105,462.732178 
	C405.683105,462.732178 405.732544,462.523254 405.950623,462.495270 
	C406.421997,462.452850 406.587463,462.324921 406.959259,461.983948 
	C407.468658,461.545685 407.683838,461.206970 408.226440,460.792633 
	C409.652313,459.426208 410.750763,458.135406 411.849243,456.844604 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M398.520325,470.932892 
	C398.587433,472.007904 398.326477,473.150269 398.043091,474.648743 
	C396.433929,477.101166 394.847198,479.197479 393.108032,481.577271 
	C392.471832,481.870300 391.987976,481.879852 391.144653,481.813782 
	C391.518127,480.093048 392.251129,478.447876 393.060059,476.467590 
	C394.821381,474.421692 396.506805,472.710938 398.520325,470.932892 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M403.366333,465.830017 
	C402.636353,467.121460 401.597717,468.507538 400.193939,469.845642 
	C400.905090,468.506622 401.981384,467.215637 403.366333,465.830017 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M405.362549,462.726746 
	C405.344116,463.465057 405.005157,464.197937 404.318604,464.949738 
	C403.942444,464.512848 403.913788,464.057037 403.824219,463.262695 
	C404.189545,462.856598 404.615753,462.788971 405.362549,462.726746 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M411.821686,456.469421 
	C410.750763,458.135406 409.652313,459.426208 407.895630,460.785736 
	C406.797394,460.818115 406.357422,460.781769 405.917450,460.745422 
	C407.516266,458.789856 409.115082,456.834290 411.039062,454.716858 
	C411.364288,454.554932 411.473175,454.629883 411.473206,454.629883 
	C411.580170,455.118011 411.687164,455.606140 411.821686,456.469421 
z"/>
<path fill="#4A94B8" opacity="1.000000" stroke="none" 
	d="
M405.867065,461.151154 
	C406.357422,460.781769 406.797394,460.818115 407.568176,460.861359 
	C407.683838,461.206970 407.468658,461.545685 406.771301,462.087982 
	C406.289124,462.291595 405.861328,462.373352 405.861328,462.373352 
	C405.846466,462.101196 405.831573,461.829041 405.867065,461.151154 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M405.877838,462.371582 
	C405.861328,462.373352 406.289124,462.291595 406.477051,462.187561 
	C406.587463,462.324921 406.421997,462.452850 406.010498,462.406158 
	C405.852325,462.345032 405.894348,462.369843 405.877838,462.371582 
z"/>
<path fill="#EFF7F8" opacity="1.000000" stroke="none" 
	d="
M411.551086,454.533386 
	C411.473175,454.629883 411.364288,454.554932 411.328674,454.495789 
	C411.293060,454.436646 411.262482,454.364349 411.290771,454.336060 
	C411.319061,454.307739 411.438629,454.247620 411.495544,454.224304 
	C411.552460,454.200989 411.629028,454.436920 411.551086,454.533386 
z"/>
<path fill="#175292" opacity="1.000000" stroke="none" 
	d="
M486.795258,685.077576 
	C482.136444,687.323059 478.865295,683.676147 475.016418,682.379517 
	C473.035339,681.712097 470.885010,681.548950 468.812622,681.150269 
	C467.209167,680.841797 465.595947,680.570496 464.011688,680.182251 
	C463.384491,680.028564 462.840363,679.380310 462.230438,679.335022 
	C452.919952,678.644897 445.525909,673.019165 437.884430,668.798523 
	C432.746124,665.960449 425.748291,664.290649 423.335144,657.345642 
	C422.976440,656.313232 421.033813,655.799438 419.784241,655.118652 
	C418.827667,654.597473 417.641968,654.385315 416.845398,653.701233 
	C413.200989,650.571411 409.218750,647.687988 406.184998,644.030029 
	C402.017517,639.005127 398.596069,633.361511 394.938904,627.240112 
	C396.004700,626.353760 397.578796,625.747925 397.881012,626.135010 
	C403.983398,633.951416 413.867615,638.456177 417.527924,648.462280 
	C417.735748,649.030518 419.735016,649.236206 420.851440,649.123657 
	C424.077240,648.798706 426.586548,648.693787 427.364471,653.058533 
	C427.793335,655.464783 429.652191,657.978943 431.552277,659.676697 
	C434.538177,662.344727 437.941223,664.692078 441.520050,666.463318 
	C442.624695,667.009949 444.921753,665.316040 446.526245,664.393127 
	C446.668884,664.311096 445.930511,661.552185 445.250793,661.374084 
	C441.265564,660.329773 439.219269,657.957886 438.141418,653.989746 
	C437.687500,652.318542 435.684540,650.250977 434.035492,649.866760 
	C427.813690,648.416870 425.398193,642.719482 422.229523,638.667542 
	C416.701111,631.598083 406.636353,629.684753 403.122894,620.769714 
	C403.026123,620.524170 402.525818,620.467651 402.305298,620.235840 
	C401.168732,619.040894 400.114807,617.762756 398.928375,616.621399 
	C397.965057,615.694641 396.467651,614.018250 395.891022,614.266113 
	C394.692200,614.781128 393.700928,616.233398 393.081696,617.520447 
	C392.408539,618.919739 392.254456,620.568665 391.874023,622.108765 
	C388.661377,620.327332 385.969910,618.225769 387.167847,613.346680 
	C391.636292,606.443787 391.782867,606.430542 400.315552,611.753479 
	C400.891479,610.783630 401.465363,609.817322 402.039215,608.850952 
	C403.115234,609.617737 404.571198,610.153076 405.203278,611.190369 
	C408.399597,616.435852 410.435883,622.768982 414.647491,626.931091 
	C422.060699,634.257202 430.774963,640.262939 438.900146,646.875854 
	C440.406494,648.101807 441.477448,650.038940 443.138550,650.867249 
	C449.479553,654.029358 456.310852,656.327087 462.329865,659.983398 
	C468.729187,663.870728 475.825806,664.756226 482.733490,666.011169 
	C490.916687,667.497742 499.359375,667.598633 507.699371,668.152832 
	C510.170410,668.317017 512.686584,667.777039 515.160889,667.921997 
	C520.425781,668.230347 525.143188,667.027954 529.986206,664.780273 
	C533.417480,663.187683 537.920593,662.487854 541.558838,663.245300 
	C544.071838,663.768494 546.281372,667.227600 547.962219,669.807556 
	C549.337769,671.918884 549.731873,674.669678 550.779663,677.787415 
	C541.217407,680.513855 531.541321,683.272766 521.191772,685.762451 
	C509.277283,685.354797 498.036285,685.216187 486.795258,685.077576 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M391.892303,622.480103 
	C392.254456,620.568665 392.408539,618.919739 393.081696,617.520447 
	C393.700928,616.233398 394.692200,614.781128 395.891022,614.266113 
	C396.467651,614.018250 397.965057,615.694641 398.928375,616.621399 
	C400.114807,617.762756 401.168732,619.040894 402.305298,620.235840 
	C402.525818,620.467651 403.026123,620.524170 403.122894,620.769714 
	C406.636353,629.684753 416.701111,631.598083 422.229523,638.667542 
	C425.398193,642.719482 427.813690,648.416870 434.035492,649.866760 
	C435.684540,650.250977 437.687500,652.318542 438.141418,653.989746 
	C439.219269,657.957886 441.265564,660.329773 445.250793,661.374084 
	C445.930511,661.552185 446.668884,664.311096 446.526245,664.393127 
	C444.921753,665.316040 442.624695,667.009949 441.520050,666.463318 
	C437.941223,664.692078 434.538177,662.344727 431.552277,659.676697 
	C429.652191,657.978943 427.793335,655.464783 427.364471,653.058533 
	C426.586548,648.693787 424.077240,648.798706 420.851440,649.123657 
	C419.735016,649.236206 417.735748,649.030518 417.527924,648.462280 
	C413.867615,638.456177 403.983398,633.951416 397.881012,626.135010 
	C397.578796,625.747925 396.004700,626.353760 394.936676,626.859131 
	C393.870514,625.764404 392.890533,624.307922 391.892303,622.480103 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M401.880615,608.499268 
	C401.465363,609.817322 400.891479,610.783630 400.315552,611.753479 
	C391.782867,606.430542 391.636292,606.443787 387.145264,612.942261 
	C385.827209,612.038147 384.848572,610.955017 384.230591,609.399292 
	C386.881592,605.097900 386.193481,603.055176 381.910858,600.969849 
	C381.910858,600.969849 381.875031,600.581482 382.040100,600.063477 
	C383.131317,598.305847 384.057465,597.066223 385.132751,595.626892 
	C383.507660,594.559753 382.220245,593.714294 380.932800,592.868896 
	C383.649445,592.208801 386.366119,591.548706 389.590546,591.269592 
	C392.405426,592.108704 394.712524,592.566833 397.019592,593.024902 
	C397.140564,594.145447 396.835175,595.827087 397.451599,596.295410 
	C401.510254,599.378784 400.728882,604.059814 401.880615,608.499268 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M486.928528,685.469360 
	C498.036285,685.216187 509.277283,685.354797 520.816040,685.811768 
	C517.390015,686.443420 513.670532,686.943237 509.941650,687.027954 
	C503.826996,687.166870 497.704498,687.109924 491.589264,686.966614 
	C490.072296,686.931091 488.570557,686.247070 486.928528,685.469360 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M380.564697,592.937134 
	C382.220245,593.714294 383.507660,594.559753 385.132751,595.626892 
	C384.057465,597.066223 383.131317,598.305847 382.050781,599.868774 
	C377.231476,598.578308 376.678436,596.240051 380.564697,592.937134 
z"/>
<path fill="#175292" opacity="1.000000" stroke="none" 
	d="
M381.903198,601.383789 
	C386.193481,603.055176 386.881592,605.097900 384.211365,609.016602 
	C383.186127,606.670288 382.540833,604.234009 381.903198,601.383789 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M397.030762,592.641235 
	C394.712524,592.566833 392.405426,592.108704 390.000824,591.254761 
	C392.560181,589.909668 395.691986,586.536377 397.030762,592.641235 
z"/>
<path fill="#5FA8C8" opacity="1.000000" stroke="none" 
	d="
M676.069214,431.288208 
	C677.700317,432.732605 679.315430,434.531250 680.909790,436.687073 
	C679.277039,435.243652 677.665161,433.443024 676.069214,431.288208 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M706.654907,608.826660 
	C706.068054,608.693909 705.528320,608.236084 704.936890,607.498962 
	C705.490784,607.646973 706.096436,608.074341 706.654907,608.826660 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M721.043945,614.639526 
	C720.615417,614.947327 720.118835,614.978394 719.250732,614.959229 
	C719.578125,614.726990 720.276978,614.544922 721.043945,614.639526 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M677.835022,578.749695 
	C677.227600,578.604553 676.626038,578.138184 675.998047,577.359680 
	C676.594727,577.507812 677.217834,577.968140 677.835022,578.749695 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M729.277222,612.249329 
	C729.711121,611.895081 730.078674,611.924744 730.724365,611.975952 
	C730.677246,612.653992 730.352051,613.310425 729.760132,614.059814 
	C729.399292,613.646240 729.305115,613.139771 729.277222,612.249329 
z"/>
<path fill="#414141" opacity="1.000000" stroke="none" 
	d="
M681.816711,582.842285 
	C681.621033,582.989258 681.371033,582.851868 681.061768,582.441040 
	C681.272034,582.260010 681.525269,582.390198 681.816711,582.842285 
z"/>
<path fill="#8ECCE3" opacity="1.000000" stroke="none" 
	d="
M417.530090,487.130127 
	C417.784363,485.973877 418.388947,484.808563 418.975372,483.307495 
	C419.037628,482.593689 419.118011,482.215668 419.287262,481.563416 
	C422.147736,479.048431 424.910767,476.796997 427.694336,474.571198 
	C429.199921,473.367249 430.740448,472.207062 432.619385,471.002625 
	C432.990448,471.426453 433.007385,471.874817 433.010437,472.659943 
	C428.747223,477.451355 424.497864,481.905975 420.117523,486.671112 
	C419.284485,487.028137 418.582397,487.074646 417.530090,487.130127 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M305.193573,473.698151 
	C300.125580,472.346741 297.197083,466.348114 299.302582,461.939148 
	C299.704468,461.097565 301.391052,459.954620 301.830841,460.187286 
	C306.307587,462.555298 308.063721,458.867859 310.477661,456.579926 
	C311.058258,456.029572 311.749115,455.595551 312.747070,455.072876 
	C310.521667,461.144989 307.938446,467.252197 305.193573,473.698151 
z"/>
<path fill="#1A4D90" opacity="1.000000" stroke="none" 
	d="
M297.023621,614.592773 
	C296.161255,614.568054 294.726532,614.286072 294.565338,613.670593 
	C292.712921,606.598083 290.994598,599.487732 289.451721,592.341919 
	C289.323120,591.746338 290.725250,590.820251 291.791504,589.997192 
	C292.622925,592.313782 293.060150,594.684692 293.538879,597.047180 
	C294.696564,602.760376 295.873627,608.469727 297.023621,614.592773 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M318.328552,446.819702 
	C315.838837,441.467438 316.793182,439.450073 323.656982,436.016388 
	C322.218719,439.538391 320.406616,443.062134 318.328552,446.819702 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M323.964478,670.657532 
	C323.017639,669.906738 322.043915,668.808594 321.029663,667.367737 
	C321.971954,668.120117 322.954773,669.215149 323.964478,670.657532 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M318.876221,661.654053 
	C317.946228,661.232178 317.011139,660.474182 316.031006,659.390076 
	C316.947632,659.815308 317.909363,660.566589 318.876221,661.654053 
z"/>
<path fill="#15639E" opacity="1.000000" stroke="none" 
	d="
M314.341492,451.874023 
	C312.222778,450.121765 313.041290,449.277435 315.686249,449.027283 
	C315.560181,449.917145 315.072052,450.804840 314.341492,451.874023 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M315.023804,655.692993 
	C314.369415,655.252991 313.693054,654.485474 313.007721,653.362061 
	C313.666443,653.792603 314.334106,654.579102 315.023804,655.692993 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M325.960815,674.712036 
	C325.487427,674.459412 325.014069,673.903992 324.763092,673.176270 
	C325.310577,673.472351 325.635712,673.940735 325.960815,674.712036 
z"/>
<path fill="#1C4386" opacity="1.000000" stroke="none" 
	d="
M320.940491,666.687744 
	C320.346893,666.479858 319.719940,665.949036 319.092041,665.064453 
	C319.696442,665.262085 320.301819,665.813416 320.940491,666.687744 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M469.660706,446.060791 
	C468.926941,447.048065 467.830017,448.012878 466.373535,448.962830 
	C467.108459,447.978088 468.203003,447.008209 469.660706,446.060791 
z"/>
<path fill="#218FB9" opacity="1.000000" stroke="none" 
	d="
M476.811768,444.774292 
	C476.503876,445.305145 476.036194,445.610596 475.271393,445.901184 
	C475.533478,445.440521 476.092712,444.994690 476.811768,444.774292 
z"/>
<path fill="#068CC3" opacity="1.000000" stroke="none" 
	d="
M586.627563,348.922760 
	C587.075806,352.932770 585.188293,355.380554 581.526978,356.755890 
	C577.288574,358.348022 574.268494,360.821503 573.879150,365.982971 
	C573.526123,370.661957 568.639587,373.740204 564.061829,373.262085 
	C562.355530,373.083862 560.556824,373.811981 558.797668,374.111664 
	C556.438049,374.513611 554.075867,374.900818 551.564453,374.409882 
	C551.299805,374.599915 550.812195,374.779419 550.802673,374.981476 
	C550.479187,381.853241 545.939941,382.454590 540.554138,382.052460 
	C537.908569,381.854919 535.235840,382.036713 532.575745,382.014099 
	C525.912781,381.957520 525.573364,381.594025 525.939636,375.058655 
	C526.181519,370.741577 522.559570,368.585388 516.351990,369.602509 
	C516.491577,371.126434 516.633484,372.675476 516.803833,374.535339 
	C510.075531,375.338379 505.230865,372.560547 501.758759,367.318420 
	C499.817535,364.387573 498.557709,361.012512 496.733398,357.993164 
	C496.139618,357.010498 494.630951,356.628754 493.713623,355.784393 
	C491.022369,353.307159 488.054657,351.008789 485.884064,348.122345 
	C483.804352,345.356659 482.587585,341.942078 480.993317,338.403900 
	C482.402039,336.178772 484.021973,334.486969 485.171997,332.520416 
	C487.647614,328.287109 489.769379,328.087128 492.832031,332.312927 
	C492.274628,332.810150 491.737122,333.388977 491.102661,333.827484 
	C490.477203,334.259766 489.753540,334.549957 488.993225,334.943817 
	C494.408295,340.922241 496.613312,341.196411 502.772949,338.234283 
	C506.696838,336.347290 510.800873,335.492279 514.397644,339.702850 
	C516.383667,342.027802 518.919678,342.978607 521.237976,339.315552 
	C522.019592,338.080566 524.191040,337.214020 525.794067,337.097382 
	C533.222961,336.556824 540.674011,336.303680 548.119934,336.022125 
	C551.336609,335.900452 554.148865,336.263245 554.141418,340.681702 
	C554.139587,341.754120 555.766357,343.778534 556.457031,343.700806 
	C557.800415,343.549744 559.241333,342.418823 560.197632,341.310455 
	C560.858887,340.544098 560.484009,338.933960 561.088989,338.057831 
	C562.112549,336.575562 563.434814,334.495636 564.838562,334.319763 
	C566.089111,334.163086 568.218506,335.953064 568.919678,337.391174 
	C571.428101,342.536041 574.283081,343.270996 578.979126,339.690918 
	C580.307617,338.678131 582.000854,338.143768 583.526794,337.389954 
	C584.018677,339.212219 584.557129,341.023499 584.989807,342.859741 
	C585.455994,344.838470 585.822937,346.840546 586.627563,348.922760 
z"/>
<path fill="#068CC3" opacity="1.000000" stroke="none" 
	d="
M590.972534,343.716949 
	C590.563171,344.657867 590.109192,345.293762 589.337036,345.974548 
	C589.093079,344.944855 589.167297,343.870239 589.241516,342.795654 
	C589.803650,343.001068 590.365845,343.206482 590.972534,343.716949 
z"/>
<path fill="#0E71AC" opacity="1.000000" stroke="none" 
	d="
M417.370911,458.000275 
	C417.001434,457.252594 416.999207,456.500305 416.998810,455.371826 
	C422.063629,451.320862 427.126617,447.646027 432.885254,443.957642 
	C434.386749,443.628937 435.192566,443.313843 435.999207,442.999359 
	C436.000000,443.000000 436.001892,443.001892 436.001892,443.001892 
	C435.833893,446.161438 439.039948,449.739868 434.464996,452.623901 
	C429.631561,449.043121 426.059631,452.728302 422.206757,455.295959 
	C420.760071,456.260101 419.230164,457.099457 417.370911,458.000275 
z"/>
<path fill="#0E71AC" opacity="1.000000" stroke="none" 
	d="
M434.979736,440.992706 
	C436.726257,440.321503 438.472809,439.650299 440.592468,438.950073 
	C441.511108,442.230591 439.975403,443.430725 436.416443,443.001099 
	C436.001892,443.001892 436.000000,443.000000 435.979218,442.699219 
	C435.632202,441.929871 435.305969,441.461304 434.979736,440.992706 
z"/>
<path fill="#107CB2" opacity="1.000000" stroke="none" 
	d="
M434.724945,441.157867 
	C435.305969,441.461304 435.632202,441.929871 435.978394,442.698608 
	C435.192566,443.313843 434.386749,443.628937 433.251892,443.949280 
	C433.438599,443.077332 433.954376,442.200195 434.724945,441.157867 
z"/>

         
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}